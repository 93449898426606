import { Horse as HorseIcon } from '@phosphor-icons/react';
import useHorseDetail from 'hooks/UseHorseDetail';
import usePermissions from 'hooks/UsePermissions';
import { ModulePermissionsEnum } from 'openapi';
import React from 'react';
import AvatarTile from 'ui/Layout/Tile/AvatarTile';

interface Props {
  className?: string;
  onAvatarEditRequest: (imageData: ArrayBuffer, mimeType: string) => Promise<void>;
}

function HorseAvatarForm({ className, onAvatarEditRequest }: Props): JSX.Element {
  const { horse } = useHorseDetail();
  const { hasPermission } = usePermissions();

  const avatarUrl = horse?.avatar_file ?? undefined;

  return (
    <AvatarTile
      canUpdate={hasPermission(ModulePermissionsEnum.MANAGE_HORSES)}
      onAvatarComplete={onAvatarEditRequest}
      avatarUrl={avatarUrl}
      className={className}
      icon={<HorseIcon />}
    />
  );
}

export default HorseAvatarForm;
