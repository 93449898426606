import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { CancelablePromise, Contact, Horse, NIFAStrawColorEnum, StallionMount, StallionmountsService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, SelectInput, TextInput } from 'ui/Inputs';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { totalMotileSpermCount } from 'utilities/Breeding';
import { formatDate } from 'utilities/date.utilities';
import { CalendarActivity } from 'utilities/Planning';
import { transformEmptyNumber, transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  skippable?: boolean;
  calendarActivity?: CalendarActivity;
  stallions: Horse[];
  contacts: Contact[];
  selectedStallion?: Horse;
  existingMount?: StallionMount;
  open: boolean;
  date?: Date;
  onSaved: (reload: boolean, isSkipped: boolean, mount?: StallionMount) => void;
  onRequestClose: () => void;
}

function SaveStallionMount({
  skippable,
  calendarActivity,
  stallions,
  contacts,
  selectedStallion,
  existingMount,
  open,
  date,
  onRequestClose,
  onSaved,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const [hasStrawsFrozen, setHasStrawsFrozen] = useState<boolean>(false);
  const { formatNumber, formatDate: formatDatePretty } = useAccount();

  const suggestedDate = date ?? new Date();

  // Skip the stallion input (fixed value) when we have a stallion selected
  // and the stallion list is empty.
  const fixedStallion = useMemo(() => {
    return selectedStallion && stallions.length === 0;
  }, [stallions, selectedStallion]);

  const schema = useMemo(() => {
    return schemas.StallionMount.omit({
      uid: true,
      portions_fresh_available: true,
      portions_fresh_destroyed_amount: true,
      portions_fresh_destroyed_on: true,
      straws_frozen_available: true,
      straws_frozen_destroyed_amount: true,
      straws_frozen_destroyed_on: true,
      storage_container: !hasStrawsFrozen ? true : undefined,
      storage_canister: !hasStrawsFrozen ? true : undefined,
      NIFA_straw_color: !hasStrawsFrozen ? true : undefined,
      activity_uid: true,
      created_on: true,
      created_by: true,
      last_modified_on: true,
      last_modified_by: true,
      stallion_uid: fixedStallion ? true : undefined,
      collection_date: date ? true : undefined,
    })
      .refine(data => data.portions_fresh !== undefined, {
        path: ['portions_fresh'],
        message: t('err-zero-or-more-portions', 'Provide 0 or more portions'),
      })
      .refine(data => data.straws_frozen !== undefined, {
        path: ['straws_frozen'],
        message: t('err-zero-or-more-straws', 'Provide 0 or more straws'),
      })
      .refine(data => data.storage_container !== undefined || !hasStrawsFrozen, {
        path: ['storage_container'],
        message: t('field-required', 'This field is required'),
      })
      .refine(data => data.storage_canister !== undefined || !hasStrawsFrozen, {
        path: ['storage_canister'],
        message: t('field-required', 'This field is required'),
      })
      .refine(data => data.NIFA_straw_color !== undefined || !hasStrawsFrozen, {
        path: ['NIFA_straw_color'],
        message: t('field-required', 'This field is required'),
      });
  }, [hasStrawsFrozen, t, fixedStallion, date]);

  const defaultValues = (): Partial<StallionMount> => {
    if (existingMount) {
      return existingMount;
    } else {
      return {
        stallion_uid: selectedStallion?.uid,
        collection_date: formatDate(suggestedDate),
        semen_collection_station: selectedStallion?.default_semen_collection_station,
      };
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    control,
    reset,
  } = useForm<StallionMount>({
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: defaultValues(),
  });

  // We show and hide the storage fields based upon straws_frozen > 0.
  const watchStrawsFrozen = watch('straws_frozen');

  const watchConcentration = watch('concentration');
  const watchVolume = watch('volume');
  const watchMovingCellsPercentage = watch('moving_cells_percentage');
  const watchMorphology = watch('morphology');
  const watchStallion = watch('stallion_uid');

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: StallionMount) => {
    if (!selectedOrganization) {
      console.error('Cannot load invoice without a selected organization');
      return;
    }

    // We have a fixed stallion.
    if (fixedStallion) {
      data.stallion_uid = selectedStallion?.uid ?? '';
    }

    // We have a fixed date.
    if (date) {
      data.collection_date = formatDate(date);
    }

    // Set the activity uid
    if (calendarActivity) {
      data.activity_uid = calendarActivity.uid;
    }

    let promise: CancelablePromise<StallionMount>;
    if (existingMount) {
      // Unset the activity_uid, server doesn't like it eventhough it's unchanged.
      data.activity_uid = undefined;
      promise = StallionmountsService.stallionmountsPartialUpdate({
        stallionOrganisationUid: selectedOrganization.uid,
        uid: existingMount.uid,
        requestBody: data,
      });
    } else {
      promise = StallionmountsService.stallionmountsCreate({
        stallionOrganisationUid: selectedOrganization.uid,
        requestBody: data,
      });
    }

    try {
      onSaved(true, false, await promise);
      onRequestClose();
    } catch (e) {
      setApiError(new ApiErrorParser<StallionMount>(e));
    }
  };

  // Event that will be fired after the modal has been closed
  const resetForm = () => {
    setApiError(undefined);
    setHasStrawsFrozen(false);
    reset(defaultValues());
  };

  // Get the niwa straw color translated.
  const nifaStrawColorOptions = useMemo(() => {
    const options: OptionItemInterface[] = [];
    Object.keys(NIFAStrawColorEnum).forEach(key => {
      options.push({ id: key, name: key.toString().replaceAll('_', ' ') });
    });
    return options;
  }, []);

  const stallionOptions = useMemo(() => {
    // Add the non hidden stallions. But also when we're editing a mount, then
    // add the stallion of that mount to.
    return stallions
      .filter(horse => !horse.hidden || horse.uid === existingMount?.stallion_uid)
      .map(horse => {
        return { id: horse.uid, name: horse.name };
      });
  }, [stallions, existingMount]);

  /*
   * Calculate the Total Motile Sperm Count in millions.
   */
  const tmsc = useMemo(() => {
    const result = totalMotileSpermCount(watchConcentration, watchVolume, watchMovingCellsPercentage, watchMorphology);
    if (!result) {
      return '-';
    }
    if (result === 0) {
      return t('no motile sperm', 'No motile sperm');
    }
    return t('million-tmsc', '{{tmsc}} million', { tmsc: formatNumber(result) });
  }, [t, formatNumber, watchConcentration, watchVolume, watchMovingCellsPercentage, watchMorphology]);

  const modalActions = useMemo((): ActionProps[] => {
    const array: ActionProps[] = [];

    if (skippable) {
      array.push({
        variant: ButtonVariant.Default,
        text: t('skip', 'Skip'),
        onClick: () => {
          onSaved(false, true);
          onRequestClose();
        },
      });
    }

    array.push({
      variant: ButtonVariant.Primary,
      text: t('save', 'Save'),
      type: 'submit',
      formId: 'addStallionMount',
      loading: isSubmitting,
    });

    return array;
  }, [t, isSubmitting, skippable, onRequestClose, onSaved]);

  // Update the hasStrawsFrozen boolean based on the frozen straw count.
  useEffect(() => {
    setHasStrawsFrozen(watchStrawsFrozen !== undefined && watchStrawsFrozen > 0);
  }, [watchStrawsFrozen]);

  // Preselect the semen collection station based on the stallion preference.
  useEffect(() => {
    const stallionUid = String(fixedStallion ?? watchStallion);
    if (stallionUid && !existingMount && stallions.length > 0) {
      const stallion = stallions.find(stallion => stallion.uid === stallionUid);
      if (stallion) {
        setValue('semen_collection_station', stallion?.default_semen_collection_station ?? undefined);
      }
    }
  }, [watchStallion, existingMount, setValue, stallions, fixedStallion]);

  return (
    <PageModal
      open={open}
      onClosed={resetForm}
      parentElement='form'
      parentProps={{ id: 'addStallionMount', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
    >
      <PageModalTitle title={t('add-mount', 'New semen collection')} onClose={onRequestClose} />

      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        <div className='flex flex-col md:flex-row gap-4 mb-4'>
          <div className='flex flex-col gap-4 grow'>
            {fixedStallion && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('stallion', 'Stallion')}</p>
                <p>{selectedStallion?.name}</p>
              </div>
            )}
            {!fixedStallion && (
              <SelectInput
                label={t('stallion', 'Stallion')}
                nullable={true}
                required={true}
                options={stallionOptions}
                error={fieldError('stallion_uid')}
                {...register('stallion_uid', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
              />
            )}
            {!date && (
              <DateInput
                control={control}
                required={true}
                label={t('semen-collection-date', 'Collection date')}
                name='collection_date'
                error={fieldError('collection_date')}
              />
            )}
            {date && (
              <div>
                <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('semen-collection-date', 'Collection date')}</p>
                <p>{formatDatePretty(date)}</p>
              </div>
            )}
            <ContactInputSelect
              name='semen_collection_station'
              control={control}
              contacts={contacts ?? []}
              label={t('semen-collection-station', 'Semen collection station')}
              error={fieldError('semen_collection_station')}
            />
            <TextInput
              label={t('semen-volume-ml', 'Volume (ml)')}
              {...register('volume', { setValueAs: transformEmptyNumber(undefined) })}
              postText={t('ml', 'ml')}
              error={fieldError('volume')}
            />
            <TextInput
              label={t('semen-concentration-million-per-ml', 'Concentration (million/ml)')}
              {...register('concentration', { setValueAs: transformEmptyNumber(undefined) })}
              postText={t('million-per-ml', 'million/ml')}
              error={fieldError('concentration')}
            />
            <TextInput
              label={t('moving-cells-percentage', 'Moving cells (percentage)')}
              {...register('moving_cells_percentage', { setValueAs: transformEmptyNumber(undefined) })}
              postText={'%'}
              error={fieldError('moving_cells_percentage')}
            />
            <TextInput
              label={t('morphology-percentage', 'Morphology (percentage)')}
              {...register('morphology', { setValueAs: transformEmptyNumber(undefined) })}
              postText={'%'}
              error={fieldError('morphology')}
            />
            {tmsc && (
              <div>
                <label className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
                  {t('total-motile-sperm-count', 'TMSC (Total Motile Sperm Count)')}
                </label>
                <p>{tmsc}</p>
              </div>
            )}
          </div>
          <div className='flex flex-col gap-4 grow'>
            <TextInput
              required={true}
              label={t('semen-portions-fresh', 'Portions fresh')}
              postText={'portions'}
              {...register('portions_fresh', { setValueAs: transformEmptyNumber(undefined) })}
              error={fieldError('portions_fresh')}
            />
            <TextInput
              required={true}
              label={t('semen-straws-frozen', 'Straws frozen')}
              postText={'straws'}
              {...register('straws_frozen', { setValueAs: transformEmptyNumber(undefined) })}
              error={fieldError('straws_frozen')}
            />
            {hasStrawsFrozen && (
              <>
                <TextInput
                  required={true}
                  label={t('semen-storage-container', 'Storage container')}
                  {...register('storage_container', { setValueAs: transformEmptyToUndefined() })}
                  error={fieldError('storage_container')}
                />
                <TextInput
                  required={true}
                  label={t('semen-storage-canister', 'Storage canister')}
                  {...register('storage_canister', { setValueAs: transformEmptyToUndefined() })}
                  error={fieldError('storage_canister')}
                />
                <SelectInput
                  required={true}
                  nullable={true}
                  options={nifaStrawColorOptions}
                  error={fieldError('NIFA_straw_color')}
                  label={t('semen-niva-straw-color', 'NIFA straw color')}
                  {...register('NIFA_straw_color', { setValueAs: (val: unknown): unknown => (val === '----' ? undefined : val) })}
                />
              </>
            )}
          </div>
        </div>
      </PageModalContent>
      <PageModalActions actions={modalActions} />
    </PageModal>
  );
}
export default SaveStallionMount;
