import useApiPromises from 'api/hooks/useApiPromises';
import classNames from 'classnames';
import { usePlanning } from 'hooks/UsePlanning';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import { ActionModal } from 'ui/Modals';
import useModal from 'ui/Modals/UseModal';

interface Props {
  className?: string;
  children: React.ReactNode;
}

// A wrapper that show a loader on the right bottom of the screen and an alert message box when the api data failed to load.
export default function PlanningLoadWrapper({ className, children }: Props): JSX.Element {
  const { apiPromises } = usePlanning();
  const { loading, errorString } = useApiPromises({ apiPromises, catchErrors: true });
  const { t } = useTranslation();
  const { showModal, closeModal, modalIsVisible } = useModal();

  const actions = useMemo(() => {
    return [
      {
        onClick: closeModal,
        variant: ButtonVariant.Primary,
        type: 'button',
        text: t('close', 'Close'),
      },
    ];
  }, [closeModal, t]);

  useEffect(() => {
    if (errorString) {
      showModal();
    }
  }, [errorString, showModal]);

  // When the error is resolved 'by auto refresh', then close the error modal.
  useEffect(() => {
    if (!errorString) {
      closeModal();
    }
  }, [errorString, closeModal]);

  return (
    <div className={classNames(className, 'relative')}>
      {children}
      {loading && (
        <div className='absolute top-44 z-50 left-0 right-0 flex justify-center pointer-events-none select-none'>
          <div className='flex flex-row bg-black bg-opacity-40 items-center gap-1 rounded p-2 text-white'>
            <Spinner size={SpinnerSize.XSmall} />
            <p className='text-sm'>{t('loading', 'Loading')}</p>
          </div>
        </div>
      )}
      <ActionModal actions={actions} open={modalIsVisible} title={t('failed-to-load-api-activities-data-header', 'Failed to load')}>
        <p>{errorString}</p>
      </ActionModal>
    </div>
  );
}
