import { IS_MOBILE_APP } from 'const';
import { OpenAPI } from 'openapi';

declare const window: Window &
  typeof globalThis & {
    _env_: {
      [id: string]: string;
    };
  };

/**
 * Because we cannot load the process.env var in production we added a file with
 * window._env_ object that holds all env vars. For production, we should load
 * the data from there and for development from process.env.
 */
export const getEnvVar = (varName: string): string | undefined => {
  if (process.env && process.env[varName]) {
    return process.env[varName];
  }

  if (window._env_ && window._env_[varName]) {
    return window._env_[varName];
  }

  console.warn(`Try to load a non-existing environment variable "${varName}"`);
  return undefined;
};

/**
 * Check whether an env var is true
 */
export const envVarIsTrue = (value: string): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  return value === 'true' || value === 'True';
};

/*
 * A class for getting the environment vars is a fashionable manner.
 */
export class Config {
  static getConfigFromEnv(): Config {
    // eslint-disable-next-line
    // @ts-ignore
    const host = `${EQUINEM_API_HOST}`;

    // eslint-disable-next-line
    // @ts-ignore
    const isSSL = `${EQUINEM_API_HOST_USE_SSL}`;

    if (host === undefined || host === '') {
      throw Error('ENV Variable "EQUINEM_API_HOST" should be set');
    }

    if (isSSL === undefined || isSSL === '') {
      throw Error('ENV Variable "SSL" should be set');
    }

    // This is the host of the frontend. This is used for the serverless functions.
    // In our MOBILE app we used this to refer to the serverless functions. But
    // on the WEB we can refer to the same host.
    // eslint-disable-next-line
    // @ts-ignore
    const frontendHost: string | undefined = MOBILE_APP_EQUINEM_FRONTEND_HOST;

    // eslint-disable-next-line
    // @ts-ignore
    const isDevelopment = `${NODE_ENV}` === 'development';

    // This means that this code is running at https://vercel.com at the moment.
    // We need to know this because vercel supports serverless functions, localhost
    // doesn't.
    // eslint-disable-next-line
    // @ts-ignore
    const isHostedAtVercel = `${VERCEL}` === '1';

    // The optional host name that is used for the webshop.
    // eslint-disable-next-line
    // @ts-ignore
    const webshopHost = `${EQUINEM_WEBSHOP_HOST}`;

    // The Equinem identifier at Microsoft. For single sign-on.
    // eslint-disable-next-line
    // @ts-ignore
    const microsoftEntraClientId = `${MICROSOFT_ENTRA_CLIENT_ID}`;

    return new Config(
      host,
      frontendHost,
      envVarIsTrue(isSSL),
      isDevelopment,
      isHostedAtVercel,
      webshopHost.length > 0 ? webshopHost : undefined,
      microsoftEntraClientId.length > 0 ? microsoftEntraClientId : undefined,
      IS_MOBILE_APP,
    );
  }

  public readonly apiUrl: string;
  public readonly frontendUrl: string | undefined;

  constructor(
    apiHost: string,
    frontendHost: string | undefined,
    ssl: boolean,
    public readonly isDevelopmentEnv: boolean,
    public readonly isHostedAtVercel: boolean,
    public readonly webshopHost: string | undefined,
    private microsoftEntraClientId: string | undefined,
    private readonly isMobileApp: boolean,
  ) {
    const httpProtocol = ssl ? 'https://' : 'http://';
    // this.apiUrl = isDevelopmentEnv ? `http://${location.host}/api` : `${httpProtocol}${host}`;
    this.apiUrl = `${httpProtocol}${apiHost}`;

    // The OpenAPI generated services need a base url to work with. This url is
    // in the config.
    OpenAPI.BASE = this.apiUrl;

    // Define the frontend url, we used this for the serverless functions
    // When we are running in a mobile app this should be set so we can
    // load the serverless functions from the frontend host. Otherwise we
    // can refer to the same host.
    this.frontendUrl = frontendHost ? `https://${frontendHost}` : undefined;
  }

  /**
   * Returns true if we can execute serverless functions at https://host/api/*
   * or if we're running in a mobile app and we can run serverless functions at https://frontendHost/api/*
   */
  public get hasServerlessFunctions(): boolean {
    return this.isHostedAtVercel || (this.frontendUrl !== undefined && this.isMobileApp);
  }

  /**
   * Retrun the url for the serverless functions. This is the same as the frontend or empty to
   * refer to the same host.
   */
  public get serverlessFunctionsUrl(): string {
    return this.isHostedAtVercel ? '' : String(this.frontendUrl);
  }

  // True when we can do vercel image optimization
  public get hasImageOptimizationAvailable(): boolean {
    return this.isHostedAtVercel;
  }

  // Returns true if we're at the webshop hostname.
  public get atWebshopHostname(): boolean {
    if (!this.webshopHost) {
      return false;
    }
    const current = new URL(window.location.href);
    const webshop = new URL(this.webshopHost);
    return current.hostname === webshop.hostname;
  }

  // Returns true if we have a custom webshop host configured.
  public get hasWebshopHost(): boolean {
    return this.webshopHost !== undefined;
  }

  // Returns the webshop route for an organization.
  public getWebshopRoute(orgPublicUuid: string): string {
    return `/shop/${orgPublicUuid}`;
  }

  // Returns the webshop url for an organization.
  public getWebshopUrl(orgPublicUuid: string): string {
    const route = this.getWebshopRoute(orgPublicUuid);
    const url = new URL(route, this.webshopHost ?? window.location.href);
    return url.toString();
  }

  /**
   * Returns the Microsoft login url. This url can be used for SSO if we have
   * the Microsoft integration set up.
   * @param redirectPath The path of our url to redirect back to after successful login.
   * @param suggestedEmail Suggest an email address to Microsoft for the SSO login.
   * @return A url to navigate to. This is where the user logs in.
   */
  public microsoftOidcUrl(redirectPath: string, suggestedEmail?: string): URL {
    const redirectUrl = new URL(redirectPath, window.location.href);
    let loginHint = '';
    if (suggestedEmail) {
      loginHint = `&login_hint=${suggestedEmail}`;
    }
    if (!this.microsoftEntraClientId) {
      throw Error('MICROSOFT_ENTRA_CLIENT_ID env var is not set.');
    }
    return new URL(
      `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?response_type=token&client_id=${this.microsoftEntraClientId}&redirect_uri=${redirectUrl.toString()}${loginHint}&scope=GroupMember.Read.All+User.ReadBasic.All+openid+offline_access`,
    );
  }
}
