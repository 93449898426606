/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `RvO` - Rvo
 * * `HorseTelex` - Horsetelex
 */
export enum externalDbEnum {
  RV_O = 'RvO',
  HORSE_TELEX = 'HorseTelex',
}
