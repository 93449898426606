import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { VaccinationRule } from 'openapi';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { table, tableHiddenHeaderMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import classNames from 'classnames';
import SaveVaccinationModal from './SaveVaccinationRuleModal';
import DeleteVaccinationModal from './DeleteVaccinationRuleModal';

interface Props {
  vaccinatinoRules: VaccinationRule[] | undefined;
  onSaved: (vaccinatinoRule: VaccinationRule) => void;
  onDeleted: () => void;
}

function VaccinationRulesTile({ vaccinatinoRules, onSaved, onDeleted }: Props): JSX.Element {
  const [selectedRule, setSelectedRule] = useState<VaccinationRule>();

  const { t } = useTranslation();
  const { showModal: showSaveModal, closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible } = useModal();
  const { showModal: showDeleteModal, closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible } = useModal();

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: showSaveModal,
        text: t('add', 'Add'),
        buttonVariant: ButtonVariant.Default,
        icon: <Plus />,
      },
    ];
  }, [showSaveModal, t]);

  return (
    <Tile title={t('vaccination-rules', 'Vaccination rules')} loading={vaccinatinoRules === undefined} actions={tileActions}>
      {vaccinatinoRules && vaccinatinoRules.length === 0 ? (
        <p className='text-gray-500 italic'>{t('no-vaccination-rules', 'There are currently no vaccination rules.')}</p>
      ) : (
        <table className={table}>
          <thead>
            <tr className={classNames(tableThead, tableHiddenHeaderMd)}>
              <td className={tableTheadTd}>{t('name', 'Name')}</td>
              <td className='w-10' />
            </tr>
          </thead>
          <tbody className={tableTbody}>
            {vaccinatinoRules?.map(rule => (
              <tr className={tableTbodyTrNoClick} key={rule.uid}>
                <td>
                  <div className='py-2'>
                    <strong>{rule.name}</strong>
                    <p className='text-sm font-light'>
                      <Trans
                        i18nKey='horse-vaccination-rule'
                        defaults='Horse has to be vaccinated within <strong>{{months}}</strong> months and <strong>{{days}}</strong> days and cannot compete for <strong>{{dayNotCompeting}}</strong> days.'
                        values={{
                          months: rule.months_between_vaccination,
                          days: rule.optional_extra_days,
                          dayNotCompeting: rule.number_of_days_not_competing_after_vaccination,
                        }}
                      />
                    </p>
                  </div>
                </td>

                <td align='right'>
                  {rule.organisation_uid !== null && (
                    <DropdownMenu
                      menuPlacement='right-end'
                      menuItems={[
                        [
                          {
                            element: t('edit', 'Edit'),
                            onClick: () => {
                              setSelectedRule(rule);
                              showSaveModal();
                            },
                          },
                        ],
                        [
                          {
                            element: t('remove', 'Remove'),
                            className: 'text-red-600',
                            onClick: () => {
                              setSelectedRule(rule);
                              showDeleteModal();
                            },
                          },
                        ],
                      ]}
                    >
                      <button className='px-2'>
                        <DotsThreeVertical size={20} weight='bold' />
                      </button>
                    </DropdownMenu>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <SaveVaccinationModal
        isVisibile={saveModalIsVisible}
        onRequestClose={() => {
          closeSaveModal();
          setSelectedRule(undefined);
        }}
        onSaved={onSaved}
        vaccinationRule={selectedRule}
      />

      <DeleteVaccinationModal
        isVisible={deleteModalIsVisible}
        onRequestClose={() => {
          closeDeleteModal();
          setSelectedRule(undefined);
        }}
        onDeleted={onDeleted}
        vaccinationRule={selectedRule}
      />
    </Tile>
  );
}

export default VaccinationRulesTile;
