import { AppRoutes } from 'AppRoutes';
import classnames from 'classnames';
import { AppliedListFilter, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper, { FilterActions } from 'components/Common/ListFilter/FilterWrapper';
import ListFilterButton, { FilterButtonTypeEnum } from 'components/Common/ListFilter/ListFilterButton';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import HorseLocationHistoryRow from 'components/Horses/HorseLocationHistoryRow';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import { useAccount } from 'context/AccountContext';
import DeleteLocationHistoryModal from 'components/Horses/LocationHistory/DeleteLocationHistoryModal';
import UpdateLocationHistoryDateModal from 'components/Horses/LocationHistory/UpdateLocationHistoryDateModal';
import { useOrganization } from 'context/OrganizationContext';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import { Contact, ContactsService, Horse, HorseLocation, HorselocationsService, HorsesService, Stable, StablesService } from 'openapi';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  defaultApiPageSize,
  table,
  tableHiddenColumnSm,
  tableHiddenHeaderMd,
  tableTbody,
  tableTheadTd,
  tableTheadTdSticky,
} from 'ui/Const';
import Page from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { ApiPromises } from 'utilities/ApiPromises';
import { stableListFilterTypes } from 'utilities/Stable';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';
import Button, { ButtonSize } from 'ui/Button';

function LocationHistory(): JSX.Element {
  const [stables, setStables] = useState<Stable[]>([]);
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [horseLocations, setHorseLocations] = useState<HorseLocation[]>();
  const [selectedHorseLocations, setSelectedHorseLocations] = useState<HorseLocation>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedOrganization, generateCacheKey } = useOrganization();
  const { reportCount, loadReportCount } = useRvoReportCount();
  const { closeModal: closeEditModal, modalIsVisible: editModalIsVisible, showModal: showEditModal } = useModal();
  const { accountDetails } = useAccount();
  const [searchParams] = useSearchParams();
  const { closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible, showModal: showDeleteModal } = useModal();

  const preferredStableQuery = accountDetails?.preferred_stable ? `?stable=${accountDetails.preferred_stable}` : '';

  /**
   * list only the RVO authorized contacts
   */
  const rvoAuthorizedContacts = useMemo(() => {
    return (contacts ?? [])?.filter(contact => contact.machtiging_rvo_gegeven);
  }, [contacts]);

  /**
   * A list of types we can filter by.
   */
  const filterTypes = useMemo((): ListFilterType[] => {
    if (!horses) {
      return [];
    }

    // simple horse filter by name
    const horseFilter: ListFilterType = {
      id: 'horse',
      name: t('horses', 'Horses'),
      options: horses.map(horse => ({ id: horse.uid, name: horse.name })),
    };

    return [horseFilter, stableListFilterTypes(t, stables, accountDetails?.preferred_stable ?? undefined)];
  }, [horses, t, stables, accountDetails?.preferred_stable]);

  const { filters, setFilters } = useListFilter(filterTypes);

  /**
   * return only the stable filter type
   */
  const stableFilterTypes = useMemo((): ListFilterType[] => {
    return filterTypes.filter(filter => filter.id === 'stable');
  }, [filterTypes]);

  /**
   * Get the selected horse based on the filter (url search query).
   */
  const selectedHorseUid = useMemo((): string | undefined => {
    const foundFilter = filters.find(filter => filter.type.id === 'horse');
    if (!foundFilter) {
      return undefined;
    }
    if (foundFilter.options.length !== 1) {
      return undefined;
    }

    return foundFilter.options[0].id;
  }, [filters]);

  /**
   * Get the selected horse based on the filter.
   */
  const selectedHorse = useMemo((): Horse | undefined => {
    if (!selectedHorseUid || !horses) return undefined;
    return horses.find(horse => horse.uid === selectedHorseUid);
  }, [horses, selectedHorseUid]);

  /**
   * Get the selected stables based on the filter.
   */
  const selectedStablesUids = useMemo((): string[] => {
    const stableFilter = filters.find(filter => filter.type.id === 'stable');
    if (!stableFilter) return [];
    return stableFilter.options.map(option => option.id);
  }, [filters]);

  /**
   * Select a horse
   */
  const selectHorse = useCallback(
    (horse?: Horse) => {
      if (!filterTypes) {
        return;
      }

      const foundFilterType = filterTypes.find(f => f.id === 'horse');
      if (!foundFilterType) {
        // should never happen.
        console.error('Filter type not found');
        return;
      }

      // The selection is cleared.
      if (!horse) {
        setFilters(filters.filter(filter => filter.type.id !== 'horse'));
        return;
      }

      const currrentFilter = filters.find(filter => filter.type.id === 'horse');

      if (currrentFilter) {
        currrentFilter.options = [{ id: horse.uid, name: horse.name }];
        setFilters([...filters.filter(filter => filter.type.id !== 'horse'), currrentFilter]);
      } else {
        const horseFilter: AppliedListFilter = {
          type: foundFilterType,
          options: [{ id: horse.uid, name: horse.name }],
        };
        setFilters([...filters, horseFilter]);
      }
    },
    [filterTypes, filters, setFilters],
  );

  // Load data from the api/cache
  const loadApiData = useCallback(
    (loadHorseLocations: boolean): ApiPromises => {
      const promises = new ApiPromises();
      if (!selectedOrganization) {
        return promises;
      }

      // load the horse locations
      if (loadHorseLocations === true) {
        promises.setPaginated<HorseLocation>(
          'horse-location',
          apiPageNumber => {
            return HorselocationsService.horselocationsList({
              locationOrganisationUid: selectedOrganization.uid,
              page: apiPageNumber,
              pageSize: defaultApiPageSize,
              horseUid: selectedHorseUid,
              horseOrLocationStable: selectedStablesUids.length > 0 ? selectedStablesUids : undefined,
              o: '-arrival_date,-id',
            });
          },
          setHorseLocations,
          selectedStablesUids === undefined || selectedHorseUid === undefined,
        );
      }

      if (loadHorseLocations === false) {
        promises.appendList<Stable>(
          'stables',
          () =>
            StablesService.stablesList({
              organisationUid: selectedOrganization?.uid ?? '',
            }),
          setStables,
          generateCacheKey('stables'),
        );

        // load all the horses (included the removed once)
        promises.appendList<Horse>(
          'all-horses',
          () => {
            return HorsesService.horsesList({
              organisationUid: selectedOrganization?.uid ?? '',
              o: 'name',
            });
          },
          setHorses,
        );

        // Load all contacts, including the removed once.
        promises.appendList<Contact>(
          'contacts',
          () =>
            ContactsService.contactsList({
              organisationUid: selectedOrganization.uid,
            }),
          setContacts,
          generateCacheKey('contacts'),
        );
      }

      setApiPromises(promises);

      return promises;
    },
    [selectedOrganization, selectedHorseUid, selectedStablesUids, generateCacheKey],
  );

  /**
   * Show the RVO button if there are any authorized contacts
   */
  const pageActions = useMemo((): FilterActions[] => {
    if (!rvoAuthorizedContacts?.length) return [];

    const preferredStableQuery = accountDetails?.preferred_stable ? `?stable=${accountDetails.preferred_stable}` : '';

    return [
      {
        badge: reportCount,
        text: t('rvo-report-list', 'RVO report list'),
        buttonCompress: false,
        icon: <></>,
        onClick: () => navigate(`${AppRoutes.HorsesRvoList.path}${preferredStableQuery}`),
      },
    ];
  }, [rvoAuthorizedContacts?.length, accountDetails?.preferred_stable, reportCount, t, navigate]);

  /**
   * Return a list of horse locations with the horse mapped to it.
   */
  const horseLocationsWithHorseMapped = useMemo(() => {
    return (horseLocations ?? [])
      .map(horseLocation => {
        const horse = horses?.find(horse => horse.uid === horseLocation.horse_uid);
        return {
          horse,
          location: horseLocation,
        };
      })
      .filter((horseLocation): horseLocation is { horse: Horse; location: HorseLocation } => horseLocation.horse !== undefined);
  }, [horseLocations, horses]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  /**
   * Reload the apiDate when the filters are changed by the user
   */
  useEffect(() => {
    if (selectedOrganization && filters.length > 0) {
      const promise = loadApiData(true);
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedStablesUids, selectedHorseUid]); //eslint-disable-line

  /**
   * Detect if there are no search params, then we should load the data for the horst locations as well
   */
  useEffect(() => {
    if (searchParams.size === 0 && selectedOrganization) {
      const promise = loadApiData(true);
      return () => promise.cancel();
    }
  }, [searchParams, selectedOrganization]); //eslint-disable-line

  /**
   * Load the report count
   */
  useEffect(() => {
    const promise = loadReportCount();
    return () => promise && promise.cancel();
  }, [loadReportCount]);

  return (
    <Page title={t('location-history', 'Location history')} loading={apiPromises} actions={pageActions}>
      {reportCount > 0 && (
        <div className='mb-4 mx-2 sm:mx-0'>
          <Alert
            message={
              <div className='flex justify-between md:items-center gap-2'>
                <p>
                  <Trans
                    i18nKey='you-have-n-pending-rvo-reports'
                    defaults='You have <strong>{{number}}</strong> pending RvO reports that needs to be reported.'
                    values={{ number: reportCount }}
                  />
                </p>

                <div className='flex md:hidden'>
                  <Button
                    size={ButtonSize.Small}
                    onClick={() => navigate(`${AppRoutes.HorsesRvoList.path}${preferredStableQuery}`)}
                    className='grow'
                  >
                    {t('rvo-report-list', 'RVO report list')}
                  </Button>
                </div>
              </div>
            }
            severity={Severity.Info}
          />
        </div>
      )}

      <PullScrollWrapper apiPromises={apiPromises}>
        <Tile noBoxOnMobile={true} overflowContent={true}>
          {filterTypes && horses && (
            <FilterWrapper>
              {stables && stables.length > 1 && (
                <ListFilterButton
                  currentCountDisplay={() => `* ${t('your-preferred-stable', 'Your preferred stable')}`}
                  type={FilterButtonTypeEnum.Stable}
                  listFilterTypes={stableFilterTypes}
                />
              )}
              <HorseSelectButton selectedHorse={selectedHorse} horses={horses} horseSelected={selectHorse} />
            </FilterWrapper>
          )}

          <table className={table}>
            <thead>
              <tr className={tableHiddenHeaderMd}>
                <td className={classnames('w-10', tableTheadTdSticky)} />
                <td className={classnames(tableTheadTd, tableTheadTdSticky)}>{t('arrival', 'Arrival')}</td>
                <td className={classnames(tableTheadTd, tableTheadTdSticky)}>{t('depature', 'Departure')}</td>
                <td className={classnames(tableTheadTd, tableTheadTdSticky)}>{t('horse', 'Horse')}</td>
                <td className={classnames(tableTheadTd, tableTheadTdSticky, tableHiddenColumnSm)}>{t('location', 'Location')}</td>
                <td className={classnames('w-10', tableTheadTdSticky)} />
              </tr>
            </thead>
            <tbody className={tableTbody}>
              {horseLocationsWithHorseMapped.map(({ horse, location }) => (
                <HorseLocationHistoryRow
                  key={location.uid}
                  contacts={contacts}
                  horse={horse}
                  horseLocation={location}
                  onEdit={() => {
                    showEditModal();
                    setSelectedHorseLocations(location);
                  }}
                  onDelete={() => {
                    showDeleteModal();
                    setSelectedHorseLocations(location);
                  }}
                />
              ))}
            </tbody>
          </table>

          <UpdateLocationHistoryDateModal
            location={selectedHorseLocations}
            isVisible={editModalIsVisible}
            onRequestCloseModal={closeEditModal}
            onClosed={() => setSelectedHorseLocations(undefined)}
            onEdited={updatedHorseLocation => {
              // (fast)  first update the record in the list
              setHorseLocations(
                (horseLocations ?? []).map(horseLocation =>
                  horseLocation.uid === updatedHorseLocation.uid ? updatedHorseLocation : horseLocation,
                ),
              );

              // (slower) We should reload the data as wellafter the location is edited, as this update can affect other rows
              loadApiData(true);
            }}
          />

          <DeleteLocationHistoryModal
            location={selectedHorseLocations}
            isVisible={deleteModalIsVisible}
            onRequestCloseModal={closeDeleteModal}
            onClosed={() => setSelectedHorseLocations(undefined)}
            onDeleted={deletedHorseLocation => {
              setHorseLocations(horseLocations?.filter(horseLocation => horseLocation.uid !== deletedHorseLocation.uid));
            }}
          />
        </Tile>
      </PullScrollWrapper>
    </Page>
  );
}

export default memo(LocationHistory);
