/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `TRANSPORT` - Transport
 * * `WATER_TREADMILL` - Water treadmill
 * * `INDOOR_ARENA` - Indoor arena
 * * `OUTDOOR_ARENA` - Outdoor arena
 * * `GALLOPING_TRACK` - Galloping track
 * * `LUNGING_RING` - Lunging ring
 * * `PADDOCK` - Paddock
 * * `SOLARIUM` - Solarium
 * * `JUMPING_YARD` - Jumping yard
 * * `TREADMILL` - Treadmill
 * * `VIBRATION_PLATE` - Vibration plate
 * * `PASTURES` - Pastures
 * * `WALKER` - walker
 * * `OTHER` - other
 */
export enum FacilityTypeEnum {
  TRANSPORT = 'TRANSPORT',
  WATER_TREADMILL = 'WATER_TREADMILL',
  INDOOR_ARENA = 'INDOOR_ARENA',
  OUTDOOR_ARENA = 'OUTDOOR_ARENA',
  GALLOPING_TRACK = 'GALLOPING_TRACK',
  LUNGING_RING = 'LUNGING_RING',
  PADDOCK = 'PADDOCK',
  SOLARIUM = 'SOLARIUM',
  JUMPING_YARD = 'JUMPING_YARD',
  TREADMILL = 'TREADMILL',
  VIBRATION_PLATE = 'VIBRATION_PLATE',
  PASTURES = 'PASTURES',
  WALKER = 'WALKER',
  OTHER = 'OTHER',
}
