import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { FertilizerBudgetTypeEnum, FertilizerConsumer, LandTypePhosphateEnum, ManureService } from 'openapi';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextAreaInput, TextInput } from 'ui/Inputs';
import { schemas } from 'openapi/zod-schemas';
import useFormError from 'api/hooks/useFormError';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import { useParams } from 'react-router-dom';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { TFunction } from 'i18next';
import { z } from 'zod';

interface Props {
  onSave: () => void;
  onError: () => void;
  onSaved: (fertilizerConsumer: FertilizerConsumer) => void;
  fertilizerConsumer: FertilizerConsumer | undefined;
}

/**
 * Transform the land type enum to a string
 *
 * This is based on:
 * @see https://gitlab.qubis.nl/equinem/equinemcore/-/issues/588
 */
export const totalPhosphatelandTypeEnumTOString = (landType: LandTypePhosphateEnum | undefined, t: TFunction): string => {
  switch (landType) {
    case LandTypePhosphateEnum.FARM_LAND:
      return t('farm-land', 'Farm land');
    case LandTypePhosphateEnum.GRASS_LAND:
      return t('grass-land', 'Grass land');
  }

  return '';
};

const schema = schemas.FertilizerConsumer.pick({
  area: true,
  consumption_norm: true,
  land_type_phosphate: true,
})
  .required()
  .extend({
    description: z.string().optional(),
  });

/**
 * The form to save the total phosphate
 */
function TotalPhosphateForm({ onSave, onError, onSaved, fertilizerConsumer }: Props): JSX.Element {
  const { selectedOrganizationUid } = useOrganization();
  const { t } = useTranslation();
  const { year, stableContactUid } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<FertilizerConsumer>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Generate the options for the land type
   */
  const landTypeOptions = useMemo((): OptionItemInterface[] => {
    return Object.keys(LandTypePhosphateEnum).map(key => ({
      id: key,
      name: totalPhosphatelandTypeEnumTOString(key as LandTypePhosphateEnum, t),
    }));
  }, [t]);

  /**
   * Submit handler
   */
  const onSubmit = async (data: FertilizerConsumer) => {
    if (!selectedOrganizationUid) return console.error('selectedOrganization is not defined');

    // fire the onSave event
    onSave();

    try {
      let savedFertilizerConsumer: FertilizerConsumer;
      const requestBody: FertilizerConsumer = {
        ...data,
        year: Number(year),
        contact_uid: String(stableContactUid),
        fertilizer_budget_type: FertilizerBudgetTypeEnum.TOTAL_PHOSPHATE,
        land_type_phosphate: data.land_type_phosphate,
        description: data.description,
      };

      // determine if we are creating or updating
      if (fertilizerConsumer) {
        const promiseUpdate = ManureService.manureFertilizerConsumersUpdate({
          contactOrganisationUid: selectedOrganizationUid,
          uid: fertilizerConsumer.uid,
          requestBody,
        });
        savedFertilizerConsumer = await promiseUpdate;
      } else {
        const promiseCreate = ManureService.manureFertilizerConsumersCreate({
          contactOrganisationUid: selectedOrganizationUid,
          requestBody,
        });
        savedFertilizerConsumer = await promiseCreate;
      }

      // fire the onSaved event
      onSaved(savedFertilizerConsumer);

      // clear states
      setApiError(undefined);
      clearErrors();
      reset();
    } catch (error) {
      // fire the onError event
      onError();
      setApiError(new ApiErrorParser<FertilizerConsumer>(error));
    }
  };

  /**
   * Set the values when the fertilizerConsumer is set
   */
  useEffect(() => {
    reset(fertilizerConsumer);
  }, [fertilizerConsumer, reset]);

  return (
    <form noValidate={true} id='save-fertilizer-consumer-form' onSubmit={handleSubmit(onSubmit)}>
      <ErrorSection errors={nonFieldErrors} />
      <div className='py-3 space-y-3'>
        <SelectInput
          label={t('land-type', 'Land type')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'land_type_phosphate')}
          options={landTypeOptions}
          nullable={true}
          error={fieldError('land_type_phosphate')}
          hint={t(
            'land-type-phosphate-hint',
            'Enter the type of land. According the offical documents, the following lands are available.',
          )}
          {...register('land_type_phosphate', { setValueAs: transformEmptyToUndefined() })}
        />

        <TextInput
          error={fieldError('area')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'area')}
          label={t('area', 'Area')}
          postText='ha'
          hint={t('area-hint', 'The area in hectares')}
          {...register('area', { valueAsNumber: true })}
        />

        <TextInput
          error={fieldError('consumption_norm')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'consumption_norm')}
          label={t('consumption_norm', 'Consumption norm')}
          hint={t('consumption-norm-hint', 'Enter the consumption norm in KG per hectare.')}
          postText='kg'
          {...register('consumption_norm', { valueAsNumber: true })}
        />

        <TextAreaInput
          error={fieldError('description')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'description')}
          label={t('description', 'Description')}
          hint={t('fertilizer-description-hint', 'Enter a description for internal use or to explain the usage.')}
          {...register('description', { setValueAs: transformEmptyToUndefined() })}
        />
      </div>
    </form>
  );
}

export default TotalPhosphateForm;
