/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ONE` - One
 * * `FUTURE` - Future
 * * `ALL` - All
 */
export enum EventsToChangeEnum {
  ONE = 'ONE',
  FUTURE = 'FUTURE',
  ALL = 'ALL',
}
