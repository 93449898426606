import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { FertilizerBudgetTypeEnum, FertilizerConsumer, ManureService } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
  fertilizerConsumer: FertilizerConsumer;
  type: FertilizerBudgetTypeEnum;
}

function DeleteFertilizerConsumerModal({ type, isVisible, onRequestClose, fertilizerConsumer, onDeleted }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<FertilizerConsumer> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const modalTitle = useMemo(() => {
    switch (type) {
      case FertilizerBudgetTypeEnum.ANIMAL_NITROGEN:
        return t('delete-animal-nitrogen', 'Delete animal nitrogen');
      case FertilizerBudgetTypeEnum.TOTAL_NITROGEN:
        return t('delete-total-nitrogen', 'Delete total nitrogen');
      case FertilizerBudgetTypeEnum.TOTAL_PHOSPHATE:
        return t('delete-total-phosphate', 'Delete total phosphate');
      default:
        return '';
    }
  }, [type, t]);

  const modalDesc = useMemo(() => {
    switch (type) {
      case FertilizerBudgetTypeEnum.ANIMAL_NITROGEN:
        return t('delete-animal-nitrogen-text', 'Are you sure you want to delete this animal nitrogen record');
      case FertilizerBudgetTypeEnum.TOTAL_NITROGEN:
        return t('delete-total-nitrogen-text', 'Are you sure you want to delete this total nitrogen record');
      case FertilizerBudgetTypeEnum.TOTAL_PHOSPHATE:
        return t('delete-total-phosphate-text', 'Are you sure you want to delete this total phosphate record');
      default:
        return '';
    }
  }, [type, t]);

  const deleteLocation = async () => {
    if (!selectedOrganization) return;

    try {
      await ManureService.manureFertilizerConsumersDestroy({
        contactOrganisationUid: selectedOrganization.uid,
        uid: fertilizerConsumer.uid,
      });

      // close the modal
      onRequestClose();

      // fire the onDeleted callback
      onDeleted();
    } catch (error) {
      setApiError(new ApiErrorParser<FertilizerConsumer>(error));
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('delete', 'Delete'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: deleteLocation,
        },
      ]}
      title={modalTitle}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2 w-full'>{modalDesc}</p>
    </ActionModal>
  );
}

export default DeleteFertilizerConsumerModal;
