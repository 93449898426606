import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactDetail, ContactsService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { objectDiff } from 'utilities/compareObject';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';

interface Props {
  contact?: Contact;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onUpdated: (contact: ContactDetail) => void;
}

export default function UpdateStableFinancialModal({ isVisible, onRequestCloseModal, onUpdated, contact }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    return schemas.Contact.pick({
      company_registration_number: true,
      bank_name: true,
      account_number: true,
      bank_code: true,
      IBAN: true,
      vat_number: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Contact>({
    resolver: zodResolver(schema),
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Submit handler
   */
  const onSubmit = async (data: Contact) => {
    if (!selectedOrganization || !contact) return;

    setSubmitting(true);

    try {
      // generate a diff so we can send only the changed data
      const updatedFields = objectDiff(contact, data);

      const updateContactPromise = ContactsService.contactsPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: contact.uid,
        requestBody: updatedFields,
      });

      const updatedContact = await updateContactPromise;

      // fire the onUpdated event
      onUpdated(updatedContact);

      // close the modal
      onRequestCloseModal();
    } catch (error: unknown) {
      setApiError(new ApiErrorParser(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setSubmitting(false);
    setApiError(undefined);
  };

  /**
   * Event that will be fired when the user closes the modal
   */
  const onClose = () => {
    onRequestCloseModal();
    setSubmitting(false);
    setApiError(undefined);
  };

  /**
   * Update the value of the modal with the contact data
   */
  useEffect(() => {
    reset(contact);
  }, [contact, reset, isVisible]);

  return (
    <PageModal open={isVisible} onClosed={onClosed}>
      <PageModalTitle title={t('update-location', 'Update location')} onClose={onClose} />
      <PageModalContent>
        <form className='w-full space-y-6' id='updateContactFinancialForm' noValidate={true} onSubmit={handleSubmit(onSubmit)}>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='grid gap-3 grid-cols-1'>
            <TextInput
              error={fieldError('company_registration_number')}
              required={zodInputIsRequired<Contact>(schema, 'company_registration_number')}
              label={t('company_registration_number', 'Company registration number')}
              {...register('company_registration_number', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('bank_name')}
              required={zodInputIsRequired<Contact>(schema, 'bank_name')}
              label={t('bank-name', 'Bank name')}
              {...register('bank_name', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('account_number')}
              required={zodInputIsRequired<Contact>(schema, 'account_number')}
              label={t('bank-account-number', 'Bank account number')}
              {...register('account_number', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('bank_code')}
              required={zodInputIsRequired<Contact>(schema, 'bank_code')}
              label={t('bank-code', 'Bank code (BIC)')}
              {...register('bank_code', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('IBAN')}
              required={zodInputIsRequired<Contact>(schema, 'IBAN')}
              label={t('iban', 'IBAN')}
              {...register('IBAN', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('vat_number')}
              required={zodInputIsRequired<Contact>(schema, 'vat_number')}
              label={t('vat-number', 'VAT number')}
              {...register('vat_number', { setValueAs: transformEmptyToUndefined() })}
            />
          </div>
        </form>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: submitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'updateContactFinancialForm',
          },
        ]}
      />
    </PageModal>
  );
}
