import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, ReactNode, useState } from 'react';
import Button, { ButtonVariant } from '../Button';
import { X } from '@phosphor-icons/react';

export interface ActionProps {
  variant: ButtonVariant;
  text: string;
  loading?: boolean;
  onClick?: () => Promise<void> | void; // When onClick returns a promise then the button will show its loader while busy.
  form?: string; // Form id to submit
  icon?: ReactNode;
}
interface Props {
  title?: string;
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
  onClosed?: () => void;
  actions: ActionProps[];
  footerText?: string;
  // in some cases we want to show the close button in the modal
  // with this prop we added control for that so a <X /> icon is placed in the upper right corner
  showCloseButton?: boolean;
}

interface ActionButtonProps {
  action: ActionProps;
  className?: string;
}

// Button of the action modal
export function ActionButton({ action, className }: ActionButtonProps): JSX.Element {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  return (
    <Button
      form={action.form}
      className={className}
      icon={action.icon}
      type={action.form ? 'submit' : 'button'}
      loading={action.loading || isBusy}
      onClick={() => {
        if (action.onClick) {
          const onClickResult = action.onClick();
          if (onClickResult?.then) {
            setIsBusy(true);
            onClickResult.finally(() => setIsBusy(false));
          }
        }
      }}
      variant={action.variant}
    >
      {action.text}
    </Button>
  );
}

export default function ActionModal({
  open,
  title,
  children,
  onClose,
  onClosed,
  actions,
  footerText,
  showCloseButton,
}: Props): JSX.Element {
  const onCloseDummyFn = () => {
    // empty function
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='z-[99] fixed' onClose={onClose ?? onCloseDummyFn}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={onClosed}
        >
          <div className='fixed inset-0 z-[99] bg-black bg-opacity-20 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-[99]'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 py-safe'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative max-h-screen-safe overflow-hidden transform rounded-lg bg-white pt-2 px-4 pb-4 text-left shadow-xl transition-all sm:my-8 w-full max-w-lg sm:p-6 flex flex-col'>
                <div className='overflow-y-scroll'>
                  <div className='flex justify-between items-center'>
                    <Dialog.Title as='h3' className='text-base font-semibold leading-6 text-gray-900'>
                      {title}
                    </Dialog.Title>
                    {showCloseButton && (
                      <button className='group p-2 block cursor-pointer' onClick={onClose}>
                        <div className='group-hover:bg-neutral-100 p-1 rounded-full'>
                          <X size={28} />
                        </div>
                      </button>
                    )}
                  </div>
                  <div className='mt-2'>{children}</div>
                </div>
                <div className='mt-5 sm:mt-6 gap-4 flex flex-col md:flex-row justify-end md:justify-end md:items-center'>
                  {footerText && <p className='mr-auto text-sm text-gray-500 text-right w-full md:text-left'>{footerText}</p>}
                  <div className='flex gap-4 justify-end'>
                    {actions.map((action, index) => (
                      <ActionButton key={index} action={action} />
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
