import { FertilizerConsumer, FertilizerOriginEnum, ManureService, MutationTypeEnum, ProductionBalanceItem } from 'openapi';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { ButtonVariant } from 'ui/Button';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import useFormError from 'api/hooks/useFormError';
import { ErrorSection } from 'ui/Error';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import { TextInput } from 'ui/Inputs';
import { useOrganization } from 'context/OrganizationContext';
import RadioButtonGroup from 'ui/Inputs/RadioGroupInput';
import { z } from 'zod';
import ApiErrorParser from 'api/ApiErrorParser';

interface FormModel {
  name: string;
  type: 'phosfate' | 'nitrogen';
  fertilizer_origin: FertilizerOriginEnum.ANORGANIC_SUPPLY | FertilizerOriginEnum.OTHER_ORGANIC_SUPPLY;
  begin_amount: number;
  end_amount: number;
  import: number;
  export: number;
  weight: number;
}

interface Props {
  productionBalanceItemBeginAmount?: ProductionBalanceItem;
  productionBalanceItemEndAmount?: ProductionBalanceItem;
  productionBalanceItemImport?: ProductionBalanceItem;
  productionBalanceItemExport?: ProductionBalanceItem;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSaved: () => void;
}

/**
 * The schema for the form
 */
const schema = z
  .object({
    name: z.string(),
    fertilizer_origin: z.string(),
    type: z.string(),
    begin_amount: z.number(),
    end_amount: z.number(),
    import: z.number(),
    export: z.number(),
  })
  .required();

function SaveProductionBalanceItemNonAnimalModal({
  isVisible,
  onRequestCloseModal,
  onSaved,
  productionBalanceItemBeginAmount,
  productionBalanceItemEndAmount,
  productionBalanceItemExport,
  productionBalanceItemImport,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { year, stableContactUid } = useParams();
  const { selectedOrganizationUid } = useOrganization();

  /**
   * Define the default values
   */
  const defaultValues = useMemo(() => {
    return {
      type: productionBalanceItemBeginAmount
        ? productionBalanceItemBeginAmount.nitrogen_weight !== null
          ? 'nitrogen'
          : 'phosfate'
        : undefined,
      fertilizer_origin: productionBalanceItemBeginAmount
        ? productionBalanceItemBeginAmount.fertilizer_origin === FertilizerOriginEnum.ANORGANIC_SUPPLY
          ? FertilizerOriginEnum.ANORGANIC_SUPPLY
          : FertilizerOriginEnum.OTHER_ORGANIC_SUPPLY
        : undefined,
      name: productionBalanceItemBeginAmount?.name ?? '',
      begin_amount: productionBalanceItemBeginAmount?.phosphate_weight || productionBalanceItemBeginAmount?.nitrogen_weight || 0,
      end_amount: productionBalanceItemEndAmount?.phosphate_weight || productionBalanceItemEndAmount?.nitrogen_weight || 0,
      import: productionBalanceItemImport?.phosphate_weight || productionBalanceItemImport?.nitrogen_weight || 0,
      export: productionBalanceItemExport?.phosphate_weight || productionBalanceItemExport?.nitrogen_weight || 0,
      weight: 0,
    } as FormModel;
  }, [
    productionBalanceItemBeginAmount,
    productionBalanceItemEndAmount?.nitrogen_weight,
    productionBalanceItemEndAmount?.phosphate_weight,
    productionBalanceItemExport?.nitrogen_weight,
    productionBalanceItemExport?.phosphate_weight,
    productionBalanceItemImport?.nitrogen_weight,
    productionBalanceItemImport?.phosphate_weight,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
    control,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const save = async (
    current: ProductionBalanceItem | undefined,
    mutationType: MutationTypeEnum,
    fertilizerOrigin: FertilizerOriginEnum,
    type: 'phosfate' | 'nitrogen',
    weight: number,
    name: string,
  ) => {
    if (!selectedOrganizationUid) return;

    const requestBody: ProductionBalanceItem = {
      name,
      contact_uid: String(stableContactUid),
      year: Number(year),
      fertilizer_origin: fertilizerOrigin,
      mutation_type: mutationType,
      nitrogen_weight: type === 'nitrogen' ? weight : undefined,
      phosphate_weight: type === 'phosfate' ? weight : undefined,
    } as ProductionBalanceItem;

    // determine if we are creating or updating
    if (current) {
      await ManureService.manureFertilizerProducersBalancePartialUpdate({
        contactOrganisationUid: selectedOrganizationUid,
        uid: current.uid,
        requestBody,
      });
    } else {
      await ManureService.manureFertilizerProducersBalanceCreate({
        contactOrganisationUid: selectedOrganizationUid,
        requestBody,
      });
    }
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: FormModel) => {
    if (!selectedOrganizationUid) return console.error('selectedOrganization is not defined');

    try {
      await save(
        productionBalanceItemBeginAmount,
        MutationTypeEnum.BEGIN_AMOUNT,
        data.fertilizer_origin,
        data.type,
        data.begin_amount,
        data.name,
      );
      await save(productionBalanceItemImport, MutationTypeEnum.IMPORT, data.fertilizer_origin, data.type, data.import, data.name);
      await save(productionBalanceItemExport, MutationTypeEnum.EXPORT, data.fertilizer_origin, data.type, data.export, data.name);
      await save(
        productionBalanceItemEndAmount,
        MutationTypeEnum.END_AMOUNT,
        data.fertilizer_origin,
        data.type,
        data.end_amount,
        data.name,
      );

      // fire the onSaved event
      onSaved();

      // clear states
      setApiError(undefined);
      clearErrors();
      onRequestCloseModal();
    } catch (error) {
      setApiError(new ApiErrorParser<FertilizerConsumer>(error));
    }
  };

  /**
   * Set the values when the fertilizerConsumer is set
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <>
      <PageModal
        open={isVisible}
        onClosed={() => {
          reset();
          setApiError(undefined);
        }}
        parentElement='form'
        parentProps={{ id: 'saveProductionItem', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('non-animal-manure', 'Non animal manure')} onClose={onRequestCloseModal} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />

          <div className='py-3 space-y-3'>
            <RadioButtonGroup<FormModel>
              name='type'
              control={control}
              required={zodInputIsRequired<FormModel>(schema, 'type')}
              options={[
                {
                  id: 'phosfate',
                  name: t('phosfate', 'Phosfate'),
                },
                {
                  id: 'nitrogen',
                  name: t('nitrogen', 'Nitrogen'),
                },
              ]}
              error={fieldError('type')}
              label={t('type', 'Type')}
            />

            <RadioButtonGroup<FormModel>
              control={control}
              required={zodInputIsRequired<FormModel>(schema, 'fertilizer_origin')}
              name='fertilizer_origin'
              error={fieldError('fertilizer_origin')}
              options={[
                {
                  id: FertilizerOriginEnum.ANORGANIC_SUPPLY,
                  name: t('anorganic-supply', 'Anorganic supply'),
                },
                {
                  id: FertilizerOriginEnum.OTHER_ORGANIC_SUPPLY,
                  name: t('other-organic-supply', 'Other organic supply'),
                },
              ]}
              label={t('fertilizer-origin', 'Fertilizer origin')}
            />

            <TextInput
              error={fieldError('name')}
              required={zodInputIsRequired<FormModel>(schema, 'name')}
              label={t('name', 'Name')}
              hint={t('fertilizer-hint', 'Enter the description of this fertilizer. E.g. champost or compost')}
              {...register('name', { setValueAs: transformEmptyToUndefined() })}
            />

            <TextInput
              error={fieldError('begin_amount')}
              required={zodInputIsRequired<FormModel>(schema, 'begin_amount')}
              label={t('begin-amount', 'Begin amount')}
              postText='kg'
              type='number'
              {...register('begin_amount', { valueAsNumber: true })}
            />

            <TextInput
              error={fieldError('import')}
              required={zodInputIsRequired<FormModel>(schema, 'import')}
              label={t('v', 'Import')}
              postText='kg'
              type='number'
              {...register('import', { valueAsNumber: true })}
            />
            <TextInput
              error={fieldError('export')}
              required={zodInputIsRequired<FormModel>(schema, 'export')}
              label={t('export', 'Export')}
              postText='kg'
              type='number'
              {...register('export', { valueAsNumber: true })}
            />
            <TextInput
              error={fieldError('end_amount')}
              required={zodInputIsRequired<FormModel>(schema, 'end_amount')}
              label={t('end-amount', 'End amount')}
              postText='kg'
              type='number'
              {...register('end_amount', { valueAsNumber: true })}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'saveProductionItem',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default SaveProductionBalanceItemNonAnimalModal;
