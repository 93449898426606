/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `OPEN` - Open
 * * `PROCESSING` - Processing
 * * `PAID` - Paid
 */
export enum PaymentStatus {
  OPEN = 'OPEN',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
}
