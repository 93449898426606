/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ANIMAL_NITROGEN` - nitrogen from animals
 * * `TOTAL_NITROGEN` - total nitrogen
 * * `TOTAL_PHOSPHATE` - total phosphate
 */
export enum FertilizerBudgetTypeEnum {
  ANIMAL_NITROGEN = 'ANIMAL_NITROGEN',
  TOTAL_NITROGEN = 'TOTAL_NITROGEN',
  TOTAL_PHOSPHATE = 'TOTAL_PHOSPHATE',
}
