import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import UseCountries from 'hooks/UseCountries';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import {
  ArrivalOrDepartureEnum,
  Contact,
  CountryEnum,
  CreateReportedHorseRelocation,
  Horse,
  HorseDetail,
  HorseLocation,
  HorseLocationMutation,
  HorselocationsService,
  ReportedhorserelocationsService,
  ReportTypeEnum,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import RadioButtonGroup from 'ui/Inputs/RadioGroupInput';
import SelectInput, { OptionItemInterface } from 'ui/Inputs/SelectInput';
import DescriptionList from 'ui/Layout/DescriptionList';
import { DefinitionItem, DescriptionListMode } from 'ui/Layout/DescriptionList/DescriptionListItem';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { contactAddress, getLocationName } from 'utilities/Contact';
import { Severity } from 'utilities/severity';
import { transformEmptyToUndefined } from 'utilities/zod';
import { z } from 'zod';

interface Props {
  horse: Horse | HorseDetail | undefined;
  horseLocationMutation: HorseLocationMutation | undefined;
  contacts: Contact[] | undefined;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onClosed: () => void;
  onSuccess: () => void;
}

interface FormModel extends CreateReportedHorseRelocation {
  departureOrExport: 'departure' | 'export';
  newArrivalDate: string;
}

function RvoReportModal({
  isVisible,
  onRequestCloseModal,
  horse,
  contacts,
  horseLocationMutation,
  onClosed: givenOnClosed,
  onSuccess,
}: Props): JSX.Element {
  const [preChecking, setPreChecking] = useState(false);
  const [horseLocation, setHorseLocation] = useState<HorseLocation>();
  const [submitting, setSubmitting] = useState(false);
  const [severity, setSeverity] = useState<Severity>();
  const [preCheckApiError, setPreCheckApiError] = useState<ApiErrorParser<CreateReportedHorseRelocation> | undefined>();

  // collect the possible arrival and import dates so we can list them in the modal as option
  // when the user clicks on one of them we should adjust the arrival date in EquineM to match the one in RvO
  const [possibleArrivalImportDates, setPossibleArrivalImportDates] = useState<{ arrival: string[]; import: string[] }>();
  // holds the data from the api to determine on error if there are other alternaives
  const [rvoCountries, setRvoCountries] = useState<OptionItemInterface[]>([]);
  // flag that indicates if the bestemmingLandCode is required
  const [bestemmingLandCodeIsRequired, setBestemmingLandCodeIsRequired] = useState<boolean>(false);
  // flag that indicates if the horse is already in equinem. if so, we should patch the horse location
  // - hide_arrival_report_notification = True for arrival
  // - hide_departure_report_notification= True for departure
  const [rvoAlreadyInEquinem, setRvoAlreadyInEquinem] = useState<boolean>(false);
  // flag that indicates that we successfully reported the horse to RVO
  const [reportedSuccessfully, setReportedSuccessfully] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { formatDate } = useAccount();
  const { loadReportCount } = useRvoReportCount();
  const { countries } = UseCountries();

  // check if there are multiple stables
  const hasMultipleStables = (contacts ?? []).filter(contact => contact.stable_location_uid !== null).length > 1;

  /**
   * Get the location country for a contact UID
   */
  const getLocationCountryForContact = useCallback(
    (locationUid: string) => {
      const find = contacts?.find(contact => contact.uid === locationUid);
      if (find) {
        return find.country;
      }
    },
    [contacts],
  );

  /**
   * Check if we should ask for departure or export
   */
  const askForDepartureOrExport = useMemo(() => {
    if (!horseLocationMutation || !horseLocation) return false;

    // we only ask for departure or export if the horse is not moved to the Netherlands
    if (horseLocationMutation.arrival_or_departure === ArrivalOrDepartureEnum.DEPARTURE && horseLocation?.destination_uid) {
      const country = getLocationCountryForContact(horseLocation?.destination_uid);
      return country !== CountryEnum.NL;
    }

    return false;
  }, [getLocationCountryForContact, horseLocation, horseLocationMutation]);

  /**
   * Form validation schema
   */
  const schema = useMemo(() => {
    let _schema = z.object({
      dierBestemmingLandCode: z.string().max(3).optional(),
    });

    // in case its an export we need to ask for departure or export
    // based on this the user should select a country
    if (askForDepartureOrExport) {
      _schema = _schema.extend({
        departureOrExport: z.union([z.literal('departure'), z.literal('export')]),
      });
    }

    // in case we have possible arrival or import dates we should ask the user to select one
    if (possibleArrivalImportDates) {
      _schema = _schema.extend({
        newArrivalDate: z.string().optional(),
      });
    }

    return (
      _schema
        // dierBestemmingLandCode should be required when the horse is exported
        .superRefine(({ dierBestemmingLandCode }, refinementContext) => {
          if (bestemmingLandCodeIsRequired && dierBestemmingLandCode === undefined) {
            return refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('required', 'Required'),
              path: ['dierBestemmingLandCode'],
            });
          }
        })
    );
  }, [askForDepartureOrExport, bestemmingLandCodeIsRequired, possibleArrivalImportDates, t]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const watchDepartureOrExportFormValue = watch('departureOrExport');
  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Event that is fired when the modal is fully closed
   */
  const onClosed = () => {
    setApiError(undefined);
    setPreCheckApiError(undefined);
    reset();
    setRvoAlreadyInEquinem(false);
    setReportedSuccessfully(false);
    setBestemmingLandCodeIsRequired(false);
    setHorseLocation(undefined);
    setPossibleArrivalImportDates(undefined);
    setSeverity(undefined);

    // call the given onClosed event
    givenOnClosed();
  };

  /**
   * Load the RVO countries options
   */
  const loadRvoCountryOptions = useCallback(() => {
    const promise = ReportedhorserelocationsService.reportedhorserelocationsRvOeuCountriesList({
      horseOrganisationUid: selectedOrganization?.uid ?? '',
    });

    // small helper to capitalize the first letter of a string
    // and make the rest lowercase
    const ucFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    promise
      .then(response => setRvoCountries(response.map(res => ({ id: res.code, name: ucFirst(res.naam) }))))
      .catch(e => !promise.isCancelled && setApiError(new ApiErrorParser(e)));
    return promise;
  }, [selectedOrganization?.uid, setApiError]);

  /**
   * Patch a horse location to hide the RVO notification
   * This is used when we have a horse that is already in EquineM
   */
  const updateLocationHistory = useCallback(async () => {
    setSubmitting(true);

    try {
      if (horseLocationMutation?.arrival_or_departure === ArrivalOrDepartureEnum.DEPARTURE) {
        await HorselocationsService.horselocationsHideDepartureCreate({
          locationOrganisationUid: selectedOrganization?.uid ?? '',
          uid: horseLocationMutation?.uid ?? '',
        });
      } else {
        await HorselocationsService.horselocationsHideArrivalCreate({
          locationOrganisationUid: selectedOrganization?.uid ?? '',
          uid: horseLocationMutation?.uid ?? '',
        });
      }

      // reload the report count
      loadReportCount();

      // call the events
      setReportedSuccessfully(true);
      onSuccess();
    } catch (e) {
      setApiError(new ApiErrorParser(e));
    } finally {
      setSubmitting(false);
    }
  }, [
    horseLocationMutation?.arrival_or_departure,
    horseLocationMutation?.uid,
    loadReportCount,
    onSuccess,
    selectedOrganization?.uid,
    setApiError,
  ]);

  /**
   * render the actions based on the state of the modal
   */
  const actions = useMemo((): ActionProps[] => {
    if (reportedSuccessfully) return [];

    // when the horse is already in equinem we should only show the report button
    if (rvoAlreadyInEquinem) {
      return [
        {
          loading: submitting,
          variant: ButtonVariant.Primary,
          text: t('report', 'Report'),
          onClick: updateLocationHistory,
        },
      ];
    }

    return [
      {
        disabled: preChecking || severity === Severity.Danger,
        loading: isSubmitting,
        variant: ButtonVariant.Primary,
        text: t('report', 'Report'),
        type: 'submit',
        formId: 'reportRvoForm',
      },
    ];
  }, [isSubmitting, preChecking, reportedSuccessfully, rvoAlreadyInEquinem, severity, submitting, t, updateLocationHistory]);

  /**
   * Construct the report call so we can call it later
   */
  const constructReportCall = useCallback(
    async (submit: boolean, reportType: ReportTypeEnum, options?: { dierBestemmingLandCode?: string }) => {
      if (!horseLocationMutation) return;

      const requestBody: CreateReportedHorseRelocation = {
        report_type: reportType,
        horselocation_uid: horseLocationMutation.uid,
        submit,
      };

      if (options?.dierBestemmingLandCode) {
        requestBody.dierBestemmingLandCode = options.dierBestemmingLandCode;
      }

      const promise = ReportedhorserelocationsService.reportedhorserelocationsCreate({
        horseOrganisationUid: selectedOrganization?.uid ?? '',
        requestBody,
      });

      return promise;
    },
    [horseLocationMutation, selectedOrganization?.uid],
  );

  /**
   * Check if the horse can be reported to RVO
   * It will call an API endpoint and check if the horse can be reported
   */
  const preCheck = useCallback(
    async (
      reportType: ReportTypeEnum,
      options?: { dierBestemmingLandCode?: string; newArrivalDate?: string },
      enabledLoadingState = true,
    ) => {
      if (!horseLocationMutation || !horseLocation) return;

      enabledLoadingState && setPreChecking(true);
      const promise = constructReportCall(false, reportType, options);

      try {
        await promise;
      } catch (e) {
        const error = new ApiErrorParser<CreateReportedHorseRelocation>(e);

        // check severity so we can set the correct severity for the errorSection component
        // but only when the property is available
        // eslint-disable-next-line
        // @ts-ignore
        const faultIndicator = error.nonFieldErrors()[0].raw?.soortFoutIndicator;
        if (faultIndicator) {
          setSeverity(faultIndicator === 'W' ? Severity.Warning : Severity.Danger);
        }

        // If the horse is already in equinem we set the flag
        if (error.nonFieldErrors()[0].code === 'rvo_already_in_equinem') {
          setRvoAlreadyInEquinem(true);
          setSeverity(Severity.Warning);
          setPreCheckApiError(error);
        } else if (error.nonFieldErrors()[0].code === 'arrival-other-date') {
          // collect the arrival and import dates so we can list them in the modal as option

          // eslint-disable-next-line
          // @ts-ignore
          const possibleImportDates: string[] = error.nonFieldErrors()[0].raw?.mogelijke_import.map((a: unknown) => a.gebeurtenisdatum);

          // eslint-disable-next-line
          // @ts-ignore
          const possibleArrivalDates: string[] = error.nonFieldErrors()[0].raw?.mogelijke_aanvoer.map((a: unknown) => a.gebeurtenisdatum);

          setPossibleArrivalImportDates({ import: possibleImportDates, arrival: possibleArrivalDates });
          setPreCheckApiError(error);
        } else if (error.fieldError('dierBestemmingLandCode', false) !== undefined) {
          // if we receive an error that the bestemmingLandCode is required we should set the flag as well
          setBestemmingLandCodeIsRequired(true);
        } else {
          setPreCheckApiError(error);
        }

        // rethrow so we can catch it in the parent
        throw e;
      } finally {
        enabledLoadingState && setPreChecking(false);
      }
    },
    [constructReportCall, horseLocation, horseLocationMutation, setPreCheckApiError],
  );

  /**
   * Actual report handler
   */
  const report = useCallback(
    async (reportType: ReportTypeEnum, options?: { dierBestemmingLandCode?: string; newArrivalDate?: string }) => {
      if (!horseLocationMutation) return;

      // if the new arrival date is set we should patch the horse location with the new arrival date
      if (options?.newArrivalDate) {
        const requestBody: Partial<HorseLocation> = {};

        if (horseLocationMutation?.arrival_or_departure === ArrivalOrDepartureEnum.DEPARTURE) {
          requestBody.departure_date = options.newArrivalDate;
        } else {
          requestBody.arrival_date = options.newArrivalDate;
        }

        await HorselocationsService.horselocationsPartialUpdate({
          locationOrganisationUid: selectedOrganization?.uid ?? '',
          uid: horseLocationMutation?.uid ?? '',
          requestBody,
        });
      }

      setSubmitting(true);
      const promise = constructReportCall(true, reportType, options);

      try {
        await promise;
        setReportedSuccessfully(true);
      } catch (e) {
        const error = new ApiErrorParser(e);
        setApiError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [constructReportCall, horseLocationMutation, selectedOrganization?.uid, setApiError],
  );

  /**
   * Submit handler
   */
  const onSubmit = async ({ departureOrExport, dierBestemmingLandCode, newArrivalDate }: FormModel) => {
    // set the default report type
    let reportType =
      horseLocationMutation?.arrival_or_departure === ArrivalOrDepartureEnum?.DEPARTURE ? ReportTypeEnum._2 : ReportTypeEnum._1;

    // Set the correct report type based on what the user selected
    if (departureOrExport) {
      reportType = departureOrExport === 'departure' ? ReportTypeEnum._2 : ReportTypeEnum._7;
    }

    try {
      // run a precheck to see if we can report this horse to RVO
      await preCheck(reportType, { dierBestemmingLandCode, newArrivalDate }, false);

      // if the precheck is successful we can report the horse to RVO
      await report(reportType, { dierBestemmingLandCode, newArrivalDate });

      // call the onSuccess handler
      onSuccess();
    } catch (e) {
      // do nothing as we are already handling the error in the preCheck/check function
    }
  };

  /**
   * Get the origin contact
   */
  const originContact = useMemo(() => {
    if (horseLocationMutation && horseLocationMutation.origin_uid) {
      return contacts?.find(contact => contact.uid === horseLocationMutation.origin_uid);
    }
  }, [contacts, horseLocationMutation]);

  /**
   * Get the destination contact
   */
  const destinationContact = useMemo(() => {
    if (horseLocationMutation && horseLocationMutation.destination_uid) {
      return contacts?.find(contact => contact.uid === horseLocationMutation.destination_uid);
    }
  }, [contacts, horseLocationMutation]);

  /**
   * Define the details of the report for our definition list
   */
  const definitionList = useMemo((): DefinitionItem[] => {
    let items: DefinitionItem[] = [
      {
        term: t('horse', 'Horse'),
        definition: <>{horse ? `${horse.name} (${horse.UELN})` : '-'}</>,
      },
      {
        term: t('date', 'Date'),
        definition:
          horseLocationMutation && horseLocationMutation.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL
            ? formatDate(horseLocationMutation.arrival_date)
            : horseLocationMutation?.departure_date
              ? formatDate(horseLocationMutation.departure_date ?? '')
              : '-',
      },
    ];

    // in case we have multiple stables we should show the stable
    if (hasMultipleStables) {
      items.push({
        term: t('stable', 'Stable'),
        definition: getLocationName(horseLocationMutation?.location_uid, contacts),
      });
    }

    // add the other details
    items = [
      ...items,
      ...[
        {
          term:
            horseLocationMutation?.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL
              ? t('origin', 'Origin')
              : t('destination', 'Destination'),
          definition: (
            <div className='flex flex-col'>
              <span>
                {horseLocationMutation?.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL
                  ? getLocationName(horseLocationMutation?.origin_uid, contacts)
                  : getLocationName(horseLocationMutation?.destination_uid, contacts)}
              </span>
              <span className='text-sm text-gray-700'>
                {horseLocationMutation?.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL
                  ? originContact
                    ? contactAddress(originContact, countries).join(' ')
                    : ''
                  : destinationContact
                    ? contactAddress(destinationContact, countries).join(' ')
                    : ''}
              </span>
            </div>
          ),
        },
        {
          term: t('type', 'Type'),
          definition:
            horseLocationMutation?.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL
              ? t('arrival', 'Arrival')
              : t('departure', 'Departure'),
        },
      ],
    ];

    return items;
  }, [contacts, countries, destinationContact, formatDate, hasMultipleStables, horse, horseLocationMutation, originContact, t]);

  /**
   * Load the RVO countries options
   */
  useEffect(() => {
    const promise = loadRvoCountryOptions();
    return () => promise.cancel();
  }, [loadRvoCountryOptions]);

  /**
   * When visible we should first precheck so we can show if this error is possible to report to RVO
   */
  useEffect(() => {
    if (!horseLocationMutation || !isVisible || !horseLocation || askForDepartureOrExport) return;
    if (isVisible && horseLocation) {
      const reportType =
        horseLocationMutation.arrival_or_departure === ArrivalOrDepartureEnum.ARRIVAL ? ReportTypeEnum._1 : ReportTypeEnum._2;

      preCheck(reportType).catch(() => {
        // do nothing as we are already handling the error in the preCheck/check function
      });
    }
  }, [askForDepartureOrExport, horseLocation, horseLocationMutation, isVisible, preCheck]);

  /**
   * Load the horse location when the modal is visible
   */
  useEffect(() => {
    if (isVisible) {
      const promise = HorselocationsService.horselocationsRetrieve({
        locationOrganisationUid: selectedOrganization?.uid ?? '',
        uid: horseLocationMutation?.uid ?? '',
      });

      promise
        .then(response => setHorseLocation(response))
        .catch(e => {
          const error = new ApiErrorParser(e);
          setApiError(error);
        });
    }
  }, [horseLocationMutation?.uid, isVisible, selectedOrganization?.uid, setApiError]);

  /**
   * Check if the bestemmingLandCode is required
   */
  useEffect(() => {
    if (!horseLocationMutation) return;

    if (watchDepartureOrExportFormValue === 'export') {
      // if the bestemmingLandCode is empty, we should require the user to select a country
      if (
        horseLocationMutation.arrival_or_departure === ArrivalOrDepartureEnum.DEPARTURE &&
        String(horseLocation?.bestemmingLandCode) === ''
      ) {
        setBestemmingLandCodeIsRequired(true);
      }
    } else {
      setBestemmingLandCodeIsRequired(false);
    }
  }, [horseLocation?.bestemmingLandCode, horseLocationMutation, watchDepartureOrExportFormValue]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'reportRvoForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
    >
      <PageModalTitle title={t('report-to-rvo', 'Report {{horseName}} to RVO', { horseName: horse?.name })} onClose={onRequestCloseModal} />
      <PageModalContent>
        <ErrorSection severity={Severity.Danger} className='mb-4' errors={nonFieldErrors} />

        {preCheckApiError && (
          <div className='mb-6'>
            <ErrorSection severity={severity} className='mb-2' errors={preCheckApiError} />
            {severity === Severity.Warning ? (
              <p className='italic text-sm'>
                {t(
                  'precheck-warning-summary',
                  'We checked this report to see if it is possible to submit it to RVO. We received a warning from RVO, but you can still submit the report',
                )}
              </p>
            ) : (
              <p className='italic text-sm'>
                {t(
                  'precheck-error-summary',
                  'We checked this report to see if it is possible to submit it to RVO. We received an error from RVO, so it is not possible to submit this report',
                )}
              </p>
            )}
          </div>
        )}

        {reportedSuccessfully && (
          <ErrorSection
            severity={Severity.Success}
            className='mb-2'
            errors={[
              t('rvo-successfully-reported-desc', 'We have successfully reported this location to the RVO. You can now close this modal.'),
            ]}
          />
        )}

        {preChecking && (
          <div className='flex items-center gap-2 mb-5'>
            <Spinner size={SpinnerSize.XSmall} />
            <span className='text-sm'>{t('submitting-to-rvo', 'We are checking if we can submit this horse location to RVO...')}</span>
          </div>
        )}

        <p>
          {t(
            'you-are-about-to-report-a-horse-location-status-change-to-rvo',
            'You are about to report a horse location/status change to RVO',
          )}
        </p>
        <DescriptionList className='mt-1 mb-4' mode={DescriptionListMode.DefaultCompact} list={definitionList} />

        {rvoAlreadyInEquinem && (
          <p>
            {t(
              'rvo-already-in-equinem-desc',
              'It seems this location is already reported to the RVO. Instead of reporting we are marking this location as reported when you click on the "report" button.',
            )}
          </p>
        )}

        {!reportedSuccessfully && (
          <>
            {possibleArrivalImportDates !== undefined && (
              <>
                {/* with alternative dates */}
                {(possibleArrivalImportDates?.arrival.length > 0 || possibleArrivalImportDates?.import.length > 0) && (
                  <>
                    <p>
                      <Trans
                        i18nKey='rvo-departure-arrival-mismatch-with-dates'
                        defaults='RVO gives a warning about a mismatch in reports. This message usually occurs because the import or arrival date in RvO does not match the one in EquineM. <br /><br />You can click on the import or arrival below to adjust the arrival date in EquineM to match the one in RvO or you can report this without changing the arrival date.'
                      />
                    </p>

                    {possibleArrivalImportDates.import.length > 0 && (
                      <RadioButtonGroup
                        flexType='col'
                        className='my-4'
                        name='newArrivalDate'
                        required={false}
                        control={control}
                        error={fieldError('newArrivalDate')}
                        options={possibleArrivalImportDates.import.map(date => ({ id: date, name: date }))}
                        label={t('import-dates', 'Import dates')}
                      />
                    )}

                    {possibleArrivalImportDates.arrival.length > 0 && (
                      <RadioButtonGroup
                        flexType='col'
                        className='my-4'
                        name='newArrivalDate'
                        required={false}
                        control={control}
                        error={fieldError('newArrivalDate')}
                        options={possibleArrivalImportDates.arrival.map(date => ({ id: date, name: date }))}
                        label={t('arriaval-dates', 'Arrival dates')}
                      />
                    )}
                  </>
                )}

                {/* no alternative dates */}
                {possibleArrivalImportDates?.arrival.length === 0 && possibleArrivalImportDates?.import.length === 0 && (
                  <p>
                    {t(
                      'rvo-departure-arrival-mismatch',
                      'RVO gives a warning about a mismatch in reports. It is still possible to report this to RVO.',
                    )}
                  </p>
                )}
              </>
            )}

            {askForDepartureOrExport && (
              <div className='space-y-4'>
                <p>
                  {t(
                    'ask-for-departure-or-export',
                    'In order to report this location, we need to know if this horse is being exported or departured. Please select the correct option below. (For an export RVO will charge you a fee)',
                  )}
                </p>

                <RadioButtonGroup
                  className='mb-4'
                  name='departureOrExport'
                  required={true}
                  control={control}
                  error={fieldError('departureOrExport')}
                  options={[
                    { id: 'departure', name: t('departure', 'Departure') },
                    { id: 'export', name: t('export', 'Export') },
                  ]}
                  label={t('departure-or-export', 'Departure or export')}
                />

                {bestemmingLandCodeIsRequired && (
                  <SelectInput
                    label={t('destination-country', 'Country of destination')}
                    hint={t(
                      'dierBestemmingLandCode-missing',
                      'In order to submit this report you need to select a country of destination from the list.',
                    )}
                    nullable={true}
                    required={true}
                    options={rvoCountries}
                    error={fieldError('dierBestemmingLandCode')}
                    nullableValue=''
                    {...register('dierBestemmingLandCode', { setValueAs: transformEmptyToUndefined() })}
                  />
                )}
              </div>
            )}
          </>
        )}
      </PageModalContent>
      <PageModalActions actions={actions} />
    </PageModal>
  );
}

export default RvoReportModal;
