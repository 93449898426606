import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { ContactDetail, ContactsService } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { ButtonVariant } from '../../ui/Button';
import { SelectInput, TextInput } from 'ui/Inputs';
import { schemas } from 'openapi/zod-schemas';
import useFormError from 'api/hooks/useFormError';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import Fieldset from 'ui/Fieldset';
import UseCountries from 'hooks/UseCountries';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  contact?: ContactDetail;
  onCreated?: (contact: ContactDetail) => void;
}

function CreateLocationModal({ isVisible, onRequestCloseModal, onCreated }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>();

  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { countries } = UseCountries();

  // Form validation
  const schema = useMemo(() => {
    return schemas.Contact.pick({
      business_name: true,
      UBN: true,
      address_line1: true,
      address_line2: true,
      address_line3: true,
      city: true,
      state: true,
      postcode: true,
      country: true,
    }).required({
      // make the business_name required for a company contact
      business_name: true,
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<ContactDetail>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close modal action
   */
  const close = () => {
    onRequestCloseModal();
    setApiError(undefined);
    clearErrors();
    reset();
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: ContactDetail) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    setSubmitting(true);

    try {
      const promiseCreate = ContactsService.contactsCreate({
        organisationUid: selectedOrganization.uid,
        requestBody: { ...data, external_location: true },
      });
      const savedContact = await promiseCreate;

      close();
      onCreated?.(savedContact);
    } catch (error) {
      setApiError(new ApiErrorParser<ContactDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageModal
        open={isVisible}
        parentElement='form'
        parentProps={{ id: 'SaveLocationForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('new-location', 'New location')} onClose={close} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />

          <div className='py-3 space-y-3'>
            <TextInput
              error={fieldError('business_name')}
              required={zodInputIsRequired<ContactDetail>(schema, 'business_name')}
              label={t('name', 'Name')}
              {...register('business_name', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('UBN')}
              required={zodInputIsRequired<ContactDetail>(schema, 'UBN')}
              label={t('UBN', 'UBN')}
              {...register('UBN', { setValueAs: transformEmptyToUndefined() })}
            />

            <Fieldset legend={t('address', 'Address')} className='grid gap-3 grid-cols-1 md:grid-cols-2 mb-4'>
              <TextInput
                error={fieldError('address_line1')}
                required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'address_line1')}
                label={t('street', 'Street')}
                {...register('address_line1', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('address_line3')}
                required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'address_line3')}
                label={t('house-number', 'House number')}
                {...register('address_line3', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('address_line2')}
                required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'address_line2')}
                label={t('address-line2', 'Address line 2')}
                {...register('address_line2', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('postcode')}
                required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'postcode')}
                label={t('postcode', 'Postcode')}
                {...register('postcode', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('city')}
                required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'city')}
                label={t('city', 'City')}
                {...register('city')}
              />
              <TextInput
                error={fieldError('state')}
                required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'state')}
                label={t('stateOrCounty', 'State or province')}
                {...register('state')}
              />
              <SelectInput
                error={fieldError('country')}
                required={zodInputIsRequired<ContactDetail>(schemas.ContactDetail, 'country')}
                options={countries}
                nullable={true}
                nullableValue=''
                label={t('country', 'Country')}
                {...register('country', { setValueAs: transformEmptyToUndefined() })}
              />
            </Fieldset>
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: submitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'SaveLocationForm',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default CreateLocationModal;
