import { zodResolver } from '@hookform/resolvers/zod';
import { Info } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import { schemas } from 'openapi/zod-schemas';
import {
  CancelablePromise,
  Horse,
  SupplierOrderItem,
  OrderItemStallionMountPicker,
  OrdersService,
  PaginatedStallionMountList,
  PickedStallionMount,
  Product,
  SemenTypeEnum,
  SupplierOrder,
  StallionMount,
  StallionmountsService,
  Category,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui/Alert';
import { ButtonVariant } from 'ui/Button';
import { table, tableHiddenColumnMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { Severity } from 'utilities/severity';
import PickSemenOrderItem from './PickSemenOrderItem';
import Badge from 'ui/Badge';
import { contactAddress, contactName } from 'utilities/Contact';
import { useAccount } from 'context/AccountContext';
import UseCountries from 'hooks/UseCountries';

interface Props {
  order?: SupplierOrder;
  stallions: Horse[];
  products: Product[]; // Only pass the semen stallion products here
  productCategories: Category[];
  open: boolean;
  onClose: (reload: boolean) => void;
}

export default function PickSemenOrder({ order, stallions, products, productCategories, open, onClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const [stallionMounts, setStallionMounts] = useState<StallionMount[]>();
  const [finalize, setFinalize] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<OrderItemStallionMountPicker> | undefined>();
  const { formatDate } = useAccount();
  const { countries } = UseCountries();

  const schema = useMemo(() => {
    return schemas.OrderItemStallionMountPicker;
  }, []);

  const { handleSubmit, control } = useForm<OrderItemStallionMountPicker>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  // const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: 'pickedstallionmount_set', // unique name for your Field Array
  });

  const semenOrderLine = useMemo((): SupplierOrderItem | undefined => {
    if (!order?.order_items) return;
    const breedingProductCategory = productCategories.find(cat => cat.default === 'BREEDING');

    const items = order.order_items.filter(orderItem => {
      const product = products.find(prod => prod.uid === orderItem.product_uid);
      return product && product.category_uid === breedingProductCategory?.uid;
    });
    if (items.length !== 1) {
      console.error('Picking order with multiple breeding order lines is not supported');
    }
    return items[0];
  }, [order, products, productCategories]);

  const shippingProduct = useMemo((): Product | undefined => {
    if (!order?.order_items) return;

    const found: Product[] = [];
    order.order_items.forEach(orderItem => {
      const product = products.find(prod => prod.uid === orderItem.product_uid);
      const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');
      if (product && product.category_uid === shippingProductCategory?.uid) {
        found.push(product);
      }
    });
    if (found.length > 1) {
      console.error('Picking order with multiple shipping order lines is not supported', order.uid);
    }
    if (found.length === 0) {
      console.error('No shipping product for order', order.uid);
      return undefined;
    }
    return found[0];
  }, [order, products, productCategories]);

  const stallionProduct = useMemo((): Product | undefined => {
    const found = products.filter(prod => prod.uid === semenOrderLine?.product_uid);
    if (found.length > 1) {
      console.error('Picking order for multiple stallion products is not supported');
    }
    return found.length > 0 ? found[0] : undefined;
  }, [semenOrderLine, products]);

  // The stallion related to the order.
  const stallion = useMemo((): Horse | undefined => {
    if (!stallionProduct) return undefined;
    return stallions.find(horse => horse.uid === stallionProduct.stallion_uid);
  }, [stallions, stallionProduct]);

  const totalPicked = useMemo(() => {
    return fields.reduce((acc, field) => acc + field.amount, 0);
  }, [fields]);

  const actions = useMemo((): ActionProps[] => {
    if (finalize) {
      return [
        {
          variant: ButtonVariant.Default,
          text: t('previous', 'Previous'),
          type: 'button',
          onClick: () => {
            setFinalize(false);
            setApiError(undefined);
          },
        },
        {
          variant: ButtonVariant.Primary,
          text: t('pick-order', 'Pick'),
          type: 'submit',
          formId: 'PickSemenOrder',
        },
      ];
    } else {
      return [
        {
          variant: totalPicked >= (semenOrderLine?.quantity ?? 0) ? ButtonVariant.Primary : ButtonVariant.Default,
          text: t('next', 'Next'),
          type: 'button',
          onClick: () => setFinalize(true),
        },
      ];
    }
  }, [finalize, t, totalPicked, semenOrderLine, setApiError]);

  const itemCount = useCallback(
    (mount: StallionMount) => {
      const found = fields.find(item => item.stallion_mount === mount.uid);
      if (!found) {
        return 0;
      }
      return found.amount;
    },
    [fields],
  );

  const summaryText = useMemo(() => {
    return semenOrderLine?.semen_type === SemenTypeEnum.FROZEN
      ? t('pick-semen-order-title-frozen', 'You picked {{amount}} / {{total}} frozen straws of stallion {{stallion}}.', {
          amount: totalPicked,
          total: semenOrderLine?.quantity ?? 0,
          stallion: stallion?.name ?? '<unknown>',
        })
      : t('pick-semen-order-title-fresh', 'You picked {{amount}} / {{total}} fresh portions of stallion {{stallion}}.', {
          amount: totalPicked,
          total: semenOrderLine?.quantity ?? 0,
          stallion: stallion?.name ?? '<unknown>',
        });
  }, [semenOrderLine, totalPicked, stallion, t]);

  const loadStallionMounts = useCallback(() => {
    if (!selectedOrganization) {
      console.error('Cannot load stallion mounts without a selected organization');
      return;
    }
    if (!semenOrderLine) {
      return;
    }
    const promise: CancelablePromise<PaginatedStallionMountList> = StallionmountsService.stallionmountsList({
      stallionOrganisationUid: selectedOrganization.uid,
      stallionUid: stallion?.uid,
      o: '-collection_date,id',
    });

    const filter = (mount: StallionMount) => {
      if (!stallion || mount.stallion_uid !== stallion.uid) {
        return false;
      }
      return (
        (semenOrderLine.semen_type === SemenTypeEnum.FRESH && (mount.portions_fresh_available ?? 0) > 0) ||
        (semenOrderLine.semen_type === SemenTypeEnum.FROZEN && (mount.straws_frozen_available ?? 0) > 0)
      );
    };

    promise.then(result => setStallionMounts(result.results?.filter(filter))).catch(e => console.error(e));
    return () => promise.cancel(); // Cancel on abort
  }, [selectedOrganization, semenOrderLine, stallion]);

  useEffect(() => {
    if (!selectedOrganization || !open) {
      return;
    }
    loadStallionMounts();
  }, [loadStallionMounts, selectedOrganization, open]);

  const onSubmit = async (data: OrderItemStallionMountPicker) => {
    if (!selectedOrganization) {
      console.error('Cannot submit picked order without a selected organization');
      return;
    }
    if (!semenOrderLine?.uid || !order) return;

    const promise: CancelablePromise<OrderItemStallionMountPicker> = OrdersService.ordersSuppliedSemenorderitemsPickstallionmountsCreate({
      orderSupplierUid: selectedOrganization.uid,
      orderUid: order.uid,
      uid: semenOrderLine.uid,
      requestBody: data,
    });

    try {
      await promise;
      onClose(true);
    } catch (e) {
      setApiError(new ApiErrorParser<OrderItemStallionMountPicker>(e));
    }
  };

  return (
    <PageModal
      open={open}
      onClosed={() => {
        // Reset to default values.
        setStallionMounts([]);
        replace([]);
        setApiError(undefined);
        setFinalize(false);
        setStallionMounts(undefined);
      }}
    >
      <PageModalTitle title={t('pick-semen-order', 'Pick semen order')} onClose={() => onClose(false)} />
      <PageModalContent>
        {stallionMounts && !finalize && semenOrderLine && (
          <>
            {stallionMounts.map(mount => (
              <PickSemenOrderItem
                stallion={stallion}
                key={mount.uid}
                mount={mount}
                initialAmount={itemCount(mount)}
                semenType={semenOrderLine.semen_type ?? SemenTypeEnum.FRESH} // TODO
                setCount={count => {
                  // Update the useFieldArray values
                  const val: PickedStallionMount = { stallion_mount: mount.uid, amount: count };
                  const index = fields.findIndex(item => item.stallion_mount === mount.uid);
                  if (index === -1) {
                    if (count > 0) append(val);
                  } else {
                    if (count === 0) {
                      remove(index);
                    } else {
                      update(index, val);
                    }
                  }
                }}
              />
            ))}
            {stallionMounts.length === 0 && (
              <div className='pb-6'>
                <Alert
                  message={t('no-mounts-in-stock', 'Sorry, there is currently no semen in stock for {{stallion}}.', {
                    stallion: stallion?.name ?? '',
                  })}
                  severity={Severity.Warning}
                  title={t('out-of-stock', 'Out of stock')}
                />
              </div>
            )}
            <p className='mt-4 text-gray-600'>
              <Info className='inline mr-1 text-lg' />
              {summaryText}
            </p>
          </>
        )}

        {finalize && semenOrderLine && order && (
          <div className='flex flex-col gap-4'>
            <div className='flex gap-4 md:gap-8 flex-col md:flex-row'>
              <div>
                <h2 className='font-medium'>{t('order-delivery-address', 'Delivery address')}</h2>
                <p>{contactName(order.historic_receiver)}</p>
                {contactAddress(order.historic_receiver, countries).map(line => (
                  <p key={line}>{line}</p>
                ))}
              </div>
              {shippingProduct && (
                <div>
                  <h2 className='font-medium'>{t('shipping', 'Shipping')}</h2>
                  <p>
                    {shippingProduct?.shipping_provider_name} <Badge>{shippingProduct?.shipping_service_type}</Badge>
                  </p>
                </div>
              )}
            </div>

            <div>
              <h2 className='font-medium mb-2'>{'Order details'}</h2>
              <table className={table}>
                <thead className={tableThead}>
                  <tr>
                    <td className='w-10' />
                    <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('stallion', 'Stallion')}</td>
                    <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('mount-date', 'Mount date')}</td>
                    <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('Type', 'Type')}</td>
                    {semenOrderLine.semen_type === SemenTypeEnum.FROZEN && (
                      <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('semen-straw-color', 'Straw color')}</td>
                    )}
                  </tr>
                </thead>
                <tbody className={tableTbody}>
                  {fields.length === 0 && (
                    <tr>
                      <td className='md:text-center p-2 md:p-12 text-neutral-600 italic' colSpan={100}>
                        {t('no-items-picked', 'No items have been picked')}
                      </td>
                    </tr>
                  )}
                  {fields.map(mountField => {
                    const mount = stallionMounts?.find(mount => mount.uid === mountField.stallion_mount);
                    return (
                      <tr key={mountField.id} className={tableTbodyTrNoClick}>
                        <td>
                          {mountField.amount} {'x'}
                        </td>
                        <td>{stallion?.name}</td>
                        <td>{mount?.collection_date && formatDate(new Date(Date.parse(mount.collection_date)))}</td>
                        <td>
                          {semenOrderLine.semen_type === SemenTypeEnum.FROZEN ? t('semen-frozen', 'Frozen') : t('semen-fresh', 'Fresh')}
                        </td>
                        {semenOrderLine.semen_type === SemenTypeEnum.FROZEN && <td>{mount?.NIFA_straw_color}</td>}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form id={'PickSemenOrder'} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
              <ul>
                {fields.map((mountField, index) => (
                  <li key={mountField.id}>
                    <Controller
                      render={({ field }) => <input type='hidden' {...field} value={mountField.amount} />}
                      name={`pickedstallionmount_set.${index}.amount`}
                      control={control}
                    />
                    <Controller
                      render={({ field }) => <input type='hidden' {...field} value={mountField.stallion_mount} />}
                      name={`pickedstallionmount_set.${index}.stallion_mount`}
                      control={control}
                    />
                  </li>
                ))}
              </ul>
            </form>
            <div className='flex flex-col gap-2'>
              {totalPicked < (semenOrderLine.quantity ?? 1) && (
                <Alert
                  severity={Severity.Danger}
                  message={t('picked-to-little', 'You picked too little, please go back and pick the correct amount.')}
                />
              )}
              {totalPicked > (semenOrderLine.quantity ?? 1) && (
                <Alert
                  severity={Severity.Warning}
                  message={t('picked-to-many', 'You picked too many, the requested amount is {{amount}}', {
                    amount: semenOrderLine.quantity,
                  })}
                />
              )}
              <ErrorSection className='mb-4' errors={apiError} />
            </div>
          </div>
        )}
      </PageModalContent>
      <PageModalActions actions={actions} />
    </PageModal>
  );
}
