/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `ANIMAL_MANURE_SUPPLY` - animal manure supply
 * * `MILK_PRODUCING_COWS` - milk producing cows
 * * `GRAZING_ANIMALS` - grazing animals
 * * `EQUINEM_HORSES` - equinem horses
 * * `STABLE_ANIMALS_FEED` - stable animals feed
 * * `OTHER_ORGANIC_SUPPLY` - other organic supply
 * * `ANORGANIC_SUPPLY` - anorganic supply
 */
export enum FertilizerOriginEnum {
  ANIMAL_MANURE_SUPPLY = 'ANIMAL_MANURE_SUPPLY',
  MILK_PRODUCING_COWS = 'MILK_PRODUCING_COWS',
  GRAZING_ANIMALS = 'GRAZING_ANIMALS',
  EQUINEM_HORSES = 'EQUINEM_HORSES',
  STABLE_ANIMALS_FEED = 'STABLE_ANIMALS_FEED',
  OTHER_ORGANIC_SUPPLY = 'OTHER_ORGANIC_SUPPLY',
  ANORGANIC_SUPPLY = 'ANORGANIC_SUPPLY',
}
