import classNames from 'classnames';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import { PrivateEnum, PublicHorseMediaSet, PublicHorsePage } from 'openapi';
import React, { useCallback, useMemo, useState } from 'react';
import OptimizedImage from 'ui/OptimizedImage';
import SlideShow from './SlideShow';
import useModal from 'ui/Modals/UseModal';
import Skeleton from 'ui/Layout/Skeleton';

interface Props {
  horsePage: PublicHorsePage | undefined;
  loading: boolean;
  className?: string;
}

/**
 * The head component of the horse public page that shows the thumbs of the images
 */
function HorseImages({ horsePage, className, loading }: Props): JSX.Element {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const isMobileDevice = useIsMobileDevice();
  const { closeModal: closeSlideShowModal, modalIsVisible: slideShowModalIsVisbile, showModal: showSlideShowModal } = useModal();

  /**
   * Return only the images of the horse from the media set
   */
  const images = useMemo((): PublicHorseMediaSet[] => {
    if (!horsePage?.horse.horsemedia_set) return [];

    return horsePage.horse.horsemedia_set
      .filter(media => media.content_type?.startsWith('image/'))
      .filter(media => [PrivateEnum._2, PrivateEnum._3].includes(media.private));
  }, [horsePage]);

  const heightClassName = images.length > 3 ? 'h-96' : 'h-96';
  const heightClassNameSmallImages = 'h-[11.6rem]';
  const classNameSmallImages = 'w-full object-cover cursor-pointer';

  /**
   * Open the slideshow modal with the given index
   */
  const openSlideShowModal = useCallback(
    (index: number) => {
      setSelectedImageIndex(index);
      showSlideShowModal();
    },
    [showSlideShowModal],
  );

  /**
   * determine the rest of the images
   */
  const restImage = useMemo(() => {
    if (images.length > 5) {
      return images.length - 5;
    }
  }, [images]);

  if (images.length === 0) {
    return <></>;
  }

  if (loading) {
    return (
      <div className={classNames('flex gap-3 my-4', className)}>
        <Skeleton className='h-52 basis-3/4' />
        <Skeleton className='h-52 basis-1/4' />
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames('flex gap-3 my-4', className, {
          'flex-col': isMobileDevice,
        })}
      >
        <div
          className={classNames({
            'w-1/2': images.length > 1 && !isMobileDevice,
          })}
        >
          <OptimizedImage
            className={classNames('w-full h-72 rounded-md object-cover cursor-pointer', heightClassName)}
            src={images[0].url}
            width={1200}
            onClick={() => openSlideShowModal(0)}
          />
        </div>

        {isMobileDevice && (
          <div
            className={classNames('grid gap-3', {
              'grid-cols-2': images.length === 3,
              'grid-cols-3': images.length === 4,
              'grid-cols-4': images.length > 4,
            })}
          >
            {images.slice(1, 5).map((media, index) => (
              <div
                key={media.url}
                className='relative h-24 rounded-md object-cover cursor-pointer overflow-hidden'
                onClick={() => openSlideShowModal(index)}
              >
                {index === 3 && restImage && (
                  <div className='absolute inset-0 bg-black/50 flex items-center justify-center text-white font-medium text-2xl'>
                    +{restImage}
                  </div>
                )}
                <OptimizedImage className='w-full h-full object-cover' src={media.url} width={256} />
              </div>
            ))}
          </div>
        )}

        {!isMobileDevice && (
          <>
            {images.length === 2 && (
              <div className='w-1/2'>
                <OptimizedImage
                  className={classNames('h-72 rounded-md', classNameSmallImages, heightClassName)}
                  src={images[1].url}
                  width={1024}
                  onClick={() => openSlideShowModal(1)}
                />
              </div>
            )}

            {images.length === 3 && (
              <div className='grid grid-cols-1 w-1/2 gap-3'>
                <OptimizedImage
                  className={classNames('rounded-t-md', classNameSmallImages, heightClassNameSmallImages)}
                  src={images[1].url}
                  width={512}
                  onClick={() => openSlideShowModal(1)}
                />

                <OptimizedImage
                  className={classNames('rounded-b-md', classNameSmallImages, heightClassNameSmallImages)}
                  src={images[2].url}
                  width={512}
                  onClick={() => openSlideShowModal(2)}
                />
              </div>
            )}

            {images.length === 4 && (
              <div className='w-1/2 flex flex-col gap-[13px]'>
                <OptimizedImage
                  className={classNames('rounded-t-md', classNameSmallImages, heightClassNameSmallImages)}
                  src={images[1].url}
                  width={512}
                  onClick={() => openSlideShowModal(1)}
                />
                <div className='grid grid-cols-2 gap-3'>
                  <OptimizedImage
                    className={classNames('rounded-bl-md', classNameSmallImages, heightClassNameSmallImages)}
                    src={images[2].url}
                    width={256}
                    onClick={() => openSlideShowModal(2)}
                  />
                  <OptimizedImage
                    className={classNames('rounded-br-md', classNameSmallImages, heightClassNameSmallImages)}
                    src={images[3].url}
                    width={256}
                    onClick={() => openSlideShowModal(3)}
                  />
                </div>
              </div>
            )}

            {images.length > 4 && (
              <div className='grid grid-cols-2 w-1/2 gap-3'>
                <OptimizedImage
                  className={classNames('rounded-tl-md', classNameSmallImages, heightClassNameSmallImages)}
                  src={images[1].url}
                  width={256}
                  onClick={() => openSlideShowModal(1)}
                />

                <OptimizedImage
                  className={classNames('rounded-tr-md', classNameSmallImages, heightClassNameSmallImages)}
                  src={images[2].url}
                  width={256}
                  onClick={() => openSlideShowModal(2)}
                />

                <OptimizedImage
                  className={classNames('rounded-bl-md', classNameSmallImages, heightClassNameSmallImages)}
                  src={images[3].url}
                  width={256}
                  onClick={() => openSlideShowModal(3)}
                />

                <div
                  className={classNames('relative w-full rounded-br-md overflow-hidden cursor-pointer', heightClassNameSmallImages)}
                  onClick={() => openSlideShowModal(4)}
                >
                  {restImage && (
                    <div className='absolute inset-0 bg-black/50 flex items-center justify-center text-white font-medium text-2xl'>
                      +{restImage}
                    </div>
                  )}
                  <OptimizedImage className='w-full h-full object-cover' src={images[4].url} width={256} />
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <SlideShow images={images} onRequestClose={closeSlideShowModal} isVisible={slideShowModalIsVisbile} index={selectedImageIndex} />
    </>
  );
}

export default HorseImages;
