import { ArrowRight } from '@phosphor-icons/react';
import { MapPin } from '@phosphor-icons/react/dist/ssr';
import { FacilityTypeEnum, Stable } from 'openapi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { facilityTypeToString } from 'utilities/Planning';

interface Props {
  facilityType: FacilityTypeEnum;
  stable?: Stable;
  onClick: () => void;
}

export default function FacilityTile({ facilityType, stable, onClick }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='rounded-xl bg-white border h-28 flex flex-col cursor-pointer' onClick={onClick}>
      <div className='grow flex flex-col text-lg opacity-85 items-center justify-center'>
        <p>{facilityTypeToString(t, facilityType)}</p>
        {stable && (
          <Badge className='flex gap-1 items-center'>
            <MapPin /> {stable?.location.business_name}
          </Badge>
        )}
      </div>
      <div className='flex items-center justify-center h-10 bg-blue-600 rounded-b-xl text-white'>
        <p>{t('view-availability', 'View availability')}</p>
        <ArrowRight />
      </div>
    </div>
  );
}
