import { BrowserStorageKeys } from 'api/UserSession';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { generatePath } from 'utilities/ReactRouter';
import { useAccount } from '../../context/AccountContext';
import Footer from './Footer';
import Header from './Header';
import { EquinemMetaHeader, WebshopMetaHeader } from './MetaHeader';
import Sidebar from './Sidebar';
import { Theme } from './Theme';

export interface SubMenuItemConfig {
  name: string;
  path: string;
  isEnabled: boolean;
  badge?: number;
}
export interface MenuItemConfig {
  name: string;
  path: string;
  icon: ReactNode;
  subMenu?: SubMenuItemConfig[];
  isEnabled: boolean;
}

interface Props {
  topMenu: MenuItemConfig[];
  bottomMenu: MenuItemConfig[];
  footerMenu: MenuItemConfig[];
  profileMenuPath: string;
  theme: Theme;
}

// Get the initial sidebar expand/collapse state based on the screen width and
// local storage.
const initialSidebarOpen = (screenWidth: number): boolean => {
  if (screenWidth <= ScreenSize.md) {
    return false;
  }
  const value = localStorage?.getItem(BrowserStorageKeys.SidebarOpen);
  if (value === null) {
    return true;
  }
  return value === 'true' ? true : false;
};

/**
 * When logged in we have headers, sidebars, footers, etc. They are wrapped in this view.
 */
export default function Layout({ topMenu, bottomMenu, footerMenu, profileMenuPath, theme }: Props): JSX.Element {
  const { width } = useScreenSize();
  const { session: user } = useAccount();
  const [suggestSidebarOpen, setSuggestSidebarOpen] = useState<boolean>(initialSidebarOpen(width));
  const { publicAccessUuid } = useParams();

  // When the screen width changes then reset the sidebar-open state based on
  // mobile or desktop.
  useEffect(() => {
    setSuggestSidebarOpen(initialSidebarOpen(width));
  }, [width]);

  const setSidebarOpen = useCallback(
    (open: boolean) => {
      // In the webshop the sidebar is either visible or not shown at all.
      if (theme === Theme.Webshop) {
        return;
      }

      setSuggestSidebarOpen(open);

      // Updated the preferred open state in the local storage.
      if (width > ScreenSize.md) {
        localStorage?.setItem(BrowserStorageKeys.SidebarOpen, open ? 'true' : 'false');
      }
    },
    [theme, width],
  );

  const sidebarOpen = useMemo(() => {
    // The sidebar is always open in the web shop (and hidden on mobile).
    if (theme === Theme.Webshop) {
      return true;
    }
    return suggestSidebarOpen;
  }, [suggestSidebarOpen, theme]);

  /**
   * Replace the react router parameters.
   */
  const includeRouterParams = useCallback(
    (menu: MenuItemConfig[]): MenuItemConfig[] => {
      return menu.map(item => {
        const res = item;
        res.path = generatePath(res.path, { publicAccessUuid: publicAccessUuid });
        if (item.subMenu) {
          res.subMenu = item.subMenu.map(subItem => {
            const subRes = subItem;
            subRes.path = generatePath(subRes.path, { publicAccessUuid: publicAccessUuid });
            return subRes;
          });
        }
        return res;
      });
    },
    [publicAccessUuid],
  );

  return (
    <div className='flex min-h-full w-full bg-neutral-50'>
      {theme === Theme.Equinem && <EquinemMetaHeader />}
      {theme === Theme.Webshop && <WebshopMetaHeader />}
      {user && (
        <Sidebar
          theme={theme}
          profileMenuPath={profileMenuPath}
          topMenu={includeRouterParams(topMenu)}
          bottomMenu={includeRouterParams(bottomMenu)}
          hideOnMobile={theme === Theme.Webshop}
          open={sidebarOpen}
          setOpen={setSidebarOpen}
        />
      )}
      <div className='flex-1 relative'>
        {user && <Header open={sidebarOpen} toggleSidebar={theme === Theme.Equinem ? () => setSidebarOpen(!sidebarOpen) : undefined} />}
        <Outlet context={{ theme }} />
        {user && <Footer theme={theme} cutoutStyle={false} menu={includeRouterParams(footerMenu.filter(menu => menu.isEnabled))} />}
      </div>
    </div>
  );
}
