import { Info, PencilSimple } from '@phosphor-icons/react';
import classNames from 'classnames';
import { FertilizerOriginEnum, MutationTypeEnum, ProductionBalanceItem } from 'openapi';
import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { table, tableHiddenColumnMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import SaveProductionBalanceItemAnimalModal from './SaveProductionBalanceItemAnimalModal';
import Tooltip from 'ui/Tooltip';
import { formatRowFosfate, formatRowNitrogen, sumUpTotal } from './helper';
import { ApiPromises } from 'utilities/ApiPromises';

interface Props {
  productionBalanceItem: ProductionBalanceItem[] | undefined;
  onSaved: () => void;
  apiPromises: ApiPromises | undefined;
}

/**
 * We calculate the balance of the animal manure here.
 *
 * This table is a fixed table, so we only fetch the items that are needed. e.g. begin amount, import, export and end amount etc for type ANIMAL_MANURE_SUPPLY
 *
 * This step is part of step 4 of the RVO document
 * @see https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf
 */
function CalculateBalanceAnimalManureTile({ productionBalanceItem, onSaved, apiPromises }: Props): JSX.Element {
  const [selectedProductionBalanceItem, setSelectedProductionBalanceItem] = useState<ProductionBalanceItem>();
  const [selectedType, setSelectedType] = useState<{
    fertilizerOrigin: FertilizerOriginEnum;
    mutationType: MutationTypeEnum;
  }>();

  const { t } = useTranslation();
  const { closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible, showModal: showSaveModal } = useModal();

  // we only need the animal manure items
  const filteredProductionBalanceItems = useMemo(() => {
    return (productionBalanceItem ?? []).filter(item => item.is_animal_manure);
  }, [productionBalanceItem]);

  const beginAmount = useMemo(() => {
    return filteredProductionBalanceItems.find(
      item => item.fertilizer_origin === FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY && item.mutation_type === MutationTypeEnum.BEGIN_AMOUNT,
    );
  }, [filteredProductionBalanceItems]);

  const importAmount = useMemo(() => {
    return filteredProductionBalanceItems.find(
      item => item.fertilizer_origin === FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY && item.mutation_type === MutationTypeEnum.IMPORT,
    );
  }, [filteredProductionBalanceItems]);

  const exportAmount = useMemo(() => {
    return filteredProductionBalanceItems.find(
      item => item.fertilizer_origin === FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY && item.mutation_type === MutationTypeEnum.EXPORT,
    );
  }, [filteredProductionBalanceItems]);

  const endAmount = useMemo(() => {
    return filteredProductionBalanceItems.find(
      item => item.fertilizer_origin === FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY && item.mutation_type === MutationTypeEnum.END_AMOUNT,
    );
  }, [filteredProductionBalanceItems]);

  /**
   * List the total amount of animal manure
   */
  const totalAnimalManure = useMemo(() => {
    const items = filteredProductionBalanceItems.filter(
      item =>
        [
          FertilizerOriginEnum.MILK_PRODUCING_COWS,
          FertilizerOriginEnum.STABLE_ANIMALS_FEED,
          FertilizerOriginEnum.GRAZING_ANIMALS,
          FertilizerOriginEnum.EQUINEM_HORSES,
        ].includes(item.fertilizer_origin) && item.mutation_type === MutationTypeEnum.PRODUCTION,
    );

    return {
      nitrogen_weight: items.reduce((total, current) => total + Number(current.nitrogen_weight), 0),
      phosphate_weight: items.reduce((total, current) => total + Number(current.phosphate_weight), 0),
    };
  }, [filteredProductionBalanceItems]);

  /**
   * Calculate the total amount of nitrogen and phosphate with the given operator
   */
  const total = useMemo(() => {
    return {
      nitrogen_weight: filteredProductionBalanceItems.reduce(
        (total, current) => sumUpTotal(current.operator, current?.nitrogen_weight, total),
        0,
      ),
      phosphate_weight: filteredProductionBalanceItems.reduce(
        (total, current) => sumUpTotal(current.operator, current?.phosphate_weight, total),
        0,
      ),
    };
  }, [filteredProductionBalanceItems]);

  const tableRecords = useMemo(() => {
    const records: {
      name: string;
      operator: 'plus' | 'min';
      productionBalanceItem: ProductionBalanceItem | undefined;
      onClick?: () => void;
      tooltip?: ReactNode;
    }[] = [];

    records.push({
      name: t('weight-begin-amount', 'Weight begin amount'),
      operator: 'plus',
      productionBalanceItem: beginAmount,
      onClick: () => {
        setSelectedType({
          fertilizerOrigin: FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY,
          mutationType: MutationTypeEnum.BEGIN_AMOUNT,
        });
        setSelectedProductionBalanceItem(beginAmount);
        showSaveModal();
      },
    });

    records.push({
      name: t('weight-import', 'Weight import'),
      productionBalanceItem: importAmount,
      operator: 'plus',
      onClick: () => {
        setSelectedType({
          fertilizerOrigin: FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY,
          mutationType: MutationTypeEnum.IMPORT,
        });
        setSelectedProductionBalanceItem(importAmount);
        showSaveModal();
      },
    });

    records.push({
      name: t('weight-manure-production', 'Weight manure production'),
      productionBalanceItem: totalAnimalManure as ProductionBalanceItem,
      operator: 'plus',
      tooltip: (
        <Tooltip content={t('use-the-animal-table', 'Use the animal manure table that is showed above')}>
          <Info className='text-gray-500' size={30} />
        </Tooltip>
      ),
    });

    records.push({
      name: t('weight-export', 'Weight export'),
      productionBalanceItem: exportAmount,
      operator: 'min',
      onClick: () => {
        setSelectedType({
          fertilizerOrigin: FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY,
          mutationType: MutationTypeEnum.EXPORT,
        });
        setSelectedProductionBalanceItem(exportAmount);
        showSaveModal();
      },
    });

    records.push({
      name: t('weight-end-amount', 'Weight end amount'),
      productionBalanceItem: endAmount,
      operator: 'min',
      onClick: () => {
        setSelectedType({
          fertilizerOrigin: FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY,
          mutationType: MutationTypeEnum.END_AMOUNT,
        });
        setSelectedProductionBalanceItem(endAmount);
        showSaveModal();
      },
    });

    return records;
  }, [beginAmount, endAmount, exportAmount, importAmount, showSaveModal, t, totalAnimalManure]);

  return (
    <Tile title={`4.2. ${t('calculate-actual-use-of-animal-manure', 'Calculate actual use of animal manure')}`} loading={apiPromises}>
      <table className={table}>
        <thead className={tableThead}>
          <tr>
            <td className={classNames('', tableTheadTd, tableHiddenColumnMd)} />
            <td className={classNames('w-60', tableTheadTd, tableHiddenColumnMd)}>{t('weight-nitrogen', 'Weight nitrogen')}</td>
            <td className={classNames('w-60', tableTheadTd, tableHiddenColumnMd)}>{t('weight-phosphate', 'Weight phosphate')}</td>
            <td className={classNames('w-24', tableTheadTd, tableHiddenColumnMd)}>
              {'+ '}
              {t('or', 'or')}
              {' -'}
            </td>
            <td className={classNames('w-10', tableTheadTd, tableHiddenColumnMd)} />
          </tr>
        </thead>

        <tbody className={tableTbody}>
          {tableRecords.map(record => (
            <tr key={record.name} className={tableTbodyTrNoClick}>
              <td className={tableHiddenColumnMd}>{record.name}</td>
              <td className={tableHiddenColumnMd}>{formatRowNitrogen(record.productionBalanceItem, t)}</td>
              <td className={tableHiddenColumnMd}>{formatRowFosfate(record.productionBalanceItem, t)}</td>

              {/* Mobile view */}
              <td className='md:hidden' colSpan={3}>
                <div className='py-2 space-y-1'>
                  <div>
                    <p className='text-gray-500 text-sm'>{t('name', 'Name')}</p>
                    <p>{record.name}</p>
                  </div>
                  <div>
                    <p className='text-gray-500 text-sm'>{t('weight-nitrogen', 'Weight nitrogen')}</p>
                    <p>{formatRowNitrogen(record.productionBalanceItem, t)}</p>
                  </div>
                  <div>
                    <p className='text-gray-500 text-sm'>{t('weight-phosphate', 'Weight phosphate')}</p>
                    <p>{formatRowFosfate(record.productionBalanceItem, t)}</p>
                  </div>
                </div>
              </td>

              <td
                className={classNames({
                  'text-green-500': record.operator === 'plus',
                  'text-red-500': record.operator === 'min',
                })}
              >
                {record.operator === 'min' ? t('min', 'Min') : t('plus', 'Plus')}
              </td>

              <td align='center'>
                {record.tooltip && record.tooltip}
                {record.onClick && (
                  <Button size={ButtonSize.Small} variant={ButtonVariant.Primary} icon={<PencilSimple />} onClick={record.onClick}>
                    {t('edit', 'Edit')}
                  </Button>
                )}
              </td>
            </tr>
          ))}

          <tr className={tableTbodyTrNoClick}>
            <td className={classNames('font-bold', tableHiddenColumnMd)}>{t('total', 'Total')}</td>
            <td className={classNames('font-bold', tableHiddenColumnMd)}>
              {total.nitrogen_weight} {'kg'}
            </td>
            <td className={classNames('font-bold', tableHiddenColumnMd)}>
              {total.phosphate_weight} {'kg'}
            </td>

            {/* mobile view */}
            <td className='md:hidden' colSpan={2}>
              <div className='py-2 space-y-1'>
                <p className='font-bold'>
                  {t('total-weight-nitrogen', 'Total weight nitrogen')}
                  {': '}
                  {total.nitrogen_weight} {'kg'}
                </p>

                <p className='font-bold'>
                  {t('total-weight-phosphate', 'Total weight phosphate')}
                  {': '}
                  {total.phosphate_weight} {'kg'}
                </p>
              </div>
            </td>

            <td />
            <td />
          </tr>
        </tbody>
      </table>

      {selectedType && (
        <SaveProductionBalanceItemAnimalModal
          productionBalanceItem={selectedProductionBalanceItem}
          type={selectedType}
          isVisible={saveModalIsVisible}
          onRequestCloseModal={closeSaveModal}
          onSaved={onSaved}
        />
      )}
    </Tile>
  );
}

export default CalculateBalanceAnimalManureTile;
