import { LinkBreak } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import HorseImages from 'components/Horses/PublicPage/HorseImages';
import Tile from 'components/Horses/PublicPage/Tile';
import FilesTile from 'components/Horses/PublicPage/Tiles/FilesTile';
import OrganizationTile from 'components/Horses/PublicPage/Tiles/OrganizationTile';
import OverviewTitle from 'components/Horses/PublicPage/Tiles/OverviewTitle';
import Pedigree from 'components/Pedigree';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { PrivateEnum, PublicHorsePage, PublicService } from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ApiPromises } from 'utilities/ApiPromises';
import { bloodlineString } from 'utilities/Horse';

function PublicHorseShare(): JSX.Element {
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [horsePage, setHorsePage] = useState<PublicHorsePage>();

  const { publicAccessUuid } = useParams();
  const { errorString, loading } = useApiPromises({ apiPromises, catchErrors: true });
  const { t } = useTranslation();
  const { width } = useScreenSize();

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback(
    (options: { loadProducts: boolean } | undefined): ApiPromises => {
      const promises = new ApiPromises();

      if (!publicAccessUuid) {
        return promises;
      }

      if (!options || options.loadProducts) {
        promises.appendSingle<PublicHorsePage>(
          'horse-page',
          () =>
            PublicService.apiV5HorsepagesRetrieve({
              publicAccessUuid,
            }),
          setHorsePage,
        );
      }

      setApiPromises(promises);

      return promises;
    },
    [publicAccessUuid],
  );

  // simple filter to list only files that are not images
  // this are the files that will be showed in the file title
  const files = horsePage?.horse.horsemedia_set
    ?.filter(media => [PrivateEnum._2, PrivateEnum._3].includes(media.private))
    .filter(media => !media.content_type?.startsWith('image/'));

  // determine if the pedigree should be shown full width
  const showPedigreeFullWidth =
    (files && files.length === 0 && horsePage?.include_medical_documents === false) ||
    (files && files.length > 0 && horsePage?.include_medical_documents === true);

  /**
   * Load from the api
   */
  useEffect(() => {
    if (publicAccessUuid) {
      const promise = loadApiData(undefined);
      return () => promise.cancel();
    }
  }, [publicAccessUuid]); //eslint-disable-line

  if (errorString) {
    return (
      <div className='bg-gray-50 flex flex-col justify-center items-center h-full'>
        <div className='max-w-screen-md mx-auto p-5 md:p-10 flex flex-col items-center gap-2'>
          <LinkBreak className='text-gray-500 mb-6' size={100} />
          <h1 className='font-bold text-4xl'>{t('cannot-load-horse-page', 'Cannot load public horse page')}</h1>
          <p className='text-center'>
            {t(
              'cannot-load-horse-page-desc',
              'We are unable to load this public horse page "{{publicAccessUuid}}" due to the following error',
              {
                publicAccessUuid,
              },
            )}
          </p>
          <p className='font-mono italic text-gray-600'>&quot;{errorString}&quot;</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='bg-gray-50 flex flex-col'>
        <div className='max-w-screen-lg mx-auto p-5 md:p-10'>
          <header className='flex justify-between items-center'>
            <div className='leading-4'>
              <h1 className='text-xl font-bold'>{horsePage?.horse.name}</h1>
              <p className='font-light text-sm'>{horsePage?.horse ? bloodlineString(horsePage?.horse) : ''}</p>
            </div>
            {horsePage?.horse.organisation.logo && <img className='h-9' src={horsePage.horse.organisation.logo} />}
          </header>

          <HorseImages horsePage={horsePage} loading={loading} />

          <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
            <OverviewTitle horsePage={horsePage} loading={loading} />

            <OrganizationTile horsePage={horsePage} loading={loading} />

            <FilesTile horsePage={horsePage} loading={loading} />

            {!loading && !showPedigreeFullWidth && (
              <Tile title={t('pedigree', 'Pedigree')}>
                <Pedigree showLevels={width < ScreenSize.lg ? 1 : 2} horse={horsePage?.horse} entryClassName='font-light' />
              </Tile>
            )}
          </div>

          {!loading && showPedigreeFullWidth && (
            <Tile title={t('pedigree', 'Pedigree')} className='mt-3'>
              <Pedigree
                showLevels={width > ScreenSize.lg ? 3 : width > ScreenSize.md ? 2 : 1}
                horse={horsePage?.horse}
                entryClassName='font-light'
              />
            </Tile>
          )}
        </div>
        <footer className='text-gray-500 text-sm font-light text-center px-4 mb-5'>
          {t('horse-public-page-copyright-footer', 'Copyright © {{year}} EquineM B.V.', { year: new Date().getFullYear() })}
        </footer>
      </div>
    </>
  );
}

export default PublicHorseShare;
