/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `HORSE` - horse
 * * `PONY` - pony
 */
export enum FertilizerNormTypeEnum {
  HORSE = 'HORSE',
  PONY = 'PONY',
}
