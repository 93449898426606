import { PencilSimple } from '@phosphor-icons/react';
import classNames from 'classnames';
import { FertilizerOriginEnum, MutationTypeEnum, ProductionBalanceItem } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { table, tableHiddenColumnMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd, tableTheadThName } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import SaveProductionBalanceItemAnimalModal from './SaveProductionBalanceItemAnimalModal';
import { formatRowFosfate, formatRowNitrogen } from './helper';
import { ApiPromises } from 'utilities/ApiPromises';

interface Props {
  productionBalanceItems: ProductionBalanceItem[] | undefined;
  onSaved: () => void;
  apiPromises: ApiPromises | undefined;
}

/**
 * This component is used to determine the balance for the animals on the farm.
 *
 * This step is part of step 4 of the RVO document
 * @see https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf
 */
function CalculateBalanceAnimalProductionManureTile({ productionBalanceItems, onSaved, apiPromises }: Props): JSX.Element {
  const [selectedProductionBalanceItem, setSelectedProductionBalanceItem] = useState<ProductionBalanceItem>();
  const [selectedType, setSelectedType] = useState<{
    fertilizerOrigin: FertilizerOriginEnum;
    mutationType: MutationTypeEnum;
  }>();

  const { t } = useTranslation();
  const { closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible, showModal: showSaveModal } = useModal();

  const producingCowsItem = useMemo(() => {
    return (productionBalanceItems ?? []).find(
      item => item.fertilizer_origin === FertilizerOriginEnum.MILK_PRODUCING_COWS && item.mutation_type === MutationTypeEnum.PRODUCTION,
    );
  }, [productionBalanceItems]);

  const equinemHorsesItem = useMemo(() => {
    return (productionBalanceItems ?? []).find(
      item => item.fertilizer_origin === FertilizerOriginEnum.EQUINEM_HORSES && item.mutation_type === MutationTypeEnum.PRODUCTION,
    );
  }, [productionBalanceItems]);

  const grazingAnimalsItem = useMemo(() => {
    return (productionBalanceItems ?? []).find(
      item => item.fertilizer_origin === FertilizerOriginEnum.GRAZING_ANIMALS && item.mutation_type === MutationTypeEnum.PRODUCTION,
    );
  }, [productionBalanceItems]);

  const stableAnimalsItem = useMemo(() => {
    return (productionBalanceItems ?? []).find(
      item => item.fertilizer_origin === FertilizerOriginEnum.STABLE_ANIMALS_FEED && item.mutation_type === MutationTypeEnum.PRODUCTION,
    );
  }, [productionBalanceItems]);

  /**
   * list the records for the table
   */
  const tableRecords = useMemo(() => {
    const records: {
      name: string;
      productionBalanceItem: ProductionBalanceItem | undefined;
      onClick: () => void;
    }[] = [];

    records.push({
      name: t('producing-cows', 'Producing cows'),
      productionBalanceItem: producingCowsItem,
      onClick: () => {
        setSelectedType({
          fertilizerOrigin: FertilizerOriginEnum.MILK_PRODUCING_COWS,
          mutationType: MutationTypeEnum.PRODUCTION,
        });
        setSelectedProductionBalanceItem(producingCowsItem);
        showSaveModal();
      },
    });

    records.push({
      name: t('other-grazing-animals-equinem-horses', 'Other grazing animals (Equinem horses)'),
      productionBalanceItem: equinemHorsesItem,
      onClick: () => {
        setSelectedType({
          fertilizerOrigin: FertilizerOriginEnum.EQUINEM_HORSES,
          mutationType: MutationTypeEnum.PRODUCTION,
        });
        setSelectedProductionBalanceItem(equinemHorsesItem);
        showSaveModal();
      },
    });

    records.push({
      name: t('other-grazing-animals', 'Other grazing animals'),
      productionBalanceItem: grazingAnimalsItem,
      onClick: () => {
        setSelectedType({
          fertilizerOrigin: FertilizerOriginEnum.GRAZING_ANIMALS,
          mutationType: MutationTypeEnum.PRODUCTION,
        });
        setSelectedProductionBalanceItem(grazingAnimalsItem);
        showSaveModal();
      },
    });

    records.push({
      name: t('stable-animals', 'Stable animals'),
      productionBalanceItem: stableAnimalsItem,
      onClick: () => {
        setSelectedType({
          fertilizerOrigin: FertilizerOriginEnum.STABLE_ANIMALS_FEED,
          mutationType: MutationTypeEnum.PRODUCTION,
        });
        setSelectedProductionBalanceItem(stableAnimalsItem);
        showSaveModal();
      },
    });

    return records;
  }, [equinemHorsesItem, grazingAnimalsItem, producingCowsItem, showSaveModal, stableAnimalsItem, t]);

  const total = useMemo(() => {
    return {
      nitrogen:
        (producingCowsItem?.nitrogen_weight ?? 0) +
        (grazingAnimalsItem?.nitrogen_weight ?? 0) +
        (stableAnimalsItem?.nitrogen_weight ?? 0) +
        (equinemHorsesItem?.nitrogen_weight ?? 0),
      phosphate:
        (producingCowsItem?.phosphate_weight ?? 0) +
        (grazingAnimalsItem?.phosphate_weight ?? 0) +
        (stableAnimalsItem?.phosphate_weight ?? 0) +
        (equinemHorsesItem?.phosphate_weight ?? 0),
    };
  }, [
    equinemHorsesItem?.nitrogen_weight,
    equinemHorsesItem?.phosphate_weight,
    grazingAnimalsItem?.nitrogen_weight,
    grazingAnimalsItem?.phosphate_weight,
    producingCowsItem?.nitrogen_weight,
    producingCowsItem?.phosphate_weight,
    stableAnimalsItem?.nitrogen_weight,
    stableAnimalsItem?.phosphate_weight,
  ]);

  return (
    <Tile
      title={`4.1. ${t(
        'Determine-the-total-manure-production-of-the-animals-on-your-farm',
        'Determine the total manure production of the animals on your farm',
      )}`}
      loading={apiPromises}
    >
      <table className={table}>
        <thead className={tableThead}>
          <tr>
            <td className={classNames(tableTheadTd, tableTheadThName, tableHiddenColumnMd)} />
            <td className={classNames('w-60', tableTheadTd, tableHiddenColumnMd)}>{t('weight-nitrogen', 'Weight nitrogen')}</td>
            <td className={classNames('w-60', tableTheadTd, tableHiddenColumnMd)}>{t('weight-phosphate', 'Weight phosphate')}</td>
            <td className={classNames('w-10', tableTheadTd, tableHiddenColumnMd)} />
          </tr>
        </thead>

        <tbody className={tableTbody}>
          {tableRecords.map(record => (
            <tr key={record.name} className={tableTbodyTrNoClick}>
              <td className={tableHiddenColumnMd}>{record.name}</td>
              <td className={tableHiddenColumnMd}>{formatRowNitrogen(record.productionBalanceItem, t)}</td>
              <td className={tableHiddenColumnMd}>{formatRowFosfate(record.productionBalanceItem, t)}</td>

              {/* Mobile view */}
              <td className='md:hidden' colSpan={3}>
                <div className='py-2 space-y-1'>
                  <div>
                    <p className='text-gray-500 text-sm'>{t('name', 'Name')}</p>
                    <p>{record.name}</p>
                  </div>
                  <div>
                    <p className='text-gray-500 text-sm'>{t('weight-nitrogen', 'Weight nitrogen')}</p>
                    <p>{formatRowNitrogen(record.productionBalanceItem, t)}</p>
                  </div>
                  <div>
                    <p className='text-gray-500 text-sm'>{t('weight-phosphate', 'Weight phosphate')}</p>
                    <p>{formatRowFosfate(record.productionBalanceItem, t)}</p>
                  </div>
                </div>
              </td>

              <td>
                <Button size={ButtonSize.Small} variant={ButtonVariant.Primary} icon={<PencilSimple />} onClick={record.onClick}>
                  {t('edit', 'Edit')}
                </Button>
              </td>
            </tr>
          ))}

          <tr className={tableTbodyTrNoClick}>
            <td className={classNames('font-bold', tableHiddenColumnMd)}>{t('total', 'Total')}</td>
            <td className={classNames('font-bold', tableHiddenColumnMd)}>
              {total.nitrogen} {'kg'}
            </td>
            <td className={classNames('font-bold', tableHiddenColumnMd)}>
              {total.phosphate} {'kg'}
            </td>

            {/* mobile view */}
            <td className='md:hidden' colSpan={3}>
              <div className='py-2 space-y-1'>
                <p className='font-bold'>
                  {t('total-weight-nitrogen', 'Total weight nitrogen')}
                  {': '}
                  {total.nitrogen} {'kg'}
                </p>

                <p className='font-bold'>
                  {t('total-weight-phosphate', 'Total weight phosphate')}
                  {': '}
                  {total.phosphate} {'kg'}
                </p>
              </div>
            </td>

            <td />
          </tr>
        </tbody>
      </table>

      {selectedType && (
        <SaveProductionBalanceItemAnimalModal
          productionBalanceItem={selectedProductionBalanceItem}
          type={selectedType}
          isVisible={saveModalIsVisible}
          onRequestCloseModal={closeSaveModal}
          onSaved={onSaved}
        />
      )}
    </Tile>
  );
}

export default CalculateBalanceAnimalProductionManureTile;
