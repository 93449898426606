import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { FacilitiesService, FacilityEvent } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
  event: FacilityEvent | undefined;
}

export default function DeleteFacilityReservationModal({ isVisible, onRequestClose, event, onDeleted }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<FacilityEvent> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const cancelReservation = async () => {
    if (!selectedOrganization || !event) return;

    try {
      const promise = FacilitiesService.facilitiesMyEventsDeleteCreate({
        organisationUid: selectedOrganization.uid,
        uid: event.uid,
      });
      await promise;

      // close the modal
      onRequestClose();

      // fire the onDeleted callback
      onDeleted();
    } catch (error) {
      setApiError(new ApiErrorParser<FacilityEvent>(error));
    }
  };

  const closeModal = () => {
    setApiError(undefined);
    onRequestClose();
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={closeModal}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: closeModal,
        },
        {
          text: t('cancel-reservation', 'Cancel reservation'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: cancelReservation,
        },
      ]}
      title={t('cancel-reservation', 'Cancel reservation')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2 w-full'>{t('delete-reservation-desc', 'Are you sure you want to cancel this reservation?')}</p>
    </ActionModal>
  );
}
