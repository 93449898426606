import React from 'react';
import { useTranslation } from 'react-i18next';
import useUnsplash from '../../../api/hooks/useUnsplash';
import { Spinner } from '../../../ui/Loading';
import { SpinnerSize } from '../../../ui/Loading/Spinner';

export default function Splash(): JSX.Element {
  const { t } = useTranslation();
  const unsplash = useUnsplash();

  return (
    <div
      style={{
        backgroundImage: unsplash.photo ? `url("${unsplash.photo.url}")` : '',
      }}
      className='relative hidden w-0 flex-auto lg:flex flex-col justify-center min-w-[450px] bg-no-repeat bg-cover m-4 rounded-3xl bg-neutral-400'
    >
      <div className='w-full h-full bg-gradient-to-t from-black rounded-3xl flex flex-col justify-between items-center'>
        <div className='w-full p-4 flex justify-end items-start h-14'>
          {unsplash.loading === true && <Spinner size={SpinnerSize.Small} />}
          {unsplash.photo && (
            <a className='underline text-xs opacity-20 mr-2 mt-1' href={unsplash.photo.author.url}>
              {t('photo-by', 'photo by')} {unsplash.photo?.author.name}
            </a>
          )}
        </div>
        <div className='w-1/2 max-w-[600px] pb-10'>
          <h1 className='text-5xl opacity-80 font-semibold leading-11 tracking-tight text-white hyphens-auto'>
            {t('splash-title', 'Sound stable management made easy')}
          </h1>
          <p className={'text-white opacity-80 mt-4'}>
            {t(
              'splash-subtitle1',
              'EquineM exists to help horse owners, stable managers, riders, grooms and health professionals organize their administration and streamline their daily operations.',
            )}
          </p>
          <div className='flex gap-3 opacity-60 justify-between items-stretch mt-8'>
            <div>
              <span className='text-white text-sm'>
                <span className='font-bold'>10+ </span>
                {t('splash-brag1', 'years of happy users')}
              </span>
            </div>
            <div className='w-[1px] bg-white opacity-50 inline-block my-1' />

            <div>
              <span className='text-white text-sm'>
                <span className='font-bold'>{'25K+'} </span>
                {t('splash-brag2', 'horses cared for')}
              </span>
            </div>
            <div className='w-[1px] bg-white opacity-50 inline-block my-1' />
            <div>
              <span className='text-white text-sm'>
                <span className='font-bold'>{'5M+'} </span>
                {t('splash-brag3', 'activities tracked')}
              </span>
            </div>
          </div>
          <img className='h-8 w-full opacity-70 mt-10' src='/logo-full-white.svg' />
        </div>
      </div>
    </div>
  );
}
