/**
 * Simple function to download a file from a given url.
 * @param fileUrl
 */
export const downloadFile = (fileUrl: string): void => {
  // <a href="path_to_file" download="proposed_file_name">Download</a>
  // In order to start a file download we need to pull a little trick.
  // We have to create an 'a' html element with the file content attached.
  // Inspired by: https://stackoverflow.com/a/42274086
  const a = document.createElement('a');
  a.href = fileUrl;

  // a.download = filename(t, selectedFile);
  // We need to append the element to the dom -> otherwise it will not
  // work in firefox.
  document.body.appendChild(a);
  a.click();
  // afterwards we remove the element again.
  a.remove();
};
