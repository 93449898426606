/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `REVOKED` - REVOKED
 * * `RECEIVED` - RECEIVED
 * * `PENDING` - PENDING
 * * `RETRY` - RETRY
 * * `SUCCESS` - SUCCESS
 * * `STARTED` - STARTED
 * * `FAILURE` - FAILURE
 * * `PROGRESS` - PROGRESS
 */
export enum StateEnum {
  REVOKED = 'REVOKED',
  RECEIVED = 'RECEIVED',
  PENDING = 'PENDING',
  RETRY = 'RETRY',
  SUCCESS = 'SUCCESS',
  STARTED = 'STARTED',
  FAILURE = 'FAILURE',
  PROGRESS = 'PROGRESS',
}
