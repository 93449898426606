/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `YES` - Yes
 * * `NO` - No
 * * `DUAL_PREGNANCY` - Dual Pregnancy
 */
export enum IsPregnantEnum {
  YES = 'YES',
  NO = 'NO',
  DUAL_PREGNANCY = 'DUAL_PREGNANCY',
}
