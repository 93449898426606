import { CaretDown, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { forwardRef, ForwardRefRenderFunction, ReactNode, SelectHTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';

export enum InputSize {
  XSmall,
  Small,
  Normal,
  Large,
}

export interface OptionItemInterface {
  id: string | number;
  name: string;
}
interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  hint?: ReactNode;
  error?: string;
  allowClear?: boolean;
  // Enable nullable option, this add a new option <option value={nullableValue}>----</option> into the select
  nullable?: boolean;
  // If the value can be nullable, we should define the value for this nullable option
  nullableValue?: string | '' | undefined;
  nullableLabel?: string;
  invalid?: boolean;
  size?: InputSize;
  options: OptionItemInterface[] | undefined;
  loading?: boolean;
}

const SelectInput: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  {
    label,
    nullable,
    nullableValue = undefined,
    nullableLabel = '----',
    error,
    allowClear = false,
    invalid = false,
    size = InputSize.Normal,
    onChange,
    onFocus,
    onBlur,
    hint,
    options,
    className,
    loading,
    ...props
  },
  ref,
) => {
  const [focused, setFocused] = useState(false);

  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLSelectElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleClear = () => {
    // handle Clear Here
  };
  const rootClassName = classNames('group relative', className);
  const inputRootClassName = classNames(
    'group w-full border flex items-center space-x-1.5 relative rounded-md border transition-all duration-200',
    {
      'border-primary': focused,
      'bg-gray-100 border-gray-200 cursor-not-allowed': props.disabled,
      'border-rose-400': error || invalid,
      'bg-white': !props.disabled,
      'py-1 px-1': size === InputSize.XSmall || size === InputSize.Small,
      'py-1.5 px-1.5': size === InputSize.Normal,
      'py-2.5 px-2.5': size === InputSize.Large,
    },
  );
  const inputClassName = classNames(
    'block w-full bg-transparent rounded-md border-0 focus:ring-0 focus:outline-none text-gray-900 ring-0 placeholder:text-gray-400',
    {
      'text-xs leading-5': size === InputSize.XSmall,
      'placeholder:text-rose-300': error || invalid,
      'cursor-not-allowed': props.disabled,
      'text-sm leading-6': size === InputSize.Small,
      'sm:text-sm sm:leading-6': size === InputSize.Normal,
      'text-large sm:text-base leading-7 sm:leading-6': size === InputSize.Large,
    },
  );
  return (
    <div className={rootClassName}>
      {label && (
        <label className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
          {label} {props.required && '*'}
        </label>
      )}
      {(!options || loading) && (
        <div className={classNames(inputRootClassName, 'flex gap-x-1')}>
          <Spinner size={SpinnerSize.XSmall} /> <span className='text-sm my-0.5 text-gray-600'>{t('loading', 'Loading')}...</span>
        </div>
      )}
      {!loading && options && (
        <div className={inputRootClassName}>
          <div className='relative w-full'>
            <select
              ref={ref}
              {...props}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={classNames('appearance-none cursor-pointer pr-8', inputClassName)}
              value={props.value}
              onChange={handleChange}
            >
              {nullable && <option value={nullableValue}>{nullableLabel}</option>}
              {options.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            <div className='absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none'>
              <CaretDown />
            </div>
          </div>
          {allowClear && props.value && (
            <div onClick={handleClear} className={'bg-neutral-50 rounded-full p-1'}>
              <X className='cursor-pointer h-3 w-3 text-gray-400' />
            </div>
          )}
        </div>
      )}
      {hint && <label className='block text-xs leading-4 text-gray-500 mt-1'>{hint}</label>}
      <label
        className={classNames('block text-xs leading-4 text-red-500 transition-all duration-200', {
          'h-auto opacity-100  mt-1': !!error,
          'h-0 opacity-0': !error,
        })}
      >
        {error || ''}
      </label>
    </div>
  );
};
export default forwardRef<HTMLSelectElement, Props>(SelectInput);
