/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `MONDAY` - monday
 * * `TUESDAY` - tuesday
 * * `WEDNESDAY` - wednesday
 * * `THURSDAY` - thursday
 * * `FRIDAY` - friday
 * * `SATURDAY` - saturday
 * * `SUNDAY` - sunday
 */
export enum AvailableWeekdaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
