import { IconContext } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

export interface ButtonGroupItem {
  onClick?: () => void;
  icon?: ReactNode;
  text?: ReactNode;
  selected?: boolean;
}

interface Props {
  items: ButtonGroupItem[];

  // Only show the icons when they're provided in the items.
  iconOnly?: boolean;
}

export default function ButtonGroup({ items, iconOnly }: Props): JSX.Element {
  return (
    <IconContext.Provider
      value={{
        size: 20,
        weight: 'regular',
      }}
    >
      <div className='flex flex-row text-sm font-medium select-none divide-x border rounded-lg bg-white'>
        {items.map((item, index) => (
          <button
            className={classNames(
              'px-1 md:px-3 h-10 focus:outline-none focus:ring-0 first:rounded-l-lg last:rounded-r-lg hover:bg-gray-100 flex items-center justify-center',
              {
                'bg-white shadow-inner text-blue-600': item.selected,
                'text-gray-600': !item.selected,
              },
            )}
            onClick={item.onClick}
            key={index}
          >
            {(!iconOnly || !item.icon) && <>{item.text}</>}
            {iconOnly && item.icon && <>{item.icon}</>}
          </button>
        ))}
      </div>
    </IconContext.Provider>
  );
}
