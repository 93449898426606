import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { AppRoutes, generateWebshopPath, WebshopRoutes } from 'AppRoutes';
import SplashWrapper, { WrapperStyle } from 'components/Common/Splash/Wrapper';
import { AccountService, EquineMPasswordReset } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Button from 'ui/Button';
import { ButtonSize, ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import Inform from 'ui/Layout/Inform';
import { LoginType } from './Login';
import useRedirectToHomeWhenLoggedIn from 'hooks/UseRedirectToHomeWhenLoggedIn';

interface Props {
  loginType: LoginType;
}

const schema = schemas.EquineMPasswordReset;

export default function ForgotPassword({ loginType }: Props): JSX.Element {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { publicAccessUuid } = useParams();
  const [searchParams] = useSearchParams();

  // Redirect to home if the user is already logged in.
  useRedirectToHomeWhenLoggedIn(loginType);

  const defaultValues = { email: String(searchParams.get('email')) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EquineMPasswordReset>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: EquineMPasswordReset) => {
    const promise = AccountService.apiV5PasswordResetCreate({ requestBody: data });
    try {
      setSubmitting(true);
      await promise;
      setSuccess(true);
    } catch (e) {
      setApiError(new ApiErrorParser<EquineMPasswordReset>(e));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SplashWrapper
      styleType={loginType === LoginType.Equinem ? WrapperStyle.Equinem : WrapperStyle.Anonymous}
      title={t('forgot-password-title', 'Password reset')}
    >
      {success ? (
        <div className='mt-8 md:mt-12'>
          <Inform
            type='mail'
            message={t('forgot-password-success', 'We have sent you an e-mail with instructions to reset your password.')}
          />
          <Link
            to={
              loginType === LoginType.Webshop ? generateWebshopPath(WebshopRoutes.Login.path, publicAccessUuid ?? '') : AppRoutes.Login.path
            }
            className='font-semibold text-blue-500 hover:text-primary'
          >
            {t('back-to-login', 'Back to login')}
          </Link>
          <p className='mt-4 text-sm text-gray-500 border-t pt-2'>
            {t(
              'forgot-password-email-sent',
              'No mail will be sent when the e-mail address is unknown to us. It may take a few minutes for the mail to show up in your mailbox.',
            )}
          </p>
        </div>
      ) : (
        <>
          <div className='pt-4'>
            <span>
              {t(
                'forgot-password-subtitle',
                "Forgotten your password? Enter your e-mail address below, and we'll send you an e-mail allowing you to reset your password.",
              )}
            </span>
          </div>
          <form className='flex flex-col mt-8 gap-4' noValidate={true} id='resetPassword' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-8'>
              <TextInput
                label={t('email-address', 'Email address')}
                autoFocus={true}
                required={true}
                {...register('email')}
                error={fieldError('email')}
                autoComplete='email'
                type='email'
              />

              <ErrorSection className='mb-4' errors={nonFieldErrors} />

              <div className='flex flex-col items-stretch'>
                <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} loading={submitting} type='submit'>
                  {t('resetMyPassword', 'Reset my password')}
                </Button>
              </div>
            </div>
            <Link
              to={
                loginType === LoginType.Webshop
                  ? generateWebshopPath(WebshopRoutes.Login.path, publicAccessUuid ?? '')
                  : AppRoutes.Login.path
              }
              className='text-center font-semibold text-blue-500 hover:text-primary'
            >
              {t('back-to-login', 'Back to login')}
            </Link>
          </form>
        </>
      )}
    </SplashWrapper>
  );
}
