import { Contact } from 'openapi';
import React from 'react';
import { table, tableTbody } from 'ui/Const';
import Row from './Row';
import UseCountries from 'hooks/UseCountries';

interface Props {
  locations: Contact[];
  onContactUpdated?: () => void;
  // this event will fire when one of the contacts is authorized
  onRvoAuthorized?: () => void;
}

function LocationTable({ locations, onContactUpdated, onRvoAuthorized }: Props): JSX.Element {
  // we get the countries here so it is only fetched once
  // and not in every row
  const { countries } = UseCountries();

  return (
    <>
      {locations && (
        <table className={table}>
          <tbody className={tableTbody}>
            {locations.map(stable => (
              <Row
                key={stable.uid}
                location={stable}
                onContactUpdated={onContactUpdated}
                countries={countries}
                onRvoAuthorized={onRvoAuthorized}
              />
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

export default LocationTable;
