import { DownloadSimple } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { PaymentStatusToColor, PaymentStatusToString } from 'components/Financial/Helpers';
import InvoiceSenderLogoBlock from 'components/Financial/InvoiceSenderLogoBlock';
import { PaymentStatus, PublicService } from 'openapi';
import { Redirect } from 'openapi/models/Redirect';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ErrorSection } from 'ui/Error';
import useInvoiceDownloader from '../api/hooks/useInvoiceDownloader';
import usePublicInvoice from '../api/hooks/usePublicInvoice';
import InvoiceFooterBlock from '../components/Financial/InvoiceFooterBlock';
import InvoiceInfoBlock from '../components/Financial/InvoiceInfoBlock';
import InvoiceItemsBlock from '../components/Financial/InvoiceItemsBlock';
import InvoiceNumber from '../components/Financial/InvoiceNumber';
import InvoiceReceiverBlock from '../components/Financial/InvoiceReceiverBlock';
import InvoiceSenderBlock from '../components/Financial/InvoiceSenderBlock';
import Badge, { BadgeSize } from '../ui/Badge/Badge';
import Button, { ButtonVariant } from '../ui/Button';
import { addDays } from 'utilities/date.utilities';
import { contactName, InvoiceSender } from 'utilities/Contact';

export default function PublicInvoice(): JSX.Element {
  const { i18n, t: translate } = useTranslation();
  const { publicAccessUuid } = useParams();
  const { download, preparing: downloadPreparing } = useInvoiceDownloader();
  const { invoice } = usePublicInvoice(publicAccessUuid);
  const [loadingPaymentUrl, setLoadingPaymentUrl] = useState<boolean>(false);
  const [loadingPaymentUrlError, setLoadingPaymentUrlError] = useState<ApiErrorParser<Redirect>>();

  // Translate to the language of the invoice.
  const t = invoice ? i18n.getFixedT(invoice.language) : translate;

  const invoiceDate = useMemo((): Date => {
    return invoice && invoice.date ? new Date(invoice.date) : new Date();
  }, [invoice]);

  const expDate = useMemo((): string => {
    let date: Date = new Date();
    if (invoice?.expiration_date) {
      date = new Date(invoice.expiration_date);
    } else {
      date = addDays(invoiceDate, invoice?.first_due_interval ?? 14);
    }
    return new Intl.DateTimeFormat(invoice?.language).format(date);
  }, [invoice, invoiceDate]);

  const formatCurrency = useCallback(
    (amount: number): string => {
      if (isNaN(amount)) {
        return '-';
      }
      const locale = invoice?.language;
      return new Intl.NumberFormat(locale, { style: 'currency', currency: invoice?.currency ?? 'EUR' }).format(amount);
    },
    [invoice],
  );

  // Get the payment link for the invoice and navigate to it.
  const getPaymentLink = useCallback(() => {
    setLoadingPaymentUrl(true);
    PublicService.apiV5InvoicesMolliePaymentLinkCreate({
      publicAccessUuid: publicAccessUuid ?? '',
      requestBody: {
        redirectUrl: window.location.href,
      },
    })
      .then(val => {
        window.location.href = val.redirect_uri;
      })
      .catch(e => {
        setLoadingPaymentUrlError(new ApiErrorParser<Redirect>(e));
      })
      .finally(() => setLoadingPaymentUrl(false));
  }, [publicAccessUuid]);

  const invoiceNr = useMemo(() => {
    if (invoice && invoice?.invoice_no) {
      return invoice?.invoice_no;
    }
    return '';
  }, [invoice]);

  const totalInclVat = useMemo(() => {
    if (!invoice || !invoice.total_incl_vat) {
      return '';
    }
    return formatCurrency(Number(invoice.total_incl_vat));
  }, [formatCurrency, invoice]);

  const invoiceSender = useMemo((): InvoiceSender | undefined => {
    if (!invoice) {
      return undefined;
    }
    return {
      name: contactName(invoice.historic_supplier) ?? '',
      address_line1: invoice.historic_supplier.address_line1,
      address_line2: invoice.historic_supplier.address_line2,
      address_line3: invoice.historic_supplier.address_line3,
      logo: invoice.logo,
      city: invoice.historic_supplier.city,
      postcode: invoice.historic_supplier.postcode,
      phone_number: invoice.historic_supplier.phone_number,
      invoice_mail_address: invoice.historic_supplier.email,
      bank_code: invoice.historic_supplier.bank_code,
      bank_name: invoice.historic_supplier.bank_name,
      account_number: invoice.historic_supplier.account_number,
      IBAN: invoice.historic_supplier.IBAN,
      vat_number: invoice.historic_supplier.vat_number,
      state: invoice.historic_supplier.state,
      country: invoice.historic_supplier.country,
    };
  }, [invoice]);

  if (!invoice) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>{t('invoice', 'Invoice')}</title>
      </Helmet>
      <div className='min-h-full flex justify-center md:py-12 md:bg-white md:bg-gradient-to-r md:from-neutral-100'>
        <div className='p-4 xl:p-0 flex flex-col md:flex-col-reverse xl:flex-row gap-4 md:gap-6 xl:gap-16 md:mx-0'>
          <div className='flex-0 hidden md:flex xl:w-[21cm] xl:min-h-[29.7cm] p-2 md:p-4 xl:p-12 bg-white shadow-letter flex-col gap-4 text-xs md:text-base'>
            <InvoiceSenderLogoBlock logoUrl={invoice.logo} />
            <InvoiceSenderBlock invoice={invoice} organization={invoiceSender} />
            <div className='pl-8 pb-8 flex'>
              <InvoiceReceiverBlock receiver={invoice.historic_customer} />
            </div>
            <InvoiceInfoBlock invoice={invoice} />
            <InvoiceItemsBlock invoice={invoice} />
            <div className='grow flex items-end'>
              <InvoiceFooterBlock invoice={invoice} />
            </div>
          </div>

          <div className='xl:w-96 flex flex-col gap-6'>
            <h1 className='text-2xl font-medium mt-4 '>{t('payment', 'Payment')}</h1>
            <p>
              {t(
                'public-invoice-intro',
                '{{ senderName }} kindly requests you the pay the amount of {{ totalInclVat }} before {{ expDate }}. You can do this by clicking the button below and follow the instructions. For any questions you can reach us by phone or email.',
                {
                  senderName: invoice.historic_supplier.business_name,
                  totalInclVat,
                  expDate,
                  invoiceNr,
                },
              )}
            </p>
            <dl>
              <dt className='font-medium text-sm mb-1'>{t('invoice-number', 'Invoice number')}</dt>
              <dd>
                <InvoiceNumber invoice={invoice} />
              </dd>
              <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('invoice-date', 'Invoice date')}</dt>
              <dd>{invoice.date && new Intl.DateTimeFormat(invoice.language).format(new Date(invoice.date))}</dd>
              {invoice.can_create_payment_link && (
                <>
                  <dt className='font-medium mt-2 pt-2 border-t text-sm mb-1'>{t('payments-status', 'Payment status')}</dt>
                  <dd>
                    <Badge size={BadgeSize.Normal} color={PaymentStatusToColor(invoice.payment_status)}>
                      {PaymentStatusToString(t, invoice.payment_status)}
                    </Badge>
                  </dd>
                </>
              )}
            </dl>
            {invoice.public_access_uuid && (
              <Button
                icon={<DownloadSimple />}
                loading={downloadPreparing}
                variant={ButtonVariant.Default}
                onClick={() => download(invoice.public_access_uuid ?? '')}
              >
                {t('download-invoice-pdf', 'Download Invoice (pdf)')}
              </Button>
            )}
            {invoice.payment_status === PaymentStatus.OPEN && invoice.can_create_payment_link && (
              <>
                <ErrorSection errors={loadingPaymentUrlError} />
                <Button loading={loadingPaymentUrl} variant={ButtonVariant.Primary} onClick={getPaymentLink}>
                  {t('pay-invoice', 'Pay Invoice')}
                </Button>
                <div className='flex gap-1 justify-center mt-2'>
                  <img src='/payment-methods/applepay.svg' alt='Apple Pay' />
                  <img src='/payment-methods/amex.svg' alt='American Express' />
                  <img src='/payment-methods/maestro.svg' alt='Maestro' />
                  <img src='/payment-methods/visa.svg' alt='Visa' />
                  <img src='/payment-methods/vpay.svg' alt='Vpay' />
                  <img src='/payment-methods/ideal.svg' alt='iDeal' />
                  <img src='/payment-methods/paypal.svg' alt='Paypal' />
                </div>
              </>
            )}
            <p className='text-xs text-center text-neutral-400'>
              <span>{t('powered-by', 'Powered by')} </span>
              <a className='underline' href='https://equinem.com'>
                {'Equinem'}
              </a>
              <span>, {t('payment-by', 'payment by')} </span>
              <a className='underline' href='https://mollie.com'>
                {'Mollie'}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
