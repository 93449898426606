import { Popover, Transition } from '@headlessui/react';
import { Barn, Funnel } from '@phosphor-icons/react';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonInternal } from '../../../ui/Button';
import { ButtonSize, ButtonVariant } from '../../../ui/Button';
import { ActionModal } from '../../../ui/Modals';
import { AppliedListFilter, ListFilterType } from '.';
import ListFilterSelector from './ListFilterSelector';
import useListFilter from './useListFilter';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';

export enum FilterButtonTypeEnum {
  Filter = 'filter',
  Stable = 'stable',
}

interface ButtonProps {
  listFilterTypes: ListFilterType[];

  type?: FilterButtonTypeEnum;

  // Show a badge if any filters are enabled. This badge shows the number of filters that are enabled.
  showCountBadge?: boolean;

  // a display function to show the current count of items in the list
  currentCountDisplay?: () => string;

  // Custom icon for the button.
  icon?: ReactNode;

  // Hide the icon on mobile
  hideIconOnMobile?: boolean;
}

interface ElementProps {
  listFilterTypes: ListFilterType[];
  currentCountDisplay?: () => string;
  children: ReactNode;
}

/**
 * Our go-to filter button with dialog
 */
export default function ListFilterButton({
  listFilterTypes,
  type = FilterButtonTypeEnum.Filter,
  showCountBadge = false,
  icon,
  hideIconOnMobile = true,
  currentCountDisplay,
}: ButtonProps): JSX.Element {
  const { filters } = useListFilter(listFilterTypes);
  const { t } = useTranslation();

  const buttonText = useMemo((): string => {
    // the stable type just show the name if no filters are applied
    // otherwise it shows the count of selected filters
    if (type === FilterButtonTypeEnum.Stable && filters.length === 0) {
      return t('stable', 'Stable');
    }

    // the filter type shows 'Filter' if no filters are applied
    if (type === FilterButtonTypeEnum.Filter) {
      return t('filter', 'Filter');
    }

    // When we only have one filter and there are multiple selected. Then just display 'x selected'.
    // This keeps the button small.
    if (filters.length === 1 && filters[0].options.length > 1) {
      return t('count-selected', '{{count}} selected', { count: filters[0].options.length });
    }

    // Create a button text based on the filter summary.
    return filters
      .map(appliedFilter => {
        return appliedFilter.options.map(value => value.name).join(', ');
      })
      .join(', ');
  }, [filters, t, type]);

  /**
   * Generate the icon for the button.
   */
  const generatedIcon = useMemo(() => {
    if (icon) {
      return icon;
    }

    if (type === FilterButtonTypeEnum.Stable) {
      return <Barn />;
    }

    return <Funnel />;
  }, [icon, type]);

  return (
    <ListFilterElement listFilterTypes={listFilterTypes} currentCountDisplay={currentCountDisplay}>
      <ButtonInternal
        badge={showCountBadge ? filters.length : undefined}
        size={ButtonSize.Normal}
        variant={ButtonVariant.Default}
        compress={true}
        icon={generatedIcon}
        showIconOnlyInCompressModeOnMobile={hideIconOnMobile}
      >
        {buttonText}
      </ButtonInternal>
    </ListFilterElement>
  );
}

// A list filter button that show a dropdown or modal of the ListFilterSelector
// depending on the screensize.
export function ListFilterElement({ listFilterTypes, currentCountDisplay, children }: ElementProps): JSX.Element {
  const { filters, setFilters } = useListFilter(listFilterTypes);
  const { t } = useTranslation();
  const [stagedFilter, setStagedFilter] = useState<AppliedListFilter[]>([...filters]);
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useScreenSize();

  return (
    <>
      {width <= ScreenSize.md && (
        <>
          <button onClick={() => setIsOpen(true)}>{children}</button>
          <ActionModal
            footerText={currentCountDisplay?.()}
            actions={[
              {
                variant: ButtonVariant.Default,
                text: t('cancel', 'Cancel'),
                onClick: () => setIsOpen(false),
              },
              {
                variant: ButtonVariant.Primary,
                text: t('apply-filter', 'Apply filter'),
                onClick: () => {
                  setFilters(stagedFilter ? [...stagedFilter] : []);
                  setIsOpen(false);
                },
              },
            ]}
            open={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <ListFilterSelector
              update={filters => {
                setStagedFilter(filters);
              }}
              listFilterTypes={listFilterTypes}
            />
          </ActionModal>
        </>
      )}

      {width > ScreenSize.md && (
        <Popover>
          {({ close }) => (
            <>
              <Popover.Button className={'!outline-none'}>{children}</Popover.Button>
              <Transition
                as={Fragment}
                enter='transition-opacity ease-out duration-200'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Popover.Panel className='absolute z-20 w-60 bg-white border rounded-lg mt-1 shadow-lg'>
                  <ListFilterSelector
                    update={filters => {
                      setStagedFilter(filters);
                    }}
                    listFilterTypes={listFilterTypes}
                  />
                  <div className='bg-neutral-50 p-4 rounded-b-lg space-y-2'>
                    <Button
                      className='w-full'
                      variant={ButtonVariant.Primary}
                      onClick={() => {
                        setFilters(stagedFilter ? [...stagedFilter] : []);
                        close();
                      }}
                      size={ButtonSize.Normal}
                    >
                      {t('apply-filter', 'Apply filter')}
                    </Button>
                    {currentCountDisplay && <p className='text-xs text-center text-gray-500'>{currentCountDisplay()}</p>}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
    </>
  );
}
