/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `HARD` - Hard
 * * `QUITE HARD` - Quite Hard
 * * `QUITE SOFT` - Quite Soft
 * * `SOFT` - Soft
 */
export enum SoftnessEnum {
  HARD = 'HARD',
  QUITE_HARD = 'QUITE HARD',
  QUITE_SOFT = 'QUITE SOFT',
  SOFT = 'SOFT',
}
