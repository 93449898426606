import { OpenAPI } from 'openapi';
import { request } from 'openapi/core/request';

export default class UploadFile {
  public static async upload(host: string, file: ArrayBuffer, query: Record<string, unknown>): Promise<URL> {
    const { url, fields } = await request<{ url: string; fields: Record<string, string> }>(
      { ...OpenAPI, BASE: '' },
      {
        method: 'GET',
        url: host + `/api/s3`,
        query,
      },
    );

    const formData = new FormData();

    // Add the form fields we've gotten from s3 to the form data.
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    // And add the file too of course.
    formData.append('file', new Blob([file]));

    const uploadResponse = await fetch(url, { method: 'POST', body: formData });

    if (!uploadResponse.ok) {
      console.error('Failed to upload file to s3 presigned url', uploadResponse.body);
      throw Error('Failed to upload file to s3 presigned url');
    }

    // Return the url from where the file can be found.
    return new URL(fields.key, url);
  }

  public static async uploadHorseAvatar(
    host: string,
    file: ArrayBuffer,
    horseUid: string,
    organizationUid: string,
    mimeType: string,
  ): Promise<URL> {
    return this.upload(host, file, {
      contentType: mimeType,
      horseUid: horseUid,
      organizationUid: organizationUid,
      requestType: 'horse-avatar',
    });
  }

  public static async uploadAccountAvatar(host: string, file: ArrayBuffer, mimeType: string): Promise<URL> {
    return this.upload(host, file, {
      contentType: mimeType,
      requestType: 'account-avatar',
    });
  }

  public static async uploadOrganizationLogo(host: string, file: ArrayBuffer, organizationUid: string, mimeType: string): Promise<URL> {
    return this.upload(host, file, {
      contentType: mimeType,
      organizationUid: organizationUid,
      requestType: 'organization-logo',
    });
  }

  // Upload terms and conditions for webshop orders.
  public static async uploadOrganizationShopTerms(
    host: string,
    file: ArrayBuffer,
    organizationUid: string,
    mimeType: string,
  ): Promise<URL> {
    return this.upload(host, file, {
      contentType: mimeType,
      organizationUid: organizationUid,
      requestType: 'organization-shop-terms',
    });
  }

  public static async uploadHorseFile(
    host: string,
    file: ArrayBuffer,
    horseUid: string,
    organizationUid: string,
    mimeType: string,
  ): Promise<URL> {
    return this.upload(host, file, {
      contentType: mimeType,
      horseUid: horseUid,
      organizationUid: organizationUid,
      requestType: 'horse-file',
    });
  }
}
