import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { EquinemMetaHeader } from 'ui/Layout/MetaHeader';
import Splash from './Splash';

/**
 * The splash wrapper can by styled for different purposes. The Anonymous style
 * is used for webshops logins and signups but also for forgot password. We want
 * this anonymous because we don't want to big Equinem marketing messages in it.
 *
 * The Equinem wrapper style is used for our default Equinem usage. It shows a
 * split screen with a big photo and Equinem marketing messages.
 */
export enum WrapperStyle {
  Anonymous, // No branding is applied.
  Equinem,
  // PaReg, <-- We can add this later.
}
interface Props {
  children: ReactNode;
  compact?: boolean; // Set to true when need more space to show your component.
  title: string;
  subTitle?: ReactNode;
  styleType: WrapperStyle;
}

export default function SplashWrapper({ children, compact, title, subTitle, styleType }: Props): JSX.Element {
  if (styleType === WrapperStyle.Anonymous) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className='flex md:items-center justify-center min-h-screen md:bg-neutral-100 md:py-2'>
          <div className='w-full md:w-4/6 lg:w-2/6 p-8 md:rounded-xl md:bg-white'>
            <div className='mb-2'>
              <h2 className='text-center lg:text-left text-2xl text-gray-800 font-semibold tracking-tight'>{title}</h2>
              <p>{subTitle}</p>
            </div>
            {children}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <EquinemMetaHeader />
        <div className='flex h-full min-h-screen'>
          <div className='flex-1 max-h-full w-full md:w-auto max-w-3xl mx-auto'>
            {!compact && (
              <div className='mt-safe-offset-4 md:mt-safe-offset-10 lg:mt-safe-offset-4 px-4 flex justify-center lg:justify-start'>
                <img className='h-10 w-auto' src='/logo.svg' alt='EquineM' />
              </div>
            )}
            <div
              className={classNames(
                { 'px-10 lg:px-20 xl:px-36 md:px-20 pt-4 lg:pt-12 xl:pt-18 pb-8 lg:pb-4': !compact },
                { 'p-10': compact },
              )}
            >
              <div className='flex flex-col mb-2'>
                <h2 className='text-center lg:text-left text-2xl text-gray-800 font-semibold tracking-tight'>{title}</h2>
                {subTitle}
              </div>
              {children}
            </div>
          </div>
          <Splash />
        </div>
      </>
    );
  }
}
