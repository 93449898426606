import { CaretRight, Horse as HorseIcon, Plus } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import { AppRoutes } from 'AppRoutes';
import CreateHorseModal from 'components/Horses/CreateHorseModal';
import { HorseUsageBadges } from 'components/Horses/HorseUsageBadges';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import usePermissions from 'hooks/UsePermissions';
import { Contact, Horse, HorseOwner, HorseownersService, HorsesService, ModulePermissionsEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import Button from 'ui/Button';
import { table, tableTbody, tableTbodyTr, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import { navBackToThisPage } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import OptimizedImage from 'ui/OptimizedImage';
import { ApiPromises } from 'utilities/ApiPromises';
import { AllColors } from 'utilities/colors';
import { bloodlineString } from 'utilities/Horse';

interface Props {
  contact: Contact;
}

const SHOWED_HORSE_COUNT = 10;

function OwnedHorseListTile({ contact }: Props): JSX.Element {
  const [horses, setHorses] = useState<Horse[]>();
  const [horseOwners, setHorseOwners] = useState<HorseOwner[]>();
  const [horsesCount, setHorsesCount] = useState<number>(0);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { loading } = useApiPromises({ apiPromises });

  const { closeModal: closeCreateHorseModal, modalIsVisible: createHorseModalIsVisible, showModal: showCreateHorseModal } = useModal();
  const { formatDate, formatNumber } = useAccount();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { hasPermission } = usePermissions();

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }

    // load all horseoweners
    promises.appendList<HorseOwner>(
      'horseowners',
      () =>
        HorseownersService.horseownersList({
          horseOrganisationUid: selectedOrganization?.uid ?? '',
          contactUid: contact.uid,
          pageSize: SHOWED_HORSE_COUNT,
        }),
      setHorseOwners,
    );

    // load all horses that are owned by this contact
    promises.appendList<Horse>(
      'owned-horses',
      () =>
        HorsesService.horsesList({
          organisationUid: selectedOrganization?.uid ?? '',
          ownerUid: contact.uid,
          hidden: false,
        }),
      setHorses,
      undefined,
      undefined,
      setHorsesCount,
    );

    setApiPromises(promises);

    return promises;
  }, [contact.uid, selectedOrganization]);

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      return [
        {
          icon: <Plus />,
          text: t('add', 'Add'),
          onClick: showCreateHorseModal,
        },
      ];
    }
  }, [hasPermission, showCreateHorseModal, t]);

  /**
   * Get a single horse by UID.
   */
  const getHorse = (horseUid: string): Horse | undefined => {
    return horses?.find(horse => horse.uid === horseUid);
  };

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  return (
    <Tile title={t('horse-ownership', 'Horse ownership')} className='mb-4' loading={loading} actions={tileActions}>
      <p className='mb-4'>{t('horse-ownership-title-desc', 'The horses that are owned or partially owned by this contact.')}</p>

      <table className={table}>
        <thead className={tableThead}>
          <tr>
            <td className='w-10' />
            <td className={tableTheadTd}>{t('name', 'Name')}</td>
            <td className={tableTheadTd}>{t('since', 'Since')}</td>
            <td className={tableTheadTd}>{t('percentage', 'Percentage')}</td>
            <td className='w-10' />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {horseOwners?.length === 0 && (
            <tr className={tableTbodyTrNoClick}>
              <td colSpan={5} className='text-center italic text-gray-600'>
                {t('no-horses', 'No horses')}
              </td>
            </tr>
          )}
          {horseOwners
            // first include the horses that are not found in the horses list
            ?.map(owner => ({
              owner,
              horse: getHorse(owner?.horse_uid),
            }))
            // and make sure the horst is found
            // where the added a typeguard to make sure the horse is not undefined
            .filter((res): res is { owner: HorseOwner; horse: Horse } => res.horse !== undefined)
            .map(({ horse, owner }) => (
              <tr
                className={tableTbodyTr}
                key={owner.uid}
                onClick={() => navigate(`${generatePath(AppRoutes.HorsesDetails.path, { uid: owner.horse_uid })}?${navBackToThisPage()}`)}
              >
                <td className='text-center w-14 pl-2'>
                  {!horse.avatar_file && (
                    <div className='rounded-full bg-gray-200 w-11 h-11 flex items-center justify-center'>
                      <HorseIcon size={22} weight='light' className='inline' />
                    </div>
                  )}
                  {horse.avatar_file && (
                    <OptimizedImage className='object-cover rounded-full p-0.5 w-12 h-12 md:p-1' src={horse.avatar_file} width={64} />
                  )}
                </td>
                <td>
                  <p className='flex gap-2 items-center'>
                    <span>{horse.name}</span>
                    {!horse.hidden && <HorseUsageBadges size={BadgeSize.Small} iconOnly={true} horse={horse} />}
                    {horse.hidden && <Badge color={AllColors.Rose}>{t('inactive', 'Inactive')}</Badge>}
                  </p>
                  <p className='text-xs text-gray-600'>{bloodlineString(horse)}</p>
                </td>
                <td>{owner.owner_since ? formatDate(owner.owner_since) : '-'}</td>
                <td>{formatNumber(Number(owner.percentage))}%</td>
                <td className='w-10'>
                  <CaretRight />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {horseOwners && horseOwners.length > SHOWED_HORSE_COUNT && (
        <div className='mt-2 flex w-full justify-center'>
          <Button
            onClick={() =>
              navigate(`${generatePath(AppRoutes.HorsesList.path)}?owned=${contact.uid}&passive=no-stable&${navBackToThisPage()}`)
            }
          >
            {t('show-all-n-horses', 'Show all {{horsesCount}} horses', { horsesCount })}
          </Button>
        </div>
      )}

      <CreateHorseModal
        open={createHorseModalIsVisible}
        onRequestCloseModal={closeCreateHorseModal}
        newOwner={contact}
        disableStable={true}
        disableUsage={true}
        disableLocation={true}
        onCreated={() => {
          loadApiData();
        }}
      />
    </Tile>
  );
}

export default OwnedHorseListTile;
