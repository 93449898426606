import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useApiPromises from 'api/hooks/useApiPromises';
import useFormError from 'api/hooks/useFormError';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import useHorseDetail from 'hooks/UseHorseDetail';
import usePermissions from 'hooks/UsePermissions';
import { Contact, HorseDetail, HorsesService, ModulePermissionsEnum, PatchedHorseDetail, Role, RolesService } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { objectDiff } from 'utilities/compareObject';
import { contactName } from 'utilities/Contact';

function HorseTeamFormTile(): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [roles, setRoles] = useState<Role[]>();

  const { horse, setHorse, contacts } = useHorseDetail();
  const { t } = useTranslation();
  const { showModal, closeModal, modalIsVisible } = useModal();
  const { selectedOrganization, selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { hasPermission } = usePermissions();
  const { loading } = useApiPromises({ apiPromises });

  /**
   * return a list of contacts without your own locations
   */
  const filteredContacts = useMemo(() => {
    if (!contacts) return [];
    return contacts.filter(contact => !contact.external_location && !contact.stable_location_uid);
  }, [contacts]);

  /**
   * Transform the list of contacts into 2 groups based on the role we have set. The
   * first group are the contacts with the role we're looking for. The other group is
   * the rest.
   */
  const contactListGrouped = useCallback(
    (role: Role | undefined): WrappedComboboxProps<Contact>[] => {
      const result: WrappedComboboxProps<Contact>[] = [];
      if (!role) return result;
      result.push({ items: filteredContacts?.filter(contact => contact.roles?.includes(role.uid)) ?? [], heading: role.name });
      result.push({ items: filteredContacts?.filter(contact => !contact.roles?.includes(role.uid)) ?? [], heading: t('others', 'Others') });
      return result;
    },
    [filteredContacts, t],
  );

  /**
   * Define the roles we're looking for
   * We can safely assume that the default_id is fixed and will not changed :-)
   */
  const riderRole = useMemo(() => roles?.find(role => role.default_id === 10), [roles]);
  const groomRole = useMemo(() => roles?.find(role => role.default_id === 6), [roles]);
  const farrierRole = useMemo(() => roles?.find(role => role.default_id === 4), [roles]);
  const vetRole = useMemo(() => roles?.find(role => role.default_id === 2), [roles]);
  const trainerRole = useMemo(() => roles?.find(role => role.default_id === 8), [roles]);

  // Form validation
  const schema = useMemo(() => {
    return schemas.HorseDetail.pick({
      default_rider_uid: true,
      default_groom_uid: true,
      default_farrier_uid: true,
      default_vet_uid: true,
      default_trainer_uid: true,
    });
  }, []);

  // Construct the default values
  const defaultValues = useMemo((): Partial<HorseDetail> => {
    return {
      default_rider_uid: horse?.default_rider_uid,
      default_groom_uid: horse?.default_groom_uid,
      default_farrier_uid: horse?.default_farrier_uid,
      default_vet_uid: horse?.default_vet_uid,
      default_trainer_uid: horse?.default_trainer_uid,
    };
  }, [horse]);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
    setValue,
  } = useForm<HorseDetail>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    closeModal();
    if (resetForm) {
      reset(defaultValues);
    }
  };

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorseDetail> = async (data: HorseDetail) => {
    if (!horse || !selectedOrganization) return;

    setSubmitting(true);
    try {
      // generate a diff of the given form data and the current horse object
      // only the changed values are pushed to the server
      const updateRequest = objectDiff<PatchedHorseDetail>(horse, data);
      const updateHorsePromise = HorsesService.horsesPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: horse.uid,
        requestBody: updateRequest,
      });
      const updatedHorse = await updateHorsePromise;

      // Update the horse
      setHorse(updatedHorse);

      // close the modal
      closeModal();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      return [
        {
          onClick: showModal,
          text: t('edit', 'Edit'),
          buttonVariant: ButtonVariant.Default,
          icon: <Pencil />,
        },
      ];
    }
  }, [hasPermission, showModal, t]);

  /**
   * Get the correct name based on the contact UID
   */
  const getContactName = (contactUid: string) => {
    const contact = contacts?.find(contact => contact.uid === contactUid);
    return contact ? contactName(contact) : contactUid;
  };

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }

    promises.appendList<Role>(
      'roles',
      () =>
        RolesService.rolesList({
          organisationUid: selectedOrganizationUid,
        }),
      setRoles,
      generateCacheKey('roles'),
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  /**
   * Update the form when the horse has been updated (triggered mostly by an update of the form)
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Tile title={t('team', 'Team')} loading={horse === undefined || loading} actions={tileActions}>
      {horse && (
        <TileDescriptionList
          list={[
            {
              term: t('default-rider', 'Default rider'),
              definition: horse.default_rider_uid ? getContactName(horse.default_rider_uid) : '-',
            },
            {
              term: t('default-groom', 'Default groom'),
              definition: horse.default_groom_uid ? getContactName(horse.default_groom_uid) : '-',
            },
            {
              term: t('default-farrier', 'Default farrier'),
              definition: horse.default_farrier_uid ? getContactName(horse.default_farrier_uid) : '-',
            },
            {
              term: t('default-vet', 'Default vet'),
              definition: horse.default_vet_uid ? getContactName(horse.default_vet_uid) : '-',
            },
            {
              term: t('default-trainer', 'Default trainer'),
              definition: horse.default_trainer_uid ? getContactName(horse.default_trainer_uid) : '-',
            },
          ]}
        />
      )}

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateHorse', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        onClosed={onClosed}
      >
        <PageModalTitle title={t('update-team', 'Update team')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />
          <div className='py-3 space-y-4'>
            <ContactInputSelect
              name='default_rider_uid'
              control={control}
              contacts={contactListGrouped(riderRole)}
              onCreated={contact => setValue('default_rider_uid', contact.uid)}
              error={fieldError('default_rider_uid')}
              label={t('default-rider', 'Default rider')}
              setValueAs={value => (value === undefined ? null : value)}
            />

            <ContactInputSelect
              name='default_groom_uid'
              control={control}
              contacts={contactListGrouped(groomRole)}
              onCreated={contact => setValue('default_groom_uid', contact.uid)}
              error={fieldError('default_groom_uid')}
              label={t('default-groom', 'Default groom')}
              setValueAs={value => (value === undefined ? null : value)}
            />

            <ContactInputSelect
              name='default_farrier_uid'
              control={control}
              contacts={contactListGrouped(farrierRole)}
              onCreated={contact => setValue('default_farrier_uid', contact.uid)}
              error={fieldError('default_farrier_uid')}
              label={t('default-farrier', 'Default farrier')}
              setValueAs={value => (value === undefined ? null : value)}
            />

            <ContactInputSelect
              name='default_vet_uid'
              control={control}
              contacts={contactListGrouped(vetRole)}
              onCreated={contact => setValue('default_vet_uid', contact.uid)}
              error={fieldError('default_vet_uid')}
              label={t('default-vet', 'Default vet')}
              setValueAs={value => (value === undefined ? null : value)}
            />

            <ContactInputSelect
              name='default_trainer_uid'
              control={control}
              contacts={contactListGrouped(trainerRole)}
              onCreated={contact => setValue('default_trainer_uid', contact.uid)}
              error={fieldError('default_trainer_uid')}
              label={t('default-trainer', 'Default trainer')}
              setValueAs={value => (value === undefined ? null : value)}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: submitting,
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateHorse',
              loading: submitting,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

export default HorseTeamFormTile;
