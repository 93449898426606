import { useOrganization } from 'context/OrganizationContext';
import { FacilitiesService, Facility, FacilityEvent, FacilityTypeEnum, Horse, Stable, StablesService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';
import FacilityTile from 'components/Activities/FacilityTile';
import {
  table,
  tableHiddenColumnMd,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTrNoClick,
  tableTheadTd,
  tableTheadTdSticky,
} from 'ui/Const';
import classNames from 'classnames';
import { CalendarCheck, DotsThreeVertical } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import Badge from 'ui/Badge';
import { organizationHorses } from 'utilities/ApiRequests';
import { formatDate } from 'utilities/date.utilities';
import DropdownMenu from 'ui/DropdownMenu';
import useModal from 'ui/Modals/UseModal';
import DeleteFacilityReservationModal from 'components/Activities/DeleteFacilityReservationModal';
import { AllColors } from 'utilities/colors';
import { BadgeSize } from 'ui/Badge/Badge';
import { RRuleSet, rrulestr } from 'rrule';
import { addMonths, startOfToday } from 'date-fns';

interface FacilityTypeGroup {
  facilityType: FacilityTypeEnum;
  stable?: Stable;
  count: number;
}

export default function Reservations(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [horses, setHorses] = useState<Horse[]>();
  const { selectedOrganizationUid, generateCacheKey, selectedOrganization } = useOrganization();
  const { formatDate: formatDatePretty, formatTime } = useAccount();

  const [facilityEvents, setFacilityEvents] = useState<FacilityEvent[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [facilities, setFacilities] = useState<Facility[]>();
  const [stables, setStables] = useState<Stable[]>();
  const { showModal: showDeleteModal, closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible } = useModal();
  const [selectedEvent, setSelectedEvent] = useState<FacilityEvent>();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }
    promises.appendList(
      'facility-events',
      () =>
        FacilitiesService.facilitiesEventsList({
          organisationUid: selectedOrganizationUid ?? '',
          contactUid: selectedOrganization?.me.uid,
          o: 'dtstart',
          dtstartLt: formatDate(addMonths(new Date(), 1)), // Look 1 months in the future
          dtendGt: formatDate(startOfToday()),
        }),
      setFacilityEvents,
    );
    promises.appendList<Facility>(
      'facilities',
      () =>
        FacilitiesService.facilitiesList({
          organisationUid: selectedOrganizationUid,
        }),
      setFacilities,
    );

    promises.appendList<Stable>(
      'stables',
      () =>
        StablesService.stablesList({
          organisationUid: selectedOrganizationUid,
        }),
      setStables,
      generateCacheKey('stables'),
    );
    promises.appendListObj<Horse>('horses', setHorses, organizationHorses(selectedOrganizationUid, generateCacheKey));

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey, selectedOrganization]);

  const facilityTypes = useMemo((): FacilityTypeGroup[] => {
    const result: FacilityTypeGroup[] = [];
    for (const facility of facilities ?? []) {
      const foundIndex = result.findIndex(item => item.facilityType === facility.facility_type && item.stable?.uid === facility.stable_uid);
      if (foundIndex === -1) {
        result.push({
          facilityType: facility.facility_type,
          stable: stables?.find(stable => stable.uid === facility.stable_uid),
          count: 1,
        });
      } else {
        const item = result[foundIndex];
        item.count = item.count + 1;
        result[foundIndex] = item;
      }
    }
    return result;
  }, [facilities, stables]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  const recurrenceText = useCallback((rruleString: string) => {
    // The RRule may come with e.g. EXDATE. Use rrulestr method to be safe.
    const rRuleSet = rrulestr(rruleString, {
      forceset: true,
    }) as RRuleSet;
    const rRule = rRuleSet.rrules()[0];
    // TODO: i18n
    return rRule.toText();
  }, []);

  return (
    <>
      <Page title={t('facilities', 'Facilities')} loading={apiPromises}>
        <div className='gap-4 mb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-2 md:px-0'>
          {facilityTypes.map(facilityTypeGroup => (
            <FacilityTile
              key={`${facilityTypeGroup.facilityType}-${facilityTypeGroup.stable?.uid}`}
              facilityType={facilityTypeGroup.facilityType}
              stable={facilityTypeGroup.stable}
              onClick={() => {
                const params = new URLSearchParams();
                params.set('facility-type', facilityTypeGroup.facilityType);
                if (facilityTypeGroup.stable) {
                  params.set('stable', facilityTypeGroup.stable.uid);
                }
                navigate(AppRoutes.FacilityAvailability.path + '?' + params.toString());
              }}
            />
          ))}
        </div>
        <Tile title={t('my-reservations', 'My reservations')}>
          {!facilityEvents ||
            (facilityEvents.length === 0 && (
              <p className='italic text-neutral-700'>{t('no-upcoming-reservations', 'No upcoming reservations')}</p>
            ))}
          {(facilityEvents?.length ?? 0) > 0 && (
            <table className={table}>
              <thead>
                <tr className={tableHiddenHeaderSm}>
                  <td className={classNames('w-10', tableTheadTdSticky)} />
                  <td className={tableTheadTdSticky}>{t('date', 'Date')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('time', 'Time')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('facility', 'Facility')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('horses', 'Horses')}</td>
                  <td className={tableTheadTdSticky} />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {facilityEvents?.map(facilityEvent => {
                  const facility = facilities?.find(facility => facility.uid === facilityEvent.facility_uid);
                  const stable = stables?.find(stable => stable.uid === facility?.stable_uid);
                  const eventHorses = horses?.filter(horse => facilityEvent.horse_uids?.includes(horse.uid));
                  return (
                    <tr className={tableTbodyTrNoClick} key={facilityEvent.uid}>
                      <td className='text-center w-10'>
                        <CalendarCheck size={24} weight='light' className='inline' />
                      </td>
                      <td>
                        <div>
                          <p>{facilityEvent.start.datetime && formatDatePretty(new Date(Date.parse(facilityEvent.start.datetime)))}</p>
                          {facilityEvent.recurrence && <p className='text-xs'>{recurrenceText(facilityEvent.recurrence)}</p>}
                        </div>
                      </td>
                      <td>
                        {facilityEvent.start.datetime && formatTime(new Date(Date.parse(facilityEvent.start.datetime)))} -{' '}
                        {facilityEvent.end?.datetime && formatTime(new Date(Date.parse(facilityEvent.end.datetime)))}
                      </td>
                      <td>
                        {facility?.name} <Badge>{stable?.location.business_name}</Badge>
                      </td>
                      <td className={tableHiddenColumnMd}>
                        {eventHorses?.map(horse => horse.name).join(', ')}
                        {facilityEvent.is_private_reservation && (
                          <Badge size={BadgeSize.Small} color={AllColors.Fuchsia}>
                            {t('private-reservation', 'Private reservation')}
                          </Badge>
                        )}
                      </td>
                      <td align='right'>
                        <DropdownMenu
                          menuPlacement='right-end'
                          menuItems={[
                            [
                              {
                                element: t('cancel-reservation', 'Cancel reservation'),
                                className: 'text-red-600',
                                onClick: () => {
                                  setSelectedEvent(facilityEvent);
                                  showDeleteModal();
                                },
                              },
                            ],
                          ]}
                        >
                          <button className='px-2'>
                            <DotsThreeVertical size={22} weight='bold' />
                          </button>
                        </DropdownMenu>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Tile>
        <DeleteFacilityReservationModal
          isVisible={deleteModalIsVisible}
          onRequestClose={() => {
            closeDeleteModal();
            setSelectedEvent(undefined);
          }}
          onDeleted={loadApiData}
          event={selectedEvent}
        />
      </Page>
    </>
  );
}
