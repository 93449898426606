import { Contact } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import LocationTable from './Rvo/LocationTable';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  contacts: Contact[];
  onContactUpdated?: () => void;
}

function ConfigureRvoModal({ isVisible, onRequestCloseModal, onContactUpdated, contacts }: Props): JSX.Element {
  const { t } = useTranslation();

  /**
   * List the locations
   */
  const locations = useMemo(() => {
    return (contacts ?? []).filter(
      contact => (contact.external_location && contact.stable_location_uid === null) || contact.stable_location_uid !== null,
    );
  }, [contacts]);

  return (
    <>
      <PageModal open={isVisible}>
        <PageModalTitle title={t('connect-locations-with-rvo', 'Connect locations with RvO')} onClose={onRequestCloseModal} />
        <PageModalContent>
          <p className='mb-5'>
            {t(
              'connect-locations-with-rvo-description',
              'Below you see your locations. You can connect them with the RvO. This way you can import your horses from the RvO.',
            )}
          </p>

          <LocationTable locations={locations} onContactUpdated={onContactUpdated} />
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              variant: ButtonVariant.Primary,
              text: t('close', 'Close'),
              onClick: onRequestCloseModal,
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default ConfigureRvoModal;
