import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { HorsesService, GetHorsesFromRvo } from 'openapi';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import PageModal, { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onStarted: () => void;
}

function RvoImportHorseLocationModal({ isVisible, onRequestCloseModal, onStarted }: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<GetHorsesFromRvo> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  /**
   * Submit handler
   */
  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const promise = HorsesService.horsesGetHorsesFromRvoCreate({
      organisationUid: selectedOrganization?.uid ?? '',
    });

    try {
      await promise;
      onStarted();
      onRequestCloseModal();
    } catch (e) {
      setApiError(new ApiErrorParser(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [onRequestCloseModal, onStarted, selectedOrganization?.uid]);

  /**
   * Render for both success and the default state the actions
   */
  const actions = useMemo((): ActionProps[] => {
    return [
      {
        loading: isSubmitting,
        variant: ButtonVariant.Primary,
        text: t('import', 'Import'),
        type: 'submit',
        onClick: onSubmit,
      },
    ];
  }, [isSubmitting, onSubmit, t]);

  return (
    <PageModal open={isVisible} onClosed={() => setApiError(undefined)}>
      <PageModalTitle title={t('rvo-import-from-rvo-modal-title', 'Import from RvO')} onClose={onRequestCloseModal} />
      <PageModalContent>
        <div className='mt-2 space-y-4'>
          <ErrorSection errors={apiError} />
          <p>
            {t(
              'rvo-import-from-rvo-modal-desc',
              'You are about to import the horses and their locations from the RvO for all of you connected locations. In this process that will be run in the background, we will create horses that are not yet created in Equinem. Are you sure you want to continue?',
            )}
          </p>
        </div>
      </PageModalContent>
      <PageModalActions actions={actions} />
    </PageModal>
  );
}

export default RvoImportHorseLocationModal;
