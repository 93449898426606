import classNames from 'classnames';
import { FertilizerBudgetTypeEnum, FertilizerConsumer } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { table, tableHiddenColumnMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import SaveFertilizerConsumerModal from './SaveFertilizerConsumerModal';
import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import useModal from 'ui/Modals/UseModal';
import { PageAction } from 'context/PageContext';
import DropdownMenu from 'ui/DropdownMenu';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import { BadgeSize } from 'ui/Badge/Badge';
import { animalNitrogenLandTypeEnumTOString } from './SaveFertilizerConsumerModal/AnimalNitrogenForm';
import DeleteFertilizerConsumerModal from './DeleteFertilizerConsumerModal';
import { ApiPromises } from 'utilities/ApiPromises';

interface Props {
  fertilizerConsumers: FertilizerConsumer[] | undefined;
  onSaved: () => void;
  onDeleted: () => void;
  apiPromises: ApiPromises | undefined;
}

/**
 * This component is used to calculate the usable space for animal manure
 *
 * This step is part of step 1 of the RVO document
 * @see https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf
 */
function CalculateBudgetAnimalNitrogenTile({ fertilizerConsumers, onSaved, onDeleted, apiPromises }: Props): JSX.Element {
  const [selectedFertilizerConsumer, setSelectedFertilizerConsumer] = useState<FertilizerConsumer | undefined>();

  const { t } = useTranslation();
  const { closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible, showModal: showSaveModal } = useModal();
  const { closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible, showModal: showDeleteModal } = useModal();

  /**
   * Return only the lise of consumers that are of type ANIMAL_NITROGEN
   */
  const filteredFertilizerConsumers = useMemo(() => {
    return (fertilizerConsumers ?? []).filter(consumer => consumer.fertilizer_budget_type === FertilizerBudgetTypeEnum.ANIMAL_NITROGEN);
  }, [fertilizerConsumers]);

  /**
   * return the total of the filtered consumers
   */
  const total = useMemo(() => {
    return filteredFertilizerConsumers.reduce((acc, consumer) => acc + consumer.total, 0);
  }, [filteredFertilizerConsumers]);

  const actions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add', 'Add'),
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: showSaveModal,
      },
    ];
  }, [showSaveModal, t]);

  return (
    <Tile
      title={`1. ${t('calculate-the-usable-space-for-animal-manure', 'Calculate the usable space for animal manure')}`}
      actions={actions}
      loading={apiPromises}
    >
      <table className={table}>
        <thead className={tableThead}>
          <tr>
            <td className={classNames('w-64', tableTheadTd, tableHiddenColumnMd)}>{t('land-type', 'Land type')}</td>
            <td className={classNames('w-32', tableTheadTd, tableHiddenColumnMd)}>{t('hectares', 'Hectares')}</td>
            <td className={classNames('w-52', tableTheadTd, tableHiddenColumnMd)}>{t('consumption-norm', 'Consumption norm')}</td>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>{t('usable-space', 'Usable space')}</td>
            <td className={classNames('w-10', tableTheadTd, tableHiddenColumnMd)} />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {filteredFertilizerConsumers.map(consumer => (
            <tr key={consumer.uid} className={tableTbodyTrNoClick}>
              <td className={tableHiddenColumnMd}>
                <p>{animalNitrogenLandTypeEnumTOString(consumer.land_type_nitrogen, t)}</p>
                {consumer.description && <p className='text-gray-500 text-sm'>{consumer.description}</p>}
              </td>
              <td className={tableHiddenColumnMd}>
                {consumer.area} {'ha'}
              </td>
              <td className={tableHiddenColumnMd}>
                <div className='flex gap-x-2 items-center'>
                  <p>
                    {consumer.consumption_norm} {'kg/ha'}
                  </p>
                  {consumer.has_derogation && (
                    <Badge size={BadgeSize.Small} color={AllColors.Blue}>
                      {t('derogration', 'Derogration')}
                    </Badge>
                  )}
                </div>
              </td>
              <td className={tableHiddenColumnMd}>
                {consumer.total} {'kg'}
              </td>

              {/* Mobile view */}

              <td className='md:hidden' colSpan={4}>
                <div className='grid grid-cols-2'>
                  <div className='py-2 space-y-1'>
                    <div>
                      <p className='text-gray-500 text-sm'>{t('land-type', 'Land type')}</p>
                      <p>{animalNitrogenLandTypeEnumTOString(consumer.land_type_nitrogen, t)}</p>
                    </div>
                    <div>
                      <p className='text-gray-500 text-sm'>{t('hectares', 'Hectares')}</p>
                      <p>
                        {consumer.area} {'ha'}
                      </p>
                    </div>
                  </div>

                  <div className='py-2 space-y-1'>
                    <div>
                      <p className='text-gray-500 text-sm'>{t('consumption-norm', 'Consumption norm')}</p>
                      <div className='flex gap-x-2 items-center'>
                        <p>
                          {consumer.consumption_norm} {'kg/ha'}
                        </p>
                        {consumer.has_derogation && (
                          <Badge size={BadgeSize.Small} color={AllColors.Blue}>
                            {t('derogration', 'Derogration')}
                          </Badge>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className='text-gray-500 text-sm'>{t('usable-space', 'Usable space')}</p>
                      <p>
                        {consumer.total} {'kg'}
                      </p>
                    </div>
                  </div>
                </div>
              </td>

              <td align='right'>
                <DropdownMenu
                  menuPlacement='right-end'
                  menuItems={[
                    [
                      {
                        element: t('edit', 'Edit'),
                        onClick: () => {
                          setSelectedFertilizerConsumer(consumer);
                          showSaveModal();
                        },
                      },
                    ],
                    [
                      {
                        element: t('remove', 'Remove'),
                        className: 'text-red-600',
                        onClick: () => {
                          setSelectedFertilizerConsumer(consumer);
                          showDeleteModal();
                        },
                      },
                    ],
                  ]}
                >
                  <button className='px-2'>
                    <DotsThreeVertical size={20} weight='bold' />
                  </button>
                </DropdownMenu>
              </td>
            </tr>
          ))}

          <tr className={tableTbodyTrNoClick}>
            <td colSpan={3}>
              <p className='font-bold'>{t('total', 'Total')}</p>
              <p className='text-gray-500 text-sm'>{t('usable-space', 'Usable space')}</p>
            </td>
            <td className='font-bold' colSpan={2}>
              {total} {'kg'}
            </td>
          </tr>
        </tbody>
      </table>

      <SaveFertilizerConsumerModal
        fertilizerConsumer={selectedFertilizerConsumer}
        type={FertilizerBudgetTypeEnum.ANIMAL_NITROGEN}
        isVisible={saveModalIsVisible}
        onRequestCloseModal={closeSaveModal}
        onSaved={onSaved}
        // we reset the selected consumer when the modal is closed
        // otherwise you will see a glitch
        onClosed={() => setSelectedFertilizerConsumer(undefined)}
      />

      {selectedFertilizerConsumer && (
        <DeleteFertilizerConsumerModal
          type={FertilizerBudgetTypeEnum.ANIMAL_NITROGEN}
          fertilizerConsumer={selectedFertilizerConsumer}
          isVisible={deleteModalIsVisible}
          onDeleted={() => {
            onDeleted();
            setSelectedFertilizerConsumer(undefined);
          }}
          onRequestClose={closeDeleteModal}
        />
      )}
    </Tile>
  );
}

export default CalculateBudgetAnimalNitrogenTile;
