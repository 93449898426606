import { IconContext, X } from '@phosphor-icons/react';
import React, { ReactNode } from 'react';

export interface TitleBarActionProps {
  text: string;
  icon: ReactNode;
  onClick: () => void;
}
interface Props {
  title?: string;
  onClose?: () => void;
  actions: TitleBarActionProps[];
}

// A ribbon of buttons that is intended to show on top (right) of a modal.
export default function TitleBarButtons({ title, onClose, actions }: Props): JSX.Element {
  return (
    <div className='flex flex-row justify-end items-center mb-1 gap-3 md:gap-0'>
      {title && <p className='select-none justify-self-start grow font-semibold text-gray-900 truncate'>{title}</p>}
      {actions.map((action, index) => (
        <div key={action.text + index} className={'group p-1 block cursor-pointer'} onClick={action.onClick}>
          <div className='group-hover:bg-neutral-100 p-1 rounded-full'>
            <IconContext.Provider
              value={{
                size: 18,
              }}
            >
              {action.icon}
            </IconContext.Provider>
          </div>
        </div>
      ))}

      {onClose !== undefined && (
        <div className={'group p-1 block cursor-pointer'} onClick={onClose}>
          <div className='bg-neutral-100 group-hover:bg-neutral-200 p-2 rounded-full'>
            <X size={18} />
          </div>
        </div>
      )}
    </div>
  );
}
