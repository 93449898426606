import { CaretLeft, CaretRight, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { PublicHorseMediaSet } from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { Portal } from 'react-portal';

interface Props {
  images: PublicHorseMediaSet[] | undefined;
  isVisible: boolean;
  onRequestClose: () => void;
  index: number; // index where the slide show should start
}

/**
 * Small slideshow component that will show a list of images
 */
export default function SlideShow({ images, isVisible, onRequestClose, index }: Props): JSX.Element {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [prevStepIndex, setPrevStepIndex] = useState<number>(0);

  /**
   * Go to the next step
   */
  const goToNext = useCallback(() => {
    const newState = currentStepIndex < (images ?? []).length - 1 ? currentStepIndex + 1 : currentStepIndex;
    setPrevStepIndex(currentStepIndex);
    setCurrentStepIndex(newState);
  }, [currentStepIndex, images]);

  /**
   * Go to the prev step
   */
  const goToPrev = useCallback(() => {
    const newState = currentStepIndex > 0 ? currentStepIndex - 1 : currentStepIndex;
    setPrevStepIndex(currentStepIndex);
    setCurrentStepIndex(newState);
  }, [currentStepIndex]);

  // flag that indicate if we can go to the next/prev step
  const canGoNext = currentStepIndex < (images ?? []).length - 1;
  const canGoPrev = currentStepIndex > 0;

  /**
   * Close fn
   */
  const close = useCallback(() => {
    setCurrentStepIndex(0);
    onRequestClose();
  }, [onRequestClose]);

  /**
   * Handle keyboard events
   */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          if (canGoPrev) {
            goToPrev();
          }
          break;
        case 'ArrowRight':
          if (canGoNext) {
            goToNext();
          }
          break;
        case 'Escape':
          close();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [canGoNext, canGoPrev, close, goToNext, goToPrev]);

  /**
   * Set the current step index to the index prop
   */
  useEffect(() => {
    if (!images) return;
    setCurrentStepIndex(index < images?.length ? index : 0);
  }, [index, images]);

  if (!images || isVisible === false) {
    return <></>;
  }

  return (
    <Portal node={document && document.getElementById('root')}>
      <div className='fixed inset-0 h-screen w-screen bg-black/80 flex items-center justify-center gap-x-8 animate-fadeIn'>
        <button className='text-white absolute top-10 right-10' onClick={close}>
          <X size={32} />
        </button>

        <button
          className={classNames('text-white', {
            invisible: !canGoPrev,
          })}
          onClick={goToPrev}
        >
          <CaretLeft size={32} />
        </button>

        <div className='relative max-w-[80%] max-h-[80%] w-full h-full'>
          {images.map((media, index) => (
            <Step
              isVisible={index === currentStepIndex}
              direction={prevStepIndex <= currentStepIndex ? 'left' : 'right'}
              key={media.url}
              imageUrl={media.url}
            />
          ))}
        </div>

        <button
          className={classNames('text-white', {
            invisible: !canGoNext,
          })}
          onClick={goToNext}
        >
          <CaretRight size={32} />
        </button>
      </div>
    </Portal>
  );
}

interface StepProps {
  direction?: 'left' | 'right';
  isVisible: boolean;
  imageUrl: string;
}

function Step({ isVisible: givenIsVisible, imageUrl, direction }: StepProps): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(givenIsVisible);

  /**
   * For the item that was active, we delay the hiding of the item so the animation can fullfill
   */
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => setIsVisible(givenIsVisible), 300);
    } else {
      setIsVisible(givenIsVisible);
    }
  }, [givenIsVisible, isVisible]);

  return (
    <img
      src={imageUrl}
      className={classNames('absolute inset-0 animate-duration-300 h-full w-full pb-10 object-contain animate-ease-linear', {
        'animate-fadeOutLeft': !givenIsVisible && direction === 'left',
        'animate-fadeInRight': givenIsVisible && direction === 'left',
        'animate-fadeOutRight': !givenIsVisible && direction === 'right',
        'animate-fadeInLeft': givenIsVisible && direction === 'right',
        hidden: !isVisible,
      })}
    />
  );
}
