/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `FIRST` - First
 * * `SECOND` - Second
 * * `THIRD` - Third
 */
export enum PregnancyCheckTermEnum {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
}
