import { Contact, ContactsService, CountryEnum } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonVariant } from 'ui/Button';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import RvoAuthorizationGuideModal from './RvoAuthorizationGuideModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { SelectInput, TextInput } from 'ui/Inputs';
import { transformEmptyToUndefined } from 'utilities/zod';
import useFormError from 'api/hooks/useFormError';
import ApiErrorParser from 'api/ApiErrorParser';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';
import { useOrganization } from 'context/OrganizationContext';
import { ErrorSection } from 'ui/Error';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { contactName } from 'utilities/Contact';
import { objectDiff } from 'utilities/compareObject';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import UseCountries from 'hooks/UseCountries';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onClosed?: () => void;
  onSuccess?: () => void;
  onContactUpdated?: () => void;
  stable?: Contact;
  locations?: Contact[];
}

function ConnectRvoModal({
  isVisible,
  onRequestCloseModal,
  stable: givenStable,
  locations,
  onSuccess,
  onClosed,
  onContactUpdated,
}: Props): JSX.Element {
  const [authorizeError, setAuthorizeError] = useState<ApiErrorParser<Array<any>> | undefined>(); //eslint-disable-line
  const [stable, setStable] = useState<Contact>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();
  const { closeModal: closeGuideModal, modalIsVisible: guideModalIsVisible, showModal: showGuideModal } = useModal();
  const { loadReportCount } = useRvoReportCount();
  const { countries } = UseCountries();

  // Form validation
  const schema = useMemo(() => {
    const fields: { [k in keyof Contact]?: true } = {
      UBN: true,
      address_line1: true,
      address_line3: true,
      city: true,
      postcode: true,
      country: true,
    };

    // set the fields and make them required
    return schemas.Contact.pick(fields).required();
  }, []);

  /**
   * Define the default values for the form
   */
  const defaultValues = useMemo((): Partial<Contact> => {
    return {
      UBN: stable?.UBN,
      address_line1: stable?.address_line1,
      address_line3: stable?.address_line3,
      city: stable?.city,
      postcode: stable?.postcode,
      country: stable?.country,
    };
  }, [stable?.UBN, stable?.address_line1, stable?.address_line3, stable?.city, stable?.country, stable?.postcode]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    clearErrors,
    reset,
  } = useForm<Contact>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close modal action
   */
  const close = useCallback(() => {
    onRequestCloseModal();
  }, [onRequestCloseModal]);

  /**
   * Render for both success and the default state the actions
   */
  const actions = useMemo((): ActionProps[] => {
    return [
      {
        disabled: !stable,
        loading: isSubmitting,
        variant: ButtonVariant.Primary,
        text: t('connect-equineM-with-rvo-button-label', 'Connect EquineM with RVO'),
        type: 'submit',
        formId: 'connectRvoForm',
      },
    ];
  }, [isSubmitting, stable, t]);

  /**
   * list only the locations in the netherlands and that are not already connected
   */
  const filteredLocations = useMemo(() => {
    return (
      locations
        ?.filter(location => location?.country === CountryEnum.NL && location.machtiging_rvo_gegeven === null)
        .filter(location => !location.stable_location_uid) ?? []
    );
  }, [locations]);

  const locationsOptions = useMemo((): OptionItemInterface[] => {
    return filteredLocations.map(location => ({
      id: location.uid,
      name: location.business_name ?? contactName(location) ?? '',
    }));
  }, [filteredLocations]);

  /**
   * Submit handler
   */
  const onSubmit = async (data: Contact) => {
    if (!selectedOrganization || !stable) return;

    // flag that indicate if we failed to update the contact
    let failedToUpdateContact = false;

    // first update the contact with the given data if needed
    const updatedStableData = objectDiff(stable, data);
    if (Object.keys(updatedStableData).length > 0) {
      const promiseContact = ContactsService.contactsPartialUpdate({
        uid: stable.uid,
        organisationUid: selectedOrganization.uid,
        requestBody: updatedStableData,
      });

      try {
        await promiseContact;

        // fire the onUpdated event for the contact
        onContactUpdated?.();
      } catch (e) {
        failedToUpdateContact = true;
        setApiError(new ApiErrorParser(e));
      }
    }

    // if we failed to update the contact, we should not continue
    if (failedToUpdateContact === false) {
      const promiseRvo = ContactsService.contactsAuthorizeRvoCreate({
        organisationUid: selectedOrganization.uid,
        uid: stable.uid,
      });

      try {
        await promiseRvo;
        onSuccess?.();
        onRequestCloseModal();
        // also load the report Count so we can show the badge in the menu
        loadReportCount();
      } catch (e) {
        setApiError(new ApiErrorParser(e));
      }
    }
  };

  /**
   * Update the form values when the stable is set
   */
  useEffect(() => {
    if (stable && isVisible) {
      setValue('UBN', stable.UBN);
      setValue('address_line1', stable.address_line1);
      setValue('address_line3', stable.address_line3);
      setValue('city', stable.city);
      setValue('postcode', stable.postcode);
      setValue('country', stable.country);
    }
  }, [stable, setValue, isVisible]);

  /**
   * Update the stable if needed
   */
  useEffect(() => {
    setStable(givenStable);
  }, [givenStable]);

  return (
    <>
      <PageModal
        open={isVisible}
        parentElement='form'
        parentProps={{ id: 'connectRvoForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        onClosed={() => {
          setAuthorizeError(undefined);
          setApiError(undefined);
          onClosed?.();
          clearErrors();
          reset();
        }}
      >
        <PageModalTitle
          title={t('connect-stable-with-rvo', 'Connect {{name}} with RVO', { name: stable?.business_name })}
          onClose={close}
        />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />
          {authorizeError && <Alert severity={Severity.Warning} message={authorizeError.nonFieldErrorsStrings().join(', ')} />}

          <div className='space-y-6'>
            <div className='space-y-2'>
              <h2 className='text-lg font-medium'>{t('step-1', 'Step 1')}</h2>
              <p>
                {t(
                  'rvo-guide-desc',
                  'In order to connect with RVO you should have setup your RVO account to authorization EquineM. Click the button below for a step by step guide on how to authorization EquineM. When you are done, you can go to step 2 to create the connection.',
                )}
              </p>
              <Button type='button' onClick={showGuideModal}>
                {t('show-authorization-guide', 'Show authorization guide')}
              </Button>
            </div>

            {locations && (
              <div className='space-y-3'>
                <h2 className='text-lg font-medium'>{t('step-2', 'Step 2')}</h2>
                <p>{t('rvo-select-location-desc', 'Select the location you want to connect with RVO.')}</p>

                <SelectInput
                  options={locationsOptions}
                  nullable={true}
                  nullableValue=''
                  label={t('location', 'Location')}
                  onChange={event => {
                    const location = locations?.find(location => location.uid === event.target.value);
                    setStable(location);
                  }}
                />
              </div>
            )}

            <div className='space-y-3'>
              <h2 className='text-lg font-medium'>{!locations ? t('step-2', 'Step 2') : t('step-3', 'Step 3')}</h2>
              <p>
                {t(
                  'rvo-review-stable-info-desc',
                  'As part of the authorization, we also need the following information. Please review and make additional changes.',
                )}
              </p>

              <TextInput
                error={fieldError('UBN')}
                required={true}
                disabled={!stable}
                label={t('ubn', 'UBN')}
                {...register('UBN', { setValueAs: transformEmptyToUndefined() })}
              />

              <div className='flex flex-col md:flex-row gap-3'>
                <TextInput
                  className='grow'
                  error={fieldError('address_line1')}
                  required={true}
                  disabled={!stable}
                  label={t('address_line1', 'Streetname')}
                  {...register('address_line1', { setValueAs: transformEmptyToUndefined() })}
                />

                <TextInput
                  className='md:w-1/3'
                  error={fieldError('address_line3')}
                  required={true}
                  disabled={!stable}
                  label={t('address_line3', 'housenumber')}
                  {...register('address_line3', { setValueAs: transformEmptyToUndefined() })}
                />
              </div>

              <TextInput
                error={fieldError('city')}
                required={true}
                disabled={!stable}
                label={t('city', 'City')}
                {...register('city', { setValueAs: transformEmptyToUndefined() })}
              />

              <TextInput
                error={fieldError('postcode')}
                required={true}
                disabled={!stable}
                label={t('postcode', 'Postcode')}
                {...register('postcode', { setValueAs: transformEmptyToUndefined() })}
              />

              <SelectInput
                error={fieldError('country')}
                required={true}
                options={countries}
                nullable={true}
                nullableValue=''
                label={t('country', 'Country')}
                {...register('country', { setValueAs: transformEmptyToUndefined() })}
              />
            </div>

            <div className='space-y-2 !mb-6'>
              <h2 className='text-lg font-medium'>{!locations ? t('step-3', 'Step 3') : t('step-4', 'Step 4')}</h2>
              <p>
                {t(
                  'rvo-create-connection-desc',
                  'After you have authorized EquineM with RVO and review your stable/location info, you can create the connection. Click the button below to create the connection.',
                )}
              </p>
            </div>
          </div>
        </PageModalContent>
        <PageModalActions actions={actions} />
      </PageModal>

      <RvoAuthorizationGuideModal isVisible={guideModalIsVisible} onRequestCloseModal={closeGuideModal} />
    </>
  );
}

export default ConnectRvoModal;
