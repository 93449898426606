import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui/Alert';
import { ButtonVariant } from 'ui/Button';
import { ActionModal } from 'ui/Modals';
import { Severity } from 'utilities/severity';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onReport: () => void;
  successMessage?: string;
}

function RvoConfirmReportModal({ isVisible, onRequestClose, onReport, successMessage }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <ActionModal
      open={isVisible}
      actions={[
        {
          text: t('later', 'Later'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('report', 'Report'),
          variant: ButtonVariant.Primary,
          onClick: onReport,
        },
      ]}
    >
      {successMessage && <Alert severity={Severity.Success} message={successMessage} />}

      <p className='mt-2'>
        {t(
          'horse-moved-rvo-can-report',
          'Since your stable is registered and connected to RVO, you need to report this action. Normally, you should report a location change within 30 days. Do you want to report this now?',
        )}
      </p>

      <p className='mt-2 italic font-sm'>
        {t('horse-moved-rvo-skip-report', 'You can skip it for now, but make sure to report it within 30 days.')}
      </p>
    </ActionModal>
  );
}

export default RvoConfirmReportModal;
