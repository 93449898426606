import React, { useCallback, useEffect, useMemo } from 'react';
import { FixedPage } from 'ui/Layout/PageFixed';
import { PageAction } from 'context/PageContext';
import { useTranslation } from 'react-i18next';
import { Plus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import {
  BluePrintState,
  CalendarActivity,
  CalendarActivityType,
  CalendarView,
  DragDropType,
  facilityTypeToString,
  GroupBy,
  listFilterActivityTypes,
  listFilterFacilities,
  SelectedActivityState,
  TimeScale,
  ViewType,
} from 'utilities/Planning';
import { PlanningProvider } from 'context/PlanningContext';
import { usePlanning } from 'hooks/UsePlanning';
import { FilterOption, ListFilterType } from 'components/Common/ListFilter';
import { Facility, FacilityTypeEnum } from 'openapi';
import Calendar, { BlockListItem, GroupByApplied } from 'context/Calendar';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import { useOrganization } from 'context/OrganizationContext';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { PlanningToolbar } from 'components/Activities/PlanningToolbar';
import { MultiDayBody, MultiDayHeader } from 'components/Activities/MultiDayView';
import { DayBody, DayViewGroupByHeader } from 'components/Activities/DayView';
import SaveActivityModal from 'components/Activities/SaveActivityModal';
import { listFilterStable } from 'utilities/Contact';
import { useAccount } from 'context/AccountContext';
import PlanningErrorModal from 'components/Activities/PlanningErrorModal';
import usePermissions from 'hooks/UsePermissions';
import { AppRoutes } from 'AppRoutes';
import { useNavigate } from 'react-router-dom';
import PlanningLoadWrapper from 'components/Activities/PlanningLoadWrapper';
import { Page } from 'ui/Layout';
import { stableListFilterTypes } from 'utilities/Stable';

interface Props {
  backButton?: () => void;
}

/**
 * We need this separate component to be able to use the PlanningDataProvider.
 * This because the PlanningDataProvider (or any other context) needs to be nested above.
 * The parent of this is the bottom of the file.
 */
function FacilityPlanningContent({ backButton }: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    facilities,
    stables,
    viewType,
    groupBy,
    loadApiData,
    loadActivityApiData,
    offset,
    previousOffset,
    activities,
    selectedActivity,
    requestBluePrint,
    clearSelectedActivity,
    unsetBluePrint,
  } = usePlanning();
  const { width: screenWidth } = useScreenSize();
  const { selectedOrganizationUid, selectedOrganization } = useOrganization();
  const { now } = useRefreshingNow();
  const { accountDetails } = useAccount();
  const { permissions } = usePermissions();

  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    const facilityTypeFilter: ListFilterType = {
      id: 'facility-type',
      name: t('facility-type', 'Facility type'),
      options: Object.values(FacilityTypeEnum).reduce<FilterOption[]>((prevVal, currentVal) => {
        prevVal.push({ id: currentVal, name: facilityTypeToString(t, currentVal) });
        return prevVal;
      }, []),
    };

    return [facilityTypeFilter];
  }, [t]);

  const stableFilterTypes = useMemo((): ListFilterType[] => {
    if ((stables?.length ?? 0) <= 1) {
      // No need to show the stable filter when we only have one stable
      return [];
    }
    return [stableListFilterTypes(t, stables ?? [], accountDetails?.preferred_stable ?? undefined)];
  }, [t, stables, accountDetails]);

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add', 'Add'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          clearSelectedActivity();
          requestBluePrint({
            state: BluePrintState.EditFull,
            day: now,
            duration: 2,
            startPeriodOffset: 6, // TODO: Pick current time
            type: CalendarActivityType.FacilityEvent,
          });
        },
      },
    ];
  }, [t, requestBluePrint, clearSelectedActivity, now]);

  const { filters } = useListFilter(filterTypes ?? []);
  const { filters: locationFilters } = useListFilter(stableFilterTypes ?? []);

  const isMyActivity = useCallback(
    (activity: CalendarActivity): boolean => {
      return selectedOrganization?.me.uid === activity.contact?.uid;
    },
    [selectedOrganization],
  );

  const filteredFacilities = useMemo((): Facility[] | undefined => {
    return listFilterFacilities(facilities ?? [], filters.concat(locationFilters));
  }, [facilities, locationFilters, filters]);

  const filteredActivities = useMemo((): CalendarActivity[] | undefined => {
    return listFilterActivityTypes(activities ?? [], filters);
  }, [activities, filters]);

  const toBlockList = (activities: CalendarActivity[], facility: Facility): BlockListItem[] => {
    if (!facility.slot_capacity) {
      return [];
    }
    const facilityActivities = activities.filter(act => act.facilities.find(fac => fac.uid === facility.uid) !== undefined);
    let dates = facilityActivities.flatMap(activity => {
      return [activity.startTime, activity.endTime];
    });

    // Remove duplicates
    dates = dates.filter((value, index) => dates.findIndex(date => date.valueOf() === value.valueOf()) === index);

    // @TODO: Join ranges together

    dates.sort((a, b) => {
      return a.getTime() - b.getTime();
    });
    const dateRanges: { start: Date; end: Date; count: number }[] = dates.flatMap((value, index, array) => {
      if (index + 1 === array.length) {
        return [];
      }
      const end = array[index + 1];
      const count = facilityActivities
        .filter(act => act.startTime <= value && act.endTime >= end)
        .map(act => {
          // Take into account how many slots are booked.
          return act.slots ?? 0;
        })
        .reduce((acc, cur) => acc + cur, 0);
      return [{ start: value, end, count }];
    });

    return dateRanges.map(dateRange => {
      return {
        startTime: dateRange.start,
        endTime: dateRange.end,
        groupByUid: facility.uid,
        fullBlock: dateRange.count >= (facility.slot_capacity ?? 0),
      } satisfies BlockListItem;
    });
  };

  // Get the reservations that are booked by others.  We put them in the so
  // called 'BlockList'. This list displays them as fully booked or as a
  // green line when you can still book at the given time.
  const blockList = useMemo((): BlockListItem[] | undefined => {
    if (!filteredActivities || !facilities) {
      return undefined;
    }
    // We only show the blocklist in 'My reservations'.
    if (groupBy !== GroupBy.FacilityAvailability) {
      return [];
    }
    let result: BlockListItem[] = [];
    for (const facility of facilities) {
      result = result.concat(
        toBlockList(
          filteredActivities.filter(activity => !isMyActivity(activity)),
          facility,
        ),
      );
    }
    return result;
  }, [filteredActivities, facilities, groupBy, isMyActivity]);

  // Get all facilities that we need to display based on the applied filters.
  // We put them into the GroupByApplied format so we can pass them to the Calendar class.
  const calendarFacilities = useMemo((): GroupByApplied[] | undefined => {
    if (!filteredFacilities) {
      return undefined;
    }
    return (
      filteredFacilities.map(facility => {
        return { groupBy: GroupBy.Facility, subject: facility };
      }) ?? []
    );
  }, [filteredFacilities]);

  // Calculate the column header width based on arbitrary values.
  const colHeaderWidth = useMemo(() => {
    if (viewType === ViewType.Week || viewType === ViewType.ThreeDay) {
      return groupBy ? 200 : 77;
    } else if (viewType === ViewType.Day) {
      if (groupBy && screenWidth <= ScreenSize.md) {
        return 150;
      } else {
        return 77;
      }
    } else {
      throw Error('Unimplemented');
    }
  }, [viewType, groupBy, screenWidth]);

  // Initially load all from the api
  useEffect(() => {
    // Next to the org uid we also require the permissions to be set in order to correctly load the api data.
    if (selectedOrganizationUid && permissions) {
      const promise = loadApiData(true);
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, viewType, permissions]); //eslint-disable-line

  // Load activities from the api
  useEffect(() => {
    // Check if we're editing/finalizing an activity. If so, don't refresh. It might mess up the form state.
    const isEditing =
      selectedActivity?.selectedActivityState === SelectedActivityState.Edit ||
      selectedActivity?.selectedActivityState === SelectedActivityState.Finalize;

    if (selectedOrganizationUid && permissions && !isEditing) {
      const filterStable = listFilterStable(stables ?? [], locationFilters);
      const promise = loadActivityApiData(filterStable.length === 0 ? undefined : filterStable);
      return () => promise.cancel();
    }
  }, [offset, now, permissions, stables, locationFilters]); //eslint-disable-line

  const calendarView = useMemo((): CalendarView | undefined => {
    if (!filteredActivities || !calendarFacilities || !blockList) {
      return undefined;
    }
    return {
      offset: offset,
      previousOffset: previousOffset,
      current: new Calendar(
        now,
        viewType,
        offset,
        calendarFacilities,
        groupBy === GroupBy.FacilityAvailability ? filteredActivities.filter(isMyActivity) : filteredActivities,
        blockList,
      ),
      left: new Calendar(now, viewType, offset - 1, calendarFacilities, []),
      right: new Calendar(now, viewType, offset + 1, calendarFacilities, []),
    };
  }, [filteredActivities, now, viewType, offset, previousOffset, calendarFacilities, blockList, isMyActivity, groupBy]);

  if (groupBy === GroupBy.Facility && facilities?.length === 0) {
    return (
      <Page title={t('facilities-reservations', 'Facility reservations')}>
        <div className='pt-24 flex flex-col gap-4 justify-center items-center'>
          <h1 className='text-xl font-medium'>{t('no-facilities-msg-header', 'No facilities')}</h1>
          <p className='max-w-96 opacity-70 text-center'>
            {t('no-facilities-msg', 'No facilities have been created. Please create them first in the manage facilities tab.')}
          </p>
        </div>
      </Page>
    );
  }

  return (
    <FixedPage actions={pageActions}>
      <PlanningLoadWrapper className='w-full h-full flex flex-col'>
        <div className='shadow-planningheader z-10 bg-primary md:bg-neutral-50'>
          {calendarView && (
            <>
              <div className='md:p-2 mt-safe'>
                <PlanningToolbar
                  backButton={backButton}
                  calendarView={calendarView}
                  stableFilterTypes={groupBy === GroupBy.FacilityAvailability ? undefined : stableFilterTypes}
                  filterTypes={groupBy === GroupBy.FacilityAvailability ? undefined : filterTypes}
                  showDay={false}
                  showTimeScaleControls={false}
                  pageActions={pageActions}
                />
              </div>
              {(viewType === ViewType.Week || viewType === ViewType.ThreeDay || viewType === ViewType.Day) && (
                <MultiDayHeader calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />
              )}
              {viewType === ViewType.Day && <DayViewGroupByHeader calendarView={calendarView} columnHeaderWidth={colHeaderWidth} />}
            </>
          )}
        </div>
        {calendarFacilities?.length === 0 && (
          <div className='pt-24 flex flex-col gap-4 justify-center items-center'>
            <h1 className='text-xl font-medium'>{t('no-facilities-in-filter-msg-header', 'No facilities in this filter')}</h1>
            <p className='max-w-96 opacity-70 text-center'>
              {t('no-facilities-in-filter-msg', 'Your filter has no facilities. Change the filter and/or stable at the top of the window.')}
            </p>
          </div>
        )}
        <div
          className='overflow-y-scroll no-scrollbar flex-1'
          onClick={() => {
            // Clear the selection when we click outside.
            clearSelectedActivity();
            unsetBluePrint();
          }}
        >
          {calendarView && (
            <>
              {(viewType === ViewType.Week || viewType === ViewType.ThreeDay) && (
                <MultiDayBody
                  onSaved={async () => {
                    backButton?.();
                  }}
                  calendarView={calendarView}
                  columnHeaderWidth={colHeaderWidth}
                  dragDropType={DragDropType.Cluster}
                />
              )}
              {viewType === ViewType.Day && (
                <DayBody
                  onSaved={async () => {
                    backButton?.();
                  }}
                  calendarView={calendarView}
                  columnHeaderWidth={colHeaderWidth}
                />
              )}
            </>
          )}
        </div>
        <SaveActivityModal
          onSaved={async () => {
            backButton?.();
          }}
        />
        <PlanningErrorModal />
      </PlanningLoadWrapper>
    </FixedPage>
  );
}

/**
 * Planning page for facilities
 */
export default function FacilityPlanning(): JSX.Element {
  const { width } = useScreenSize();

  return (
    <PlanningProvider
      viewType={width > ScreenSize.md ? ViewType.Week : ViewType.ThreeDay}
      groupBy={GroupBy.Facility}
      timeScale={TimeScale.TimeScale}
    >
      <FacilityPlanningContent />
    </PlanningProvider>
  );
}

export function FacilityAvailability(): JSX.Element {
  const navigate = useNavigate();
  return (
    <PlanningProvider viewType={ViewType.Day} groupBy={GroupBy.FacilityAvailability} timeScale={TimeScale.TimeScale}>
      <FacilityPlanningContent
        backButton={() => {
          navigate(AppRoutes.Reservations.path);
        }}
      />
    </PlanningProvider>
  );
}
