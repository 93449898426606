/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `FARM_LAND` - farm land
 * * `NATURE_LAND` - nature land
 * * `WATER_BARRIER` - water barrier
 * * `OTHER` - other
 */
export enum LandTypeNitrogenEnum {
  FARM_LAND = 'FARM_LAND',
  NATURE_LAND = 'NATURE_LAND',
  WATER_BARRIER = 'WATER_BARRIER',
  OTHER = 'OTHER',
}
