import ListFiles, { ListFile } from 'components/Horses/HorseFiles/ListFiles';
import { PrivateEnum, PublicHorseMediaSet, PublicHorsePage } from 'openapi';
import React, { useMemo, useState } from 'react';
import Tile from '../Tile';
import useModal from 'ui/Modals/UseModal';
import ViewFileModal from 'components/Horses/HorseFiles/ViewFileModal';
import { useTranslation } from 'react-i18next';
import Skeleton from 'ui/Layout/Skeleton';

interface Props {
  horsePage: PublicHorsePage | undefined;
  loading: boolean;
}

function FilesTile({ horsePage, loading }: Props): JSX.Element {
  const [selectedFile, setSelectedFile] = useState<PublicHorseMediaSet | undefined>();

  const { modalIsVisible: previewModalIsVisible, closeModal: closePreviewModal, showModal: showPreviewModal } = useModal();
  const { t } = useTranslation();

  /**
   * Open the file in a modal
   */
  const openFile = async (fileUid?: string) => {
    const file = horsePage?.horse.horsemedia_set?.find(file => file.uid === fileUid);
    if (!file) {
      console.error('Failed to open file. File not found');
      return;
    }

    setSelectedFile(file);
    showPreviewModal();
  };

  /**
   * Return only the files (non images) of the horse from the media set
   */
  const publicFiles = useMemo((): ListFile[] => {
    if (!horsePage?.horse.horsemedia_set) return [];

    return horsePage.horse.horsemedia_set
      .filter(media => !media.content_type?.startsWith('image/'))
      .filter(media => [PrivateEnum._2, PrivateEnum._3].includes(media.private))
      .map<ListFile>(media => ({
        uid: media.uid,
        filename: media.filename,
        url: media.url,
        contentType: media.content_type,
        YTVim: media.YTVim ?? undefined,
      }));
  }, [horsePage]);

  /**
   * Return only the files (non images) of the horse from the media set
   */
  const privateFiles = useMemo((): ListFile[] => {
    if (!horsePage?.horse.horsemedia_set) return [];

    return horsePage.horse.horsemedia_set
      .filter(media => media.private === PrivateEnum._1)
      .map<ListFile>(media => ({
        uid: media.uid,
        filename: media.filename,
        url: media.url,
        contentType: media.content_type,
        YTVim: media.YTVim ?? undefined,
      }));
  }, [horsePage]);

  return (
    <>
      {(loading || publicFiles.length > 0) && (
        <Tile title={t('files', 'Files')}>
          {loading && (
            <div className='grid grid-cols-4 gap-2'>
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
            </div>
          )}
          {!loading && <ListFiles files={publicFiles} onOpenFile={fileUid => openFile(fileUid)} />}
        </Tile>
      )}
      {horsePage?.include_medical_documents && (loading || privateFiles.length > 0) && (
        <Tile title={t('medical-files', 'Medical files')}>
          {loading && (
            <div className='grid grid-cols-4 gap-2'>
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
              <Skeleton className='w-24 h-24' />
            </div>
          )}
          {!loading && <ListFiles files={privateFiles} onOpenFile={fileUid => openFile(fileUid)} />}
        </Tile>
      )}

      <ViewFileModal
        file={
          selectedFile
            ? {
                uid: selectedFile.uid,
                name: selectedFile.filename,
                url: selectedFile.url,
                contentType: selectedFile.content_type,
              }
            : undefined
        }
        isVisible={previewModalIsVisible}
        onRequestClose={closePreviewModal}
      />
    </>
  );
}

export default FilesTile;
