import ApiErrorParser from 'api/ApiErrorParser';
import { eventsToChangeEnumToString } from 'context/Calendar';
import { useOrganization } from 'context/OrganizationContext';
import { EventsToChangeEnum, HorseGroup, RealActivities } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { RadioButtonBase } from 'ui/Inputs/RadioInput';
import { ActionModal } from 'ui/Modals';
import { CalendarActivity } from 'utilities/Planning';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDelete: (activity: CalendarActivity, eventsToChange?: EventsToChangeEnum) => Promise<void>;
  activity: CalendarActivity | undefined;
}

// Modal is shown when a recurring activity is about te be removed.
export default function DeleteActivityModal({ isVisible, onRequestClose, activity, onDelete }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<RealActivities> | undefined>();
  const [eventsToChange, setEventsToChange] = useState<EventsToChangeEnum>(EventsToChangeEnum.ONE);

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const closeModal = () => {
    setApiError(undefined);
    onRequestClose();
  };

  // Create translated radio button group items for the removal options if we have an activity with recurrence.
  const eventsToChangeOptions = useMemo((): RadioButtonGroupOption[] => {
    return (
      [EventsToChangeEnum.ONE, EventsToChangeEnum.FUTURE, EventsToChangeEnum.ALL]?.map(option => {
        return { id: option, name: eventsToChangeEnumToString(option, t) };
      }) ?? []
    );
  }, [t]);

  const modalActions = useMemo(() => {
    return [
      {
        text: t('cancel', 'Cancel'),
        variant: ButtonVariant.Default,
        onClick: () => {
          setApiError(undefined);
          onRequestClose();
        },
      },
      {
        text: t('delete', 'Delete'),
        variant: ButtonVariant.PrimaryDanger,
        onClick: async () => {
          if (!selectedOrganization || !activity) return;

          try {
            await onDelete(activity, eventsToChange);

            // close the modal
            onRequestClose();
          } catch (error) {
            setApiError(new ApiErrorParser<HorseGroup>(error));
          }
        },
      },
    ];
  }, [t, activity, eventsToChange, onDelete, onRequestClose, selectedOrganization]);

  return (
    <ActionModal open={isVisible} onClose={closeModal} actions={modalActions} title={t('delete-activity', 'Delete activity')}>
      {apiError && <ErrorSection errors={apiError} />}

      <p className='mt-2 w-full'>
        {t('delete-recurring-activity-text', 'This is a recurring activity. Which activities do you want to remove?')}
      </p>
      <div className='space-y-2 mt-1.5 select-none'>
        {eventsToChangeOptions.map(item => (
          <RadioButtonBase
            key={item.id}
            value={item.id}
            isSelected={item.id === eventsToChange}
            name={item.id}
            label={<div className='flex items-center gap-0.5'>{item.name}</div>}
            onChange={() => setEventsToChange(item.id)}
            compact={false}
          />
        ))}
      </div>
    </ActionModal>
  );
}
