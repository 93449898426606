/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HorseLocation } from '../models/HorseLocation';
import type { HorseLocationUpdate } from '../models/HorseLocationUpdate';
import type { PaginatedHorseLocationList } from '../models/PaginatedHorseLocationList';
import type { PaginatedHorseLocationMutationList } from '../models/PaginatedHorseLocationMutationList';
import type { PatchedHorseLocationUpdate } from '../models/PatchedHorseLocationUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HorselocationsService {
  /**
   * @returns PaginatedHorseLocationList
   * @throws ApiError
   */
  public static horselocationsList({
    locationOrganisationUid,
    arrivalDateGt,
    arrivalDateLte,
    departureDateGt,
    departureDateLte,
    horseUid,
    horseUidIn,
    horseOrLocationStable,
    location,
    locationIn,
    o,
    page,
    pageSize,
  }: {
    locationOrganisationUid: string,
    arrivalDateGt?: string,
    arrivalDateLte?: string,
    departureDateGt?: string,
    departureDateLte?: string,
    horseUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    horseUidIn?: Array<string>,
    horseOrLocationStable?: Array<string>,
    location?: number,
    /**
     * Multiple values may be separated by commas.
     */
    locationIn?: Array<number>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedHorseLocationList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{location__organisation__uid}/horselocations',
      path: {
        'location__organisation__uid': locationOrganisationUid,
      },
      query: {
        'arrival_date__gt': arrivalDateGt,
        'arrival_date__lte': arrivalDateLte,
        'departure_date__gt': departureDateGt,
        'departure_date__lte': departureDateLte,
        'horse__uid': horseUid,
        'horse__uid__in': horseUidIn,
        'horse_or_location_stable': horseOrLocationStable,
        'location': location,
        'location__in': locationIn,
        'o': o,
        'page': page,
        'page_size': pageSize,
      },
    });
  }
  /**
   * @returns HorseLocation
   * @throws ApiError
   */
  public static horselocationsRetrieve({
    locationOrganisationUid,
    uid,
  }: {
    locationOrganisationUid: string,
    uid: string,
  }): CancelablePromise<HorseLocation> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{location__organisation__uid}/horselocations/{uid}',
      path: {
        'location__organisation__uid': locationOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns HorseLocation
   * @throws ApiError
   */
  public static horselocationsUpdate({
    locationOrganisationUid,
    uid,
    requestBody,
  }: {
    locationOrganisationUid: string,
    uid: string,
    requestBody: HorseLocationUpdate,
  }): CancelablePromise<HorseLocation> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{location__organisation__uid}/horselocations/{uid}',
      path: {
        'location__organisation__uid': locationOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns HorseLocation
   * @throws ApiError
   */
  public static horselocationsPartialUpdate({
    locationOrganisationUid,
    uid,
    requestBody,
  }: {
    locationOrganisationUid: string,
    uid: string,
    requestBody?: PatchedHorseLocationUpdate,
  }): CancelablePromise<HorseLocation> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{location__organisation__uid}/horselocations/{uid}',
      path: {
        'location__organisation__uid': locationOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public static horselocationsDestroy({
    locationOrganisationUid,
    uid,
  }: {
    locationOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{location__organisation__uid}/horselocations/{uid}',
      path: {
        'location__organisation__uid': locationOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns any No response body
   * @throws ApiError
   */
  public static horselocationsHideArrivalCreate({
    locationOrganisationUid,
    uid,
  }: {
    locationOrganisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{location__organisation__uid}/horselocations/{uid}/hide_arrival',
      path: {
        'location__organisation__uid': locationOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns any No response body
   * @throws ApiError
   */
  public static horselocationsHideDepartureCreate({
    locationOrganisationUid,
    uid,
  }: {
    locationOrganisationUid: string,
    uid: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{location__organisation__uid}/horselocations/{uid}/hide_departure',
      path: {
        'location__organisation__uid': locationOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * @returns PaginatedHorseLocationMutationList
   * @throws ApiError
   */
  public static horselocationsMutationsWithoutReportList({
    locationOrganisationUid,
    arrivalDateGt,
    arrivalDateLte,
    departureDateGt,
    departureDateLte,
    horseUid,
    horseUidIn,
    horseOrLocationStable,
    location,
    locationIn,
    o,
    page,
    pageSize,
  }: {
    locationOrganisationUid: string,
    arrivalDateGt?: string,
    arrivalDateLte?: string,
    departureDateGt?: string,
    departureDateLte?: string,
    horseUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    horseUidIn?: Array<string>,
    horseOrLocationStable?: Array<string>,
    location?: number,
    /**
     * Multiple values may be separated by commas.
     */
    locationIn?: Array<number>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedHorseLocationMutationList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{location__organisation__uid}/horselocations/mutations_without_report',
      path: {
        'location__organisation__uid': locationOrganisationUid,
      },
      query: {
        'arrival_date__gt': arrivalDateGt,
        'arrival_date__lte': arrivalDateLte,
        'departure_date__gt': departureDateGt,
        'departure_date__lte': departureDateLte,
        'horse__uid': horseUid,
        'horse__uid__in': horseUidIn,
        'horse_or_location_stable': horseOrLocationStable,
        'location': location,
        'location__in': locationIn,
        'o': o,
        'page': page,
        'page_size': pageSize,
      },
    });
  }
}
