import React from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';

interface Props {
  vat_number_checked: boolean | null | undefined;
}

export default function VatVerifiedBadge({ vat_number_checked }: Props): JSX.Element {
  const { t } = useTranslation();

  if (vat_number_checked === true) {
    // Valid VAT number
    return <Badge color={AllColors.Emerald}>{t('verified', 'Verified')}</Badge>;
  }

  if (vat_number_checked === false) {
    // Valid VAT number
    return <Badge color={AllColors.Rose}>{t('invalid', 'Invalid')}</Badge>;
  }
  return <></>;
}
