import React, { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { ButtonSize, ButtonVariant } from '.';
import ButtonInternal from './ButtonInternal';
import classNames from 'classnames';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  loading?: boolean;
  icon?: ReactNode;
  iconReverse?: boolean; // reverse the icon, so it will be on the right side
  compress?: boolean; // Icon-only on small devices
  // shows a badge with a number
  badge?: number;
  className?: string;
}

const Button: React.FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const {
    variant = ButtonVariant.Default,
    size = ButtonSize.Normal,
    onClick,
    children,
    loading = false,
    disabled = false,
    icon,
    iconReverse,
    compress,
    badge,
    className,
    ...rest
  } = props;

  return (
    <button
      className={classNames('rounded-lg print:hidden', className)}
      title={children?.toString()}
      ref={ref}
      data-variant={variant}
      onClick={onClick}
      {...rest}
      disabled={loading || disabled}
    >
      <ButtonInternal
        badge={badge}
        variant={variant}
        size={size}
        loading={loading}
        icon={icon}
        iconReverse={iconReverse}
        disabled={loading || disabled}
        compress={compress}
      >
        {children}
      </ButtonInternal>
    </button>
  );
});
Button.displayName = 'Button';
export default Button;
