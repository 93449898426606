import { User } from '@phosphor-icons/react';
import UploadFile from 'api/UploadFile';
import { useAccount } from 'context/AccountContext';
import { useConfig } from 'context/ConfigContext';
import { AccountService, PatchedAccount } from 'openapi';
import React from 'react';
import AvatarTile from 'ui/Layout/Tile/AvatarTile';

interface Props {
  className?: string;
}

function ProfileAvatarForm({ className }: Props): JSX.Element {
  const { accountDetails, setAccountDetails } = useAccount();
  const { config } = useConfig();

  const avatarUrl = accountDetails?.avatar_file ?? undefined;

  // Avatar implementation
  const onAvatarComplete = async (imageData: ArrayBuffer, mimeType: string) => {
    // Required because we use vercel to upload the image.
    if (!config?.hasServerlessFunctions) throw Error('Serverless functions are not enabled.');

    // Upload the image to s3 via Vercel serverless function.
    const url = await UploadFile.uploadAccountAvatar(config.serverlessFunctionsUrl, imageData, mimeType);

    // Patch our account with the avatar image s3 url.
    const patchedAccount: PatchedAccount = { avatar_file: url.toString() };
    const promise = AccountService.apiV5AccountPartialUpdate({ requestBody: patchedAccount });

    setAccountDetails(await promise);
  };

  return <AvatarTile className={className} avatarUrl={avatarUrl} onAvatarComplete={onAvatarComplete} icon={<User />} canUpdate={true} />;
}

export default ProfileAvatarForm;
