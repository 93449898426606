import { TFunction } from 'i18next';
import { contactName } from './Contact';
import { Stable } from 'openapi';
import { ListFilterType } from 'components/Common/ListFilter';

/**
 * A stable filter with the option to mark a specific stable.
 */
export const stableListFilterTypes = (t: TFunction, stables: Stable[], markedUid?: string): ListFilterType => {
  return {
    id: 'stable',
    name: t('stable', 'Stable'),
    options: (stables ?? []).map(stable => {
      if (markedUid === stable.uid) {
        return { id: stable.uid, name: `${contactName(stable.location)} *` };
      } else {
        return { id: stable.uid, name: contactName(stable.location) ?? '' };
      }
    }),
  };
};
