/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventDestroy } from '../models/EventDestroy';
import type { Facility } from '../models/Facility';
import type { FacilityEvent } from '../models/FacilityEvent';
import type { MyFacilityEvent } from '../models/MyFacilityEvent';
import type { PaginatedFacilityEventList } from '../models/PaginatedFacilityEventList';
import type { PaginatedFacilityList } from '../models/PaginatedFacilityList';
import type { PatchedFacility } from '../models/PatchedFacility';
import type { PatchedFacilityEvent } from '../models/PatchedFacilityEvent';
import type { PatchedMyFacilityEvent } from '../models/PatchedMyFacilityEvent';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FacilitiesService {
  /**
   * List, edit and create Facilities for a stable. Limitations like facility opening hours are
   * optional and applied in validation when a facility is planned by means of a FacilityEvent.
   * Leaving out a limitation (like max_hours_per_event) will be interpreted as if there is no
   * limit on said attribute.
   * @returns PaginatedFacilityList
   * @throws ApiError
   */
  public static facilitiesList({
    organisationUid,
    availableFromLte,
    availableToGte,
    facilityType,
    facilityTypeIn,
    hidden,
    o,
    page,
    pageSize,
    slotCapacityGte,
    stableUid,
    stableUidIn,
  }: {
    organisationUid: string,
    availableFromLte?: string,
    availableToGte?: string,
    /**
     * * `TRANSPORT` - Transport
     * * `WATER_TREADMILL` - Water treadmill
     * * `INDOOR_ARENA` - Indoor arena
     * * `OUTDOOR_ARENA` - Outdoor arena
     * * `GALLOPING_TRACK` - Galloping track
     * * `LUNGING_RING` - Lunging ring
     * * `PADDOCK` - Paddock
     * * `SOLARIUM` - Solarium
     * * `JUMPING_YARD` - Jumping yard
     * * `TREADMILL` - Treadmill
     * * `VIBRATION_PLATE` - Vibration plate
     * * `PASTURES` - Pastures
     * * `WALKER` - walker
     * * `OTHER` - other
     */
    facilityType?: 'GALLOPING_TRACK' | 'INDOOR_ARENA' | 'JUMPING_YARD' | 'LUNGING_RING' | 'OTHER' | 'OUTDOOR_ARENA' | 'PADDOCK' | 'PASTURES' | 'SOLARIUM' | 'TRANSPORT' | 'TREADMILL' | 'VIBRATION_PLATE' | 'WALKER' | 'WATER_TREADMILL',
    /**
     * Multiple values may be separated by commas.
     */
    facilityTypeIn?: Array<string>,
    hidden?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    slotCapacityGte?: number,
    stableUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    stableUidIn?: Array<string>,
  }): CancelablePromise<PaginatedFacilityList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/facilities',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'available_from__lte': availableFromLte,
        'available_to__gte': availableToGte,
        'facility_type': facilityType,
        'facility_type__in': facilityTypeIn,
        'hidden': hidden,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'slot_capacity__gte': slotCapacityGte,
        'stable__uid': stableUid,
        'stable__uid__in': stableUidIn,
      },
    });
  }
  /**
   * List, edit and create Facilities for a stable. Limitations like facility opening hours are
   * optional and applied in validation when a facility is planned by means of a FacilityEvent.
   * Leaving out a limitation (like max_hours_per_event) will be interpreted as if there is no
   * limit on said attribute.
   * @returns Facility
   * @throws ApiError
   */
  public static facilitiesCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: Facility,
  }): CancelablePromise<Facility> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/facilities',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List, edit and create Facilities for a stable. Limitations like facility opening hours are
   * optional and applied in validation when a facility is planned by means of a FacilityEvent.
   * Leaving out a limitation (like max_hours_per_event) will be interpreted as if there is no
   * limit on said attribute.
   * @returns Facility
   * @throws ApiError
   */
  public static facilitiesRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<Facility> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/facilities/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * List, edit and create Facilities for a stable. Limitations like facility opening hours are
   * optional and applied in validation when a facility is planned by means of a FacilityEvent.
   * Leaving out a limitation (like max_hours_per_event) will be interpreted as if there is no
   * limit on said attribute.
   * @returns Facility
   * @throws ApiError
   */
  public static facilitiesUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: Facility,
  }): CancelablePromise<Facility> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/facilities/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List, edit and create Facilities for a stable. Limitations like facility opening hours are
   * optional and applied in validation when a facility is planned by means of a FacilityEvent.
   * Leaving out a limitation (like max_hours_per_event) will be interpreted as if there is no
   * limit on said attribute.
   * @returns Facility
   * @throws ApiError
   */
  public static facilitiesPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedFacility,
  }): CancelablePromise<Facility> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/facilities/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List, edit and create Facilities for a stable. Limitations like facility opening hours are
   * optional and applied in validation when a facility is planned by means of a FacilityEvent.
   * Leaving out a limitation (like max_hours_per_event) will be interpreted as if there is no
   * limit on said attribute.
   * @returns void
   * @throws ApiError
   */
  public static facilitiesDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/facilities/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns PaginatedFacilityEventList
   * @throws ApiError
   */
  public static facilitiesEventsList({
    organisationUid,
    activityActivityTypeUid,
    activityActivityTypeUidIn,
    contactUid,
    createdByUid,
    dtendGt,
    dtendLte,
    dtstartGte,
    dtstartLt,
    facilityStableUid,
    facilityStableUidIn,
    facilityUid,
    facilityUidIn,
    o,
    page,
    pageSize,
  }: {
    organisationUid: string,
    activityActivityTypeUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    activityActivityTypeUidIn?: Array<string>,
    contactUid?: string,
    createdByUid?: string,
    dtendGt?: string,
    dtendLte?: string,
    dtstartGte?: string,
    dtstartLt?: string,
    facilityStableUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    facilityStableUidIn?: Array<string>,
    facilityUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    facilityUidIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
  }): CancelablePromise<PaginatedFacilityEventList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/facilities/events',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'activity__activity_type__uid': activityActivityTypeUid,
        'activity__activity_type__uid__in': activityActivityTypeUidIn,
        'contact__uid': contactUid,
        'created_by__uid': createdByUid,
        'dtend__gt': dtendGt,
        'dtend__lte': dtendLte,
        'dtstart__gte': dtstartGte,
        'dtstart__lt': dtstartLt,
        'facility__stable__uid': facilityStableUid,
        'facility__stable__uid__in': facilityStableUidIn,
        'facility__uid': facilityUid,
        'facility__uid__in': facilityUidIn,
        'o': o,
        'page': page,
        'page_size': pageSize,
      },
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns FacilityEvent
   * @throws ApiError
   */
  public static facilitiesEventsCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: FacilityEvent,
  }): CancelablePromise<FacilityEvent> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/facilities/events',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns FacilityEvent
   * @throws ApiError
   */
  public static facilitiesEventsRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<FacilityEvent> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/facilities/events/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns FacilityEvent
   * @throws ApiError
   */
  public static facilitiesEventsUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: FacilityEvent,
  }): CancelablePromise<FacilityEvent> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/facilities/events/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns FacilityEvent
   * @throws ApiError
   */
  public static facilitiesEventsPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedFacilityEvent,
  }): CancelablePromise<FacilityEvent> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/facilities/events/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns any No response body
   * @throws ApiError
   */
  public static facilitiesEventsDeleteCreate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: EventDestroy,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/facilities/events/{uid}/delete',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns MyFacilityEvent
   * @throws ApiError
   */
  public static facilitiesMyEventsCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: MyFacilityEvent,
  }): CancelablePromise<MyFacilityEvent> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/facilities/my_events',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns MyFacilityEvent
   * @throws ApiError
   */
  public static facilitiesMyEventsUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: MyFacilityEvent,
  }): CancelablePromise<MyFacilityEvent> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/facilities/my_events/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns MyFacilityEvent
   * @throws ApiError
   */
  public static facilitiesMyEventsPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedMyFacilityEvent,
  }): CancelablePromise<MyFacilityEvent> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/facilities/my_events/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * You can plan (book) a facility through means of FacilityEvents. The underlying model is an Event
   * that combines a Facility with one or more Horses and an optional RealActivities.
   *
   * Typically, users may create events as long as the facilities time related constraints are respected.
   * Users may edit and delete their own facility events (again, respecting said constraints). A user who
   * is facility manager may create and edit events that fall outside said constraints and do the same for
   * events made by colleagues.
   *
   * Facility events are also created through linking facilities when creating or updating activities
   * over the organisation activities endpoints.
   * @returns any No response body
   * @throws ApiError
   */
  public static facilitiesMyEventsDeleteCreate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: EventDestroy,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/facilities/my_events/{uid}/delete',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
