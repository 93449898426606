import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageAction } from 'context/PageContext';
import { useTranslation } from 'react-i18next';
import { Check, LightningA, Plus } from '@phosphor-icons/react';
import Button, { ButtonVariant } from 'ui/Button';
import { activityNameInCal, BluePrintState, CalendarActivity, CalendarActivityType, SelectedActivityState } from 'utilities/Planning';
import { usePlanning } from 'hooks/UsePlanning';
import SaveActivityModal from 'components/Activities/SaveActivityModal';
import { Page } from 'ui/Layout';
import { useOrganization } from 'context/OrganizationContext';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { ActivitiesService, RealActivities } from 'openapi';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { PageMaxWidth } from 'ui/Layout/Page';
import PlanningErrorModal from 'components/Activities/PlanningErrorModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { defaultApiPageSize } from 'ui/Const';
import ActivityModal from 'components/Activities/ActivityModal';
import { ActivityHeightSpacious } from 'components/Activities/DayGrid';
import { addYears, isSameDay, startOfDay } from 'date-fns';
import { useAccount } from 'context/AccountContext';
import { AvatarInitials, AvatarSize } from 'ui/Avatar';
import { contactInitials } from 'utilities/Contact';
import { Tile } from 'ui/Layout/Tile';
import { formatDate } from 'utilities/date.utilities';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';

interface ActivityHistoryProps {
  // Show the component as page or tile
  componentType: 'tile' | 'page';

  // Show future items or historic items
  timeType: 'history' | 'future';

  // Filter for a specific horse
  horseUid?: string;
}

interface ActivityDateBucketComponentProps {
  dateBucket: ActivityDateBucket;
}

interface ActivityDateBucket {
  activities: CalendarActivity[];
  date: Date;
}

function ActivityDateBucketComponent({ dateBucket }: ActivityDateBucketComponentProps): JSX.Element {
  const appliedGroupByUid = undefined;
  const { formatDate } = useAccount();
  const { selectedActivity, setSelectedActivity, unsetBluePrint } = usePlanning();
  return (
    <div>
      <p className='text-xs text-gray-700 font-semibold'>{formatDate(dateBucket.date)}</p>
      {dateBucket.activities.map(activity => {
        const primaryAssignee = activity.assignedTo.find(assignee => assignee.primary)?.contact;
        return (
          <ActivityModal key={activity.uid} activity={activity} groupByUid={appliedGroupByUid}>
            <div
              className='rounded box-border flex pr-1 py-0 justify-center items-center cursor-pointer select-none'
              onClick={e => {
                setSelectedActivity(activity, SelectedActivityState.Info, appliedGroupByUid);
                unsetBluePrint(0);
                e.stopPropagation();
              }}
              style={{
                marginTop: 4,
                height: ActivityHeightSpacious,
                backgroundColor: activity.secondaryColor,
                borderColor: activity.primaryColor,
                borderLeftWidth: 6,
                borderRightWidth: selectedActivity?.activity.uid === activity.uid ? 1 : 0,
                borderTopWidth: selectedActivity?.activity.uid === activity.uid ? 1 : 0,
                borderBottomWidth: selectedActivity?.activity.uid === activity.uid ? 1 : 0,
              }}
            >
              {activity.done && (
                <div
                  style={{
                    backgroundColor: activity.primaryColor,
                  }}
                  className='flex items-center justify-center w-4 h-4 text-white p-0.5 rounded-full ml-0.5'
                >
                  <Check />
                </div>
              )}
              <div className='grow ml-1 truncate'>
                <p className='text-sm truncate'>
                  <span className='font-medium'>
                    {activityNameInCal(activity)} {activity.isAutomaticallyPlanned && <LightningA className='inline' />}
                  </span>
                </p>
                {activity.startEndText && <p className='text-xs opacity-90 truncate -mt-1'>{activity.startEndText}</p>}
              </div>
              {primaryAssignee && (
                <AvatarInitials
                  size={AvatarSize.XSmall}
                  initials={primaryAssignee ? contactInitials(primaryAssignee) : '?'}
                  uuid={primaryAssignee?.uid}
                />
              )}
            </div>
          </ActivityModal>
        );
      })}
    </div>
  );
}

/**
 * We need this separate component to be able to use the PlanningDataProvider.
 */
export default function ActivityList({ horseUid, componentType, timeType }: ActivityHistoryProps): JSX.Element {
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { t } = useTranslation();
  const { selectedOrganizationUid } = useOrganization();
  const { loadApiData, setActivitiesData, selectedActivity, setSelectedActivity, requestBluePrint, getDayPartForTime, activities, horses } =
    usePlanning();
  const { now } = useRefreshingNow();
  const navigate = useNavigate();

  const pageActions = useMemo((): PageAction[] => {
    if (timeType === 'history') {
      return [];
    }
    return [
      {
        text: t('add-activity', 'Add activity'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          if (selectedActivity) {
            setSelectedActivity(selectedActivity?.activity, SelectedActivityState.Selected);
          }
          requestBluePrint({
            state: BluePrintState.EditFull,
            type: CalendarActivityType.Activity,
            day: now,
            dayPart: getDayPartForTime(now.getHours(), now.getMinutes()),
          });
        },
      },
    ];
  }, [t, requestBluePrint, setSelectedActivity, selectedActivity, now, getDayPartForTime, timeType]);

  // Cap to a max amount of items when we're in the tile view.
  const maxItems = useMemo(() => {
    return componentType === 'tile' ? 8 : undefined;
  }, [componentType]);

  // Load data from the api/cache
  const loadActivityApiData = useCallback((): ApiPromises => {
    if (!selectedOrganizationUid) {
      return new ApiPromises();
    }
    const promises = apiPromises ? new ApiPromises() : loadApiData(true);
    let horseUidIn: string[] | undefined;
    if (horseUid) {
      horseUidIn = [horseUid];
    }
    promises.setPaginated<RealActivities>(
      'activities',
      apiPageNumber =>
        ActivitiesService.activitiesList({
          page: apiPageNumber,
          pageSize: maxItems ?? defaultApiPageSize,
          horseUidIn,
          dtstartLt: timeType === 'history' ? formatDate(new Date()) : undefined,
          dtendGt: timeType === 'future' ? formatDate(new Date()) : formatDate(addYears(new Date(), -100)),
          organisationUid: selectedOrganizationUid,
          o: timeType === 'history' ? '-dtstart' : 'dtstart',
        }),
      setActivitiesData,
    );

    setApiPromises(promises);
    return promises;
  }, [apiPromises, loadApiData, setActivitiesData, selectedOrganizationUid, horseUid, timeType, maxItems]);

  // Initially load all from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadActivityApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  /**
   * Place activities into date buckets. This way we can group them and add a date label above.
   */
  const groupedActivities = useMemo((): ActivityDateBucket[] => {
    const result: ActivityDateBucket[] = [];
    let current: ActivityDateBucket | undefined = undefined;

    for (const activity of activities ?? []) {
      if (!current) {
        current = { date: startOfDay(activity.startTime), activities: [] };
      } else if (!isSameDay(current.date, activity.startTime)) {
        result.push(current);
        current = { date: startOfDay(activity.startTime), activities: [] };
      }
      current.activities.push(activity);
    }
    if (current) {
      result.push(current);
    }
    return result;
  }, [activities]);

  const title = useMemo(() => {
    let text = timeType === 'history' ? t('activity-history', 'Activity history') : t('activity-planned', 'Planned activities');
    if (horseUid && componentType === 'page') {
      const foundHorse = horses?.find(horse => horse.uid === horseUid);
      if (foundHorse) {
        text += ` (${foundHorse.name})`;
      } else {
        text += ` (${t('horse', 'Horse')})`;
      }
    }
    return text;
  }, [t, horses, horseUid, componentType, timeType]);

  const breadCrumbs = useMemo(() => {
    const foundHorse = horses?.find(horse => horse.uid === horseUid);
    return [AppRoutes.HorsesList, { ...AppRoutes.HorsesDetails, name: foundHorse?.name ?? '' }];
  }, [horseUid, horses]);

  if (componentType === 'page') {
    return (
      <Page breadCrumbs={breadCrumbs} title={title} actions={pageActions} loading={apiPromises} maxWidth={PageMaxWidth.Tile}>
        <PullScrollWrapper apiPromises={apiPromises}>
          <Tile noBoxOnMobile={true}>
            <div className='p-4 flex gap-6 flex-col'>
              {groupedActivities.map(dateBucket => (
                <ActivityDateBucketComponent key={dateBucket.date.valueOf()} dateBucket={dateBucket} />
              ))}
            </div>
          </Tile>
        </PullScrollWrapper>
        <SaveActivityModal />
        <PlanningErrorModal />
      </Page>
    );
  } else {
    return (
      <Tile noBoxOnMobile={true} title={title}>
        <div className='flex gap-4 flex-col'>
          {groupedActivities.map(dateBucket => (
            <ActivityDateBucketComponent key={dateBucket.date.valueOf()} dateBucket={dateBucket} />
          ))}
          {activities && activities.length === 0 && (
            <p className='italic text-gray-500'>{t('no-activities-horse', 'There were no activities for this horse.')}</p>
          )}
          {activities && maxItems && activities.length === maxItems && (
            <Button
              onClick={() => {
                navigate(generatePath(AppRoutes.HorseActivityHistory.path, { uid: horseUid ?? '' }));
              }}
            >
              {t('show-all', 'Show all')}
            </Button>
          )}
        </div>
        <SaveActivityModal />
        <PlanningErrorModal />
      </Tile>
    );
  }
}
