/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `BEGIN_AMOUNT` - begin amount
 * * `IMPORT` - import
 * * `PRODUCTION` - production
 * * `EXPORT` - export
 * * `END_AMOUNT` - end amount
 */
export enum MutationTypeEnum {
  BEGIN_AMOUNT = 'BEGIN_AMOUNT',
  IMPORT = 'IMPORT',
  PRODUCTION = 'PRODUCTION',
  EXPORT = 'EXPORT',
  END_AMOUNT = 'END_AMOUNT',
}
