import { DotsThreeVertical, House } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import usePermissions from 'hooks/UsePermissions';
import { Contact, HorseLocation, ModulePermissionsEnum } from 'openapi';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'ui/DropdownMenu';
import { contactName } from 'utilities/Contact';

interface Props {
  location: HorseLocation | undefined;
  contacts: Contact[] | undefined;
  isCurrent: boolean; // flag if this is the current location
  onEdit: () => void;
  onDelete: () => void;
}

function ListItem({ location, contacts, isCurrent, onEdit, onDelete }: Props): JSX.Element {
  const { formatDate } = useAccount();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  /**
   * Get the location name
   */
  const getLocationName = useCallback(
    (locationUid: string) => {
      const find = contacts?.find(contact => contact.uid === locationUid);
      if (find) {
        return contactName(find);
      }

      return `<${t('unknown', 'Unknown')}>`;
    },
    [contacts, t],
  );

  /**
   * Define the actions for the dropdown
   */
  const actions = useMemo(() => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      return [
        [
          {
            element: t('edit', 'Edit'),
            onClick: onEdit,
          },
        ],
        [
          {
            element: t('remove', 'Remove'),
            className: 'text-red-600',
            onClick: onDelete,
          },
        ],
      ];
    }
  }, [t, onEdit, onDelete, hasPermission]);

  // no location, return just a fragment
  if (!location) return <></>;

  return (
    <div className='flex flex-col w-full'>
      <div className='flex gap-x-1 items-center'>
        {getLocationName(location.location_uid)} {isCurrent && <House />}
        {actions && (
          <DropdownMenu buttonClassName='ml-auto' menuPlacement='right-end' menuItems={actions}>
            <button className='px-2'>
              <DotsThreeVertical size={20} weight='bold' />
            </button>
          </DropdownMenu>
        )}
      </div>
      <div className='flex flex-col text-sm'>
        <span>
          {t('arrival', 'Arrival')}: {formatDate(location.arrival_date)}
        </span>
        {location.departure_date && (
          <span>
            {t('depature', 'Departure')}: {location.departure_date ? formatDate(location.departure_date) : ''}
          </span>
        )}
      </div>
    </div>
  );
}

export default ListItem;
