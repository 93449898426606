import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { ManureService, ProductionBalanceItem } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  productionBalanceItemBeginAmount?: ProductionBalanceItem;
  productionBalanceItemEndAmount?: ProductionBalanceItem;
  productionBalanceItemImport?: ProductionBalanceItem;
  productionBalanceItemExport?: ProductionBalanceItem;
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
}

function DeleteBalanceItemNonAnimalModal({
  productionBalanceItemBeginAmount,
  productionBalanceItemEndAmount,
  productionBalanceItemExport,
  productionBalanceItemImport,
  isVisible,
  onRequestClose,
  onDeleted,
}: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<ProductionBalanceItem> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const deleteItem = async (item: ProductionBalanceItem | undefined) => {
    if (!selectedOrganization || !item) return;

    await ManureService.manureFertilizerProducersBalanceDestroy({
      contactOrganisationUid: selectedOrganization.uid,
      uid: item.uid,
    });
  };

  const deleteLocation = async () => {
    try {
      await deleteItem(productionBalanceItemBeginAmount);
      await deleteItem(productionBalanceItemEndAmount);
      await deleteItem(productionBalanceItemExport);
      await deleteItem(productionBalanceItemImport);

      // close the modal
      onRequestClose();

      // fire the onDeleted callback
      onDeleted();
    } catch (error) {
      setApiError(new ApiErrorParser<ProductionBalanceItem>(error));
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('delete', 'Delete'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: deleteLocation,
        },
      ]}
      title={t('delete-fertilizer-non-animal', 'Delete non animal fertilizer')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>
        {t('delete-fertilizer-non-animal-hint', 'Are you sure you want to delete this non animal fertilizer?')}
      </p>
    </ActionModal>
  );
}

export default DeleteBalanceItemNonAnimalModal;
