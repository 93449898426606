import { TFunction } from 'i18next';

export interface Country {
  id: string;
  name: string;
}

export const getCountries = (t: TFunction): Country[] => {
  return (
    [
      {
        id: 'AF',
        name: t('afghanistan', 'Afghanistan'),
      },
      {
        id: 'AX',
        name: t('aland-islands', 'Åland Islands'),
      },
      {
        id: 'AL',
        name: t('albania', 'Albania'),
      },
      {
        id: 'DZ',
        name: t('algeria', 'Algeria'),
      },
      {
        id: 'AS',
        name: t('american-samoa', 'American Samoa'),
      },
      {
        id: 'AD',
        name: t('andorra', 'Andorra'),
      },
      {
        id: 'AO',
        name: t('angola', 'Angola'),
      },
      {
        id: 'AI',
        name: t('anguilla', 'Anguilla'),
      },
      {
        id: 'AQ',
        name: t('antarctica', 'Antarctica'),
      },
      {
        id: 'AG',
        name: t('antigua-and-barbuda', 'Antigua and Barbuda'),
      },
      {
        id: 'AR',
        name: t('argentina', 'Argentina'),
      },
      {
        id: 'AM',
        name: t('armenia', 'Armenia'),
      },
      {
        id: 'AW',
        name: t('aruba', 'Aruba'),
      },
      {
        id: 'AU',
        name: t('australia', 'Australia'),
      },
      {
        id: 'AT',
        name: t('austria', 'Austria'),
      },
      {
        id: 'AZ',
        name: t('azerbaijan', 'Azerbaijan'),
      },
      {
        id: 'BS',
        name: t('bahamas', 'Bahamas'),
      },
      {
        id: 'BH',
        name: t('bahrain', 'Bahrain'),
      },
      {
        id: 'BD',
        name: t('bangladesh', 'Bangladesh'),
      },
      {
        id: 'BB',
        name: t('barbados', 'Barbados'),
      },
      {
        id: 'BY',
        name: t('belarus', 'Belarus'),
      },
      {
        id: 'BE',
        name: t('belgium', 'Belgium'),
      },
      {
        id: 'BZ',
        name: t('belize', 'Belize'),
      },
      {
        id: 'BJ',
        name: t('benin', 'Benin'),
      },
      {
        id: 'BM',
        name: t('bermuda', 'Bermuda'),
      },
      {
        id: 'BT',
        name: t('bhutan', 'Bhutan'),
      },
      {
        id: 'BO',
        name: t('bolivia', 'Bolivia'),
      },
      {
        id: 'BQ',
        name: t('bonaire-sint-eustatius-and-saba', 'Bonaire, Sint Eustatius and Saba'),
      },
      {
        id: 'BA',
        name: t('bosnia-and-herzegovina', 'Bosnia and Herzegovina'),
      },
      {
        id: 'BW',
        name: t('botswana', 'Botswana'),
      },
      {
        id: 'BV',
        name: t('bouvet-island', 'Bouvet Island'),
      },
      {
        id: 'BR',
        name: t('brazil', 'Brazil'),
      },
      {
        id: 'IO',
        name: t('british-indian-ocean-territory', 'British Indian Ocean Territory'),
      },
      {
        id: 'BN',
        name: t('brunei', 'Brunei'),
      },
      {
        id: 'BG',
        name: t('bulgaria', 'Bulgaria'),
      },
      {
        id: 'BF',
        name: t('burkina-faso', 'Burkina Faso'),
      },
      {
        id: 'BI',
        name: t('burundi', 'Burundi'),
      },
      {
        id: 'CV',
        name: t('cabo-verde', 'Cabo Verde'),
      },
      {
        id: 'KH',
        name: t('cambodia', 'Cambodia'),
      },
      {
        id: 'CM',
        name: t('cameroon', 'Cameroon'),
      },
      {
        id: 'CA',
        name: t('canada', 'Canada'),
      },
      {
        id: 'KY',
        name: t('cayman-islands', 'Cayman Islands'),
      },
      {
        id: 'CF',
        name: t('central-african-republic', 'Central African Republic'),
      },
      {
        id: 'TD',
        name: t('chad', 'Chad'),
      },
      {
        id: 'CL',
        name: t('chile', 'Chile'),
      },
      {
        id: 'CN',
        name: t('china', 'China'),
      },
      {
        id: 'CX',
        name: t('christmas-island', 'Christmas Island'),
      },
      {
        id: 'CC',
        name: t('cocos-keeling-islands', 'Cocos (Keeling) Islands'),
      },
      {
        id: 'CO',
        name: t('colombia', 'Colombia'),
      },
      {
        id: 'KM',
        name: t('comoros', 'Comoros'),
      },
      {
        id: 'CG',
        name: t('congo', 'Congo'),
      },
      {
        id: 'CD',
        name: t('congo-the-democratic-republic-of-the', 'Congo (the Democratic Republic of the)'),
      },
      {
        id: 'CK',
        name: t('cook-islands', 'Cook Islands'),
      },
      {
        id: 'CR',
        name: t('costa-rica', 'Costa Rica'),
      },
      {
        id: 'CI',
        name: t('cote-divoire', "Côte d'Ivoire"),
      },
      {
        id: 'HR',
        name: t('croatia', 'Croatia'),
      },
      {
        id: 'CU',
        name: t('cuba', 'Cuba'),
      },
      {
        id: 'CW',
        name: t('curacao', 'Curaçao'),
      },
      {
        id: 'CY',
        name: t('cyprus', 'Cyprus'),
      },
      {
        id: 'CZ',
        name: t('czechia', 'Czechia'),
      },
      {
        id: 'DK',
        name: t('denmark', 'Denmark'),
      },
      {
        id: 'DJ',
        name: t('djibouti', 'Djibouti'),
      },
      {
        id: 'DM',
        name: t('dominica', 'Dominica'),
      },
      {
        id: 'DO',
        name: t('dominican-republic', 'Dominican Republic'),
      },
      {
        id: 'EC',
        name: t('ecuador', 'Ecuador'),
      },
      {
        id: 'EG',
        name: t('egypt', 'Egypt'),
      },
      {
        id: 'SV',
        name: t('el-salvador', 'El Salvador'),
      },
      {
        id: 'GQ',
        name: t('equatorial-guinea', 'Equatorial Guinea'),
      },
      {
        id: 'ER',
        name: t('eritrea', 'Eritrea'),
      },
      {
        id: 'EE',
        name: t('estonia', 'Estonia'),
      },
      {
        id: 'SZ',
        name: t('eswatini', 'Eswatini'),
      },
      {
        id: 'ET',
        name: t('ethiopia', 'Ethiopia'),
      },
      {
        id: 'FK',
        name: t('falkland-islands-malvinas', 'Falkland Islands (Malvinas)'),
      },
      {
        id: 'FO',
        name: t('faroe-islands', 'Faroe Islands'),
      },
      {
        id: 'FJ',
        name: t('fiji', 'Fiji'),
      },
      {
        id: 'FI',
        name: t('finland', 'Finland'),
      },
      {
        id: 'FR',
        name: t('france', 'France'),
      },
      {
        id: 'GF',
        name: t('french-guiana', 'French Guiana'),
      },
      {
        id: 'PF',
        name: t('french-polynesia', 'French Polynesia'),
      },
      {
        id: 'TF',
        name: t('french-southern-territories', 'French Southern Territories'),
      },
      {
        id: 'GA',
        name: t('gabon', 'Gabon'),
      },
      {
        id: 'GM',
        name: t('gambia', 'Gambia'),
      },
      {
        id: 'GE',
        name: t('georgia', 'Georgia'),
      },
      {
        id: 'DE',
        name: t('germany', 'Germany'),
      },
      {
        id: 'GH',
        name: t('ghana', 'Ghana'),
      },
      {
        id: 'GI',
        name: t('gibraltar', 'Gibraltar'),
      },
      {
        id: 'GR',
        name: t('greece', 'Greece'),
      },
      {
        id: 'GL',
        name: t('greenland', 'Greenland'),
      },
      {
        id: 'GD',
        name: t('grenada', 'Grenada'),
      },
      {
        id: 'GP',
        name: t('guadeloupe', 'Guadeloupe'),
      },
      {
        id: 'GU',
        name: t('guam', 'Guam'),
      },
      {
        id: 'GT',
        name: t('guatemala', 'Guatemala'),
      },
      {
        id: 'GG',
        name: t('guernsey', 'Guernsey'),
      },
      {
        id: 'GN',
        name: t('guinea', 'Guinea'),
      },
      {
        id: 'GW',
        name: t('guinea-bissau', 'Guinea-Bissau'),
      },
      {
        id: 'GY',
        name: t('guyana', 'Guyana'),
      },
      {
        id: 'HT',
        name: t('haiti', 'Haiti'),
      },
      {
        id: 'HM',
        name: t('heard-island-and-mcdonald-islands', 'Heard Island and McDonald Islands'),
      },
      {
        id: 'VA',
        name: t('holy-see', 'Holy See'),
      },
      {
        id: 'HN',
        name: t('honduras', 'Honduras'),
      },
      {
        id: 'HK',
        name: t('hong-kong', 'Hong Kong'),
      },
      {
        id: 'HU',
        name: t('hungary', 'Hungary'),
      },
      {
        id: 'IS',
        name: t('iceland', 'Iceland'),
      },
      {
        id: 'IN',
        name: t('india', 'India'),
      },
      {
        id: 'ID',
        name: t('indonesia', 'Indonesia'),
      },
      {
        id: 'IR',
        name: t('iran', 'Iran'),
      },
      {
        id: 'IQ',
        name: t('iraq', 'Iraq'),
      },
      {
        id: 'IE',
        name: t('ireland', 'Ireland'),
      },
      {
        id: 'IM',
        name: t('isle-of-man', 'Isle of Man'),
      },
      {
        id: 'IL',
        name: t('israel', 'Israel'),
      },
      {
        id: 'IT',
        name: t('italy', 'Italy'),
      },
      {
        id: 'JM',
        name: t('jamaica', 'Jamaica'),
      },
      {
        id: 'JP',
        name: t('japan', 'Japan'),
      },
      {
        id: 'JE',
        name: t('jersey', 'Jersey'),
      },
      {
        id: 'JO',
        name: t('jordan', 'Jordan'),
      },
      {
        id: 'KZ',
        name: t('kazakhstan', 'Kazakhstan'),
      },
      {
        id: 'KE',
        name: t('kenya', 'Kenya'),
      },
      {
        id: 'KI',
        name: t('kiribati', 'Kiribati'),
      },
      {
        id: 'KP',
        name: t('korea-north', 'Korea (North)'),
      },
      {
        id: 'KR',
        name: t('korea-south', 'Korea (South)'),
      },
      {
        id: 'KW',
        name: t('kuwait', 'Kuwait'),
      },
      {
        id: 'KG',
        name: t('kyrgyzstan', 'Kyrgyzstan'),
      },
      {
        id: 'LA',
        name: t('laos', 'Laos'),
      },
      {
        id: 'LV',
        name: t('latvia', 'Latvia'),
      },
      {
        id: 'LB',
        name: t('lebanon', 'Lebanon'),
      },
      {
        id: 'LS',
        name: t('lesotho', 'Lesotho'),
      },
      {
        id: 'LR',
        name: t('liberia', 'Liberia'),
      },
      {
        id: 'LY',
        name: t('libya', 'Libya'),
      },
      {
        id: 'LI',
        name: t('liechtenstein', 'Liechtenstein'),
      },
      {
        id: 'LT',
        name: t('lithuania', 'Lithuania'),
      },
      {
        id: 'LU',
        name: t('luxembourg', 'Luxembourg'),
      },
      {
        id: 'MO',
        name: t('macao', 'Macao'),
      },
      {
        id: 'MG',
        name: t('madagascar', 'Madagascar'),
      },
      {
        id: 'MW',
        name: t('malawi', 'Malawi'),
      },
      {
        id: 'MY',
        name: t('malaysia', 'Malaysia'),
      },
      {
        id: 'MV',
        name: t('maldives', 'Maldives'),
      },
      {
        id: 'ML',
        name: t('mali', 'Mali'),
      },
      {
        id: 'MT',
        name: t('malta', 'Malta'),
      },
      {
        id: 'MH',
        name: t('marshall-islands', 'Marshall Islands'),
      },
      {
        id: 'MQ',
        name: t('martinique', 'Martinique'),
      },
      {
        id: 'MR',
        name: t('mauritania', 'Mauritania'),
      },
      {
        id: 'MU',
        name: t('mauritius', 'Mauritius'),
      },
      {
        id: 'YT',
        name: t('mayotte', 'Mayotte'),
      },
      {
        id: 'MX',
        name: t('mexico', 'Mexico'),
      },
      {
        id: 'FM',
        name: t('micronesia', 'Micronesia (Federated States of)'),
      },
      {
        id: 'MD',
        name: t('moldova', 'Moldova'),
      },
      {
        id: 'MC',
        name: t('monaco', 'Monaco'),
      },
      {
        id: 'MN',
        name: t('mongolia', 'Mongolia'),
      },
      {
        id: 'ME',
        name: t('montenegro', 'Montenegro'),
      },
      {
        id: 'MS',
        name: t('montserrat', 'Montserrat'),
      },
      {
        id: 'MA',
        name: t('morocco', 'Morocco'),
      },
      {
        id: 'MZ',
        name: t('mozambique', 'Mozambique'),
      },
      {
        id: 'MM',
        name: t('myanmar', 'Myanmar'),
      },
      {
        id: 'NA',
        name: t('namibia', 'Namibia'),
      },
      {
        id: 'NR',
        name: t('nauru', 'Nauru'),
      },
      {
        id: 'NP',
        name: t('nepal', 'Nepal'),
      },
      {
        id: 'NL',
        name: t('netherlands', 'Netherlands'),
      },
      {
        id: 'NC',
        name: t('new-caledonia', 'New Caledonia'),
      },
      {
        id: 'NZ',
        name: t('new-zealand', 'New Zealand'),
      },
      {
        id: 'NI',
        name: t('nicaragua', 'Nicaragua'),
      },
      {
        id: 'NE',
        name: t('niger', 'Niger'),
      },
      {
        id: 'NG',
        name: t('nigeria', 'Nigeria'),
      },
      {
        id: 'NU',
        name: t('niue', 'Niue'),
      },
      {
        id: 'NF',
        name: t('norfolk-island', 'Norfolk Island'),
      },
      {
        id: 'MK',
        name: t('north-macedonia', 'North Macedonia'),
      },
      {
        id: 'MP',
        name: t('northern-mariana-islands', 'Northern Mariana Islands'),
      },
      {
        id: 'NO',
        name: t('norway', 'Norway'),
      },
      {
        id: 'OM',
        name: t('oman', 'Oman'),
      },
      {
        id: 'PK',
        name: t('pakistan', 'Pakistan'),
      },
      {
        id: 'PW',
        name: t('palau', 'Palau'),
      },
      {
        id: 'PS',
        name: t('palestine', 'Palestine, State of'),
      },
      {
        id: 'PA',
        name: t('panama', 'Panama'),
      },
      {
        id: 'PG',
        name: t('papua-new-guinea', 'Papua New Guinea'),
      },
      {
        id: 'PY',
        name: t('paraguay', 'Paraguay'),
      },
      {
        id: 'PE',
        name: t('peru', 'Peru'),
      },
      {
        id: 'PH',
        name: t('philippines', 'Philippines'),
      },
      {
        id: 'PN',
        name: t('pitcairn', 'Pitcairn'),
      },
      {
        id: 'PL',
        name: t('poland', 'Poland'),
      },
      {
        id: 'PT',
        name: t('portugal', 'Portugal'),
      },
      {
        id: 'PR',
        name: t('puerto-rico', 'Puerto Rico'),
      },
      {
        id: 'QA',
        name: t('qatar', 'Qatar'),
      },
      {
        id: 'RE',
        name: t('reunion', 'Réunion'),
      },
      {
        id: 'RO',
        name: t('romania', 'Romania'),
      },
      {
        id: 'RU',
        name: t('russian-federation', 'Russian Federation'),
      },
      {
        id: 'RW',
        name: t('rwanda', 'Rwanda'),
      },
      {
        id: 'BL',
        name: t('saint-barthelemy', 'Saint Barthélemy'),
      },
      {
        id: 'SH',
        name: t('saint-helena-ascension-and-tristan-da-cunha', 'Saint Helena, Ascension and Tristan da Cunha'),
      },
      {
        id: 'KN',
        name: t('saint-kitts-and-nevis', 'Saint Kitts and Nevis'),
      },
      {
        id: 'LC',
        name: t('saint-lucia', 'Saint Lucia'),
      },
      {
        id: 'MF',
        name: t('saint-martin-french-part', 'Saint Martin (French part)'),
      },
      {
        id: 'PM',
        name: t('saint-pierre-and-miquelon', 'Saint Pierre and Miquelon'),
      },
      {
        id: 'VC',
        name: t('saint-vincent-and-the-grenadines', 'Saint Vincent and the Grenadines'),
      },
      {
        id: 'WS',
        name: t('samoa', 'Samoa'),
      },
      {
        id: 'SM',
        name: t('san-marino', 'San Marino'),
      },
      {
        id: 'ST',
        name: t('sao-tome-and-principe', 'Sao Tome and Principe'),
      },
      {
        id: 'SA',
        name: t('saudi-arabia', 'Saudi Arabia'),
      },
      {
        id: 'SN',
        name: t('senegal', 'Senegal'),
      },
      {
        id: 'RS',
        name: t('serbia', 'Serbia'),
      },
      {
        id: 'SC',
        name: t('seychelles', 'Seychelles'),
      },
      {
        id: 'SL',
        name: t('sierra-leone', 'Sierra Leone'),
      },
      {
        id: 'SG',
        name: t('singapore', 'Singapore'),
      },
      {
        id: 'SX',
        name: t('sint-maarten-dutch-part', 'Sint Maarten (Dutch part)'),
      },
      {
        id: 'SK',
        name: t('slovakia', 'Slovakia'),
      },
      {
        id: 'SI',
        name: t('slovenia', 'Slovenia'),
      },
      {
        id: 'SB',
        name: t('solomon-islands', 'Solomon Islands'),
      },
      {
        id: 'SO',
        name: t('somalia', 'Somalia'),
      },
      {
        id: 'ZA',
        name: t('south-africa', 'South Africa'),
      },
      {
        id: 'GS',
        name: t('south-georgia-and-the-south-sandwich-islands', 'South Georgia and the South Sandwich Islands'),
      },
      {
        id: 'SS',
        name: t('south-sudan', 'South Sudan'),
      },
      {
        id: 'ES',
        name: t('spain', 'Spain'),
      },
      {
        id: 'LK',
        name: t('sri-lanka', 'Sri Lanka'),
      },
      {
        id: 'SD',
        name: t('sudan', 'Sudan'),
      },
      {
        id: 'SR',
        name: t('suriname', 'Suriname'),
      },
      {
        id: 'SJ',
        name: t('svalbard-and-jan-mayen', 'Svalbard and Jan Mayen'),
      },
      {
        id: 'SE',
        name: t('sweden', 'Sweden'),
      },
      {
        id: 'CH',
        name: t('switzerland', 'Switzerland'),
      },
      {
        id: 'SY',
        name: t('syrian-arab-republic', 'Syrian Arab Republic'),
      },
      {
        id: 'TW',
        name: t('taiwan', 'Taiwan'),
      },
      {
        id: 'TJ',
        name: t('tajikistan', 'Tajikistan'),
      },
      {
        id: 'TZ',
        name: t('tanzania', 'Tanzania'),
      },
      {
        id: 'TH',
        name: t('thailand', 'Thailand'),
      },
      {
        id: 'TL',
        name: t('timor-leste', 'Timor-Leste'),
      },
      {
        id: 'TG',
        name: t('togo', 'Togo'),
      },
      {
        id: 'TK',
        name: t('tokelau', 'Tokelau'),
      },
      {
        id: 'TO',
        name: t('tonga', 'Tonga'),
      },
      {
        id: 'TT',
        name: t('trinidad-and-tobago', 'Trinidad and Tobago'),
      },
      {
        id: 'TN',
        name: t('tunisia', 'Tunisia'),
      },
      {
        id: 'TR',
        name: t('turkey', 'Turkey'),
      },
      {
        id: 'TM',
        name: t('turkmenistan', 'Turkmenistan'),
      },
      {
        id: 'TC',
        name: t('turks-and-caicos-islands', 'Turks and Caicos Islands'),
      },
      {
        id: 'TV',
        name: t('tuvalu', 'Tuvalu'),
      },
      {
        id: 'UG',
        name: t('uganda', 'Uganda'),
      },
      {
        id: 'UA',
        name: t('ukraine', 'Ukraine'),
      },
      {
        id: 'AE',
        name: t('united-arab-emirates', 'United Arab Emirates'),
      },
      {
        id: 'GB',
        name: t('united-kingdom', 'United Kingdom'),
      },
      {
        id: 'US',
        name: t('united-states', 'United States of America'),
      },
      {
        id: 'UY',
        name: t('uruguay', 'Uruguay'),
      },
      {
        id: 'UZ',
        name: t('uzbekistan', 'Uzbekistan'),
      },
      {
        id: 'VU',
        name: t('vanuatu', 'Vanuatu'),
      },
      {
        id: 'VE',
        name: t('venezuela', 'Venezuela'),
      },
      {
        id: 'VN',
        name: t('vietnam', 'Vietnam'),
      },
      {
        id: 'VG',
        name: t('virgin-islands-british', 'Virgin Islands (British)'),
      },
      {
        id: 'VI',
        name: t('virgin-islands-us', 'Virgin Islands (U.S.)'),
      },
      {
        id: 'WF',
        name: t('wallis-and-futuna', 'Wallis and Futuna'),
      },
      {
        id: 'EH',
        name: t('western-sahara', 'Western Sahara'),
      },
      {
        id: 'YE',
        name: t('yemen', 'Yemen'),
      },
      {
        id: 'ZM',
        name: t('zambia', 'Zambia'),
      },
      {
        id: 'ZW',
        name: t('zimbabwe', 'Zimbabwe'),
      },
    ]
      // and sort by name
      .sort((a, b) => a.name.localeCompare(b.name))
  );
};
