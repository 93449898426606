import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import Spinner, { SpinnerSize } from 'ui/Loading/Spinner';
import { PageModal } from 'ui/Modals';
import { ActionProps } from 'ui/Modals/ActionModal';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';

interface HorseFile {
  uid: string;
  url: string;
  name: string;
  contentType?: string;
}

interface Props {
  isVisible: boolean;
  file: HorseFile | undefined;
  onRequestClose: () => void;
  downloadFile?: (fileUid: string) => void;
}

function ViewFileModal({ file, onRequestClose, isVisible, downloadFile }: Props): JSX.Element {
  const [previewIframeLoading, setPreviewIframeLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  // Returns true if the given file is an image
  const fileIsImage = (contentType: string | undefined) => {
    return contentType?.startsWith('image/');
  };

  // Returns true if the given file is a video
  const fileIsVideo = (contentType: string | undefined) => {
    return contentType?.startsWith('video/');
  };

  // Returns true if the selected file is an image
  const selectedFileIsImage = useMemo(() => {
    return file ? fileIsImage(file.contentType) : false;
  }, [file]);

  // Returns true if the selected file is a video
  const selectedFileIsVideo = useMemo(() => {
    return file ? fileIsVideo(file.contentType) : false;
  }, [file]);

  /**
   * Download the opened file
   */
  const downloadOpenedFile = useCallback(() => {
    if (!file) {
      console.error('Cannot download file if no file is selected');
      return;
    }
    downloadFile?.(file.uid);
  }, [downloadFile, file]);

  /**
   * Actions for the modal
   */
  const modalActions = useMemo((): ActionProps[] => {
    const actions: ActionProps[] = [];
    if (downloadFile) {
      actions.push({
        onClick: downloadOpenedFile,
        variant: ButtonVariant.Default,
        text: t('download', 'Download'),
      });
    }

    return actions;
  }, [downloadFile, downloadOpenedFile, t]);

  return (
    <PageModal width={PageModalWidth.Lg} open={isVisible} onClosed={() => setPreviewIframeLoading(false)}>
      <PageModalTitle title={file?.name ?? 'File'} onClose={onRequestClose} />
      <PageModalContent>
        <div className='h-full relative min-h-40 max-h-[80vh] bg-neutral-100'>
          {selectedFileIsImage ? (
            <img
              src={file?.url ?? ''}
              onLoad={() => setPreviewIframeLoading(false)}
              className='w-full h-full object-contain bg-neutral-100'
            />
          ) : selectedFileIsVideo ? (
            <video
              controls={true}
              autoPlay={true}
              src={file?.url ?? ''}
              onLoadedData={() => setPreviewIframeLoading(false)}
              className='w-full max-h-[80vh] object-contain bg-neutral-100'
            />
          ) : (
            <iframe className='w-full h-[70vh]' onLoad={() => setPreviewIframeLoading(false)} src={file?.url ?? ''} />
          )}
          {previewIframeLoading && (
            <div className='absolute top-1/2 left-1/2 p-2 border rounded-full bg-white'>
              <Spinner size={SpinnerSize.Normal} />
            </div>
          )}
        </div>
      </PageModalContent>
      <PageModalActions actions={modalActions} />
    </PageModal>
  );
}

export default ViewFileModal;
