import classNames from 'classnames';
import React, { ReactNode, useMemo, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OptionItemInterface } from './SelectInput';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import { Checks, XCircle } from '@phosphor-icons/react';

export type MultiSelectInputProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  error?: string;
  required?: boolean;
  loading?: boolean;
  searchPlaceholder?: string;
  hint?: ReactNode;
  className?: string;
  options: OptionItemInterface[];
  showToolbar: boolean;
};

// A multi select. It includes a fancy search bar with select all
// and select none buttons.
export default function MultiSelectInput<T extends FieldValues>({
  name,
  control,
  label,
  error,
  required,
  loading,
  hint,
  className,
  options,
  searchPlaceholder,
  showToolbar,
}: MultiSelectInputProps<T>): JSX.Element {
  const [searchText, setSearchText] = useState<string>('');
  const { t } = useTranslation();

  // Filter the countries list based in the search text.
  const filteredItems = useMemo((): OptionItemInterface[] => {
    if (searchText.length === 0) {
      return options;
    }
    return options.filter(
      option =>
        option.name.toLocaleLowerCase().startsWith(searchText.toLocaleLowerCase()) ||
        String(option.id).toLocaleLowerCase().startsWith(searchText.toLocaleLowerCase()),
    );
  }, [options, searchText]);

  return (
    <div className={classNames('group relative', className)}>
      {label && (
        <label className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
          {label} {required && '*'}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div
            className={classNames('overflow-y-scroll max-h-48 text-sm border rounded-lg px-2 pb-2', {
              // we do not render this box styles when we have less than 8 items
              contents: options.length <= 8 && !loading,
            })}
          >
            {showToolbar && (
              <div className='flex border-b items-center mb-2 gap-2 sticky top-0 bg-white'>
                <input
                  type='search'
                  onChange={e => setSearchText(e.currentTarget.value)}
                  placeholder={searchPlaceholder}
                  className='placeholder:italic placeholder:text-sm w-full px-2 h-10 outline-none'
                />
                <div className='h-6 w-0 border-r' />
                <button
                  type='button'
                  title={t('select-all', 'Select all')}
                  className='grow whitespace-nowrap cursor-pointer text-gray-700 flex items-center gap-1'
                  onClick={() => field.onChange([...options.map(v => v.id)])}
                >
                  <Checks size={22} />
                  <span className='hidden sm:inline'>{t('select-all', 'Select all')}</span>
                </button>
                <div className='h-3 sm:h-6 w-0 border-r' />
                <button
                  type='button'
                  title={t('select-none', 'Select none')}
                  className='grow whitespace-nowrap cursor-pointer text-gray-700 flex items-center gap-1'
                  onClick={() => field.onChange([])}
                >
                  <XCircle size={19} />
                  <span className='hidden sm:inline'>{t('select-none', 'Select none')}</span>
                </button>
              </div>
            )}
            <div className='min-h-20'>
              <div
                className={classNames({
                  'mt-2': !showToolbar,
                  'min-h-6 grid grid-cols-2 md:grid-cols-4 gap-3': options.length > 8 && !loading,

                  // we do not render this box styles when we have less than 8 items
                  'space-y-1': options.length < 8 && !loading,
                })}
              >
                {filteredItems.length === 0 && (
                  <p className='italic text-gray-600 text-sm'>{t('no-options-available', 'No options available')}</p>
                )}
                {loading && (
                  <div className='flex items-center gap-x-2'>
                    <Spinner size={SpinnerSize.XSmall} />
                    <p className='italic text-gray-600 text-sm'>{t('loading-options', 'Loading the list with options...')}</p>
                  </div>
                )}
                {!loading &&
                  filteredItems.map(opt => (
                    <div key={opt.id} className='flex gap-2 items-center'>
                      <input
                        onChange={event => {
                          let values = (field.value ?? []) as Array<string | number>;
                          const inValues = values.includes(opt.id);
                          if (event.currentTarget.checked && !inValues) {
                            values.push(opt.id);
                          } else if (!event.currentTarget.checked && inValues) {
                            values = values.filter(v => v !== opt.id);
                          }
                          field.onChange(values);
                        }}
                        checked={((field.value ?? []) as Array<string | number>).includes(opt.id)}
                        id={`multi_select_countries_${opt.id}`}
                        type='checkbox'
                      />
                      <label htmlFor={`multi_select_countries_${opt.id}`}>{opt.name}</label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      />
      {hint && <label className='block text-xs leading-4 text-gray-500 mt-1'>{hint}</label>}
      <label
        className={classNames('block text-xs leading-4 text-red-500 transition-all duration-200', {
          'h-auto opacity-100  mt-1': !!error,
          'h-0 opacity-0': !error,
        })}
      >
        {error || ''}
      </label>
    </div>
  );
}
