import { CaretDown, CaretUp, Warning } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

interface Props {
  legend: string;
  description?: string;
  canToggle?: boolean;
  hasError?: boolean;
  children: ((isOpen: boolean) => ReactNode) | ReactNode;
  classNameWrapper?: string;
  className?: string;
  // disable the whole fieldset style
  // but instead just render the children
  disabled?: boolean;
}

/**
 * The fieldset component create a native <fieldset><legend>...</legend></fieldset> structure
 * and can be wrapped arround input fields.
 *
 * It also add an option to toggle (open/close) the content of the Fieldset
 */
function Fieldset({ legend, description, children, hasError, canToggle, classNameWrapper, className, disabled }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(canToggle ? false : true);

  /**
   * Toggle event, should be fired when the user click on the button
   */
  const toggle = () => {
    if (!canToggle) return;

    setIsOpen(prevState => !prevState);
  };

  useEffect(() => {
    if (canToggle) {
      setIsOpen(false);
    }
  }, [canToggle]);

  if (disabled) {
    return <div className={classNames(classNameWrapper, className)}>{typeof children === 'function' ? children(isOpen) : children}</div>;
  }

  return (
    <fieldset className={classNames('mt-4 relative', classNameWrapper)}>
      {canToggle && (
        <button className='absolute right-2 -top-7' type='button' onClick={toggle}>
          {isOpen && <CaretUp />}
          {!isOpen && <CaretDown />}
        </button>
      )}
      <legend
        className={classNames('w-full border-b border-dashed border-gray-300 font-medium pb-1 mb-2 flex items-center gap-x-2', {
          'cursor-pointer': canToggle,
          'text-red-500': canToggle && hasError,
        })}
        onClick={toggle}
      >
        {legend}
        {canToggle && hasError && <Warning className='text-red-500' />}
      </legend>

      <div
        className={classNames(
          'pt-3 px-[2px]',
          {
            'overflow-hidden transition-[max-height] animate-slow': canToggle,
            // to simulate a slide-close effect, we need a fixed height as a dynamic height is not working
            // the max-h-[...] is also capable of doign this without to be that fixed.
            // we just use a big max-h number, one that should be work in most cases
            'max-h-[0px]': canToggle && !isOpen,
            'max-h-[1000px]': canToggle && isOpen,
          },
          className,
        )}
      >
        {description && <p className='text-gray-500 text-xs mb-6 leading-4'>{description}</p>}
        {typeof children === 'function' ? children(isOpen) : children}
      </div>
    </fieldset>
  );
}

export default Fieldset;
