/**
 * Small helper that return a query string for the preferred stable.
 */
export default function preferredStableQuery(preferredStableUid: string | undefined | null): string {
  let urlSuffix = '';
  if (preferredStableUid) {
    const stableSearchParam = new URLSearchParams();
    stableSearchParam.append('stable', preferredStableUid);
    urlSuffix = `?${stableSearchParam.toString()}`;
  }

  return urlSuffix;
}
