import * as Sentry from '@sentry/react';
import { useConfig } from 'context/ConfigContext';
import { useCallback, useEffect, useState } from 'react';

interface ReturnType {
  loading: boolean;
  error?: string;
  photo?: Photo;
}

interface PhotoAuthor {
  name: string;
  url: string;
}

interface Photo {
  url: string;
  blurHash: string;
  author: PhotoAuthor;
}

const useUnsplash = (): ReturnType => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [photo, setPhoto] = useState<Photo>();

  const { config } = useConfig();
  const serverlessFunctionsUrl = `${config?.serverlessFunctionsUrl ?? ''}/api/unsplash`;

  useEffect(() => {
    if (loading || photo) {
      return;
    }
    const abortController = new AbortController();
    load(abortController.signal);
  }, []); // eslint-disable-line

  const load = useCallback(
    (abort: AbortSignal) => {
      if (abort.aborted || loading) {
        return;
      }
      setLoading(true);
      fetch(serverlessFunctionsUrl, { method: 'GET' })
        .then(response => {
          if (!response.ok) {
            setError('Failed to load unsplash json');
            return;
          }
          if (abort.aborted) {
            return;
          }
          response
            .json()
            .then(json => {
              const photo: Photo = {
                blurHash: json.blur_hash,
                url: json.urls.regular,
                author: { name: json.user.name, url: `${json.user.links.html}?utm_source=equinem&utm_medium=referral` },
              };
              setPhoto(photo);
            })
            .catch(e => {
              Sentry.captureException(e);
              setError('Failed to load unsplash json');
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(e => {
          if (!abort.aborted) {
            setError('Unable to fetch unsplash image from serverless function');
            Sentry.captureException(e);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [loading, serverlessFunctionsUrl],
  );

  return {
    loading,
    error,
    photo,
  };
};

export default useUnsplash;
