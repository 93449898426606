/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HorsePage } from '../models/HorsePage';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HorsepagesService {
  /**
   * @returns HorsePage
   * @throws ApiError
   */
  public static horsepagesCreate({
    horseOrganisationUid,
    requestBody,
  }: {
    horseOrganisationUid: string,
    requestBody: HorsePage,
  }): CancelablePromise<HorsePage> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{horse__organisation__uid}/horsepages',
      path: {
        'horse__organisation__uid': horseOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
