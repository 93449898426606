import { PencilSimple } from '@phosphor-icons/react';
import UseCountries from 'hooks/UseCountries';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { AllColors } from 'utilities/colors';
import SaveTrustedCountriesModal from '../SaveTrustedCountriesModal';
import { useOrganization } from 'context/OrganizationContext';

function TrustedCountriesBreedingSettingTile(): JSX.Element {
  const { selectedOrganizationDetails, refresh } = useOrganization();
  const { t } = useTranslation();
  const { countryById } = UseCountries();

  const {
    showModal: showSaveTrustedCountriesModal,
    closeModal: closeSaveTrustedCountriesModal,
    modalIsVisible: saveTrustedCountriesModalIsVisible,
  } = useModal();

  const trustedCountriesActions = useMemo(() => {
    return [
      {
        onClick: showSaveTrustedCountriesModal,
        buttonVariant: ButtonVariant.Default,
        icon: <PencilSimple />,
        text: t('edit', 'Edit'),
      },
    ];
  }, [t, showSaveTrustedCountriesModal]);

  return (
    <Tile title={t('trusted-countries', 'Trusted countries')} actions={trustedCountriesActions}>
      <p className='pb-2'>
        {t(
          'trusted-countries-info',
          'Orders that are shipped to the following countries only need to pay the product down payment price upfront and can later pay the residual (by invoice).',
        )}
      </p>
      <div className='flex gap-1 flex-wrap'>
        {(selectedOrganizationDetails?.down_payment_permitted_countries ?? []).slice(0, 20).map(countryCode => (
          <Badge color={AllColors.Indigo} key={countryCode}>
            {countryById(countryCode)?.name}
          </Badge>
        ))}
        {(selectedOrganizationDetails?.down_payment_permitted_countries?.length ?? 0) > 20 && (
          <span>
            {t('trusted-countries-more', 'and {{count}} more.', {
              count: (selectedOrganizationDetails?.down_payment_permitted_countries.length ?? 0) - 20,
            })}
          </span>
        )}
        {(selectedOrganizationDetails?.down_payment_permitted_countries?.length ?? 0) === 0 && (
          <span className='italic'>
            {t('no-trusted-countries-selected', 'No countries selected, all countries pay the full price upfront.')}
          </span>
        )}
      </div>

      <SaveTrustedCountriesModal
        visible={saveTrustedCountriesModalIsVisible}
        onRequestCloseModal={closeSaveTrustedCountriesModal}
        onSaved={refresh}
      />
    </Tile>
  );
}

export default TrustedCountriesBreedingSettingTile;
