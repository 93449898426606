import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { AppRoutes } from 'AppRoutes';
import { useOrganization } from 'context/OrganizationContext';
import useHorseDetail from 'hooks/UseHorseDetail';
import usePermissions from 'hooks/UsePermissions';
import { HorsesService, ModulePermissionsEnum, VaccinationRule, VaccinationrulesService } from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import { PatchedHorseDetail } from 'openapi/models/PatchedHorseDetail';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { objectDiff } from 'utilities/compareObject';
import { transformEmpty, transformEmptyNumber, zodInputIsRequired } from 'utilities/zod';

function CareHorseForm(): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [vaccinationRules, setVaccinationRules] = useState<VaccinationRule[]>([]);
  const { hasPermission } = usePermissions();

  const { horse, setHorse } = useHorseDetail();
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { showModal, closeModal, modalIsVisible } = useModal();

  // Form validation
  const schema = useMemo(() => {
    return schemas.HorseDetail.pick({
      vaccination_rules_uid: true,
      farrier_weeks: true,
      dentist_weeks: true,
      deworm_weeks: true,
      weight: true,
      temperature: true,
      heart_rate: true,
      respiratory_rate: true,
    }).refine(
      (val): boolean => {
        // make sure the user select the correct option
        const vaccinationOptions = vaccinationRules.map(vac => vac.uid);
        return vaccinationOptions.includes(val.vaccination_rules_uid ?? '');
      },
      () => ({ message: t('no-vaccination-rule-selected', 'No vaccination rule selected'), path: ['vaccination_rules_uid'] }),
    );
  }, [t, vaccinationRules]);

  // Construct the default values
  const defaultValues = useMemo((): Partial<HorseDetail> => {
    return {
      vaccination_rules_uid: horse?.vaccination_rules_uid,
      farrier_weeks: horse?.farrier_weeks,
      dentist_weeks: horse?.dentist_weeks,
      deworm_weeks: horse?.deworm_weeks,
      weight: horse?.weight,
      temperature: horse?.temperature,
      heart_rate: horse?.heart_rate,
      respiratory_rate: horse?.respiratory_rate,
    };
  }, [horse]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    watch,
  } = useForm<HorseDetail>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const vaccinationRuleFormValue = watch('vaccination_rules_uid');

  /**
   * Get a vaccination rule based on a UID
   */
  const getVaccinationRule = useCallback(
    (uid: string | undefined): VaccinationRule | undefined => {
      return vaccinationRules.find(vac => vac.uid === uid);
    },
    [vaccinationRules],
  );

  /**
   * Return the correct name of an given vaccination_rules_uid
   */
  const currentVaccinationRule = useMemo((): VaccinationRule | undefined => {
    return getVaccinationRule(horse?.vaccination_rules_uid);
  }, [getVaccinationRule, horse?.vaccination_rules_uid]);

  /**
   * Return the correct name of an given vaccination_rules_uid
   */
  const selectedVaccinationRule = useMemo((): VaccinationRule | undefined => {
    return getVaccinationRule(vaccinationRuleFormValue);
  }, [getVaccinationRule, vaccinationRuleFormValue]);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    closeModal();
    if (resetForm) {
      reset(defaultValues);
    }
  };

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    closeModal();
    // clear the errors
    clearErrors();
    setApiError(undefined);
  };

  /**
   * Load the vaccination rules from the API
   */
  const loadVaccinationRules = useCallback(() => {
    if (!selectedOrganization) return;
    const promise = VaccinationrulesService.vaccinationrulesList({ organisationUid: selectedOrganization.uid });

    promise.then(res => setVaccinationRules(res)).catch(error => console.error(error));
    return promise;
  }, [selectedOrganization]);

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorseDetail> = async (data: HorseDetail) => {
    if (!horse || !selectedOrganization) return;
    setSubmitting(true);
    try {
      // generate a diff of the given form data and the current horse object
      // only the changed values are pushed to the server
      const updateRequest = objectDiff<PatchedHorseDetail>(horse, data);
      const updateHorsePromise = HorsesService.horsesPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: horse.uid,
        requestBody: updateRequest,
      });
      const updatedHorse = await updateHorsePromise;

      // Update the horse
      setHorse(updatedHorse);

      // close the modal
      closeModal();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      return [
        {
          onClick: showModal,
          text: t('edit', 'Edit'),
          buttonVariant: ButtonVariant.Default,
          icon: <Pencil />,
        },
      ];
    }
  }, [showModal, t, hasPermission]);

  /**
   * Update the form when the horse has been updated (triggered mostly by an update of the form)
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  /**
   * Load the vaccination rules onConstruct
   */
  useEffect(() => {
    const promise = loadVaccinationRules();

    return () => promise?.cancel();
  }, [loadVaccinationRules]);

  return (
    <Tile title={t('care', 'Care')} loading={horse === undefined} actions={tileActions}>
      {horse && (
        <TileDescriptionList
          list={[
            { term: t('vaccination-rules', 'Vaccination rules'), definition: currentVaccinationRule?.name ?? '-' },
            {
              term: t('farrier-visit', 'Farrier visit'),
              definition: horse.farrier_weeks
                ? t('farrier-visit-text', '{{weeks}} weeks between each visit', { weeks: horse.farrier_weeks })
                : '-',
            },
            {
              term: t('dentist-visit', 'Dentist visit'),
              definition: horse.dentist_weeks
                ? t('dentist-visit-text', '{{weeks}} weeks between each visit', { weeks: horse.dentist_weeks })
                : '-',
            },
            {
              term: t('deworm', 'Deworm'),
              definition: horse.deworm_weeks ? t('deworm-text', '{{weeks}} weeks between each deworm', { weeks: horse.deworm_weeks }) : '-',
            },
            {
              term: t('weight', 'Weight'),
              definition: horse.weight ? `${horse.weight} kg` : '-',
            },
            {
              term: t('temperature', 'Temperature'),
              definition: horse.temperature ? `${horse.temperature} ${String.fromCharCode(8451)}` : '-',
            },
            {
              term: t('heart-rate', 'Heart rate'),
              definition: horse.heart_rate ? `${horse.heart_rate} bpm` : '-',
            },
            {
              term: t('respiratory-rate', 'Respiratory rate'),
              definition: horse.respiratory_rate ? `${horse.respiratory_rate} bpm` : '-',
            },
          ]}
        />
      )}

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateHorse', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        onClosed={onClosed}
      >
        <PageModalTitle title={t('update-horse', 'Update Horse')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='py-3 space-y-4'>
            <SelectInput
              error={fieldError('vaccination_rules_uid')}
              options={vaccinationRules.map(vac => ({ id: vac.uid, name: vac.name ?? vac.uid }))}
              label={t('vaccination-rule', 'Vaccination rule')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'vaccination_rules_uid')}
              {...register('vaccination_rules_uid')}
              hint={
                <Trans
                  i18nKey='vaccination-form-input-desc'
                  defaults='{{ horse }} has to be vaccinated within <strong>{{ monthsBetweenVaccination }}</strong> months and <strong>{{ optionalExtraDays }}</strong> days and cannot compete for <strong>{{numberOfDaysNotCompetingAfterVaccination }}</strong> days. You can also add your own vaccination rule <0>here</0>.'
                  values={{
                    horse: horse?.name,
                    monthsBetweenVaccination: selectedVaccinationRule?.months_between_vaccination ?? 0,
                    optionalExtraDays: selectedVaccinationRule?.optional_extra_days ?? 0,
                    numberOfDaysNotCompetingAfterVaccination: selectedVaccinationRule?.number_of_days_not_competing_after_vaccination ?? 0,
                  }}
                  components={[
                    <Link key='link' className='text-link' to={AppRoutes.HorsesSettings.path}>
                      here
                    </Link>,
                  ]}
                />
              }
            />

            <TextInput
              error={fieldError('farrier_weeks')}
              label={t('farrier-weeks', 'Farrier weeks')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'farrier_weeks')}
              {...register('farrier_weeks', { setValueAs: transformEmptyNumber(null) })}
            />

            <TextInput
              error={fieldError('dentist_weeks')}
              label={t('dentist-weeks', 'Dentist weeks')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'dentist_weeks')}
              {...register('dentist_weeks', { setValueAs: transformEmptyNumber(null) })}
            />

            <TextInput
              error={fieldError('deworm_weeks')}
              label={t('deworm-weeks', 'Deworm weeks')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'deworm_weeks')}
              {...register('deworm_weeks', { setValueAs: transformEmptyNumber(null) })}
            />

            <TextInput
              error={fieldError('weight')}
              label={t('weight', 'Weight')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'weight')}
              {...register('weight', { setValueAs: transformEmpty(null) })}
              postText='kg'
            />

            <TextInput
              error={fieldError('temperature')}
              label={t('temperature', 'Temperature')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'temperature')}
              {...register('temperature', { setValueAs: transformEmpty(null) })}
              postText={String.fromCharCode(8451)}
            />

            <TextInput
              error={fieldError('heart_rate')}
              label={t('heart_rate', 'Heart rate')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'heart_rate')}
              {...register('heart_rate', { setValueAs: transformEmptyNumber(null) })}
              postText='bpm'
            />

            <TextInput
              error={fieldError('respiratory_rate')}
              label={t('respiratory-rate', 'Respiratory rate')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'respiratory_rate')}
              {...register('respiratory_rate', { setValueAs: transformEmptyNumber(null) })}
              postText='bpm'
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: submitting,
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateHorse',
              loading: submitting,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

export default CareHorseForm;
