import { Plus } from '@phosphor-icons/react';
import { cachedPaginatedApiData } from 'api/ApiCache';
import ApiErrorParser from 'api/ApiErrorParser';
import { generateWebshopPath, WebshopRoutes } from 'AppRoutes';
import { orderRequiresPayment } from 'components/Breeding/Helpers';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import {
  CancelablePromise,
  CatalogueProduct,
  Contact,
  ContactsService,
  Horse,
  HorsesService,
  OrdersService,
  PaginatedCatalogueProductList,
  PaginatedContactList,
  PaginatedHorseList,
  PublicService,
  PurchaserOrderDetail,
  Redirect,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import Button, { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import DescriptionList from 'ui/Layout/DescriptionList';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ActionModal } from 'ui/Modals';
import useModal from 'ui/Modals/UseModal';
import { contactAddress, contactName } from 'utilities/Contact';
import NewOrderModal from './NewOrder';
import WebshopOrderItemDetails from './OrderItemDetails';
import UseCountries from 'hooks/UseCountries';

// A page that shows the details of a webshop order.
export default function WebshopOrderDetailsPage(): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization, selectedOrganizationDetails, generateCacheKey: getCacheId } = useOrganization();
  const { formatDate, parseAndFormatMoney } = useAccount();
  const { setApiError } = usePage();
  const [order, setOrder] = useState<PurchaserOrderDetail>();
  const { uid } = useParams();
  const { publicAccessUuid } = useParams();
  const [catalogue, setCatalogue] = useState<CatalogueProduct[]>();
  const { setApiError: setPageApiError } = usePage();
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const { closeModal, modalIsVisible, showModal } = useModal();
  const { countries } = UseCountries();

  const [newOrderPaymentLinkError, setNewOrderPaymentLinkError] = useState<ApiErrorParser<Redirect> | undefined>();
  const {
    closeModal: closePaymentLinkErrorModal,
    modalIsVisible: paymentLinkErrorModalVisible,
    showModal: showPaymentLinkErrorModal,
  } = useModal();

  const actions = useMemo(() => {
    return [
      {
        onClick: showModal,
        text: t('new-repeat-order', 'New repeat order'),
        icon: <Plus />,
      },
    ];
  }, [showModal, t]);

  // Create a payment link for the order and redirect to it.
  const payOrder = useCallback(async () => {
    if (!order) {
      console.error('Trying to create order payment link but order is not set.');
      return;
    }
    try {
      const url = new URL(
        generateWebshopPath(generatePath(WebshopRoutes.OrderDetails.path, { uid: uid }), publicAccessUuid ?? ''),
        window.location.href,
      );
      const paymentLink = await PublicService.apiV5OrdersMolliePaymentLinkCreate({
        publicAccessUuid: order.public_access_uuid ?? '',
        requestBody: {
          redirectUrl: url.toString(),
        },
      });
      window.location.href = paymentLink.redirect_uri;
    } catch (e) {
      setNewOrderPaymentLinkError(new ApiErrorParser<Redirect>(e));
      showPaymentLinkErrorModal();
    }
  }, [order, uid, publicAccessUuid, showPaymentLinkErrorModal]);

  // Load the order from api
  const loadOrder = useCallback((): CancelablePromise<PurchaserOrderDetail> => {
    const promise = OrdersService.ordersPurchasedRetrieve({
      purchaserUid: selectedOrganization?.uid ?? '',
      uid: uid ?? '',
    });
    promise
      .then(result => {
        setApiError(undefined);
        setOrder(result);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PurchaserOrderDetail>(e));
        }
      });
    return promise;
  }, [selectedOrganization, setApiError, uid]);

  // Load the horses from api and/or cache
  const loadHorses = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedHorseList> => {
      const promise = HorsesService.horsesList({
        organisationUid: selectedOrganization?.uid ?? '',
        onUnknownLocation: false,
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedContactList>(e), horses === undefined);
        }
      });
      cachedPaginatedApiData<Horse>(getCacheId('horses'), promise, setHorses, disableCache);
      return promise;
    },
    [selectedOrganization, horses, getCacheId, setApiError],
  );

  // Load the stallion semen products from api
  const loadCatalogue = useCallback((): CancelablePromise<PaginatedCatalogueProductList> => {
    const promise = PublicService.apiV5PublicOrganisationsProductCatalogueList({
      organisationPublicAccessUuid: publicAccessUuid ?? '',
    });

    promise
      .then(res => {
        setCatalogue(res.results);
        setPageApiError(undefined);
      })
      .catch(e => {
        if (!promise.isCancelled) {
          setPageApiError(new ApiErrorParser<PaginatedCatalogueProductList>(e));
        }
      });
    return promise;
  }, [setPageApiError, publicAccessUuid]);
  // Load the contacts from api and/or cache
  const loadContacts = useCallback(
    (disableCache: boolean): CancelablePromise<PaginatedContactList> => {
      const promise = ContactsService.contactsList({
        organisationUid: selectedOrganization?.uid ?? '',
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedContactList>(e), contacts === undefined);
        }
      });

      cachedPaginatedApiData<Contact>(getCacheId('contacts'), promise, setContacts, disableCache);
      return promise;
    },
    [selectedOrganization, contacts, getCacheId, setApiError],
  );

  // Get the currency from the order.
  const currency = useMemo((): string => {
    if (!order?.order_items || order.order_items.length === 0) {
      return selectedOrganizationDetails?.currency ?? 'EUR';
    }
    return order.order_items[0].unit_price_currency;
  }, [order, selectedOrganizationDetails?.currency]);

  const paymentStatus = useMemo(() => {
    return parseAndFormatMoney(order?.payment_summary.paid ?? '0', currency);
  }, [currency, parseAndFormatMoney, order]);

  // When nothing is payed, we assume that the order needs to be payed.
  // TODO: Don't assume, we need to be sure. This requires a backend field.
  const showPayOrderButton = useMemo(() => {
    if (!order || !orderRequiresPayment(order)) {
      return false;
    }
    return !order.payment_summary.paid || order.payment_summary.paid === '0' || order.payment_summary.paid === '0.00';
  }, [order]);

  // Load the order
  useEffect(() => {
    if (selectedOrganization && order?.uid !== uid) {
      const promise = loadOrder();
      return () => promise.cancel();
    }
  }, [selectedOrganization, uid]); //eslint-disable-line

  // Load the products
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadCatalogue();
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the horses
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadHorses(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  // Load the contacts
  useEffect(() => {
    // We need to have a selected organization.
    if (selectedOrganization) {
      const promise = loadContacts(false);
      return () => promise.cancel();
    }
  }, [selectedOrganization]); //eslint-disable-line

  const breadCrumbs = useMemo(() => {
    // We'll have to set the full path
    return [
      {
        path: generateWebshopPath(WebshopRoutes.OrderList.path, publicAccessUuid ?? ''),
        name: t('orders', 'Orders'),
        component: WebshopRoutes.OrderList.component,
        public: WebshopRoutes.OrderList.public,
      },
    ];
  }, [publicAccessUuid, t]);

  // Show the word order and the order number (uid).
  const pageTitle = `${t('order', 'Order')} ${order?.uid ?? ''}`;

  return (
    <Page title={pageTitle} breadCrumbs={breadCrumbs} maxWidth={PageMaxWidth.Tile} actions={actions}>
      <div className='space-y-4'>
        <Tile title={t('general', 'General')}>
          {order && (
            <DescriptionList
              list={[
                { term: t('order-number', 'Order number'), definition: order.uid },
                {
                  term: t('customer', 'Customer'),
                  definition: (
                    <div>
                      <p>{contactName(order.historic_customer)}</p>
                      {contactAddress(order.historic_customer, countries).map(line => (
                        <p key={line}>{line}</p>
                      ))}
                    </div>
                  ),
                },
                {
                  term: t('receiver', 'Receiver'),
                  definition: (
                    <div>
                      <p>{contactName(order.historic_receiver)}</p>
                      {contactAddress(order.historic_receiver, countries).map(line => (
                        <p key={line}>{line}</p>
                      ))}
                    </div>
                  ),
                },
                { term: t('note', 'Note'), definition: order.customer_note },
                {
                  term: t('requested-shipping-date', 'Requested shipping date'),
                  definition: order.shipping_date ? formatDate(new Date(order.shipping_date)) : '',
                },
                {
                  term: t('paid', 'Paid'),
                  definition: (
                    <div className='space-y-2'>
                      <p>{paymentStatus}</p>
                      {showPayOrderButton && selectedOrganizationDetails?.can_create_payment_link && (
                        <Button variant={ButtonVariant.Primary} onClick={payOrder}>
                          {t('pay-order', 'Pay order')}
                        </Button>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          )}
        </Tile>
        {(order?.order_items ?? []).map(item => (
          <WebshopOrderItemDetails key={item.uid} products={catalogue} orderItem={item} horses={horses ?? []} />
        ))}
      </div>
      {horses && order && contacts && (
        <NewOrderModal
          open={modalIsVisible}
          horses={horses}
          contacts={contacts}
          onContactUpdate={() => loadContacts(false)}
          repeatOrder={order}
          catalogue={catalogue}
          onPaymentLinkFailure={(newOrderUid, error) => {
            setNewOrderPaymentLinkError(error);
            showPaymentLinkErrorModal();
          }}
          onRequestClose={(createdOrderUid?: string) => {
            if (createdOrderUid) {
              // New repeat order is successfully created.
              loadHorses(false);
              loadOrder();
            }
            closeModal();
          }}
          onHorsesUpdate={() => {
            loadHorses(false);
          }}
        />
      )}
      <ActionModal
        open={paymentLinkErrorModalVisible}
        onClose={() => setNewOrderPaymentLinkError(undefined)}
        actions={[
          {
            text: t('close', 'Close'),
            variant: ButtonVariant.Default,
            onClick: closePaymentLinkErrorModal,
          },
        ]}
        title={t('failed-to-create-payment-link-title', 'Failed to create payment link')}
      >
        <div className='space-y-4'>
          <p>
            {t(
              'failed-to-create-payment-link-message-order',
              'Failed to create a payment request for the order. Your order has been created but has not been payed. Please try again or contact us.',
            )}
          </p>
          <ErrorSection errors={newOrderPaymentLinkError} />
        </div>
      </ActionModal>
    </Page>
  );
}
