import { Directory, Filesystem } from '@capacitor/filesystem';
import { IS_MOBILE_APP } from 'const';
import { FileOpener } from '@capacitor-community/file-opener';
import { encode } from 'uint8-to-base64';

const MOBILE_CACHE_PATH = 'equinem_cache';

/**
 * Download function for on a mobile App
 * This function will save the files to the local filesystem and return the file path
 */
async function downloadForMobile(url: string, abortSignal?: AbortSignal): Promise<string> {
  if (!IS_MOBILE_APP) {
    throw new Error('downloadForMobile() is only supported for MOBILE_APP');
  }

  // NOTE: we do not need to ask for File Permission
  // The cache folder is always writeable for both IOS and Andrid
  // @see https://capacitorjs.com/docs/v4/apis/filesystem#requestpermissions

  // First delete the cache dir to avoid stacking all (huge) temp files here
  // We are not sure when Android or IOS clear the cache folder, so to be sure we remove the files by our self
  try {
    await Filesystem.rmdir({
      directory: Directory.Cache,
      recursive: true,
      path: MOBILE_CACHE_PATH,
    });
  } catch (error) {
    // ignore errors
  }

  // (re)create the cache folder
  try {
    await Filesystem.mkdir({
      directory: Directory.Cache,
      path: MOBILE_CACHE_PATH,
    });
  } catch (e) {
    throw new Error('Cannot create a cache dir');
  }

  // first fetch the file
  const response = await fetch(url, { signal: abortSignal });
  if (!response.ok) {
    throw new Error(`Failed to fetch the file from ${url}`);
  }

  // get the correct Filename from the path
  const match = url.match(/\/([^/?]+)\?/);
  const filename = match ? match[1] : 'image';
  const arrayBuffer = await response.arrayBuffer();

  // convert the uint8Array to base64
  const base64Data = encode(new Uint8Array(arrayBuffer));

  try {
    const file = await Filesystem.writeFile({
      path: `${MOBILE_CACHE_PATH}/${filename}`,

      directory: Directory.Cache,
      data: base64Data,
    });
    return file.uri;
  } catch (e) {
    console.error(e);
    const error = e instanceof Error ? e.message : 'unknown error';
    throw new Error(`Cannot write files to ${filename} with error: ${error}`);
  }
}

/**
 * Open a file in a native dialog for mobile
 * @param url
 * @param abortSignal
 * @returns
 */
export async function openFileForMobile(url: string, abortSignal?: AbortSignal): Promise<void> {
  if (!IS_MOBILE_APP) {
    throw new Error('openFileForMobile() is only supported for MOBILE_APP');
  }

  // download all files locally
  const filePath = await downloadForMobile(url, abortSignal);

  // show the native IOS/Android File preview dialog
  FileOpener.open({ filePath });
}
