import { Minus, Plus, Trash } from '@phosphor-icons/react';
import classNames from 'classnames';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { NestedCyst } from 'openapi';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type NestedCystWithId = NestedCyst & { id?: string };

interface Props {
  cysts: NestedCystWithId[];
  cystAdded?: (cyst: NestedCyst) => void;
  cystRemoved?: (index: number) => void;
  cystUpdated?: (index: number, cyst: NestedCyst) => void;
}

/**
 * Make the cyst size smaller or bigger. Do it by steps of 0.5 cm. Or when smaller then 0.5 do it with 0.1 steps.
 * @param numberAsString Current size as string
 * @param direction Make it smaller or bigger
 * @returns The new size as string.
 */
const updateCystSizeMetric = (numberAsString: string, direction: 'plus' | 'minus'): string => {
  const current = Number(numberAsString) * 10;
  const miniSteps = current < 5 || (current === 5 && direction === 'minus');
  const append = direction === 'plus' ? (miniSteps ? 1 : 5) : miniSteps ? -1 : -5;
  const number = Math.min(Math.max(0.1, (current + append) / 10), 6);
  return number.toString();
};

// For touch devices we use the TouchEvents instead of the html5 draggable feature.
interface TouchDraggable {
  index?: number;
  x: number;
  y: number;
}

// Pixel size of the cyst (x and y)
const cystSize = 38;

// Fixed size of /breeding/uterus.png
const imageWidth = 400;
const imageHeight = 500;

const posToPercentage = (posInPixels: number, axis: 'x' | 'y'): number => {
  return Math.round((posInPixels / (axis === 'x' ? imageWidth : imageHeight)) * 100);
};

const percentageToPos = (posInPercentage: number, axis: 'x' | 'y'): number => {
  return ((axis === 'x' ? imageWidth : imageHeight) / 100) * posInPercentage;
};

export default function CystMap({ cysts, cystAdded, cystUpdated, cystRemoved }: Props): JSX.Element {
  const cystClass =
    'touch-none	rounded-full bg-red-500 bg-opacity-80 flex items-center justify-center after:rounded-full after:w-5 after:h-5 after:bg-red-500 after:text-blue-500';
  const { t } = useTranslation();
  const cystElementRef = useRef<HTMLDivElement>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | undefined>();
  const [showHoldLongerMessage, setShowHoldLongerMessage] = useState<boolean>(false);
  const [touchDragging, setTouchDragging] = useState<TouchDraggable | undefined>();
  const imageAreaRef = useRef<HTMLDivElement>(null);
  const { width: screenWidth } = useScreenSize();

  // For touch devices we use the TouchEvents instead of the html5 draggable feature.
  // This method gets called on touchStart and touchMove of a cyst.
  // The `index` is filled when we're moving an exiting item.
  const touchEvent = useCallback(
    (index?: number, touch?: React.Touch) => {
      if (!cystUpdated || !cystAdded) {
        return;
      }
      const boundingRect = imageAreaRef.current?.getBoundingClientRect();
      let x = (touch?.clientX ?? 0) - (boundingRect?.x ?? 0);
      let y = (touch?.clientY ?? 0) - (boundingRect?.y ?? 0);

      // Restrict to a minimum of 0.
      x = Math.max(0, x);
      y = Math.max(0, y);

      // Restrict to the dimensions of the image.
      x = Math.min(imageWidth, x);
      y = Math.min(imageHeight, y);

      if (index === undefined || selectedItemIndex === index) {
        setTouchDragging({ index, x, y });
      }
    },
    [setTouchDragging, imageAreaRef, selectedItemIndex, cystAdded, cystUpdated],
  );

  return (
    <div className='border rounded-lg flex flex-col select-none relative'>
      {cystAdded && (
        <div className='border-b w-full h-12 flex flex-row items-center gap-2 p-2'>
          <div
            ref={cystElementRef}
            draggable={screenWidth > ScreenSize.md && cystAdded !== undefined}
            onClick={() => {
              if (!cystUpdated) {
                return;
              }
              setSelectedItemIndex(undefined);
              setShowHoldLongerMessage(true);
            }}
            onDragStart={e => {
              if (!cystUpdated) {
                return;
              }
              setSelectedItemIndex(undefined);
              e.dataTransfer.setData('Text', e.currentTarget.id);
              e.dataTransfer.dropEffect = 'move';
              if (cystElementRef.current) {
                e.dataTransfer.setDragImage(cystElementRef.current, cystSize / 2, cystSize / 2);
              }
            }}
            onTouchStart={e => touchEvent(undefined, e.touches[0])}
            onTouchMove={e => touchEvent(undefined, e.touches[0])}
            onTouchEnd={() => {
              if (!touchDragging || !cystAdded) {
                return;
              }
              // Create a 'fresh' new cyst with a size of 2 cm.
              cystAdded({
                size_metric: '2',
                x: posToPercentage(touchDragging.x - cystSize / 2, 'x'),
                y: posToPercentage(touchDragging.y - cystSize / 2, 'y'),
              });
              setSelectedItemIndex(cysts.length);
              setTouchDragging(undefined);
            }}
            className={classNames('cursor-grab z-10', cystClass)}
            // The `transform: 'translate(0, 0)'` makes the drag image have a transparent background.
            style={{ width: cystSize, height: cystSize, minWidth: cystSize, minHeight: cystSize, transform: 'translate(0, 0)' }}
          />

          {showHoldLongerMessage && (
            <p className='text-sm text-gray-700'>{t('drag-drop-cyst-help-hold-longer', 'Hold down to start dragging.')}</p>
          )}
          {!showHoldLongerMessage && <p className='text-sm text-gray-700'>{t('drag-drop-cyst-help', 'Drag and drop to indicate cyst.')}</p>}
        </div>
      )}
      <div ref={imageAreaRef} className='relative'>
        <img
          style={{ width: imageWidth, height: imageHeight, minWidth: imageWidth, minHeight: imageHeight }}
          className='rounded-lg'
          src='/breeding/uterus.png'
        />
        <div
          className='absolute inset-0'
          onDragOver={e => {
            e.preventDefault();
          }}
          onDrop={e => {
            if (!cystAdded || !cystUpdated) {
              return;
            }
            const posX = e.clientX - e.currentTarget.getBoundingClientRect().left - cystSize / 2;
            const posY = e.clientY - e.currentTarget.getBoundingClientRect().top - cystSize / 2;
            const percX = posToPercentage(posX, 'x');
            const percY = posToPercentage(posY, 'y');
            const data = e.dataTransfer.getData('text');
            setShowHoldLongerMessage(false);

            if (data) {
              const index = Number(data);
              cystUpdated(index, { ...cysts[index], x: percX, y: percY });
              setSelectedItemIndex(index);
            } else {
              cystAdded({ size_metric: '2', x: percX, y: percY });
              setSelectedItemIndex(cysts.length);
            }
            e.stopPropagation();
            e.preventDefault();
          }}
          onClick={() => setSelectedItemIndex(undefined)}
        >
          <div>
            {cysts.map((cystField, index) => (
              <div
                key={cystField?.id ?? cystField.uid}
                // The `transform: 'translate(0, 0)'` makes the drag image have a transparent background.
                style={{
                  left: percentageToPos(cystField.x, 'x'),
                  top: percentageToPos(cystField.y, 'y'),
                  width: cystSize,
                  transform: 'translate(0, 0)',
                }}
                className={classNames('absolute transition-all flex items-center flex-col', {
                  'opacity-100 z-30': selectedItemIndex === index,
                  'opacity-50': selectedItemIndex !== undefined && selectedItemIndex !== index,
                  hidden: touchDragging?.index === index,
                })}
                onClick={e => {
                  if (!cystUpdated) {
                    return;
                  }
                  setSelectedItemIndex(index);
                  e.stopPropagation();
                }}
                draggable={screenWidth > ScreenSize.md && cystAdded !== undefined}
                onDragStart={e => {
                  if (!cystUpdated) {
                    return;
                  }
                  e.dataTransfer.setData('text', index.toString());
                  if (cystElementRef.current) {
                    e.dataTransfer.setDragImage(cystElementRef.current, cystSize / 2, cystSize / 2);
                  }
                  setSelectedItemIndex(undefined);
                }}
              >
                <div
                  onTouchStart={e => {
                    if (selectedItemIndex !== index) {
                      return;
                    }
                    touchEvent(index, e.touches[0]);
                  }}
                  onTouchMove={e => touchEvent(index, e.touches[0])}
                  onTouchEnd={() => {
                    if (!cystUpdated) {
                      return;
                    }
                    if (touchDragging?.index === undefined) {
                      setTouchDragging(undefined);
                      return;
                    }
                    cystUpdated(touchDragging.index, {
                      ...cysts[touchDragging.index],
                      x: posToPercentage(touchDragging.x - cystSize / 2, 'x'),
                      y: posToPercentage(touchDragging.y - cystSize / 2, 'y'),
                    });
                    setSelectedItemIndex(touchDragging.index);
                    setTouchDragging(undefined);
                  }}
                  style={{ width: cystSize, height: cystSize }}
                  className={classNames(cystClass, { 'cursor-grab': cystUpdated !== undefined })}
                />
                <div className='bg-blue-200 rounded mt-1 text-xs text-center py-0.5 font-medium text-nowrap w-full'>
                  {cystField.size_metric}
                  {t('centimeter-short', 'cm')}
                </div>
                <div
                  className={classNames(
                    'absolute -left-11 -bottom-2 bg-blue-500 rounded-full transition-all w-10 h-10 flex items-center justify-center cursor-pointer hover:bg-blue-600 text-white',
                    {
                      'visible scale-100 opacity-100': selectedItemIndex === index,
                      'invisible scale-75 opacity-0': selectedItemIndex !== index,
                    },
                  )}
                  onClick={e => {
                    if (!cystUpdated) {
                      return;
                    }
                    cystUpdated(index, { ...cystField, size_metric: updateCystSizeMetric(cystField.size_metric, 'minus') });
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Minus />
                </div>
                <div
                  className={classNames(
                    'absolute -right-11 -bottom-2 bg-blue-500 rounded-full transition-all w-10 h-10 flex items-center justify-center cursor-pointer hover:bg-blue-600 text-white',
                    {
                      'visible scale-100 opacity-100': selectedItemIndex === index,
                      'invisible scale-75 opacity-0': selectedItemIndex !== index,
                    },
                  )}
                  onClick={e => {
                    if (!cystUpdated) {
                      return;
                    }
                    cystUpdated(index, { ...cystField, size_metric: updateCystSizeMetric(cystField.size_metric, 'plus') });
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Plus />
                </div>
                <div
                  className={classNames(
                    'absolute -bottom-11 bg-red-500 rounded-full transition-all w-8 h-8 flex items-center justify-center cursor-pointer hover:bg-red-600 text-white',
                    {
                      'visible scale-100 opacity-100': selectedItemIndex === index,
                      'invisible scale-75 opacity-0': selectedItemIndex !== index,
                    },
                  )}
                  onClick={e => {
                    if (!cystRemoved) {
                      return;
                    }
                    setSelectedItemIndex(undefined);
                    cystRemoved(index);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Trash />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {touchDragging && touchDragging.x && touchDragging.y && (
        <div
          style={{ left: touchDragging.x - cystSize / 2, top: touchDragging.y + cystSize * 0.75, width: cystSize, height: cystSize }}
          className={classNames('absolute', cystClass)}
        />
      )}
    </div>
  );
}
