/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProductList } from '../models/PaginatedProductList';
import type { PatchedProduct } from '../models/PatchedProduct';
import type { Product } from '../models/Product';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductsService {
  /**
   * Products come in different flavors. Currently there are 2 default categories: [Breeding, Shipping].
   * The model's fields constitutes the union of each category's required fields:
   * - Breeding: needs a `horse` (that isn't a mare)
   * - Shipping: needs a `shipping_provider_name` and `shipping_service_type`
   *
   * Product supports two prices:
   * - `price` (in the filter indicated as 'price_A'): if set, this represents the current price of
   * this product should it be ordered.
   * - `future_price` (in the filter indicated as 'price_B': to set a price that should apply to this
   * product somewhere in the future.
   *
   * This comes as a pair with `future_price_application_date`: you should set or null both at a time.
   * Once the application date has passed, you can expect Backend to have updates the product such that
   * the once future_price is now the (current) price.
   * @returns PaginatedProductList
   * @throws ApiError
   */
  public static productsList({
    organisationUid,
    category,
    categoryDefaultIn,
    currentPriceGte,
    currentPriceLte,
    freshAvailable,
    frozenAvailable,
    futurePriceGte,
    futurePriceLte,
    hidden,
    nameIcontains,
    o,
    page,
    pageSize,
    planModule,
    planModuleIn,
    shippingCountriesIcontains,
    shippingProviderNameIcontains,
    shippingServiceType,
    stallionUid,
    stallionUidIn,
  }: {
    organisationUid: string,
    category?: number,
    /**
     * Multiple values may be separated by commas.
     */
    categoryDefaultIn?: Array<string>,
    /**
     * Current price is greater than or equal to
     */
    currentPriceGte?: number,
    /**
     * Current price is smaller than or equal to
     */
    currentPriceLte?: number,
    freshAvailable?: boolean,
    frozenAvailable?: boolean,
    /**
     * Future price is greater than or equal to
     */
    futurePriceGte?: number,
    /**
     * Future price is smaller than or equal to
     */
    futurePriceLte?: number,
    hidden?: boolean,
    nameIcontains?: string,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    /**
     * Required when the plan should restrict access to active service contracts.
     *
     * * `ACCOUNTING` - Accounting
     * * `SHOP_AND_BREEDING` - Shop and breeding
     * * `FEED_AND_MEDICINE` - Feed and medicine
     * * `FACILITY_PLANNING` - Facility planning
     * * `FINANCIAL_AND_PAYMENT` - Financial and payment
     * * `USER_ACCOUNTS` - User accounts
     * * `HORSE_SERVICES_CARE` - Horse services, care
     * * `HORSE_SERVICES_FULL` - Horse services, full
     * * `RVO_REPORTS` - RvO reports
     */
    planModule?: 'ACCOUNTING' | 'FACILITY_PLANNING' | 'FEED_AND_MEDICINE' | 'FINANCIAL_AND_PAYMENT' | 'HORSE_SERVICES_CARE' | 'HORSE_SERVICES_FULL' | 'RVO_REPORTS' | 'SHOP_AND_BREEDING' | 'USER_ACCOUNTS',
    /**
     * Multiple values may be separated by commas.
     */
    planModuleIn?: Array<string>,
    shippingCountriesIcontains?: string,
    shippingProviderNameIcontains?: string,
    /**
     * * `REGULAR` - Regular
     * * `PICK_UP` - Pick up
     * * `SAME_DAY_DELIVERY` - Same day delivery
     * * `NEXT_DAY_DELIVERY` - Next day delivery
     * * `SUNDAY_HOLIDAY_DELIVERY` - Sunday and holiday delivery
     */
    shippingServiceType?: 'NEXT_DAY_DELIVERY' | 'PICK_UP' | 'REGULAR' | 'SAME_DAY_DELIVERY' | 'SUNDAY_HOLIDAY_DELIVERY',
    stallionUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    stallionUidIn?: Array<string>,
  }): CancelablePromise<PaginatedProductList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/products',
      path: {
        'organisation__uid': organisationUid,
      },
      query: {
        'category': category,
        'category__default__in': categoryDefaultIn,
        'current_price_gte': currentPriceGte,
        'current_price_lte': currentPriceLte,
        'fresh_available': freshAvailable,
        'frozen_available': frozenAvailable,
        'future_price_gte': futurePriceGte,
        'future_price_lte': futurePriceLte,
        'hidden': hidden,
        'name__icontains': nameIcontains,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'plan__module': planModule,
        'plan__module__in': planModuleIn,
        'shipping_countries__icontains': shippingCountriesIcontains,
        'shipping_provider_name__icontains': shippingProviderNameIcontains,
        'shipping_service_type': shippingServiceType,
        'stallion__uid': stallionUid,
        'stallion__uid__in': stallionUidIn,
      },
    });
  }
  /**
   * Products come in different flavors. Currently there are 2 default categories: [Breeding, Shipping].
   * The model's fields constitutes the union of each category's required fields:
   * - Breeding: needs a `horse` (that isn't a mare)
   * - Shipping: needs a `shipping_provider_name` and `shipping_service_type`
   *
   * Product supports two prices:
   * - `price` (in the filter indicated as 'price_A'): if set, this represents the current price of
   * this product should it be ordered.
   * - `future_price` (in the filter indicated as 'price_B': to set a price that should apply to this
   * product somewhere in the future.
   *
   * This comes as a pair with `future_price_application_date`: you should set or null both at a time.
   * Once the application date has passed, you can expect Backend to have updates the product such that
   * the once future_price is now the (current) price.
   * @returns Product
   * @throws ApiError
   */
  public static productsCreate({
    organisationUid,
    requestBody,
  }: {
    organisationUid: string,
    requestBody: Product,
  }): CancelablePromise<Product> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{organisation__uid}/products',
      path: {
        'organisation__uid': organisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Products come in different flavors. Currently there are 2 default categories: [Breeding, Shipping].
   * The model's fields constitutes the union of each category's required fields:
   * - Breeding: needs a `horse` (that isn't a mare)
   * - Shipping: needs a `shipping_provider_name` and `shipping_service_type`
   *
   * Product supports two prices:
   * - `price` (in the filter indicated as 'price_A'): if set, this represents the current price of
   * this product should it be ordered.
   * - `future_price` (in the filter indicated as 'price_B': to set a price that should apply to this
   * product somewhere in the future.
   *
   * This comes as a pair with `future_price_application_date`: you should set or null both at a time.
   * Once the application date has passed, you can expect Backend to have updates the product such that
   * the once future_price is now the (current) price.
   * @returns Product
   * @throws ApiError
   */
  public static productsRetrieve({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<Product> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/products/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * Products come in different flavors. Currently there are 2 default categories: [Breeding, Shipping].
   * The model's fields constitutes the union of each category's required fields:
   * - Breeding: needs a `horse` (that isn't a mare)
   * - Shipping: needs a `shipping_provider_name` and `shipping_service_type`
   *
   * Product supports two prices:
   * - `price` (in the filter indicated as 'price_A'): if set, this represents the current price of
   * this product should it be ordered.
   * - `future_price` (in the filter indicated as 'price_B': to set a price that should apply to this
   * product somewhere in the future.
   *
   * This comes as a pair with `future_price_application_date`: you should set or null both at a time.
   * Once the application date has passed, you can expect Backend to have updates the product such that
   * the once future_price is now the (current) price.
   * @returns Product
   * @throws ApiError
   */
  public static productsUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody: Product,
  }): CancelablePromise<Product> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{organisation__uid}/products/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Products come in different flavors. Currently there are 2 default categories: [Breeding, Shipping].
   * The model's fields constitutes the union of each category's required fields:
   * - Breeding: needs a `horse` (that isn't a mare)
   * - Shipping: needs a `shipping_provider_name` and `shipping_service_type`
   *
   * Product supports two prices:
   * - `price` (in the filter indicated as 'price_A'): if set, this represents the current price of
   * this product should it be ordered.
   * - `future_price` (in the filter indicated as 'price_B': to set a price that should apply to this
   * product somewhere in the future.
   *
   * This comes as a pair with `future_price_application_date`: you should set or null both at a time.
   * Once the application date has passed, you can expect Backend to have updates the product such that
   * the once future_price is now the (current) price.
   * @returns Product
   * @throws ApiError
   */
  public static productsPartialUpdate({
    organisationUid,
    uid,
    requestBody,
  }: {
    organisationUid: string,
    uid: string,
    requestBody?: PatchedProduct,
  }): CancelablePromise<Product> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{organisation__uid}/products/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Products come in different flavors. Currently there are 2 default categories: [Breeding, Shipping].
   * The model's fields constitutes the union of each category's required fields:
   * - Breeding: needs a `horse` (that isn't a mare)
   * - Shipping: needs a `shipping_provider_name` and `shipping_service_type`
   *
   * Product supports two prices:
   * - `price` (in the filter indicated as 'price_A'): if set, this represents the current price of
   * this product should it be ordered.
   * - `future_price` (in the filter indicated as 'price_B': to set a price that should apply to this
   * product somewhere in the future.
   *
   * This comes as a pair with `future_price_application_date`: you should set or null both at a time.
   * Once the application date has passed, you can expect Backend to have updates the product such that
   * the once future_price is now the (current) price.
   * @returns void
   * @throws ApiError
   */
  public static productsDestroy({
    organisationUid,
    uid,
  }: {
    organisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{organisation__uid}/products/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
      },
    });
  }
}
