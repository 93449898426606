import { HandPalm, IconContext, Warning, WifiSlash, GlobeX } from '@phosphor-icons/react';
import { ApiErrorType } from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import Button, { ButtonVariant } from 'ui/Button';
import Spinner, { SpinnerSize } from 'ui/Loading/Spinner';
import { ActionModal } from 'ui/Modals';
import { ApiPromiseErrors, ApiPromises } from 'utilities/ApiPromises';
import { AllColors } from 'utilities/colors';
import { Tile } from './Tile';
import useLoadApiPromises from 'api/hooks/useLoadApiPromises';

interface WrapperProps {
  loading?: boolean | ApiPromises;
  children: ReactNode;
}

interface PageErrorBarProps {
  onClick: () => void;
  onRequestReload: () => void;
  title: string;
  description: string;
  icon: ReactNode;
}

interface PageErrorDetailsProps {
  errors: ApiPromiseErrors;
}

const errorIcon = (errorType: ApiErrorType, isMobileDevice: boolean) => {
  switch (errorType) {
    case ApiErrorType.CancelError:
    case ApiErrorType.ApplicationError:
      return <Warning />;
    case ApiErrorType.AuthorizationError:
      return <HandPalm />;
    case ApiErrorType.NetworkError:
      return isMobileDevice ? <WifiSlash /> : <GlobeX />;
  }
};

function PageErrorBar({ onClick, onRequestReload, title, description, icon }: PageErrorBarProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='flex flex-row justify-between w-full bg-amber-300 md:rounded-lg text-amber-800 mb-2 select-none'>
      <p className='space-x-1 cursor-pointer m-2.5' onClick={onClick}>
        <IconContext.Provider
          value={{
            size: 20,
            className: 'mb-0.5 inline',
          }}
        >
          {icon}
        </IconContext.Provider>
        <span className='font-medium'>{title}.</span>
        <span>{description}</span>
      </p>
      <p
        className='flex items-center font-medium cursor-pointer p-2.5 text-amber-800 border-l border-amber-400 md:border-amber-300 md:rounded-r-lg'
        onClick={onRequestReload}
      >
        {t('reload', 'Reload')}
      </p>
    </div>
  );
}

function PageErrorDetails({ errors }: PageErrorDetailsProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col gap-2 px-4 pt-2 pb-4 divide-y rounded border mt-4'>
      {errors.errors.map(error => (
        <pre key={error.id} className='space-y-1 pt-2 whitespace-normal break-all'>
          <p className='space-x-2'>
            <span>{'REQ:'}</span>
            {error.error.method && <Badge color={AllColors.Fuchsia}>{error.error.method}</Badge>}
            {error.error.url && <span>{error.error.url}</span>}
            <Badge size={BadgeSize.Small} color={AllColors.Neutral}>
              {error.id}
            </Badge>
            {error.hasDataFromCache && (
              <Badge size={BadgeSize.Small} color={AllColors.Neutral}>
                {'cached'}
              </Badge>
            )}
          </p>
          <p className='space-x-2'>
            <span>{'RES:'}</span>
            {error.error.statusCode && <Badge color={AllColors.Red}>{error.error.statusCode}</Badge>}
            <span>
              {error.error.nonFieldErrorsStrings().length
                ? error.error.nonFieldErrorsStrings().join(' ')
                : t('unknown-error-occurred', 'An unknown error occurred')}
            </span>
          </p>
        </pre>
      ))}
    </div>
  );
}

export function PageErrorWrapper({ loading, children }: WrapperProps): JSX.Element {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  // Get the error from the usePage context. This is for backwards compatibility
  // reasons. All errors should come in via the ApiPromises object.
  const { error } = usePage();
  const { t } = useTranslation();
  const isMobileDevice = useIsMobileDevice();
  const { selectedOrganization } = useOrganization();
  const { loadInternal, apiPromisesError, setLoadingState, setErrorInfo, errorInfo, canShowLoader } = useLoadApiPromises();

  // TODO: We might consider showing a small loader somewhere when reloading silently.

  const reload = useCallback(() => {
    if (!loading || typeof loading === 'boolean') {
      window.location.reload();
    } else {
      loading.refresh();
      loadInternal(loading);
    }
  }, [loading, loadInternal]);

  // Updates the loading state (based on ApiPromises).
  useEffect(() => {
    if (typeof loading === 'boolean' || !loading) {
      setLoadingState(error ? false : loading ? true : false);
      if (error) {
        setErrorInfo({
          errorType: ApiErrorType.ApplicationError,
          title: error.title,
          description: error.description,
          isFatal: error.isFatal,
        });
      } else {
        setErrorInfo(undefined);
      }
    } else {
      loadInternal(loading);
    }
  }, [loading, t, error, loadInternal, setLoadingState, setErrorInfo]);

  if (canShowLoader) {
    return (
      <div className='flex flex-col items-center pt-12'>
        <Spinner size={SpinnerSize.Normal} />
      </div>
    );
  }

  if (errorInfo && errorInfo.isFatal) {
    return (
      <Tile className='mt-2'>
        <div className='flex flex-col items-center md:items-start md:flex-row gap-5 p-6'>
          <div className='mt-2 flex justify-center p-4 bg-red-100 rounded-full text-red-700'>
            <IconContext.Provider
              value={{
                size: 60,
                weight: 'thin',
              }}
            >
              {errorIcon(errorInfo.errorType, isMobileDevice ?? false)}
            </IconContext.Provider>
          </div>
          <div className='grow'>
            <h1 className='font-semibold text-2xl'>{errorInfo.title}</h1>
            <p>{errorInfo.description}</p>
            {selectedOrganization?.me.roles.length === 0 && (
              <p>
                {t('no-role-desc', 'This may be caused because there is no role assigned to you in "{{orgname}}".', {
                  orgname: selectedOrganization.name,
                })}
              </p>
            )}
            {showDetails && <div className='mt-4'>{apiPromisesError && <PageErrorDetails errors={apiPromisesError} />}</div>}
            <div className='mt-6 space-x-2'>
              <Button variant={ButtonVariant.Primary} onClick={reload}>
                {t('reload-page', 'Reload the page')}
              </Button>
              {!showDetails && apiPromisesError && (
                <Button variant={ButtonVariant.Default} onClick={() => setShowDetails(true)}>
                  {t('show-details', 'Show details')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Tile>
    );
  }

  return (
    <>
      {errorInfo && !errorInfo.isFatal && (
        <PageErrorBar
          onClick={() => {
            if (apiPromisesError) {
              setShowDetails(true);
            }
          }}
          onRequestReload={reload}
          icon={errorIcon(errorInfo.errorType, isMobileDevice ?? false)}
          title={errorInfo.title}
          description={errorInfo.description}
        />
      )}
      {children}
      <ActionModal
        open={showDetails}
        actions={[
          {
            text: t('close', 'Close'),
            variant: ButtonVariant.Default,
            onClick: () => setShowDetails(false),
          },
        ]}
        title={t('error-details', 'Error details')}
      >
        {apiPromisesError && <PageErrorDetails errors={apiPromisesError} />}
      </ActionModal>
    </>
  );
}
