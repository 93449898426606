import { Barn, Calendar, Horse } from '@phosphor-icons/react';
import { AppRoutes } from 'AppRoutes';
import classNames from 'classnames';
import { sumUpTotal } from 'components/Manure/helper';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import useManureSelection from 'hooks/UseManureSelection';
import {
  Contact,
  ContactsService,
  FertilizerBudgetTypeEnum,
  FertilizerConsumer,
  ManureService,
  NitrogenApplication,
  ProductionBalanceItem,
} from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from 'ui/Button';
import ButtonDropdown from 'ui/Button/ButtonDropdown';
import { table, tableTbody, tableTbodyTrNoClick, tableTheadThName } from 'ui/Const';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';

function ManurePage(): JSX.Element {
  const [fertilizerConsumers, setFertilizerConsumers] = useState<FertilizerConsumer[]>();
  const [productionBalanceItem, setProductionBalanceItem] = useState<ProductionBalanceItem[]>();
  const [effectiveNitrogenItems, setEffectiveNitrogenItems] = useState<NitrogenApplication[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { selectedStable, selectedYear, stableOptions, yearOptions } = useManureSelection({ contacts });
  const { t } = useTranslation();
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const navigate = useNavigate();

  const actions = useMemo((): PageAction[] | undefined => {
    return [
      {
        icon: <Horse />,
        text: t('manure-horse-summary', 'Manure horse summary'),
        onClick: () => navigate(`${AppRoutes.ManureHorseSummary.path}?stable=${selectedStable?.uid}&year=${selectedYear}`),
      },
    ];
  }, [navigate, selectedStable?.uid, selectedYear, t]);

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback(
    (options?: { loadManureData: true }): ApiPromises => {
      const promises = new ApiPromises();

      if (!selectedOrganizationUid) {
        return promises;
      }

      if (options?.loadManureData && selectedYear && selectedStable) {
        promises.appendList<FertilizerConsumer>(
          `manure-fertilizer-consumers-${Number(selectedYear)}-${selectedStable.uid}`,
          () =>
            ManureService.manureFertilizerConsumersList({
              contactOrganisationUid: selectedOrganizationUid,
              contactUid: selectedStable.uid,
              year: Number(selectedYear),
            }),
          setFertilizerConsumers,
          generateCacheKey(`manure-fertilizer-consumers-${Number(selectedYear)}-${selectedStable.uid}`),
        );

        promises.appendList<ProductionBalanceItem>(
          `manure-production-balance-item-${Number(selectedYear)}-${selectedStable.uid}`,
          () =>
            ManureService.manureFertilizerProducersBalanceList({
              contactOrganisationUid: selectedOrganizationUid,
              contactUid: selectedStable.uid,
              year: Number(selectedYear),
            }),
          setProductionBalanceItem,
          generateCacheKey(`manure-production-balance-item-${Number(selectedYear)}-${selectedStable.uid}`),
        );

        promises.appendList<NitrogenApplication>(
          `manure-effective-nitrogen-${Number(selectedYear)}-${selectedStable.uid}`,
          () =>
            ManureService.manureFertilizerProducersEffectiveNitrogenList({
              contactOrganisationUid: selectedOrganizationUid,
              contactUid: selectedStable.uid,
              year: Number(selectedYear),
            }),
          setEffectiveNitrogenItems,
          generateCacheKey(`manure-effective-nitrogen-${Number(selectedYear)}-${selectedStable.uid}`),
        );
      }

      if (!options) {
        promises.appendListObj<Contact>('contacts', setContacts, {
          promiseCallback: () => {
            return ContactsService.contactsList({
              organisationUid: selectedOrganizationUid,
              hidden: false,
              isUser: false,
            });
          },
        });
      }

      setApiPromises(promises);

      return promises;
    },
    [generateCacheKey, selectedOrganizationUid, selectedStable, selectedYear],
  );

  /**
   * Small helper that show a clean total with an indicator if it is negative
   */
  const calculatTotal = (available: number | undefined, usage: number | undefined) => {
    const total = Number(available) - Number(usage);
    const isPostive = total > 0;
    const isNegative = total < 0;

    // at default the negative value is shown, so we need to add a + sign if its positive
    const operator = isPostive ? '+' : '';
    return (
      <span
        className={classNames({
          'text-green-500': isPostive,
          'text-red-500': isNegative,
        })}
      >{`${operator}${isNaN(total) ? 0 : total} kg`}</span>
    );
  };

  /**
   * Sum all the budgets per category
   */
  const budgetTotals = useMemo(() => {
    if (!fertilizerConsumers || fertilizerConsumers.length === 0) {
      return undefined;
    }

    return {
      animalNitrogen: fertilizerConsumers
        .filter(consumer => consumer.fertilizer_budget_type === FertilizerBudgetTypeEnum.ANIMAL_NITROGEN)
        .reduce((acc, consumer) => acc + consumer.total, 0),
      totalNitrogen: fertilizerConsumers
        .filter(consumer => consumer.fertilizer_budget_type === FertilizerBudgetTypeEnum.TOTAL_NITROGEN)
        .reduce((acc, consumer) => acc + consumer.total, 0),
      totalPhosphate: fertilizerConsumers
        .filter(consumer => consumer.fertilizer_budget_type === FertilizerBudgetTypeEnum.TOTAL_PHOSPHATE)
        .reduce((acc, consumer) => acc + consumer.total, 0),
    };
  }, [fertilizerConsumers]);

  /**
   * Sum the balance totals
   */
  const balanceTotals = useMemo(() => {
    if (!productionBalanceItem || productionBalanceItem.length === 0) {
      return undefined;
    }

    return {
      nitrogen_weight: (productionBalanceItem ?? []).reduce(
        (total, current) => sumUpTotal(current.operator, current?.nitrogen_weight, total),
        0,
      ),
      phosphate_weight: (productionBalanceItem ?? []).reduce(
        (total, current) => sumUpTotal(current.operator, current?.phosphate_weight, total),
        0,
      ),
    };
  }, [productionBalanceItem]);

  // calculate the effect nitrogen total
  const effectiveNitrogenTotal = effectiveNitrogenItems?.reduce((acc, item) => acc + item.effective_nitrogen_weight, 0) ?? 0;

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  useEffect(() => {
    if (selectedOrganizationUid && selectedYear) {
      loadApiData({ loadManureData: true });
    }
  }, [loadApiData, selectedOrganizationUid, selectedStable, selectedYear]);

  return (
    <Page
      title={t('manure-administration-year', 'Manure administration {{year}}', { year: selectedYear })}
      maxWidth={PageMaxWidth.Default}
      actions={actions}
      loading={apiPromises}
    >
      <div className='flex gap-x-3 mb-4 px-2 md:px-0'>
        <ButtonDropdown
          selectedOption={selectedStable?.uid}
          options={stableOptions}
          showCaretDown={true}
          icon={<Barn />}
          showIconOnlyOnMobile={false}
        >
          {selectedStable ? (selectedStable.business_name ?? '') : t('stable', 'Stable')}
        </ButtonDropdown>
        <ButtonDropdown
          selectedOption={selectedStable?.uid}
          options={yearOptions}
          showCaretDown={true}
          icon={<Calendar />}
          showIconOnlyOnMobile={false}
        >
          {selectedYear ?? t('year', 'Year')}
        </ButtonDropdown>
      </div>

      <div className='space-y-5'>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-5'>
          <Tile title={t('calculate-budget', 'Calculate budget')}>
            <div className='flex flex-col h-full'>
              <p className='mb-3'>
                {t(
                  'calculate-yearly-budget-tile-desc',
                  'Here you can calculate your yearly budget for your animial nitrogen, Nitrogen usage space and Phosphate usage space.',
                )}
              </p>

              {selectedStable && selectedYear && (
                <div className='mt-auto'>
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={() =>
                      navigate(generatePath(AppRoutes.ManureBudget.path, { stableContactUid: selectedStable?.uid, year: selectedYear }))
                    }
                  >
                    {budgetTotals ? t('view-or-edit-budget', 'View/edit year budget') : t('calculate-your-budget', 'Calculate your budget')}
                  </Button>
                </div>
              )}
            </div>
          </Tile>

          <Tile title={t('calculate-balance', 'Calculate balance')}>
            <div className='flex flex-col h-full'>
              <p className='mb-3'>
                {t(
                  'calculate-yearly-balance-tile-desc',
                  'Here you can calculate your yearly usage for animal manure and determine the total nitrogen and phosphate usage.',
                )}
              </p>

              {selectedStable && selectedYear && (
                <div className='mt-auto'>
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={() =>
                      navigate(generatePath(AppRoutes.ManureBalance.path, { stableContactUid: selectedStable?.uid, year: selectedYear }))
                    }
                  >
                    {budgetTotals ? t('view-or-edit-balance', 'View/edit balance') : t('calculate-your-budget', 'Calculate your budget')}
                  </Button>
                </div>
              )}
            </div>
          </Tile>

          <Tile title={t('calculate-nitrogen-coefficient', 'Calculate nitrogen coefficient')}>
            <div className='flex flex-col h-full'>
              <p className='mb-3'>
                {t(
                  'calculate-yearly-effective-nitrogen-desc',
                  'Here you can calculate your yearly effective nitrogen coefficient for your animal manure.',
                )}
              </p>

              {selectedStable && selectedYear && (
                <div className='mt-auto'>
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={() =>
                      navigate(
                        generatePath(AppRoutes.ManureEffectiveNitrogen.path, { stableContactUid: selectedStable?.uid, year: selectedYear }),
                      )
                    }
                  >
                    {effectiveNitrogenTotal
                      ? t('view-or-edit-nitrogen-coefficient', 'View/edit nitrogen coefficient')
                      : t('calculate-your-nitrogen-coefficient', 'Calculate your nitrogen coefficient')}
                  </Button>
                </div>
              )}
            </div>
          </Tile>
        </div>

        <Tile title={t('usage-standard-for-nitrogen-from-animal-manure', 'Usage standard for nitrogen from animal manure')}>
          <table className={table}>
            <tbody className={tableTbody}>
              <tr className={tableTbodyTrNoClick}>
                <td className={tableTheadThName}>{t('usable-space', 'Usable space')}</td>
                <td align='right'>
                  {budgetTotals?.animalNitrogen ?? 0} {'kg'}
                </td>
              </tr>
              <tr className={tableTbodyTrNoClick}>
                <td>{t('amount-used', 'Amount used')}</td>
                <td align='right'>
                  {balanceTotals?.nitrogen_weight ?? 0} {'kg'}
                </td>
              </tr>
              <tr className={tableTbodyTrNoClick}>
                <td className='font-bold'>{t('total-year', 'Total {{year}}', { year: selectedYear })}</td>
                <td className='font-bold' align='right'>
                  {calculatTotal(budgetTotals?.animalNitrogen, balanceTotals?.nitrogen_weight)}
                </td>
              </tr>
            </tbody>
          </table>
        </Tile>

        <Tile title={t('usage-standard-for-nitrogen-from-manure-types', 'Usage standard for nitrogen from manure types')}>
          <table className={table}>
            <tbody className={tableTbody}>
              <tr className={tableTbodyTrNoClick}>
                <td className={tableTheadThName}>{t('usable-space', 'Usable space')}</td>
                <td align='right'>
                  {budgetTotals?.totalNitrogen ?? 0} {'kg'}
                </td>
              </tr>
              <tr className={tableTbodyTrNoClick}>
                <td>{t('amount-used', 'Amount used')}</td>
                <td align='right'>
                  {effectiveNitrogenTotal ?? 0} {'kg'}
                </td>
              </tr>
              <tr className={tableTbodyTrNoClick}>
                <td className='font-bold'>{t('total-year', 'Total {{year}}', { year: selectedYear })}</td>
                <td className='font-bold' align='right'>
                  {calculatTotal(budgetTotals?.totalNitrogen, effectiveNitrogenTotal)}
                </td>
              </tr>
            </tbody>
          </table>
        </Tile>

        <Tile title={t('usage-standard-for-phosphate-from-manure-types', 'Usage standard for phosphate from manure types')}>
          <table className={table}>
            <tbody className={tableTbody}>
              <tr className={tableTbodyTrNoClick}>
                <td className={tableTheadThName}>{t('usable-space', 'Usable space')}</td>
                <td align='right'>
                  {budgetTotals?.totalPhosphate ?? 0} {'kg'}
                </td>
              </tr>
              <tr className={tableTbodyTrNoClick}>
                <td>{t('amount-used', 'Amount used')}</td>
                <td align='right'>
                  {balanceTotals?.phosphate_weight ?? 0} {'kg'}
                </td>
              </tr>
              <tr className={tableTbodyTrNoClick}>
                <td className='font-bold'>{t('total-year', 'Total {{year}}', { year: selectedYear })}</td>
                <td className='font-bold' align='right'>
                  {calculatTotal(budgetTotals?.totalPhosphate, balanceTotals?.phosphate_weight)}
                </td>
              </tr>
            </tbody>
          </table>
        </Tile>
      </div>
    </Page>
  );
}

export default ManurePage;
