import { IconContext, Pencil, Placeholder } from '@phosphor-icons/react';
import classNames from 'classnames';
import useImageCrop from 'components/Common/ImageCrop/UseImageCrop';
import { useConfig } from 'context/ConfigContext';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import OptimizedImage, { OptionalOptimizedImageUrl } from 'ui/OptimizedImage';

interface Props {
  className?: string;
  avatarUrl?: string;
  onAvatarComplete: (imageData: ArrayBuffer, mimeType: string) => Promise<void>;
  icon: ReactNode;
  buttonTitle?: string;
  canUpdate?: boolean;
}

function AvatarTile({
  className,
  onAvatarComplete: onAvaterComplete,
  avatarUrl,
  icon,
  buttonTitle: givenButtonTitle,
  canUpdate,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { config } = useConfig();

  // set a default icon as placeholder in case there is no avatar
  const iconPlaceholder = icon ?? <Placeholder />;

  // set a default button title
  const buttonTitle = givenButtonTitle ?? t('upload-new-avatar', 'Upload new Avatar');

  // Avatar implementation
  const { imageModal, upload } = useImageCrop({
    modalTitle: t('update-avatar', 'Update avatar'),
    onCropComplete: onAvaterComplete,
  });

  return (
    <Tile className={className}>
      <div
        className={classNames('rounded-xl h-full grow', {
          'cursor-pointer': canUpdate,
        })}
        onClick={canUpdate ? upload : undefined}
        style={{ backgroundImage: `url("${OptionalOptimizedImageUrl(avatarUrl, config?.hasImageOptimizationAvailable ?? false, 128)}")` }}
      >
        <div className='backdrop-blur-md bg-white/40 rounded-xl h-full flex flex-col items-center justify-center gap-8 py-8'>
          <div className='rounded-full overflow-hidden relative bg-gray-400 flex items-center justify-center shrink-0 w-52 h-52 lg:w-[200px] lg:h-[200px] group'>
            {avatarUrl && <OptimizedImage className='w-full h-full object-cover' src={avatarUrl} width={128} />}
            {!avatarUrl && (
              <IconContext.Provider
                value={{
                  className: 'text-gray-50',
                  size: 60,
                }}
              >
                {iconPlaceholder}
              </IconContext.Provider>
            )}
            {canUpdate && (
              <div className='flex md:hidden group-hover:flex justify-center items-center group-hover:animate-slideInUp group-hover:animate-duration-200 animate-repeat-5 absolute bottom-0 left-0 p-[2px] w-full py-7 text-gray-300 bg-black/40 text-lg'>
                <Pencil weight='light' size={30} />
              </div>
            )}
          </div>
          {canUpdate && <Button>{buttonTitle}</Button>}
        </div>
      </div>

      {imageModal}
    </Tile>
  );
}

export default AvatarTile;
