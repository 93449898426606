import classNames from 'classnames';
import { FertilizerOriginEnum, NitrogenApplication } from 'openapi';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { table, tableHiddenColumnMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import useModal from 'ui/Modals/UseModal';
import { PageAction } from 'context/PageContext';
import DropdownMenu from 'ui/DropdownMenu';
import SaveEffectiveNitrogenItemModal from './SaveEffectiveNitrogenItemModal';
import { fertilizerOriginToString } from './helper';
import DeleteEffectiveNitrogenModal from './DeleteEffectiveNitrogenModal';
import { ApiPromises } from 'utilities/ApiPromises';

interface Props {
  effectiveNitrogenItems: NitrogenApplication[] | undefined;
  onSaved: () => void;
  onDeleted: () => void;
  apiPromises: ApiPromises | undefined;
}

/**
 * This component is used to calculate the effective nitrogen
 *
 * This step is part of step 6 of the RVO document
 * @see https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf
 */
function CalculateEffectiveNitrogenManureTile({ effectiveNitrogenItems, onSaved, onDeleted, apiPromises }: Props): JSX.Element {
  const [selectedEffectiveNitrogenItem, setSelectedEffectiveNitrogenItem] = useState<NitrogenApplication>();

  const { t } = useTranslation();
  const { closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible, showModal: showSaveModal } = useModal();
  const { closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible, showModal: showDeleteModal } = useModal();

  /**
   * Show, in case of is_animal_manure, the type of manure as it is showed on the RVO document
   * e.g. Dierlijke mest, type: melkkoeien
   */
  const fertilizerType = useCallback(
    (item: NitrogenApplication) => {
      if (!item.is_animal_manure) return item.fertilizer_description;

      const findFertilizer = Object.keys(FertilizerOriginEnum).find(key => key === item.fertilizer_description);
      if (findFertilizer) {
        return (
          t('animal-manure-type', 'Animal manure type') +
          ': ' +
          fertilizerOriginToString(item.fertilizer_description as FertilizerOriginEnum, t)
        );
      }

      return item.fertilizer_description;
    },
    [t],
  );

  const actions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add', 'Add'),
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: showSaveModal,
      },
    ];
  }, [showSaveModal, t]);

  // total of the effective nitrogen
  const total = effectiveNitrogenItems?.reduce((acc, item) => acc + item.effective_nitrogen_weight, 0) ?? 0;

  return (
    <Tile
      title={`6. ${t('calculate-effective-nitrogen', 'Calculate actual nitrogen use with the efficiency coefficient')}`}
      actions={actions}
      loading={apiPromises}
    >
      <table className={table}>
        <thead className={tableThead}>
          <tr>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>{t('manure-type', 'Manure type')}</td>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>
              {t('total-weight-of-active-nitrogen', 'Total weight of active nitrogen')}
            </td>
            <td className={classNames('w-10', tableTheadTd, tableHiddenColumnMd)} />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {effectiveNitrogenItems?.map(item => (
            <tr key={item.uid} className={tableTbodyTrNoClick}>
              <td className='h-20'>
                <p>{fertilizerType(item)}</p>
                <p className='text-gray-600 text-sm'>{item.application_description}</p>
              </td>
              <td>
                <p>
                  {item.effective_nitrogen_weight} {'kg'}
                </p>
                <div className='text-gray-600 text-sm'>
                  <p>
                    {t('nitrogen-weight', 'Nitrogen weight')}: {item.nitrogen_weight} {'kg'}
                  </p>
                  <p>
                    {t('coefficient-percentage', 'Coefficient percentage')}: {item.application_coefficient} {'%'}
                  </p>
                </div>
              </td>
              <td align='right'>
                <DropdownMenu
                  menuPlacement='right-end'
                  menuItems={[
                    [
                      {
                        element: t('edit', 'Edit'),
                        onClick: () => {
                          setSelectedEffectiveNitrogenItem(item);
                          showSaveModal();
                        },
                      },
                    ],
                    [
                      {
                        element: t('remove', 'Remove'),
                        className: 'text-red-600',
                        onClick: () => {
                          setSelectedEffectiveNitrogenItem(item);
                          showDeleteModal();
                        },
                      },
                    ],
                  ]}
                >
                  <button className='px-2'>
                    <DotsThreeVertical size={20} weight='bold' />
                  </button>
                </DropdownMenu>
              </td>
            </tr>
          ))}

          <tr className={tableTbodyTrNoClick}>
            <td className='font-bold'>{t('total', 'Total')}</td>
            <td className='font-bold'>
              {total} {'kg'}
            </td>
          </tr>
        </tbody>
      </table>

      <SaveEffectiveNitrogenItemModal
        isVisible={saveModalIsVisible}
        onRequestCloseModal={() => {
          setSelectedEffectiveNitrogenItem(undefined);
          closeSaveModal();
        }}
        onSaved={onSaved}
        effectiveNitrogenItem={selectedEffectiveNitrogenItem}
      />

      <DeleteEffectiveNitrogenModal
        isVisible={deleteModalIsVisible}
        onDeleted={() => {
          setSelectedEffectiveNitrogenItem(undefined);
          onDeleted();
        }}
        onRequestClose={closeDeleteModal}
        effectiveNitrogenItem={selectedEffectiveNitrogenItem}
      />
    </Tile>
  );
}

export default CalculateEffectiveNitrogenManureTile;
