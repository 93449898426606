import { PencilSimple, TrashSimple } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import { AppRoutes } from 'AppRoutes';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { Horse, MareCycleCheck, MarecyclecheckService, SexEnum } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import DescriptionList from 'ui/Layout/DescriptionList';
import Page, { navBackToThisPage, PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { ActionModal } from 'ui/Modals';
import { NavLink, generatePath } from 'react-router-dom';
import useModal from 'ui/Modals/UseModal';
import { ApiPromises } from 'utilities/ApiPromises';
import { organizationHorses } from 'utilities/ApiRequests';
import { SaveMareCycleCheck } from 'components/Breeding/SaveMareCycleCheck';
import { eggLocationEnumToString, softnessEnumToString, uterusEnumToString } from 'utilities/Breeding';
import { table, tableTbodyTrNoClick } from 'ui/Const';
import CystMap from 'components/Breeding/CystMap';

export default function MareCycleCheckDetailsPage(): JSX.Element {
  const [horses, setHorses] = useState<Horse[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [removeMareCycleCheckDialogError, setRemoveMareCycleCheckDialogError] = useState<ApiErrorParser<void> | undefined>();
  const [mareCycleCheck, setMareCycleCheck] = useState<MareCycleCheck>();

  const { t } = useTranslation();
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { formatDate } = useAccount();
  const {
    closeModal: closeUpdateMareCycleCheckModal,
    modalIsVisible: updateMareCycleCheckModalIsVisible,
    showModal: showUpdateMareCycleCheckModal,
  } = useModal();
  const { uid } = useParams();
  const {
    closeModal: closeRemoveMareCycleCheckDialog,
    modalIsVisible: updateRemoveMareCycleCheckDialog,
    showModal: showRemoveMareCycleCheckDialog,
  } = useModal();
  const navigate = useNavigate();

  const mare = useMemo(() => {
    return horses?.find(horse => horse.uid === mareCycleCheck?.mare_uid);
  }, [horses, mareCycleCheck]);

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    if (!selectedOrganizationUid || !uid) {
      return promises;
    }
    promises.appendSingle<MareCycleCheck>(
      'mare-cycle-check',
      () =>
        MarecyclecheckService.marecyclecheckRetrieve({
          mareOrganisationUid: selectedOrganizationUid,
          uid: uid,
        }),
      setMareCycleCheck,
    );
    promises.appendListObj<Horse>('horses', setHorses, organizationHorses(selectedOrganizationUid, generateCacheKey));
    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, uid, generateCacheKey]);

  const actions = useMemo(() => {
    return [
      {
        onClick: showUpdateMareCycleCheckModal,
        text: t('edit', 'Edit'),
        icon: <PencilSimple />,
      },
      {
        onClick: showRemoveMareCycleCheckDialog,
        text: t('remove', 'Remove'),
        buttonVariant: ButtonVariant.Danger,
        icon: <TrashSimple />,
      },
    ];
  }, [t, showRemoveMareCycleCheckDialog, showUpdateMareCycleCheckModal]);

  // Remove the cycle check and navigate back to the cycle check list.
  const removeMareCycleCheck = async (): Promise<void> => {
    const promise = MarecyclecheckService.marecyclecheckDestroy({
      mareOrganisationUid: selectedOrganizationUid ?? '',
      uid: uid ?? '',
    });
    try {
      await promise;
      closeRemoveMareCycleCheckDialog();
      navigate(AppRoutes.BreedingMareCycleCheckList.path);
    } catch (e) {
      setRemoveMareCycleCheckDialogError(new ApiErrorParser(e));
    }
  };

  const breadCrumbs = useMemo(
    () => [{ name: t('mare-cycle-check-list', 'Mare cycle check list'), path: AppRoutes.BreedingMareCycleCheckList.path }],
    [t],
  );

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid, uid]); //eslint-disable-line

  return (
    <Page
      title={t('mare-cycle-check', 'Mare cycle check')}
      breadCrumbs={breadCrumbs}
      maxWidth={PageMaxWidth.Tile}
      actions={actions}
      loading={apiPromises}
    >
      <div className='space-y-4'>
        <Tile title={t('general', 'General')}>
          {mareCycleCheck && (
            <DescriptionList
              list={[
                { term: t('date', 'Date'), definition: formatDate(mareCycleCheck.date ?? '') },
                {
                  term: t('mare', 'Mare'),
                  definition: (
                    <NavLink
                      className='text-blue-500'
                      to={{
                        pathname: generatePath(AppRoutes.HorsesDetails.path, { uid: mare?.uid ?? '' }),
                        search: navBackToThisPage().toString(),
                      }}
                    >
                      {mare?.name}
                    </NavLink>
                  ),
                },
                { term: t('uterus', 'Uterus'), definition: uterusEnumToString(mareCycleCheck.uterus) },
                {
                  term: t('uterus-has-fluid', 'Uterus has fluid'),
                  definition: mareCycleCheck.uterus_has_fluid === true ? t('yes', 'Yes') : t('no', 'No'),
                },
                { term: t('cervix', 'Cervix'), definition: softnessEnumToString(mareCycleCheck.cervix) },
                { term: t('note', 'Note'), definition: mareCycleCheck.extra_info },
              ]}
            />
          )}
        </Tile>
        <Tile title={t('eggs', 'Eggs')}>
          {(mareCycleCheck?.egg_set?.length ?? 0) === 0 && (
            <p className='text-gray-500 italic text-center py-4'>{t('no-eggs-located', 'No eggs were located.')}</p>
          )}
          {(mareCycleCheck?.egg_set?.length ?? 0) > 0 && (
            <table className={table}>
              <thead>
                <tr>
                  <td className='text-sm font-medium text-gray-600'>{t('egg-location', 'Location')}</td>
                  <td className='text-sm font-medium text-gray-600'>{t('egg-size', 'Size')}</td>
                  <td className='text-sm font-medium text-gray-600'>{t('egg-hardness', 'Hardness')}</td>
                </tr>
              </thead>
              <tbody>
                {mareCycleCheck?.egg_set?.map(egg => (
                  <tr className={tableTbodyTrNoClick} key={egg.uid}>
                    <td>{eggLocationEnumToString(egg.location)}</td>
                    <td>
                      {egg.size_metric} {t('cm-metric', 'cm')}
                    </td>
                    <td>{softnessEnumToString(egg.hardness)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Tile>
        <Tile title={t('cysts', 'Cysts')}>
          {(mareCycleCheck?.cyst_set?.length ?? 0) === 0 && (
            <p className='text-gray-500 italic text-center py-4'>{t('no-cysts-located', 'No cysts were located.')}</p>
          )}
          {(mareCycleCheck?.cyst_set?.length ?? 0) > 0 && <CystMap cysts={mareCycleCheck?.cyst_set ?? []} />}
        </Tile>
      </div>
      {mareCycleCheck && horses && (
        <SaveMareCycleCheck
          mares={horses.filter(horse => horse.sex === SexEnum._2)}
          existingMareCycleCheck={mareCycleCheck}
          open={updateMareCycleCheckModalIsVisible}
          onSaved={(reload: boolean) => {
            if (reload) {
              loadApiData();
            }
          }}
          onRequestClose={() => closeUpdateMareCycleCheckModal()}
        />
      )}
      <ActionModal
        open={updateRemoveMareCycleCheckDialog}
        actions={[
          {
            text: t('cancel', 'Cancel'),
            variant: ButtonVariant.Default,
            onClick: closeRemoveMareCycleCheckDialog,
          },
          {
            text: t('remove', 'Remove'),
            variant: ButtonVariant.PrimaryDanger,
            onClick: removeMareCycleCheck,
          },
        ]}
        title={t('remove-mare-cycle-check-confirm-title', 'Remove mare cycle check')}
      >
        <>
          <ErrorSection errors={removeMareCycleCheckDialogError} />
          <p>{t('remove-mare-cycle-confirm-text', 'Are you sure you want to remove this cycle check?')}</p>
        </>
      </ActionModal>
    </Page>
  );
}
