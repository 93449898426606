import { DotsThreeVertical, GridNine, IconContext, ListBullets } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { fileIcon, TileThumb } from './Thumb';
import DropdownMenu, { DropdownMenuItemArray } from 'ui/DropdownMenu';
import { tableTbodyTr } from 'ui/Const';
import { useTranslation } from 'react-i18next';

export enum View {
  Tiles,
  List,
}

export interface ListFile {
  uid: string;
  url: string;
  filename: string;
  description?: string;
  YTVim?: string;
  contentType?: string;
}

interface Props {
  files: ListFile[];
  onOpenFile: (fileUid: string) => void;
  onRename?: (fileUid: string) => void;
  onDelete?: (fileUid: string) => void;
}

function ListFiles({ files, onOpenFile, onRename, onDelete }: Props): JSX.Element {
  const [fileView, setFileView] = useState<View>(View.Tiles);

  const { t } = useTranslation();

  const menuItems = useCallback(
    (file: ListFile): DropdownMenuItemArray[] => {
      const items: DropdownMenuItemArray[] = [];

      if (onRename && file.uid !== undefined) {
        items.push([
          {
            element: t('rename', 'Rename'),
            onClick: () => onRename?.(file.uid as string),
          },
        ]);
      }

      if (onDelete && file.uid !== undefined) {
        items.push([
          {
            element: t('remove', 'Remove'),
            className: 'text-red-600',
            onClick: () => onDelete?.(file.uid as string),
          },
        ]);
      }

      return items;
    },
    [t, onRename, onDelete],
  );

  return (
    <>
      {files && files.length > 0 && (
        <div>
          <div className='flex gap-x-1 justify-start mb-2'>
            <button
              onClick={() => setFileView(View.Tiles)}
              className={classNames('p-1 rounded', {
                'bg-gray-100': fileView === View.Tiles,
              })}
            >
              <GridNine />
            </button>
            <button
              onClick={() => setFileView(View.List)}
              className={classNames('p-1 rounded', {
                'bg-gray-100': fileView === View.List,
              })}
            >
              <ListBullets />
            </button>
          </div>
          {fileView === View.Tiles && (
            <div className='flex gap-1 flex-wrap'>
              {files.map(file => {
                const menuItemsRender = menuItems(file);
                return (
                  <div
                    className='w-32 h-32 group relative cursor-pointer'
                    key={file.uid}
                    onClick={menuItemsRender.length === 0 ? () => onOpenFile(file.uid) : undefined}
                  >
                    <TileThumb name={file.filename} url={file.url} YTVim={file.YTVim} contentType={file.contentType} />

                    {menuItemsRender.length > 0 && (
                      <>
                        <div
                          className='absolute top-0 bg-gradient-to-br from-transparent from-50% md:opacity-0 md:group-hover:opacity-80 to-white w-full h-full transition-opacity'
                          onClick={() => onOpenFile(file.uid)}
                        />
                        <DropdownMenu menuPlacement={'right-end'} menuItems={menuItemsRender}>
                          <button className='pl-2 pt-2 pr-1 pb-1 absolute bottom-1 right-0 md:opacity-0 md:group-hover:opacity-100 group-hover:scale-125 transition-all'>
                            <DotsThreeVertical weight='bold' size={20} />
                          </button>
                        </DropdownMenu>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {fileView === View.List && (
            <>
              <table className='w-full'>
                <tbody>
                  {files.map(file => {
                    const menuItemsRender = menuItems(file);
                    return (
                      <tr className={tableTbodyTr} key={file.uid ?? file.url}>
                        <td onClick={() => onOpenFile(file.uid)} className='w-10'>
                          <IconContext.Provider
                            value={{
                              size: 22,
                              weight: 'light',
                            }}
                          >
                            {fileIcon(file.contentType, file.YTVim)}
                          </IconContext.Provider>
                        </td>
                        <td className='break-all' onClick={() => onOpenFile(file.uid)}>
                          {file.filename}
                        </td>
                        {menuItemsRender.length > 0 && (
                          <td>
                            <DropdownMenu menuItems={menuItems(file)}>
                              <button className='pl-2 py-2'>
                                <DotsThreeVertical weight='bold' size={22} />
                              </button>
                            </DropdownMenu>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ListFiles;
