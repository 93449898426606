import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePublicInvoice from '../api/hooks/usePublicInvoice';
import InvoiceFooterBlock from '../components/Financial/InvoiceFooterBlock';
import InvoiceInfoBlock from '../components/Financial/InvoiceInfoBlock';
import InvoiceItemsBlock from '../components/Financial/InvoiceItemsBlock';
import InvoiceReceiverBlock from '../components/Financial/InvoiceReceiverBlock';
import InvoiceSenderLogoBlock from '../components/Financial/InvoiceSenderLogoBlock';
import InvoiceSenderBlock from '../components/Financial/InvoiceSenderBlock';
// Load some printer css that is used by topdf.ts
import './PublicInvoicePrint.css';
import { contactName, InvoiceSender } from 'utilities/Contact';

export default function PublicInvoicePrint(): JSX.Element {
  const { publicAccessUuid } = useParams();
  const [showPrintLoadFinished, setShowPrintLoadFinished] = useState<boolean>(false);

  const { invoice } = usePublicInvoice(publicAccessUuid);

  const invoiceSender = useMemo((): InvoiceSender | undefined => {
    if (!invoice) {
      return undefined;
    }
    return {
      name: contactName(invoice.historic_supplier) ?? '',
      address_line1: invoice.historic_supplier.address_line1,
      address_line2: invoice.historic_supplier.address_line2,
      address_line3: invoice.historic_supplier.address_line3,
      logo: invoice.logo,
      city: invoice.historic_supplier.city,
      postcode: invoice.historic_supplier.postcode,
      phone_number: invoice.historic_supplier.phone_number,
      invoice_mail_address: invoice.historic_supplier.email,
      bank_code: invoice.historic_supplier.bank_code,
      bank_name: invoice.historic_supplier.bank_name,
      account_number: invoice.historic_supplier.account_number,
      IBAN: invoice.historic_supplier.IBAN,
      vat_number: invoice.historic_supplier.vat_number,
      state: invoice.historic_supplier.state,
      country: invoice.historic_supplier.country,
    };
  }, [invoice]);

  if (!invoice) {
    return <></>;
  }

  return (
    <div className='h-full flex flex-col gap-4'>
      <InvoiceSenderLogoBlock onLogoLoaded={() => setShowPrintLoadFinished(true)} logoUrl={invoiceSender?.logo ?? undefined} />
      <InvoiceSenderBlock invoice={invoice} organization={invoiceSender} />
      <div className='pl-8 pb-8 flex'>
        <InvoiceReceiverBlock receiver={invoice.historic_customer} />
      </div>
      <InvoiceInfoBlock invoice={invoice} />
      <InvoiceItemsBlock invoice={invoice} />
      <div className='grow flex items-end'>
        <InvoiceFooterBlock invoice={invoice} />
      </div>
      {showPrintLoadFinished && <div id='print-load-finished' />}
    </div>
  );
}
