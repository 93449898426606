import React, { useCallback, useEffect, useState } from 'react';
import { AppliedListFilter, FilterOption, ListFilterType } from '.';
import useListFilter from './useListFilter';

interface Props {
  listFilterTypes: ListFilterType[];
  update: (filters: AppliedListFilter[]) => void;
}

// A filter selector component
export default function ListFilterSelector({ listFilterTypes, update }: Props): JSX.Element {
  const { filters } = useListFilter(listFilterTypes);
  const [stagedFilter, setStagedFilter] = useState<AppliedListFilter[]>([...filters]);

  useEffect(() => {
    setStagedFilter([...filters]);
  }, [filters]);

  const addFilter = useCallback(
    (listFilterType: ListFilterType, option: FilterOption) => {
      let copy: AppliedListFilter[] = stagedFilter ? [...stagedFilter] : [];

      // if we deal with a radio type, we should remove the current options for this type
      if (listFilterType.type === 'radio') {
        copy = copy.filter(sf => sf.type.id !== listFilterType.id);
      }

      const found: AppliedListFilter | undefined = copy.find(alf => alf.type === listFilterType);
      if (found) {
        if (!found.options.find(o => o.id === option.id)) {
          found.options.push(option);
        }
      } else {
        copy.push({ type: listFilterType, options: [option] });
      }
      setStagedFilter(copy);
      update(copy);
    },
    [stagedFilter, setStagedFilter, update],
  );

  const removeFilter = useCallback(
    (listFilterType: ListFilterType, option: FilterOption) => {
      let copy: AppliedListFilter[] = stagedFilter ? [...stagedFilter] : [];
      const found: AppliedListFilter | undefined = copy.find(alf => alf.type === listFilterType);
      if (found) {
        found.options = found.options.filter(f => f.id !== option.id);
        if (found.options.length === 0) {
          copy = copy.filter(f => f.type.id !== found.type.id);
        }
      }
      setStagedFilter(copy);
      update(copy);
    },
    [stagedFilter, setStagedFilter, update],
  );

  return (
    <div className='w-full max-h-full md:max-h-96 flex flex-col select-none'>
      <div className='md:px-4 pb-4 rounded-t-lg overflow-y-scroll bg-white'>
        {listFilterTypes.map(filterType => {
          return (
            <div key={filterType.id}>
              <p className='font-medium mt-4'>{filterType.name}</p>
              <ol>
                {filterType.options.map(filterOption => {
                  return (
                    <li className={'ml-2 mt-0.5 flex items-center gap-2'} key={filterOption.id}>
                      <input
                        id={`listfilter-${filterType.id}-${filterOption.id}`}
                        type={filterType.type ?? 'checkbox'}
                        name={`listfilter-${filterType.id}`}
                        checked={stagedFilter.find(sf => sf.type === filterType && sf.options.includes(filterOption)) !== undefined}
                        onChange={event => {
                          if (event.target.checked) {
                            addFilter(filterType, filterOption);
                          } else {
                            removeFilter(filterType, filterOption);
                          }
                        }}
                      />
                      <label htmlFor={`listfilter-${filterType.id}-${filterOption.id}`}>
                        {filterOption.name}
                        {filterOption.count !== undefined && <span className='ml-2 text-neutral-400'>({filterOption.count})</span>}
                      </label>
                    </li>
                  );
                })}
              </ol>
            </div>
          );
        })}
      </div>
    </div>
  );
}
