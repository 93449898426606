import { Plus } from '@phosphor-icons/react';
import { ModulePermissionsEnum, PrivateEnum } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import HorseFiles from './HorseFiles';
import useHorseFiles from 'api/hooks/useHorseFiles';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import usePermissions from 'hooks/UsePermissions';
import { PageAction } from 'context/PageContext';

export enum FileListType {
  General, // Media and general files
  Medical, // Private medical files
}
interface Props {
  horseUid?: string;
  fileListType: FileListType;
}

export default function FilesTile({ horseUid, fileListType }: Props): JSX.Element {
  const { t } = useTranslation();
  const { width } = useScreenSize();
  const { hasPermission } = usePermissions();

  // flag that indicate that the tile is small
  const tileIsSmall = width < ScreenSize.lg && width >= ScreenSize.md;

  // Which types do we list
  const filePrivacyTypes = useMemo((): PrivateEnum[] => {
    if (fileListType === FileListType.Medical) {
      // Medical files are all private.
      return [PrivateEnum._1];
    }
    if (fileListType === FileListType.General) {
      // General files can be public and internal (previously known as hidden).
      return [PrivateEnum._3, PrivateEnum._2];
    }
    return [];
  }, [fileListType]);

  // As what type do we upload files
  const uploadFilePrivacyType = useMemo((): PrivateEnum => {
    if (fileListType === FileListType.Medical) {
      // Medical files are all private.
      return PrivateEnum._1;
    }
    if (fileListType === FileListType.General) {
      // General files can be public and internal (previously known as hidden).
      return PrivateEnum._3;
    }

    throw Error('Unknown FileListType');
  }, [fileListType]);

  const useHorseHookType = useHorseFiles({ horseUid, uploadFilePrivacy: uploadFilePrivacyType });
  const { loading, selectAndUploadFiles } = useHorseHookType;

  const tileActions = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.EDIT_HORSE_FILES)) {
      return [
        {
          onClick: selectAndUploadFiles,
          text: tileIsSmall ? t('add', 'Add') : t('add-file', 'Add file'),
          buttonVariant: ButtonVariant.Default,
          icon: <Plus />,
        },
      ];
    }
  }, [hasPermission, selectAndUploadFiles, t, tileIsSmall]);

  const title = useMemo(() => {
    if (fileListType === FileListType.Medical) {
      return t('medical-records', 'Medical documents');
    } else {
      return t('media-and-documents', 'Media & documents');
    }
  }, [fileListType, t]);

  // permission check in case of medial files
  if (fileListType === FileListType.Medical && !hasPermission(ModulePermissionsEnum.MEDICAL_FILES)) {
    return <></>;
  }

  // permission check in case of general files
  if (
    fileListType === FileListType.General &&
    !hasPermission([ModulePermissionsEnum.VIEW_HORSE_FILES, ModulePermissionsEnum.EDIT_HORSE_FILES])
  ) {
    return <></>;
  }

  return (
    <Tile title={title} loading={loading} actions={tileActions}>
      {fileListType === FileListType.Medical && (
        <p className='text-gray-500 mb-2'>
          {t(
            'medical-documents-desc',
            'Store medical records and documents. These files are only shown when you have the right permissions.',
          )}
        </p>
      )}

      {horseUid && (
        <HorseFiles
          displayTypes={filePrivacyTypes}
          uploadType={uploadFilePrivacyType}
          useHorseHookType={useHorseHookType}
          canUpload={
            fileListType === FileListType.General
              ? hasPermission(ModulePermissionsEnum.EDIT_HORSE_FILES)
              : hasPermission(ModulePermissionsEnum.MEDICAL_FILES)
          }
        />
      )}
    </Tile>
  );
}
