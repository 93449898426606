/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `LEFT` - Left
 * * `RIGHT` - Right
 */
export enum LocationEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
