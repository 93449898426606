import { FertilizerOriginEnum, ManureService, NitrogenApplication } from 'openapi';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { ButtonVariant } from 'ui/Button';
import { useParams } from 'react-router-dom';
import { schemas } from 'openapi/zod-schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import useFormError from 'api/hooks/useFormError';
import { ErrorSection } from 'ui/Error';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import { TextInput } from 'ui/Inputs';
import { useOrganization } from 'context/OrganizationContext';
import ApiErrorParser from 'api/ApiErrorParser';
import TogggleInput from 'ui/Inputs/ToggleInput';
import { z } from 'zod';
import SelectInput, { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { fertilizerOriginToString } from '../helper';

interface FormModel extends NitrogenApplication {
  animal_type: string;
}

interface Props {
  effectiveNitrogenItem?: NitrogenApplication;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSaved: () => void;
}

function SaveEffectiveNitrogenItemModal({ isVisible, onRequestCloseModal, onSaved, effectiveNitrogenItem }: Props): JSX.Element {
  const [isAnimalManure, setIsAnimalManure] = useState<boolean>(false);

  const { t } = useTranslation();
  const { year, stableContactUid } = useParams();
  const { selectedOrganizationUid } = useOrganization();

  /**
   * Define the default values
   */
  const defaultValues = useMemo(() => {
    return {
      application_coefficient: effectiveNitrogenItem?.application_coefficient ?? 0,
      application_description: effectiveNitrogenItem?.application_description ?? '',
      fertilizer_description: effectiveNitrogenItem?.fertilizer_description ?? '',
      nitrogen_weight: effectiveNitrogenItem?.nitrogen_weight ?? 0,
      is_animal_manure: effectiveNitrogenItem?.is_animal_manure ?? false,
    };
  }, [
    effectiveNitrogenItem?.application_coefficient,
    effectiveNitrogenItem?.application_description,
    effectiveNitrogenItem?.fertilizer_description,
    effectiveNitrogenItem?.is_animal_manure,
    effectiveNitrogenItem?.nitrogen_weight,
  ]);

  /**
   * Define the scheme
   */
  const schema = useMemo(() => {
    let fields: { [k in keyof NitrogenApplication]?: true } = {
      application_description: true,
      application_coefficient: true,
      nitrogen_weight: true,
      is_animal_manure: true,
    };

    if (!isAnimalManure) {
      fields = {
        ...fields,
        fertilizer_description: true,
      };
    }

    let _schema = schemas.NitrogenApplication.pick(fields).extend({
      // make sure the string is not empty
      application_description: z.string().min(1).max(128),
    });

    if (isAnimalManure) {
      _schema = _schema.extend({
        animal_type: z.string(),
      });
    } else {
      _schema = _schema.extend({
        fertilizer_description: z.string().min(1).max(128),
      });
    }

    return _schema.required();
  }, [isAnimalManure]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
    control,
    watch,
  } = useForm<FormModel>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Submit handler
   */
  const onSubmit = async (data: FormModel) => {
    if (!selectedOrganizationUid) return console.error('selectedOrganization is not defined');

    try {
      const requestBody: NitrogenApplication = {
        ...data,
        year: Number(year),
        contact_uid: String(stableContactUid),
        fertilizer_description: isAnimalManure ? data.animal_type : data.fertilizer_description,
      };

      // determine if we are creating or updating
      if (effectiveNitrogenItem) {
        const promiseUpdate = ManureService.manureFertilizerProducersEffectiveNitrogenPartialUpdate({
          contactOrganisationUid: selectedOrganizationUid,
          uid: effectiveNitrogenItem.uid,
          requestBody,
        });
        await promiseUpdate;
      } else {
        const promiseCreate = ManureService.manureFertilizerProducersEffectiveNitrogenCreate({
          contactOrganisationUid: selectedOrganizationUid,
          requestBody,
        });
        await promiseCreate;
      }

      // fire the onSaved event
      onSaved();

      // clear states
      setApiError(undefined);
      clearErrors();

      onRequestCloseModal();
    } catch (error) {
      setApiError(new ApiErrorParser<NitrogenApplication>(error));
    }
  };

  /**
   * Generate the options for the land type
   */
  const animalOptions = useMemo((): OptionItemInterface[] => {
    return [
      {
        id: FertilizerOriginEnum.MILK_PRODUCING_COWS,
        name: fertilizerOriginToString(FertilizerOriginEnum.MILK_PRODUCING_COWS, t),
      },
      {
        id: FertilizerOriginEnum.GRAZING_ANIMALS,
        name: fertilizerOriginToString(FertilizerOriginEnum.GRAZING_ANIMALS, t),
      },
      {
        id: FertilizerOriginEnum.STABLE_ANIMALS_FEED,
        name: fertilizerOriginToString(FertilizerOriginEnum.STABLE_ANIMALS_FEED, t),
      },
    ];
  }, [t]);

  /**
   * Watch the type_of_contact field and set the form value
   */
  useEffect(() => {
    const subscription = watch(({ is_animal_manure }, { name }) => {
      if (name === 'is_animal_manure') {
        setIsAnimalManure(Boolean(is_animal_manure));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Set the values when the effectiveNitrogenItem is set
   */
  useEffect(() => {
    reset(defaultValues);
    setIsAnimalManure(Boolean(effectiveNitrogenItem?.is_animal_manure));
  }, [defaultValues, effectiveNitrogenItem, reset]);

  return (
    <>
      <PageModal
        open={isVisible}
        onClosed={() => {
          reset(defaultValues);
          setApiError(undefined);
        }}
        parentElement='form'
        parentProps={{ id: 'saveEffectiveNitrogenItem', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('add-new', 'Add new')} onClose={onRequestCloseModal} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />
          <p>
            <Trans
              i18nKey='effective-nitrogen-save-modal-desc'
              defaults='You can refer to tabel 9 that is located <0>here</0> on the RVO site when adding your records to calculate the effective nitrogen</strong>.'
              components={[
                <a
                  key='link'
                  href='https://www.rvo.nl/sites/default/files/2023-12/Tabel-9-Werkzame-stikstof-landbouwgrond-2024.pdf'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='text-blue-500 underline'
                >
                  here
                </a>,
              ]}
            />
          </p>

          <div className='py-3 space-y-3'>
            <TogggleInput
              name='is_animal_manure'
              control={control}
              error={fieldError('is_animal_manure')}
              label={t('is-animal-manure', 'Is animal manure')}
            />

            {isAnimalManure && (
              <SelectInput
                label={t('animal-type', 'Animal type')}
                required={true}
                options={animalOptions}
                error={fieldError('animal_type')}
                {...register('animal_type', { setValueAs: transformEmptyToUndefined() })}
              />
            )}

            {!isAnimalManure && (
              <TextInput
                error={fieldError('fertilizer_description')}
                required={zodInputIsRequired<FormModel>(schema, 'fertilizer_description')}
                label={t('effective-nitrogen-fertilizer-type-and-origin', 'Type and origin of fertilizer')}
                hint={t(
                  'effective-nitrogen-fertilizer-type-and-origin-hint',
                  'For example "Pig manure" or "Solid manure supplied from grazing animals"',
                )}
                {...register('fertilizer_description')}
              />
            )}

            <TextInput
              error={fieldError('application_description')}
              required={zodInputIsRequired<FormModel>(schema, 'application_description')}
              label={t('effective-nitrogen-application', 'Application')}
              hint={t('effective-nitrogen-application-description-hint', 'e.g. "On a farm with grazing" or "On clay and peat"')}
              {...register('application_description')}
            />

            <TextInput
              error={fieldError('application_coefficient')}
              required={zodInputIsRequired<FormModel>(schema, 'application_coefficient')}
              label={t('effective-nitrogen-application-coefficient', 'Working coefficient')}
              hint={t('effective-nitrogen-application-coefficient-hint', 'in whole percentages. e.g. 40 or 60')}
              postText='%'
              type='number'
              {...register('application_coefficient', { valueAsNumber: true })}
            />
            <TextInput
              error={fieldError('nitrogen_weight')}
              required={zodInputIsRequired<FormModel>(schema, 'nitrogen_weight')}
              label={t('nitrogen-weight', 'Nitrogen weight')}
              postText='kg'
              type='number'
              {...register('nitrogen_weight', { valueAsNumber: true })}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'saveEffectiveNitrogenItem',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default SaveEffectiveNitrogenItemModal;
