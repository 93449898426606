import { addDays, addWeeks, areIntervalsOverlapping, endOfDay, getWeek, Interval, startOfDay, startOfWeek } from 'date-fns';
import { TFunction } from 'i18next';
import { Contact, Facility, EventsToChangeEnum, Horse, Stable, StableNames } from 'openapi';
import { dayOverlap } from 'utilities/date.utilities';
import { activityIsAssignedToContact, CalendarActivity, CalendarActivityType, GroupBy, ViewType } from 'utilities/Planning';

export interface GroupByApplied {
  groupBy: GroupBy;
  subject?: Contact | Horse | StableNames | Stable | Facility;
}

interface CalendarDay {
  day: Date;
  activities: CalendarActivity[];
  blockList: BlockListItem[];
}

/**
 * Blocked item in planning. This is used to indicate that we can't add calendar activities on these ranges.
 */
export interface BlockListItem {
  startTime: Date;
  endTime: Date;
  groupByUid?: string;
  // Is the time fully blocked or do we still have capacity available (partially blocked). This is now mainly used for facility planning.
  fullBlock: boolean;
}

export interface CalendarCluster {
  id: string;
  groupBy: GroupByApplied;
  columns: CalendarDay[];
  maxActivitiesInADay: number;
  maxActivitiesInADayPart: number[];
}

/**
 * Compare two GroupByApplied with each other.
 * It checks if the groupBy type and groupBy subject (uid) are equal.
 **/
export const equalGroupByApplied = (a?: GroupByApplied, b?: GroupByApplied): boolean => {
  if (!a && !b) {
    return true;
  }
  if ((a === undefined) !== (b === undefined)) {
    return false;
  }
  if (a?.groupBy !== b?.groupBy) {
    return false;
  }
  if (!a?.subject?.uid && !b?.subject?.uid) {
    return true;
  }
  return a?.subject === b?.subject;
};

/**
 * The data model for the planning activities. Clusters the activities by GroupByApplied (horse, staff, stable, etc) and puts the activities in the correct day.
 * This is done according to the ViewType.
 * When i.e. the `viewType` is Week, then a view of 7 days will be generated. The `page` indicates how many weeks you want to look ahead or back.
 *
 */
export default class Calendar {
  private _weekNumber = 0;
  private _year = 0;
  private _days: Date[] = [];
  private _rows: CalendarCluster[] = [];
  private _dailyNotes: CalendarActivity[];

  private _maxActivitiesInADay: number;
  private _maxActivitiesInADayPart: number[];
  private _hadActivities = false;

  constructor(
    private readonly now: Date,

    // Build the calendar for Month, Week or Day
    public readonly viewType: ViewType,

    // Offset according to the viewType. I.e. weekView, `1` is next week and `-1` is last week.
    public readonly page: number,

    // Group by horse, staff, etc
    private _groupBy: GroupByApplied[],

    // The fetched activities from usePlanning.
    private _activities: CalendarActivity[],

    // This is used to indicate that we can't add calendar activities on these ranges.
    private _blockList?: BlockListItem[],
  ) {
    this.calculateVisibleDays();
    this._rows = this.aggregate();

    const daysInterval: Interval = { start: this.days[0], end: endOfDay(this.days[this.days.length - 1]) };
    this._dailyNotes = this._activities.filter(
      activity =>
        areIntervalsOverlapping(daysInterval, { start: activity.startTime, end: activity.endTime }) &&
        activity.type !== CalendarActivityType.Activity,
    );

    const res = this.calculateMaxActivitiesInADayPart(this._rows.flatMap(row => row.columns));
    this._maxActivitiesInADay = res.maxActivitiesInADay;
    this._maxActivitiesInADayPart = res.maxActivitiesInADayPart;
  }

  public get weekNumber(): number {
    return this._weekNumber;
  }

  public get maxActivitiesInADay(): number {
    return this._maxActivitiesInADay;
  }

  public get maxActivitiesInADayPart(): number[] {
    return this._maxActivitiesInADayPart;
  }

  // Clusters are the activities grouped by horse, staff, etc.
  public get clusters(): CalendarCluster[] {
    return this._rows;
  }

  // Get all daily notes.
  public get dailyNotes(): CalendarActivity[] {
    return this._dailyNotes;
  }

  public get days(): Date[] {
    return this._days;
  }

  /**
   * An announcement is a DailyNote that is not assigned to a stable and is not executable.
   */
  public get hasAnnouncements(): boolean {
    return this._dailyNotes.find(dailyNote => !dailyNote.stableUid) !== undefined;
  }

  public hasDailyNotesForStable(stableUid: string): boolean {
    return this._dailyNotes.find(dailyNote => dailyNote.stableUid === stableUid) !== undefined;
  }

  /**
   * Returns true if we have at least one activity in this calendar.
   */
  public get hasActivities(): boolean {
    return this._hadActivities;
  }

  // Calculate which days should be visible according to the page.
  private calculateVisibleDays(): void {
    if (this.viewType === ViewType.Day) {
      const day = new Date(this.now);
      day.setDate(day.getDate() + this.page);
      this._days = [startOfDay(day)];
      this._weekNumber = getWeek(day);
      this._year = day.getFullYear();
    } else if (this.viewType === ViewType.Week) {
      const result = startOfWeek(this.now, { weekStartsOn: 1 });
      const firstDayOfWeek = addWeeks(result, this.page);
      const days: Date[] = [firstDayOfWeek];
      for (let dayNo = 1; dayNo < 7; dayNo++) {
        days.push(addDays(firstDayOfWeek, dayNo));
      }
      this._days = days;
      this._weekNumber = getWeek(firstDayOfWeek);
      this._year = firstDayOfWeek.getFullYear();

      // TODO Display IE Dec 2024 - Jan 2025
    } else if (this.viewType === ViewType.ThreeDay) {
      const result = startOfDay(this.now);
      const firstDay = addDays(result, this.page * 3);
      const days: Date[] = [firstDay];
      for (let dayNo = 1; dayNo < 3; dayNo++) {
        days.push(addDays(firstDay, dayNo));
      }
      this._days = days;
      this._weekNumber = getWeek(firstDay);
      this._year = firstDay.getFullYear();

      // TODO Display IE Dec 2024 - Jan 2025
    } else {
      throw Error('Months are not yet implemented');
    }
  }

  // For a given day, calculate the max amount of activities (per day part)
  private calculateMaxActivitiesInADayPart(calendarDays: CalendarDay[]): {
    maxActivitiesInADay: number;
    maxActivitiesInADayPart: number[];
  } {
    if (calendarDays.length === 0) {
      return { maxActivitiesInADay: 0, maxActivitiesInADayPart: [] };
    }
    const highest = Math.max(
      ...calendarDays.map(calDay => {
        if (calDay.activities.length === 0) {
          return 0;
        }
        return Math.max(...calDay.activities.map(act => act.dayPart));
      }),
    );
    const daypartCal: number[] = new Array<number>(highest + 1).fill(0);
    for (const calendarDay of calendarDays) {
      for (let i = 0; i < daypartCal.length; i++) {
        const count = calendarDay.activities.filter(act => act.dayPart === i).length;
        if (daypartCal[i] < count) {
          daypartCal[i] = count;
        }
      }
    }
    return { maxActivitiesInADay: Math.max(...calendarDays.map(calDay => calDay.activities.length)), maxActivitiesInADayPart: daypartCal };
  }

  // Build the calendar.
  private aggregate(): CalendarCluster[] {
    const rows: CalendarCluster[] = [];
    for (const groupBy of this._groupBy) {
      let calendarDays: CalendarDay[] = [];
      if (groupBy.groupBy === GroupBy.Horse) {
        if (!groupBy.subject) {
          throw Error('Subject must be set for GroupBy.Horse');
        }
        calendarDays = this._days.map(day => ({
          day,
          activities: this._activities.filter(
            activity =>
              activity.type === CalendarActivityType.Activity && dayOverlap(activity, day) && activity.horseUid === groupBy.subject?.uid,
          ),
          blockList: this._blockList?.filter(item => item.groupByUid === groupBy.subject?.uid && dayOverlap(item, day)) ?? [],
        }));
      } else if (groupBy.groupBy === GroupBy.StaffCatchAll) {
        calendarDays = this._days.map(day => ({
          day,
          activities: this._activities.filter(activity => {
            return activity.type === CalendarActivityType.Activity && activity.assignedTo.length === 0 && dayOverlap(activity, day);
          }),
          blockList: this._blockList?.filter(item => item.groupByUid === groupBy.subject?.uid && dayOverlap(item, day)) ?? [],
        }));
      } else if (groupBy.groupBy === GroupBy.Staff) {
        calendarDays = this._days.map(day => ({
          day,
          activities: this._activities.filter(activity => {
            let assigneeMatch = false;
            if (groupBy.subject) {
              assigneeMatch = activityIsAssignedToContact(activity, groupBy.subject.uid);
            } else {
              // No subject is set. This means the unassigned activities go into this cluster.
              assigneeMatch = activity.assignedTo.length === 0;
            }
            return activity.type === CalendarActivityType.Activity && dayOverlap(activity, day) && assigneeMatch;
          }),
          blockList: this._blockList?.filter(item => item.groupByUid === groupBy.subject?.uid && dayOverlap(item, day)) ?? [],
        }));
      } else if (groupBy.groupBy === GroupBy.Stable) {
        if (!groupBy.subject) {
          throw Error('Subject must be set for GroupBy.Stable');
        }
        calendarDays = this._days.map(day => ({
          day,
          activities: this._activities.filter(
            activity =>
              activity.type === CalendarActivityType.Activity && dayOverlap(activity, day) && activity.stableUid === groupBy.subject?.uid,
          ),
          blockList: this._blockList?.filter(item => item.groupByUid === groupBy.subject?.uid && dayOverlap(item, day)) ?? [],
        }));
      } else if (groupBy.groupBy === GroupBy.Facility) {
        if (!groupBy.subject) {
          throw Error('Subject must be set for GroupBy.Facility');
        }
        calendarDays = this._days.map(day => ({
          day,
          activities: this._activities.filter(
            activity =>
              activity.type === CalendarActivityType.FacilityEvent &&
              dayOverlap(activity, day) &&
              activity.facilities.find(facility => facility.uid === groupBy.subject?.uid),
          ),
          blockList: this._blockList?.filter(item => item.groupByUid === groupBy.subject?.uid && dayOverlap(item, day)) ?? [],
        }));
      } else {
        calendarDays = this._days.map(day => ({
          day,
          activities: this._activities.filter(activity => activity.type === CalendarActivityType.Activity && dayOverlap(activity, day)),
          blockList: this._blockList?.filter(item => item.groupByUid === groupBy.subject?.uid && dayOverlap(item, day)) ?? [],
        }));
      }
      if (!this._hadActivities && calendarDays.find(item => item.activities.length > 0)) {
        this._hadActivities = true;
      }
      rows.push({
        id: groupBy.subject?.uid ?? 'groupbynone',
        groupBy: groupBy,
        columns: calendarDays,
        ...this.calculateMaxActivitiesInADayPart(calendarDays),
      });
    }
    return rows;
  }
}

// 'Events to change' are used for editing or removing recurring CalendarActivities.
// You can request to update/rm all, one or future activities.
export const eventsToChangeEnumToString = (eventsToChange: EventsToChangeEnum, t: TFunction): string => {
  switch (eventsToChange) {
    case EventsToChangeEnum.ALL:
      return t('events-to-change-all', 'All activities');
    case EventsToChangeEnum.FUTURE:
      return t('events-to-change-future', 'This and future activities');
    case EventsToChangeEnum.ONE:
      return t('events-to-change-one', 'Only this activity');
  }
};
