import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../AppRoutes';
import usePermissions from 'hooks/UsePermissions';
import { useAccount } from 'context/AccountContext';
import { ModulePermissionsEnum } from 'openapi';
import React from 'react';
import { useOrganization } from 'context/OrganizationContext';
import { useTranslation } from 'react-i18next';
import { Warning } from '@phosphor-icons/react';
import { Page } from 'ui/Layout';

/**
 * The home page is a redirect page that will redirect the user to the correct page based on the permissions
 */
export default function Home(): JSX.Element {
  const navigate = useNavigate();
  const { hasPermission, loaded: permissionsLoaded } = usePermissions();
  const { session } = useAccount();
  const location = useLocation();
  const { organizations, loading } = useOrganization();
  const { t } = useTranslation();

  useEffect(() => {
    if (permissionsLoaded) {
      if (session) {
        if (
          hasPermission([ModulePermissionsEnum.VIEW_HORSES, ModulePermissionsEnum.VIEW_OWN_HORSES, ModulePermissionsEnum.MANAGE_HORSES])
        ) {
          return navigate(AppRoutes.HorsesList.path);
        } else if (hasPermission(ModulePermissionsEnum.VIEW_SCHEDULE)) {
          return navigate(AppRoutes.MyPlanning.path);
        } else {
          return navigate(AppRoutes.Profile.path);
        }
      } else {
        return navigate(`${AppRoutes.Login.path}?redirect=${location.pathname}${location.search}`);
      }
    }
  }, [hasPermission, location.pathname, location.search, navigate, permissionsLoaded, session]);

  if (organizations.length === 0 && !loading) {
    return (
      <Page title='Organization'>
        <div className='mt-32 flex flex-col justify-center items-center gap-2'>
          <Warning size={50} />
          <h1 className='text-2xl'>{t('no-organization-message-header', 'Not part of an organization')}</h1>
          <p className='max-w-96 text-center'>
            {t(
              'no-organization-message',
              'You are not part of any organization. Please contact your administration to become part of an organization.',
            )}
          </p>
        </div>
      </Page>
    );
  }

  return <></>;
}
