import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { Stable, StableContact, StablesService } from 'openapi';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { ButtonVariant } from 'ui/Button';
import { SelectInput, TextInput } from 'ui/Inputs';
import { schemas } from 'openapi/zod-schemas';
import useFormError from 'api/hooks/useFormError';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import Fieldset from 'ui/Fieldset';
import { z } from 'zod';
import UseCountries from 'hooks/UseCountries';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSaved?: (stable: Stable) => void;
}

function CreateStableModal({ isVisible, onRequestCloseModal, onSaved }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();
  const { countries } = UseCountries();

  // Form validation
  const schema = useMemo(() => {
    return schemas.StableContact.pick({
      business_name: true,
      phone_number: true,
      address_line1: true,
      address_line3: true,
      address_line2: true,
      postcode: true,
      city: true,
      state: true,
      country: true,
      UBN: true,
    }).extend({
      // We cannot add an empty string to the .country() schema therefore we need to implement a custom string validation
      country: z.string(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
  } = useForm<StableContact>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close modal action
   */
  const close = () => {
    onRequestCloseModal();
    setApiError(undefined);
    clearErrors();
    reset();
  };

  /**
   * Submit handler
   */
  const onSubmit = async (data: StableContact) => {
    if (!selectedOrganization) return console.error('selectedOrganization is not defined');
    try {
      const promiseCreate = StablesService.stablesCreate({
        organisationUid: selectedOrganization.uid,
        requestBody: {
          // TODO uid, created_by_uid, location_uid are not required and should be removed
          // see https://gitlab.qubis.nl/equinem/equinemcore/-/issues/349
          uid: '',
          created_by_uid: '',
          location_uid: '',
          location: data,
        },
      });
      const savedStable = await promiseCreate;

      close();
      onSaved?.(savedStable);
    } catch (error) {
      setApiError(new ApiErrorParser<StableContact>(error));
    }
  };

  return (
    <>
      <PageModal
        open={isVisible}
        parentElement='form'
        parentProps={{ id: 'save-stable-form', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('new-stable', 'New stable')} onClose={close} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />

          <div className='py-3 space-y-3'>
            <TextInput
              error={fieldError('business_name')}
              required={zodInputIsRequired<StableContact>(schema, 'business_name')}
              label={t('name', 'Name')}
              {...register('business_name', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('UBN')}
              required={zodInputIsRequired<StableContact>(schema, 'UBN')}
              label={t('UBN', 'UBN')}
              {...register('UBN', { setValueAs: transformEmptyToUndefined() })}
            />
            <TextInput
              error={fieldError('phone_number')}
              required={zodInputIsRequired<StableContact>(schema, 'phone_number')}
              type='tel'
              label={t('phone_number', 'Phone number')}
              {...register('phone_number', { setValueAs: transformEmptyToUndefined() })}
            />

            <Fieldset legend={t('address', 'Address')} className='grid gap-3 grid-cols-1 md:grid-cols-2 mb-4'>
              <TextInput
                error={fieldError('address_line1')}
                required={zodInputIsRequired<StableContact>(schema, 'address_line1')}
                label={t('street', 'Street')}
                {...register('address_line1', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('address_line3')}
                required={zodInputIsRequired<StableContact>(schema, 'address_line3')}
                label={t('house-number', 'House number')}
                {...register('address_line3', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('address_line2')}
                required={zodInputIsRequired<StableContact>(schema, 'address_line2')}
                label={t('address-line2', 'Address line 2')}
                {...register('address_line2', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('postcode')}
                required={zodInputIsRequired<StableContact>(schema, 'postcode')}
                label={t('postcode', 'Postcode')}
                {...register('postcode', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('city')}
                required={zodInputIsRequired<StableContact>(schema, 'city')}
                label={t('city', 'City')}
                {...register('city', { setValueAs: transformEmptyToUndefined() })}
              />
              <TextInput
                error={fieldError('state')}
                required={zodInputIsRequired<StableContact>(schema, 'state')}
                label={t('stateOrCounty', 'State or province')}
                {...register('state', { setValueAs: transformEmptyToUndefined() })}
              />
              <SelectInput
                error={fieldError('country')}
                required={zodInputIsRequired<StableContact>(schema, 'country')}
                options={countries}
                nullable={true}
                nullableValue=''
                label={t('country', 'Country')}
                {...register('country', { setValueAs: transformEmptyToUndefined() })}
              />
            </Fieldset>
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'save-stable-form',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default CreateStableModal;
