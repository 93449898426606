import ApiErrorParser from 'api/ApiErrorParser';
import { HorseMedia } from 'openapi';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  file: HorseMedia | undefined;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onDeleted: () => void;
  deleteFile: (file: HorseMedia) => Promise<void>;
}

function DeleteFileModal({ file, isVisible, onRequestCloseModal, onDeleted, deleteFile }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<void> | undefined>();

  const { t } = useTranslation();

  const onDeleteFile = useCallback(async () => {
    if (file) {
      const promise = deleteFile(file);
      promise
        .then(() => {
          onDeleted();
          onRequestCloseModal();
          setApiError(undefined);
        })
        .catch(e => {
          setApiError(new ApiErrorParser<void>(e));
        });
      return promise;
    }
  }, [deleteFile, file, onDeleted, onRequestCloseModal]);

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestCloseModal}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: () => {
            onRequestCloseModal();
            setApiError(undefined);
          },
        },
        {
          text: t('delete', 'Delete'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: onDeleteFile,
        },
      ]}
      title={t('delete-file', 'Delete file')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>
        {t('delete-file-desc', 'Are you sure you want to delete "{{filename}}"?', {
          filename: file?.filename ?? 'file',
        })}
      </p>
    </ActionModal>
  );
}

export default DeleteFileModal;
