/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `LARGE EDEMA` - Edema Large
 * * `MEDIUM EDEMA` - Edema Medium
 * * `SMALL EDEMA` - Edema Small
 * * `NO EDEMA` - Nothing
 */
export enum UterusEnum {
  LARGE_EDEMA = 'LARGE EDEMA',
  MEDIUM_EDEMA = 'MEDIUM EDEMA',
  SMALL_EDEMA = 'SMALL EDEMA',
  NO_EDEMA = 'NO EDEMA',
}
