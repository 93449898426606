import { Barn, House } from '@phosphor-icons/react';
import { CancelablePromise, Contact, ContactsService, PaginatedContactList } from 'openapi';
import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AvatarIcon, AvatarInitials, AvatarSize } from 'ui/Avatar';
import SelectList, { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import useModal from 'ui/Modals/UseModal';
import { ContactType, contactInitials, contactName, getContactType, textFilter } from 'utilities/Contact';
import SaveContactModal from './SaveContactModal';
import { useOrganization } from 'context/OrganizationContext';
import ApiErrorParser from 'api/ApiErrorParser';
import { cachedPaginatedApiData } from 'api/ApiCache';
import classNames from 'classnames';
import { FloatProps } from '@headlessui-float/react';

/**
 * Complex type that extend some magic types from React Form hooks and include own fields
 *
 * See --> https://github.com/orgs/react-hook-form/discussions/7851#discussioncomment-2219298
 */
interface Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> {
  name: TName; // override from UseControllerProps and make it required
  control: Control<TFieldValues>; // override from UseControllerProps and make it required
  label?: string;
  hint?: string;
  error?: string;
  required?: boolean;
  placement?: FloatProps['placement'];
  className?: string;
  // if we pass null, we tigger the component to load the contacts self
  contacts: WrappedComboboxProps<Contact>[] | Contact[] | null;
  onCreated?: (contact: Contact) => void;
  // if we pass a setValueAs function, we can transform the value that will be set to the control.setValue
  // just like the setValueAs from the ReactFromHook setValueAs
  setValueAs?: (value: string | null | undefined) => string | null | undefined;
  loading?: boolean;
  disabled?: boolean;
  // flag that will remove the deleted contacts from the list
  removeDeletedContacts?: boolean;
}

/**
 * Contact Select field create a filtered select input. It is also possible to create a new contact from this context
 */
function ContactInputSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hint,
  error,
  required,
  placement,
  className,
  contacts: givenContacts,
  name,
  control,
  onCreated,
  setValueAs,
  loading,
  disabled,
  removeDeletedContacts = true,
}: Props<TFieldValues, TName>): JSX.Element {
  const [defaultName, setDefaultName] = useState<string>();
  const [contacts, setContacts] = useState<WrappedComboboxProps<Contact>[]>();
  const [rawContacts, setRawContacts] = useState<Contact[]>();
  const [apiError, setApiError] = useState<ApiErrorParser<Contact> | undefined>();

  const { selectedOrganization, generateCacheKey } = useOrganization();

  const { t } = useTranslation();
  const { closeModal: closeContactModal, modalIsVisible: contactModalIsVisible, showModal: showContactModal } = useModal();

  /**
   * Small wrapper arround the contactName helper
   */
  const nameContact = useCallback(
    (contact?: Contact): string => {
      if (!contact) {
        return '';
      }
      return contactName(contact) ?? `<${t('nameless-contact', 'Nameless contact')}>`;
    },
    [t],
  );

  /**
   * Create the onCreate function if we passed an onCreated function
   * If we omit the onCreated function, we just return an undefined function
   * this will trigger the SelectList to omit the create option
   */
  const onCreate = onCreated
    ? (query: string) => {
        setDefaultName(query);
        showContactModal();
      }
    : undefined;

  /**
   * Load the contacts from api and/or cache
   */
  const loadContacts = useCallback(
    (organisationUid: string): CancelablePromise<PaginatedContactList> => {
      const promise = ContactsService.contactsList({
        organisationUid,
        hidden: false,
      });
      promise.catch(e => {
        if (!promise.isCancelled) {
          setApiError(new ApiErrorParser<PaginatedContactList>(e));
        }
      });

      cachedPaginatedApiData<Contact>(generateCacheKey('active-contacts'), promise, setRawContacts);
      return promise;
    },
    [generateCacheKey, setApiError],
  );

  /**
   * Update the contact state if changed from the paren
   */
  useEffect(() => {
    if (givenContacts && givenContacts.length > 0 && 'items' in givenContacts[0]) {
      const items = (givenContacts as WrappedComboboxProps<Contact>[]).map(group => {
        return {
          heading: group.heading,
          items: removeDeletedContacts ? group.items.filter(contact => !contact.hidden) : group.items,
        };
      });
      setContacts(items);
    } else if (givenContacts) {
      const items = removeDeletedContacts ? (givenContacts as Contact[]).filter(contact => !contact.hidden) : (givenContacts as Contact[]);
      setContacts([{ items }]);
    }
  }, [givenContacts, removeDeletedContacts]);

  /**
   * When the raw contacts are loaded, update the contacts state as well
   */
  useEffect(() => {
    if (rawContacts) {
      const items = removeDeletedContacts ? rawContacts.filter(contact => !contact.hidden) : rawContacts;
      setContacts([{ items }]);
    }
  }, [rawContacts, removeDeletedContacts]);

  /**
   * Inital load the contacts but only when the given contacts is null
   */
  useEffect(() => {
    if (!selectedOrganization || givenContacts !== null) return;
    const promise = loadContacts(selectedOrganization.uid);
    return () => promise.cancel();
  }, [givenContacts, selectedOrganization]); //eslint-disable-line

  return (
    <>
      <SelectList<Contact, TFieldValues, TName>
        disabled={disabled}
        className={className}
        placement={placement}
        required={required}
        hint={hint}
        error={error}
        label={label}
        options={contacts ?? []}
        control={control}
        loading={loading || contacts === undefined}
        errorMessage={apiError && apiError.nonFieldErrorsStrings().join(', ')}
        name={name}
        idField='uid'
        onFilter={(query, items) => textFilter(items ?? [], query)}
        onCreate={onCreate}
        notFoundLabel={t('contact-not-found', 'Contact not found')}
        createLabel={t('create-new-contact', 'Create new contact')}
        displayInput={value => (value ? nameContact(value) : '')}
        displayOption={(contact, selected, _, customIcon) => (
          <>
            {getContactType(contact) === ContactType.Stable ? (
              <AvatarIcon size={AvatarSize.XSmall} icon={<Barn />} uuid={contact.uid} />
            ) : (
              <>
                {!customIcon && getContactType(contact) === ContactType.Business && (
                  <AvatarIcon size={AvatarSize.XSmall} icon={<House />} uuid={contact.uid} />
                )}
                {!customIcon && [ContactType.User, ContactType.Contact].includes(getContactType(contact)) && (
                  <AvatarInitials size={AvatarSize.XSmall} initials={contactInitials(contact)} uuid={contact.uid} />
                )}
                {customIcon && <AvatarIcon size={AvatarSize.XSmall} icon={customIcon} uuid={contact.uid} />}
              </>
            )}

            <span
              className={classNames('block truncate', {
                'font-medium': selected,
                'font-normal': !selected,
              })}
            >
              {nameContact(contact)}
            </span>
          </>
        )}
        setValueAs={setValueAs}
      />

      <SaveContactModal
        isVisible={contactModalIsVisible}
        onRequestCloseModal={closeContactModal}
        onSaved={newContact => {
          setContacts(prevState => {
            if (!prevState) {
              return [
                {
                  items: [newContact],
                },
              ];
            }

            // loop over the items, and make sure we create a copy of the items and add the newly contact at the last group
            const updatedList: WrappedComboboxProps<Contact>[] = [];
            prevState.forEach((group, index) => {
              const items = [...group.items];

              if (index === prevState.length - 1) {
                items.push(newContact);
              }

              updatedList.push({
                heading: group.heading,
                items: items,
              });
            });

            return updatedList;
          });
          onCreated?.(newContact);
        }}
        defaultName={defaultName}
        includeAddressFields={true}
      />
    </>
  );
}

export default ContactInputSelect;
