import { useConfig } from 'context/ConfigContext';
import React from 'react';

interface Props {
  src: string; // Image from one of our amazon s3 buckets
  width: 32 | 64 | 128 | 256 | 512 | 1024 | 1200; // You can add widths in vercel.json
  quality?: 25 | 50 | 75 | 100; // range 1 to 100.
  className?: string;
  onLoaded?: () => void;
  onClick?: () => void;
}

export const OptimizedImageUrl = (src: string | undefined, width: Props['width'], quality: Props['quality'] = 100): string => {
  if (!src) {
    return '';
  }
  return `/_vercel/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality}`;
};

// When hosted on vercel we can optimize, otherwise not.
export const OptionalOptimizedImageUrl = (
  src: string | undefined,
  optimize: boolean,
  width: Props['width'],
  quality: Props['quality'] = 100,
): string => {
  if (optimize) {
    return OptimizedImageUrl(src, width, quality);
  } else {
    return src ?? '';
  }
};

/**
 * Vercel helps us creating fast and cached thumbs of our images. This is
 * configured in the image section of `vercel.json`.
 * Docs: https://vercel.com/docs/image-optimization
 */
export default function OptimizedImage({ src, width, quality = 100, className, onLoaded, onClick }: Props): JSX.Element {
  const { config } = useConfig();
  return (
    <img
      onClick={onClick}
      onLoad={() => {
        if (onLoaded) {
          onLoaded();
        }
      }}
      className={className}
      width={width}
      src={OptionalOptimizedImageUrl(src, config?.hasImageOptimizationAvailable ?? false, width, quality)}
    />
  );
}
