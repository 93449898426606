import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { PageAction } from 'context/PageContext';
import useHorseDetail from 'hooks/UseHorseDetail';
import usePermissions from 'hooks/UsePermissions';
import { HorseDetail, HorsesService, ModulePermissionsEnum, PatchedHorseDetail } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { DateInput, TextInput } from 'ui/Inputs';
import { Tile } from 'ui/Layout/Tile';
import TileDescriptionList from 'ui/Layout/Tile/TileDescriptionList';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { objectDiff } from 'utilities/compareObject';
import { stringArrayToString } from 'utilities/string.utility';
import { zodInputIsRequired } from 'utilities/zod';

function IdentificationHorseFrom(): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { horse, setHorse } = useHorseDetail();
  const { t } = useTranslation();
  const { showModal, closeModal, modalIsVisible } = useModal();
  const { selectedOrganization } = useOrganization();
  const { formatDate } = useAccount();
  const { hasPermission } = usePermissions();

  // Form validation
  const schema = useMemo(() => {
    return schemas.HorseDetail.pick({
      chip_nr: true,
      local_federation_number: true,
      UELN: true,
      FEI_pass_nr: true,
      FEI_expiry_date: true,
    });
  }, []);

  // Construct the default values
  const defaultValues = useMemo((): Partial<HorseDetail> => {
    return {
      chip_nr: horse?.chip_nr,
      local_federation_number: horse?.local_federation_number,
      UELN: horse?.UELN,
      FEI_pass_nr: horse?.FEI_pass_nr,
      FEI_expiry_date: horse?.FEI_expiry_date,
    };
  }, [horse]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
  } = useForm<HorseDetail>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    closeModal();
    if (resetForm) {
      reset(defaultValues);
    }
  };

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorseDetail> = async (data: HorseDetail) => {
    if (!horse || !selectedOrganization) return;
    setSubmitting(true);
    try {
      // generate a diff of the given form data and the current horse object
      // only the changed values are pushed to the server
      const updateRequest = objectDiff<PatchedHorseDetail>(horse, data);
      const updateHorsePromise = HorsesService.horsesPartialUpdate({
        organisationUid: selectedOrganization.uid,
        uid: horse.uid,
        requestBody: updateRequest,
      });
      const updatedHorse = await updateHorsePromise;

      // Update the horse
      setHorse(updatedHorse);

      // close the modal
      closeModal();
    } catch (error) {
      setApiError(new ApiErrorParser<HorseDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      return [
        {
          onClick: showModal,
          text: t('edit', 'Edit'),
          buttonVariant: ButtonVariant.Default,
          icon: <Pencil />,
        },
      ];
    }
  }, [hasPermission, showModal, t]);

  /**
   * Update the form when the horse has been updated (triggered mostly by an update of the form)
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Tile title={t('identification', 'Identification')} loading={horse === undefined} actions={tileActions}>
      {horse && (
        <TileDescriptionList
          list={[
            { term: t('chip-nr', 'Chip number'), definition: horse.chip_nr || '-' },
            { term: t('UELN', 'UELN'), definition: horse.UELN || '-' },
            { term: t('federation-nr', 'Federation number'), definition: horse.local_federation_number || '-' },
            {
              term: t('FEI', 'FEI'),
              definition: stringArrayToString([horse.FEI_pass_nr, horse.FEI_expiry_date ? formatDate(horse.FEI_expiry_date) : undefined]),
            },
          ]}
        />
      )}

      <PageModal
        open={modalIsVisible}
        parentElement='form'
        parentProps={{ id: 'updateHorse', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        onClosed={onClosed}
      >
        <PageModalTitle title={t('update-identification', 'Update identification')} onClose={onClose} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />
          <div className='py-3 space-y-4'>
            <TextInput
              error={fieldError('chip_nr')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'chip_nr')}
              label={t('chip-nr', 'Chip number')}
              {...register('chip_nr')}
            />
            <TextInput
              error={fieldError('UELN')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'UELN')}
              label={t('UELN', 'UELN')}
              hint={t('UELN_abbr', 'Universal Equine Life Number')}
              {...register('UELN')}
            />
            <TextInput
              error={fieldError('local_federation_number')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'local_federation_number')}
              label={t('federation-nr', 'Federation number')}
              {...register('local_federation_number')}
            />
            <TextInput
              error={fieldError('FEI_pass_nr')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'FEI_pass_nr')}
              label={t('fei-pass-nr', 'FEI Pass number')}
              {...register('FEI_pass_nr')}
            />

            <DateInput
              control={control}
              error={fieldError('FEI_expiry_date')}
              required={zodInputIsRequired<HorseDetail>(schemas.HorseDetail, 'FEI_expiry_date')}
              label={t('fei-expiry-date', 'FEI expiry date')}
              name='FEI_expiry_date'
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: submitting,
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateHorse',
              loading: submitting,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </Tile>
  );
}

export default IdentificationHorseFrom;
