import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { ActivityType, FacilitiesService, Facility } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
  facility: Facility | undefined;
}

export default function DeleteFacilityModal({ isVisible, onRequestClose, facility, onDeleted }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<ActivityType> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const removeActivityType = async () => {
    if (!selectedOrganization || !facility) return;

    try {
      const promise = FacilitiesService.facilitiesDestroy({
        organisationUid: selectedOrganization.uid,
        uid: facility.uid,
      });
      await promise;

      // close the modal
      onRequestClose();

      // fire the onDeleted callback
      onDeleted();
    } catch (error) {
      setApiError(new ApiErrorParser<ActivityType>(error));
    }
  };

  const closeModal = () => {
    setApiError(undefined);
    onRequestClose();
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={closeModal}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: closeModal,
        },
        {
          text: t('delete', 'Delete'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: removeActivityType,
        },
      ]}
      title={t('delete-facility', 'Delete facility')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>{t('delete-facility-desc', 'Are you sure you want to remove this facility?')}</p>
    </ActionModal>
  );
}
