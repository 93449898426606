import classNames from 'classnames';
import { usePage } from 'context/PageContext';
import React from 'react';
import { NavLink } from 'react-router-dom';
import FooterButton from './FooterButton';
import FooterActionButton from './FooterActionButton';
import { MenuItemConfig } from '../Layout';
import { Theme } from '../Theme';

interface Props {
  menu: MenuItemConfig[];
  theme: Theme;
  cutoutStyle: boolean; // True to display action buttons as a sort of cut out of the footer menu.
}

/**
 * Footer navigation for mobile only
 */
export default function Footer({ menu, cutoutStyle }: Props): JSX.Element {
  const { actions, breadCrumbs } = usePage();

  const split = menu.length % 2 === 0 && actions.length > 0 && breadCrumbs.length === 0;
  const menuItemsLeft = split ? menu.slice(0, menu.length / 2) : menu;
  const menuItemsRight = split ? menu.slice(menu.length / 2) : [];

  // get the primary button that can be used in the plus icon
  // TODO We should support multiple mobileAction that we can show in a dropdownMenu that
  // will be slideIn from the bottom (IOS style)
  const mobileAction = actions.find(action => action.isMobileAddAction);

  if (menu && menu.length === 0) {
    return <></>;
  }

  return (
    <div className='md:hidden print:hidden fixed z-10 inset-x-0 bottom-0 pb-safe bg-neutral-100'>
      <div className='h-16 w-full flex select-none items-stretch justify-stretch px-4'>
        {menuItemsLeft.map((config, key) => (
          <NavLink className='pt-3 grow overflow-hidden px-1' key={key} to={config.path}>
            <FooterButton path={config.path} icon={config.icon} name={config.name} />
          </NavLink>
        ))}
        <div className={classNames('transition-transform', { 'scale-50': !mobileAction, 'scale-100': mobileAction })}>
          {mobileAction && <FooterActionButton key={mobileAction.text} action={mobileAction} cutoutStyle={cutoutStyle} />}
        </div>
        {menuItemsRight.map((config, key) => (
          <NavLink className='pt-3 grow overflow-hidden px-1' key={key} to={config.path}>
            <FooterButton path={config.path} icon={config.icon} name={config.name} />
          </NavLink>
        ))}
      </div>
    </div>
  );
}
