import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  childName?: string;
  dam?: string | ReactNode;
  sire?: string | ReactNode;
  // add custom classNames for the elements/entries (child, sire, dam)
  entryClassName?: string;
  placeholder?: string;
}

/**
 * An Entry means a child (horse name), Sire name (or PedigreeEntry) or Dam name (or PedigreeEntry)
 *
 * It is basically a set of HTML nodes that construct block (from left to right). If nested, it create a tree.
 */
function PedigreeEntry({ childName, dam, sire, entryClassName, placeholder }: Props): JSX.Element {
  const widthClassName = 'max-w-[200px]';
  const { t } = useTranslation();

  return (
    <div className='flex items-center'>
      <div
        className={classNames('flex flex-col relative pr-10 pl-8 py-5', widthClassName, {
          'after:content-[""] after:absolute after:right-0 after:top-[50%] after:h-[2px] after:w-[20px] after:border-b':
            dam !== undefined || sire !== undefined,
        })}
      >
        <span
          className={classNames(entryClassName, {
            'italic font-thin': !childName,
          })}
        >
          {childName || `<${placeholder ?? 'no set'}>`}
        </span>
      </div>

      {(dam !== undefined || sire !== undefined) && (
        <div
          className={classNames(
            'flex flex-col relative',
            'before:content-[""] before:h-[50%] before:top-[25%] before:absolute before:mt-[2px] before:border-l',
            'after:content-[""] after:h-[50%] after:w-[20px] after:border-t after:border-b after:absolute after:top-[25%]',
          )}
        >
          <div className='flex'>
            {typeof sire === 'string' && (
              <div className={classNames('flex flex-col pl-8 py-5', widthClassName)}>
                <span
                  className={classNames(entryClassName, {
                    'italic font-thin': !sire,
                  })}
                >
                  {sire || `<${t('no-sire', 'no sire')}>`}
                </span>
              </div>
            )}
            {typeof sire !== 'string' && <>{sire}</>}
          </div>

          <div className='flex'>
            {typeof dam === 'string' && (
              <div className={classNames('flex flex-col pl-8 py-5', widthClassName)}>
                <span
                  className={classNames(entryClassName, {
                    'italic font-thin': !dam,
                  })}
                >
                  {dam || `<${t('no-dam', 'no dam')}>`}
                </span>
              </div>
            )}
            {typeof dam !== 'string' && <>{dam}</>}
          </div>
        </div>
      )}
    </div>
  );
}

export default PedigreeEntry;
