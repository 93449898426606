import { ArrowLeft, Password } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import classNames from 'classnames';
import { useConfig } from 'context/ConfigContext';
import { AccountService, PublicOrganisation, PublicService, SSO, TokenObtainPair } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorSection } from 'ui/Error';
import { AppRoutes, generateWebshopPath, WebshopRoutes } from '../AppRoutes';
import SplashWrapper, { WrapperStyle } from '../components/Common/Splash/Wrapper';
import { useAccount } from '../context/AccountContext';
import Button, { ButtonInternal } from '../ui/Button';
import { ButtonSize, ButtonVariant } from '../ui/Button';
import { TextInput } from '../ui/Inputs';
import useRedirectToHomeWhenLoggedIn from 'hooks/UseRedirectToHomeWhenLoggedIn';

export enum LoginType {
  Webshop,
  Equinem,
  // PaReg, <-- We can add this later.
}
interface Props {
  loginType: LoginType;
}

// This page has four states. The state where you enter your username (email)
// and the state where you enter your password. This is used so we can check if
// an SSO login is available.
enum PageState {
  Username, // Enter your username
  Password, // Enter your password
  PasswordOrSSO, // Choose to enter your password or login with sso
  UsernamePassword, // Enter your username and password
}

interface Credentials {
  username: string;
  password: string;
}

export default function Login({ loginType }: Props): JSX.Element {
  const [loginError, setLoginError] = useState<string>();
  const { login } = useAccount();
  const [username, setUsername] = useState<string>('');
  const [pageState, setPageState] = useState<PageState>(loginType === LoginType.Webshop ? PageState.UsernamePassword : PageState.Username);
  const [publicOrganization, setPublicOrganization] = useState<PublicOrganisation | undefined>();
  const [loginProviders, setLoginProviders] = useState<string[]>([]);

  // Redirect to home if the user is already logged in.
  useRedirectToHomeWhenLoggedIn(loginType);

  const { publicAccessUuid } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { config } = useConfig();

  const {
    handleSubmit,
    register,
    setValue,
    setFocus,
    formState: { isSubmitting },
  } = useForm<Credentials>({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!publicAccessUuid || loginType !== LoginType.Webshop) {
      return;
    }
    const promise = PublicService.apiV5PublicOrganisationsRetrieve({
      publicAccessUuid: publicAccessUuid,
    });
    promise.then(setPublicOrganization).catch(() => {
      // No problem if we can't fetch the public org.
    });
    return () => promise.cancel();
  }, [publicAccessUuid, loginType]); //eslint-disable-line

  const title = useMemo(() => {
    if (loginType === LoginType.Webshop) {
      if (publicOrganization) {
        return `${t('login-webshop-addressed', 'Sign in for')} ${publicOrganization.name}`;
      } else {
        return t('login-webshop', 'Sign in');
      }
    }
    if (pageState === PageState.Password) {
      return t('login-enter-password', 'Enter your password');
    }
    return t('login.signin', 'Sign in to your account');
  }, [publicOrganization, loginType, t, pageState]);

  // Redirect to the microsoft sso login page.
  const redirectToMicrosoftLogin = useCallback(
    (prefferedUsername: string) => {
      if (!config) {
        return;
      }
      let url: URL | undefined = undefined;
      try {
        url = config.microsoftOidcUrl(AppRoutes.MicrosoftOidcLoginResponse.path, prefferedUsername);
      } catch (e) {
        // Failed to generate the url
        console.error(e);
        return;
      }
      window.location.href = url.toString();
    },
    [config],
  );

  // Handler for login button click.
  const onSubmit = async (data: Credentials) => {
    setUsername(data.username);
    if (!data.username) {
      setLoginError(t('username-empty-error', 'Email is required'));
      return;
    }
    if (pageState === PageState.Username) {
      let loginProviderStrings: string[] = [];

      try {
        const loginType = await AccountService.apiV5CheckSsoCreate({ requestBody: { email: data.username } });
        loginProviderStrings = (loginType.providers ?? '').split(',');
        setLoginProviders(loginProviderStrings);
        // reset the error message, if we get here we know the email is valid
        setLoginError(undefined);
      } catch (e) {
        const error = new ApiErrorParser<SSO>(e);
        setLoginError(error.nonFieldErrorsStrings().join(', '));
        return;
      }

      if (!loginProviderStrings.includes('email') && loginProviders.includes('microsoft')) {
        redirectToMicrosoftLogin(data.username);
      } else if (loginProviderStrings.includes('email') && loginProviderStrings.length > 1) {
        setPageState(PageState.PasswordOrSSO);
      } else {
        setPageState(PageState.Password);
      }
      return;
    }

    if (!data.password) {
      setLoginError(t('password-empty-error', 'Password is required'));
      return;
    }

    try {
      await login(data.username, data.password, true);
      if (loginType === LoginType.Equinem) {
        // in the user has a return url in the hash
        // we should redirect to that url instead
        const returnPath = location.hash.replace('#', '');
        navigate(returnPath === '' ? AppRoutes.Home.path : returnPath);
      } else {
        navigate(generateWebshopPath(WebshopRoutes.OrderList.path, publicAccessUuid ?? ''));
      }
    } catch (e) {
      const error = new ApiErrorParser<TokenObtainPair>(e);
      setLoginError(error.nonFieldErrorsStrings().join(', '));
    }
  };

  // Set focus to the password field when going into the 'password page state'.
  useEffect(() => {
    if (pageState === PageState.Password) {
      setTimeout(() => {
        // Delayed the focus. Otherwise the focus will not work because it's
        // still being rerendered.
        setFocus('password');
      }, 200);
    }
  }, [pageState, setFocus]);

  return (
    <SplashWrapper title={title} styleType={loginType === LoginType.Equinem ? WrapperStyle.Equinem : WrapperStyle.Anonymous}>
      <form className='flex flex-col gap-8 mt-8' id='login' noValidate={true} onSubmit={handleSubmit(onSubmit)}>
        <ErrorSection errors={loginError} />
        <TextInput
          {...register('username')}
          autoFocus={true}
          type='email'
          autoComplete='email'
          required
          label='Email address'
          className={classNames('block', { hidden: pageState === PageState.Password || pageState === PageState.PasswordOrSSO })}
        />

        <div className={classNames('flex flex-col gap-8', { hidden: pageState === PageState.Username })}>
          {(pageState === PageState.Password || pageState === PageState.PasswordOrSSO) && (
            <div>
              <p className='text-sm font-medium text-gray-600 mb-2'>{t('email', 'Email')}</p>
              <p>{username}</p>
            </div>
          )}

          {pageState === PageState.PasswordOrSSO && (
            <>
              {loginProviders.includes('microsoft') && (
                <div className='flex items-stretch flex-col gap-4'>
                  <Button
                    type='button'
                    onClick={() => redirectToMicrosoftLogin(username)}
                    size={ButtonSize.Large}
                    icon={<img src='/integrations/microsoft.png' width={17} />}
                  >
                    {t('login-with-microsoft', 'Login with Microsoft')}
                  </Button>
                  <Button type='button' onClick={() => setPageState(PageState.Password)} icon={<Password />} size={ButtonSize.Large}>
                    {t('login-with-password', 'Login with Password')}
                  </Button>
                </div>
              )}
            </>
          )}

          <TextInput
            {...register('password')}
            id='password'
            type='password'
            autoComplete='current-password'
            required
            label='Password'
            className={classNames('block', { hidden: pageState !== PageState.Password && pageState !== PageState.UsernamePassword })}
          />
        </div>

        {pageState !== PageState.PasswordOrSSO && (
          <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} loading={isSubmitting} type='submit'>
            {pageState === PageState.Username ? t('next', 'Next') : t('login', 'Login')}
          </Button>
        )}
        {pageState !== PageState.Username && (
          <div
            className={classNames('font-medium text-blue-500 flex items-center', {
              'justify-between': pageState === PageState.Password, // Place 'change email' left and 'Forgot pwd right'
              'justify-start': pageState === PageState.PasswordOrSSO,
              'justify-end': pageState === PageState.UsernamePassword,
            })}
          >
            {(pageState === PageState.Password || pageState === PageState.PasswordOrSSO) && ( // Show the 'Change email' button when we're in the password page state.
              <button
                type='button'
                className='flex gap-1 items-center'
                onClick={() => {
                  setValue('password', '');
                  setPageState(PageState.Username);
                  setLoginError(undefined);
                }}
              >
                <ArrowLeft />
                <span>{t('login-change-email', 'Change email')}</span>
              </button>
            )}
            {(pageState === PageState.Password || pageState === PageState.UsernamePassword) && (
              <Link
                to={
                  loginType === LoginType.Webshop
                    ? `${generateWebshopPath(WebshopRoutes.ForgotPassword.path, publicAccessUuid ?? '')}?email=${encodeURIComponent(username)}`
                    : `${AppRoutes.ForgotPassword.path}?email=${encodeURIComponent(username)}`
                }
              >
                {t('forgot', 'Forgot password')}
              </Link>
            )}
          </div>
        )}

        <div className={'flex flex-col items-stretch gap-4'}>
          {/* in some cases we want to disable the signup, e.g. when we are coming from the invitation page */}
          {/* by adding ?disable-signup in the url we can disable the signup button */}
          {pageState !== PageState.Password && pageState !== PageState.PasswordOrSSO && !location.search.startsWith('?disable-signup') && (
            <>
              <div className='text-gray-400 text-sm gap-4 flex flex-row items-center'>
                <hr className='grow' />
                <span>{t('or', 'or')}</span>
                <hr className='grow' />
              </div>
              <Link
                className={'font-semibold rounded-lg'}
                to={
                  loginType === LoginType.Webshop
                    ? generateWebshopPath(WebshopRoutes.Signup.path, publicAccessUuid ?? '')
                    : AppRoutes.Signup.path
                }
              >
                <ButtonInternal variant={ButtonVariant.Default} size={ButtonSize.Large}>
                  {t('create-account', 'Create an account')}
                </ButtonInternal>
              </Link>
            </>
          )}
        </div>
      </form>
    </SplashWrapper>
  );
}
