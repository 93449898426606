import { Popover, Transition } from '@headlessui/react';
import { CaretDown, Check, Funnel } from '@phosphor-icons/react';
import React, { Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import { ButtonInternal, ButtonSize, ButtonVariant } from '.';
import { ActionModal } from 'ui/Modals';
import classNames from 'classnames';

export interface ButtonDropdownOption {
  value: string;
  name: string;
  onClick: () => void;
}

interface ButtonProps {
  selectedOption?: string;
  options: ButtonDropdownOption[];
  // Custom icon for the button.
  icon?: ReactNode;
  children: ReactNode;
  showCaretDown?: boolean;
  showIconOnlyOnMobile?: boolean;
}

/**
 * Our go-to filter button with dialog
 */
export default function ButtonDropdown({
  options,
  selectedOption,
  icon,
  children,
  showCaretDown,
  showIconOnlyOnMobile,
}: ButtonProps): JSX.Element {
  return (
    <ListFilterElement options={options} selectedOption={selectedOption}>
      <ButtonInternal
        size={ButtonSize.Normal}
        variant={ButtonVariant.Default}
        compress={true}
        icon={icon ?? <Funnel />}
        showIconOnlyInCompressModeOnMobile={showIconOnlyOnMobile}
      >
        {children} {showCaretDown && <CaretDown />}
      </ButtonInternal>
    </ListFilterElement>
  );
}

interface ElementProps {
  selectedOption?: string;
  options: ButtonDropdownOption[];
  children: ReactNode;
}

// A button that show a dropdown or modal of the ListFilterSelector
// depending on the screensize.
export function ListFilterElement({ selectedOption, options, children }: ElementProps): JSX.Element {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useScreenSize();

  return (
    <>
      {width <= ScreenSize.md && (
        <>
          <button onClick={() => setIsOpen(true)}>{children}</button>
          <ActionModal
            actions={[
              {
                variant: ButtonVariant.Default,
                text: t('cancel', 'Cancel'),
                onClick: () => setIsOpen(false),
              },
            ]}
            open={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <div className='flex flex-col'>
              {options.map(option => {
                return (
                  <button
                    type='button'
                    className={classNames('relative text-left first:rounded-t-lg last:rounded-b-lg hover:bg-gray-50 py-2 px-8 w-full', {
                      'bg-gray-50': selectedOption === option.value,
                    })}
                    key={option.name}
                    onClick={() => {
                      option.onClick();
                      setIsOpen(false);
                    }}
                  >
                    {selectedOption === option.value && <Check className='absolute left-2 top-3' size={15} />}
                    {option.name}
                  </button>
                );
              })}
            </div>
          </ActionModal>
        </>
      )}

      {width > ScreenSize.md && (
        <Popover>
          {({ close }) => (
            <>
              <Popover.Button className={'!outline-none'}>{children}</Popover.Button>
              <Transition
                as={Fragment}
                enter='transition-opacity ease-out duration-200'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Popover.Panel className='absolute z-20 min-w-24 max-w-60 max-h-80 overflow-y-scroll bg-white border rounded-lg mt-1 shadow-lg'>
                  <div className='flex flex-col'>
                    {options.map(option => {
                      return (
                        <button
                          type='button'
                          className={classNames(
                            'relative text-left first:rounded-t-lg last:rounded-b-lg hover:bg-gray-50 py-2 px-8 w-full',
                            {
                              'bg-gray-50': selectedOption === option.value,
                            },
                          )}
                          key={option.name}
                          onClick={() => {
                            option.onClick();
                            close();
                          }}
                        >
                          {selectedOption === option.value && <Check className='absolute left-2 top-3' size={15} />}
                          {option.name}
                        </button>
                      );
                    })}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
    </>
  );
}
