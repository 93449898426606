import { AppRoutes } from 'AppRoutes';
import CalculateEffectiveNitrogenManureTile from 'components/Manure/CalculateEffectiveNitrogenManureTile';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, ContactsService, ManureService, NitrogenApplication } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import Page from 'ui/Layout/Page';
import { ApiPromises } from 'utilities/ApiPromises';
import { contactName } from 'utilities/Contact';

/**
 * This is based on step 6 of the official document
 *
 * @see https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf
 */
function ManureEffectiveNitrogenPage(): JSX.Element {
  const [contacts, setContacts] = useState<Contact[]>();
  const [effectiveNitrogenItems, setEffectiveNitrogenItems] = useState<NitrogenApplication[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { t } = useTranslation();
  const { year, stableContactUid } = useParams();

  const { selectedOrganizationUid, generateCacheKey } = useOrganization();

  const selectedStable = useMemo(() => {
    return contacts?.find(contact => contact.uid === stableContactUid);
  }, [contacts, stableContactUid]);

  /**
   * Set the breadcrumbs with the stable and year in the path
   */
  const breadCrumbs = useMemo(() => {
    return [
      {
        name: AppRoutes.Manure.name,
        path: `${generatePath(AppRoutes.Manure.path)}?stable=${stableContactUid}&year=${year}`,
      },
    ];
  }, [stableContactUid, year]);

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback(
    (options?: { loadManureData: true }): ApiPromises => {
      const promises = new ApiPromises();

      if (!selectedOrganizationUid || !year) {
        return promises;
      }

      if (options === undefined) {
        promises.appendListObj<Contact>('contacts', setContacts, {
          promiseCallback: () => {
            return ContactsService.contactsList({
              organisationUid: selectedOrganizationUid,
              hidden: false,
              isUser: false,
            });
          },
        });
      }

      if (options === undefined || options.loadManureData) {
        promises.appendList<NitrogenApplication>(
          `manure-effective-nitrogen-${Number(year)}-${stableContactUid}`,
          () =>
            ManureService.manureFertilizerProducersEffectiveNitrogenList({
              contactOrganisationUid: selectedOrganizationUid,
              contactUid: stableContactUid,
              year: Number(year),
            }),
          setEffectiveNitrogenItems,
          generateCacheKey(`manure-effective-nitrogen-${Number(year)}-${stableContactUid}`),
        );
      }

      setApiPromises(promises);

      return promises;
    },
    [generateCacheKey, selectedOrganizationUid, stableContactUid, year],
  );

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  return (
    <Page
      title={t('calculate-effective-nitrogen-title', 'Calculate effective nitrogen {{year}} for {{stableName}}', {
        year,
        stableName: selectedStable ? contactName(selectedStable) : '',
      })}
      breadCrumbs={breadCrumbs}
    >
      <p className='mb-5 px-5 md:px-0'>
        <Trans
          i18nKey='calculate-effective-nitrogen-desc'
          defaults='Below we show step <strong>6</strong> of the official document that has been released by the RvO. In the following steps you can calculate your <strong>yearly effective nitrogen</strong>. For more information you can <0>visit</0> the official document that has been released by the RvO.'
          components={[
            <a
              key='link'
              href='https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf'
              target='_blank'
              rel='noreferrer noopener'
              className='text-blue-500 underline'
            >
              visit
            </a>,
          ]}
        />
      </p>
      <div className='space-y-4'>
        <CalculateEffectiveNitrogenManureTile
          apiPromises={apiPromises}
          effectiveNitrogenItems={effectiveNitrogenItems}
          onDeleted={() => loadApiData({ loadManureData: true })}
          onSaved={() => loadApiData({ loadManureData: true })}
        />
      </div>
    </Page>
  );
}

export default ManureEffectiveNitrogenPage;
