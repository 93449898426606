import { usePlanning } from 'hooks/UsePlanning';
import React, { CSSProperties, Fragment, ReactNode, useMemo, useState } from 'react';
import { Float } from '@headlessui-float/react';
import { ActionModal } from 'ui/Modals';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';
import TitleBarButtons from 'ui/Modals/TitleBarButtons';
import { ActionButton, ActionProps } from 'ui/Modals/ActionModal';
import { ButtonVariant } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { BluePrintState, CalendarActivityType } from 'utilities/Planning';
import SaveActivityForm from './SaveActivityForm';

export interface Props {
  children: ReactNode;
  style?: CSSProperties | undefined;
  // On activity save from blue print modal
  onSaved?: () => Promise<void>;
}

/**
 * An inline modal for creating new calendar items.
 */
export default function BluePrintModal({ children, style, onSaved }: Props): JSX.Element {
  const { t } = useTranslation();
  const { width } = useScreenSize();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { bluePrint, requestBluePrint, unsetBluePrint, loadActivityApiData } = usePlanning();

  const title = () => {
    if (bluePrint) {
      if (bluePrint.type === CalendarActivityType.Activity) {
        return t('new-activity', 'New activity');
      } else if (bluePrint.type === CalendarActivityType.FacilityEvent) {
        return t('new-facility-event', 'Facility reservation');
      } else {
        if (bluePrint.stableUid) {
          return t('new-daily-note', 'New message/task');
        } else {
          return t('new-message', 'New message');
        }
      }
    } else {
      return '';
    }
  };

  const modalActions = useMemo((): ActionProps[] => {
    const result: ActionProps[] = [];
    result.push({
      variant: ButtonVariant.Default,
      text: t('more-options', 'More options'),
      onClick: () => {
        if (bluePrint) {
          requestBluePrint({ ...bluePrint, state: BluePrintState.EditFull });
        }
      },
    });
    result.push({
      variant: ButtonVariant.Primary,
      text: t('save', 'Save'),
      form: 'SaveActivityForm',
      loading: isSubmitting,
    });
    return result;
  }, [t, bluePrint, requestBluePrint, isSubmitting]);

  if (width <= ScreenSize.md) {
    return (
      <div style={style}>
        {children}
        <ActionModal onClose={() => unsetBluePrint()} actions={modalActions} open={bluePrint?.state === BluePrintState.EditCompact}>
          <TitleBarButtons title={title()} actions={[]} onClose={() => unsetBluePrint()} />
          <SaveActivityForm compact={true} onSaved={() => loadActivityApiData().watchAll()} submitting={setIsSubmitting} />
        </ActionModal>
      </div>
    );
  }

  return (
    <Float
      offset={4}
      show={bluePrint?.state === BluePrintState.EditCompact}
      flip={true}
      composable={true}
      portal={true}
      as={Fragment}
      dialog={true}
      enter='transition duration-200 ease-out'
      enter-from='opacity-0 -translate-y-1'
      enter-to='opacity-100 translate-y-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <Float.Reference>
        <div style={style}>{children}</div>
      </Float.Reference>
      <Float.Content>
        <div
          className='w-72 rounded-lg bg-white pl-4 pb-4 pt-2 pr-2 shadow-[0_8px_30px_rgb(0,0,0,0.22)]'
          onClick={e => e.stopPropagation()} // Don't hide when the modal is clicked
        >
          <TitleBarButtons title={title()} actions={[]} onClose={() => unsetBluePrint()} />
          <SaveActivityForm
            onSaved={async () => {
              loadActivityApiData().watchAll();
              onSaved?.();
            }}
            compact={true}
            submitting={setIsSubmitting}
          />
          <div className='pr-2 mt-5 sm:mt-6 gap-4 flex'>
            {modalActions.map((action, index) => (
              <ActionButton className='w-full' key={index + action.text} action={action} />
            ))}
          </div>
        </div>
      </Float.Content>
    </Float>
  );
}
