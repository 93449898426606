import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiPromiseErrors, ApiPromises } from 'utilities/ApiPromises';

interface ReturnType {
  loading: boolean;
  apiPromisesError: ApiPromiseErrors | undefined;
  errorString: string | undefined;
}

interface Props {
  apiPromises?: ApiPromises;
  catchErrors?: boolean;
}

// Simple hook for loading feedback of ApiPromises class.
const useApiPromises = ({ apiPromises, catchErrors }: Props): ReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiPromisesError, setApiPromisesError] = useState<ApiPromiseErrors | undefined>();
  const [errorString, setErrorString] = useState<string>();

  const { t } = useTranslation();

  useEffect(() => {
    if (!apiPromises) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const promise = apiPromises.watchAll();
    promise
      .then(() => {
        setApiPromisesError(undefined);
        setErrorString(undefined);
      })
      .catch(e => {
        // Only catch the errors if the catchErrors is set to true
        if (catchErrors) {
          const apiException = e as ApiPromiseErrors;
          setApiPromisesError(apiException);

          let errorStr = t('unknown-error-occurred', 'An unknown error occurred');
          const error = apiException.errors[0];
          if (error.error.nonFieldErrorsStrings().length > 0) {
            errorStr = error.error.nonFieldErrorsStrings().join(' ');
          }

          setErrorString(errorStr);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiPromises, catchErrors, t]);

  return {
    loading,
    apiPromisesError,
    errorString,
  };
};

export default useApiPromises;
