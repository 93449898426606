import { IconContext } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Skeleton from 'ui/Layout/Skeleton';

interface Props {
  icon: ReactNode;
  name: string;
  value: ReactNode;
  loading?: boolean;
}

const SKELETON_WIDTH_CLASSNAMES = ['w-52', 'w-48', 'w-64', 'w-32', 'w-56', 'w-72'];

/**
 * Small component that will show a detail row
 */
function DetailRow({ icon, name, value, loading }: Props): JSX.Element {
  if (loading) {
    return (
      <li className='flex gap-x-4 items-center'>
        <Skeleton className='w-9 h-9 rounded-md' />
        <Skeleton className={classNames('h-9', SKELETON_WIDTH_CLASSNAMES[Math.floor(Math.random() * SKELETON_WIDTH_CLASSNAMES.length)])} />
      </li>
    );
  }

  return (
    <li className='flex gap-x-4 items-center'>
      <div className='bg-primary text-white p-2 rounded-md'>
        <IconContext.Provider
          value={{
            size: 25,
          }}
        >
          {icon}
        </IconContext.Provider>
      </div>
      <div className='flex flex-col leading-5'>
        <span className='font-semibold'>{name}</span>
        <span>{value}</span>
      </div>
    </li>
  );
}

export default DetailRow;
