import { zodResolver } from '@hookform/resolvers/zod';
import { HorseDetail, HorseLocation, HorselocationsService } from 'openapi';
import useFormError from 'api/hooks/useFormError';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { DateInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { zodInputIsRequired } from 'utilities/zod';
import { ButtonVariant } from 'ui/Button';
import { useOrganization } from 'context/OrganizationContext';
import ApiErrorParser from 'api/ApiErrorParser';
import { objectDiff } from 'utilities/compareObject';

interface Props {
  location: HorseLocation | undefined;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onClosed: () => void;
  onEdited: (updatedHorseLocation: HorseLocation) => void;
}

function UpdateLocationHistoryDateModal({
  location,
  isVisible,
  onRequestCloseModal,
  onClosed: givenOnClosed,
  onEdited,
}: Props): JSX.Element {
  const { selectedOrganizationUid } = useOrganization();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    const fields: { [k in keyof HorseLocation]?: true } = {};

    if (location?.arrival_date) {
      fields.arrival_date = true;
    }

    if (location?.departure_date) {
      fields.departure_date = true;
    }

    return schemas.HorseLocation.pick(fields);
  }, [location?.arrival_date, location?.departure_date]);

  // Construct the default values
  const defaultValues = useMemo((): Partial<HorseLocation> => {
    return {
      arrival_date: location?.arrival_date,
      departure_date: location?.departure_date,
    };
  }, [location?.arrival_date, location?.departure_date]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    clearErrors,
  } = useForm<HorseLocation>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    reset(defaultValues);
    givenOnClosed();
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorseLocation> = async (data: HorseLocation) => {
    if (!selectedOrganizationUid || !location) return;

    try {
      const diff = objectDiff(location, data);
      const promise = HorselocationsService.horselocationsPartialUpdate({
        locationOrganisationUid: selectedOrganizationUid,
        uid: location.uid,
        requestBody: diff,
      });

      const updatedHorseLocation = await promise;

      // close the modal
      onRequestCloseModal();

      // fire event
      onEdited(updatedHorseLocation);
    } catch (error) {
      setApiError(new ApiErrorParser<HorseDetail>(error));
    }
  };

  /**
   * Set the default values when the modal is opened
   */
  useEffect(() => {
    if (isVisible) {
      reset(defaultValues);
    }
  }, [defaultValues, isVisible, reset]);

  return (
    <>
      <PageModal
        open={isVisible}
        parentElement='form'
        parentProps={{ id: 'updateHorseLocation', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        onClosed={onClosed}
        width={PageModalWidth.Sm}
      >
        <PageModalTitle title={t('update-location-history', 'Update location history')} onClose={onRequestCloseModal} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          {/* small trick to avoid the dialog is focus on the dateInput */}
          {/* As focus on the input directly, does not updating the selected date as the inital state of the dateIput is null (= today) */}
          <div tabIndex={1} />
          <div className='space-y-2'>
            {location?.arrival_date && (
              <DateInput
                control={control}
                error={fieldError('arrival_date')}
                required={zodInputIsRequired<HorseLocation>(schema, 'arrival_date')}
                label={t('arrival-date', 'Arrival date')}
                name='arrival_date'
              />
            )}

            {location?.departure_date && (
              <DateInput
                control={control}
                error={fieldError('departure_date')}
                required={zodInputIsRequired<HorseLocation>(schema, 'departure_date')}
                label={t('departure-date', 'Departure date')}
                name='departure_date'
              />
            )}
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: isSubmitting,
              onClick: onRequestCloseModal,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateHorseLocation',
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default UpdateLocationHistoryDateModal;
