import { Check } from '@phosphor-icons/react';
import classNames from 'classnames';
import { Contact, ProviderEnum } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarInitials, AvatarSize } from 'ui/Avatar';
import Badge from 'ui/Badge';
import Button from 'ui/Button';
import { table, tableTbody, tableTbodyTrNoClick, tableTheadTd, tableTheadTdSticky } from 'ui/Const';
import { PageModal } from 'ui/Modals';
import { PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { AllColors } from 'utilities/colors';
import { contactInitials, contactName, ContactType, getContactType } from 'utilities/Contact';
import UnlinkBookkeepingContactModal from './UnlinkBookkeepingContactModal';
import { integrationName } from 'utilities/Integrations';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  integration: ProviderEnum.EXACTNL | ProviderEnum.MONEYBIRD | ProviderEnum.SNELSTART;
  contacts: Contact[];
  onUnlinked: () => void;
}

function ContactSyncStatus({ isVisible, onRequestClose, integration, contacts, onUnlinked }: Props): JSX.Element {
  const [selectedContact, setSelectedContact] = useState<Contact>();

  const { closeModal, modalIsVisible, showModal } = useModal();
  const { t } = useTranslation();

  /**
   * We only need the users and contacts from the list of contacts
   */
  const filteredContacts = useMemo(() => {
    return contacts.filter(contact => [ContactType.User, ContactType.Contact].includes(getContactType(contact)));
  }, [contacts]);

  return (
    <PageModal open={isVisible}>
      <PageModalTitle
        title={t('integration-sync-status-contacts', '{{integrationName}} sync status contacts', {
          integrationName: integrationName(t, integration),
        })}
        onClose={onRequestClose}
      />
      <PageModalContent>
        <table className={table}>
          <thead>
            <tr>
              <td className={classNames('w-12 md:w-14', tableTheadTdSticky)} />
              <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('name', 'Name')}</td>
              <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('synced', 'Synced')}</td>
              <td className={classNames('w-10', tableTheadTdSticky)} />
            </tr>
          </thead>
          <tbody className={tableTbody}>
            {filteredContacts.map(contact => {
              return (
                <tr className={tableTbodyTrNoClick} key={contact.uid}>
                  <td className='text-center w-12 md:w-14'>
                    {[ContactType.User, ContactType.Contact].includes(getContactType(contact)) && (
                      <AvatarInitials size={AvatarSize.Small} initials={contactInitials(contact)} uuid={contact.uid} />
                    )}
                  </td>
                  <td>
                    <span className='mr-2'>{contactName(contact)}</span>
                    {getContactType(contact) === ContactType.Business && <Badge color={AllColors.Amber}>{t('business', 'Business')}</Badge>}
                  </td>
                  <td>
                    {integration === ProviderEnum.EXACTNL && <>{contact.exactnl_contact_id !== '' ? <Check /> : '-'}</>}
                    {integration === ProviderEnum.MONEYBIRD && <>{contact?.moneybird_contact_id ? <Check /> : '-'}</>}
                    {integration === ProviderEnum.SNELSTART && <>{contact?.snelstart_contact_id ? <Check /> : '-'}</>}
                  </td>
                  <td className={classNames('w-10 text-center')}>
                    {(contact.exactnl_contact_id !== '' || contact?.moneybird_contact_id || contact?.snelstart_contact_id) && (
                      <Button
                        onClick={() => {
                          setSelectedContact(contact);
                          showModal();
                        }}
                      >
                        {t('disconnect', 'Disconnect')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <UnlinkBookkeepingContactModal
          unlinkIntegration={integration}
          onUnlinked={onUnlinked}
          contact={selectedContact}
          isVisible={modalIsVisible}
          onRequestClose={closeModal}
        />
      </PageModalContent>
    </PageModal>
  );
}

export default ContactSyncStatus;
