import { TFunction } from 'i18next';
import { ProviderEnum } from 'openapi';

export interface Integration {
  id: IntegrationId;
  title: string;
  description: string;
  iconUrl: string;
}

export const integrationName = (t: TFunction, bookkeepingProvider: ProviderEnum): string | undefined => {
  return integrations(t).find(integration => integration.id.toString() === bookkeepingProvider)?.title;
};

// Integration identifier. The value is used at string identifier at the api.
export enum IntegrationId {
  mollie = 'mollie',
  moneybird = 'moneybird',
  exactnl = 'exactnl',
  yuki = 'yuki',
  microsoft = 'microsoft',
  snelstart = 'snelstart',
}

// Our external integrations configuration.
export const integrations = (t: TFunction): Integration[] => {
  return [
    {
      id: IntegrationId.mollie,
      title: 'Mollie',
      description: t('mollie-integration-desc', 'Use Mollie to enable online payments by your customers.'),
      iconUrl: '/integrations/mollie.png',
    },
    {
      id: IntegrationId.moneybird,
      title: 'Moneybird',
      description: t(
        'moneybird-integration-desc',
        'Integrate your Equinem financial administration with Moneybird for bookkeeping purposes.',
      ),
      iconUrl: '/integrations/moneybird.png',
    },
    {
      id: IntegrationId.exactnl,
      title: 'Exact Online',
      description: t(
        'exact-integration-desc',
        'Integrate your Equinem financial administration with Exact Online for bookkeeping purposes.',
      ),
      iconUrl: '/integrations/exact.png',
    },
    {
      id: IntegrationId.snelstart,
      title: 'Snelstart',
      description: t(
        'snelstart-integration-desc',
        'Integrate your Equinem financial administration with Snelstart for bookkeeping purposes.',
      ),
      iconUrl: '/integrations/snelstart.png',
    },
    {
      id: IntegrationId.yuki,
      title: 'Yuki',
      description: t('yuki-integration-desc', 'Integrate your Equinem financial administration with Yuki for bookkeeping purposes.'),
      iconUrl: '/integrations/yuki.png',
    },
    {
      id: IntegrationId.microsoft,
      title: 'Microsoft',
      description: t('microsoft-integration-desc', 'Use for single sign-on purposes. Your Equinem accounts are managed by Microsoft.'),
      iconUrl: '/integrations/microsoft.png',
    },
  ];
};
