import React from 'react';
import { useTranslation } from 'react-i18next';
import Page, { PageMaxWidth } from '../../ui/Layout/Page';
import ShopTermsTile from 'components/Organization/Tiles/ShopTermsTile';
import TrustedCountriesBreedingSettingTile from 'components/Breeding/Settings/TrustedCountriesBreedingSettingTile';
import StallionAndShippingProductsBreedingTile from 'components/Breeding/Settings/StallionAndShippingProductsBreedingTile';

export default function BreedingSettingsPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Page title={t('settings', 'Settings')} maxWidth={PageMaxWidth.Tile}>
      <div className='space-y-4'>
        <StallionAndShippingProductsBreedingTile />
        <ShopTermsTile />
        <TrustedCountriesBreedingSettingTile />
      </div>
    </Page>
  );
}
