/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AveragedHistoricHorseCount } from '../models/AveragedHistoricHorseCount';
import type { FertilizerConsumer } from '../models/FertilizerConsumer';
import type { NitrogenApplication } from '../models/NitrogenApplication';
import type { PaginatedFertilizerConsumerList } from '../models/PaginatedFertilizerConsumerList';
import type { PaginatedNitrogenApplicationList } from '../models/PaginatedNitrogenApplicationList';
import type { PaginatedProductionBalanceItemList } from '../models/PaginatedProductionBalanceItemList';
import type { PatchedFertilizerConsumer } from '../models/PatchedFertilizerConsumer';
import type { PatchedNitrogenApplication } from '../models/PatchedNitrogenApplication';
import type { PatchedProductionBalanceItem } from '../models/PatchedProductionBalanceItem';
import type { ProductionBalanceItem } from '../models/ProductionBalanceItem';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ManureService {
  /**
   * **Step 1/3 in the manure administration**
   *
   * Fertilizer is consumed by farm land. Depending on the type of land and type of crop on
   * that land, it will consume some yearly amount of different types of fertilizer.
   * By summarizing an organization's grass lands and crops, you'll know the yearly budget
   * (or 'gebruiksruimte') for different types of fertilizer, which can then be balanced
   * against fertilizer producers.
   *
   * These records are usable in the budget tables in the yearly manure administration. The
   * record's FertilizerBudgetType identifies the respective table.
   *
   * Expected usage for the Dutch RvO manure balance calculation is exemplified in steps 1 2 and 3
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * | RvO Table | fertilizer_budget_type | description examples |
   * |-----------|------------------------|----------------------|
   * | stikstof uit dierlijke mest     | ANIMAL_NITROGEN  | landbouwgrond |
   * | totale stikstof gerbruiksruimte | TOTAL_NITROGEN   | grasland (met beweiden), suikerbieten |
   * | totale fosfaat gerbruiksruimte  | TOTAL_PHOSPHATE | grasland, bouwland |
   *
   *
   * Note: The summary of areas does not represent the total farmland that belonged
   * to the respective contact in the respective year. Plots of land can be entered
   * multiple times with different descriptions, for different budgets. For example:
   * - the amounts of grass lands and crop lands together define the total farmland
   * - the summarized crops that aren't follow-ups define the amount of crop lands
   * @returns PaginatedFertilizerConsumerList
   * @throws ApiError
   */
  public static manureFertilizerConsumersList({
    contactOrganisationUid,
    contactUid,
    contactUidIn,
    fertilizerBudgetType,
    fertilizerBudgetTypeIn,
    o,
    page,
    pageSize,
    year,
  }: {
    contactOrganisationUid: string,
    contactUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    contactUidIn?: Array<string>,
    /**
     * * `ANIMAL_NITROGEN` - nitrogen from animals
     * * `TOTAL_NITROGEN` - total nitrogen
     * * `TOTAL_PHOSPHATE` - total phosphate
     */
    fertilizerBudgetType?: 'ANIMAL_NITROGEN' | 'TOTAL_NITROGEN' | 'TOTAL_PHOSPHATE',
    /**
     * Multiple values may be separated by commas.
     */
    fertilizerBudgetTypeIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    year?: number,
  }): CancelablePromise<PaginatedFertilizerConsumerList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_consumers',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
      },
      query: {
        'contact__uid': contactUid,
        'contact__uid__in': contactUidIn,
        'fertilizer_budget_type': fertilizerBudgetType,
        'fertilizer_budget_type__in': fertilizerBudgetTypeIn,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'year': year,
      },
    });
  }
  /**
   * **Step 1/3 in the manure administration**
   *
   * Fertilizer is consumed by farm land. Depending on the type of land and type of crop on
   * that land, it will consume some yearly amount of different types of fertilizer.
   * By summarizing an organization's grass lands and crops, you'll know the yearly budget
   * (or 'gebruiksruimte') for different types of fertilizer, which can then be balanced
   * against fertilizer producers.
   *
   * These records are usable in the budget tables in the yearly manure administration. The
   * record's FertilizerBudgetType identifies the respective table.
   *
   * Expected usage for the Dutch RvO manure balance calculation is exemplified in steps 1 2 and 3
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * | RvO Table | fertilizer_budget_type | description examples |
   * |-----------|------------------------|----------------------|
   * | stikstof uit dierlijke mest     | ANIMAL_NITROGEN  | landbouwgrond |
   * | totale stikstof gerbruiksruimte | TOTAL_NITROGEN   | grasland (met beweiden), suikerbieten |
   * | totale fosfaat gerbruiksruimte  | TOTAL_PHOSPHATE | grasland, bouwland |
   *
   *
   * Note: The summary of areas does not represent the total farmland that belonged
   * to the respective contact in the respective year. Plots of land can be entered
   * multiple times with different descriptions, for different budgets. For example:
   * - the amounts of grass lands and crop lands together define the total farmland
   * - the summarized crops that aren't follow-ups define the amount of crop lands
   * @returns FertilizerConsumer
   * @throws ApiError
   */
  public static manureFertilizerConsumersCreate({
    contactOrganisationUid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    requestBody: FertilizerConsumer,
  }): CancelablePromise<FertilizerConsumer> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_consumers',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 1/3 in the manure administration**
   *
   * Fertilizer is consumed by farm land. Depending on the type of land and type of crop on
   * that land, it will consume some yearly amount of different types of fertilizer.
   * By summarizing an organization's grass lands and crops, you'll know the yearly budget
   * (or 'gebruiksruimte') for different types of fertilizer, which can then be balanced
   * against fertilizer producers.
   *
   * These records are usable in the budget tables in the yearly manure administration. The
   * record's FertilizerBudgetType identifies the respective table.
   *
   * Expected usage for the Dutch RvO manure balance calculation is exemplified in steps 1 2 and 3
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * | RvO Table | fertilizer_budget_type | description examples |
   * |-----------|------------------------|----------------------|
   * | stikstof uit dierlijke mest     | ANIMAL_NITROGEN  | landbouwgrond |
   * | totale stikstof gerbruiksruimte | TOTAL_NITROGEN   | grasland (met beweiden), suikerbieten |
   * | totale fosfaat gerbruiksruimte  | TOTAL_PHOSPHATE | grasland, bouwland |
   *
   *
   * Note: The summary of areas does not represent the total farmland that belonged
   * to the respective contact in the respective year. Plots of land can be entered
   * multiple times with different descriptions, for different budgets. For example:
   * - the amounts of grass lands and crop lands together define the total farmland
   * - the summarized crops that aren't follow-ups define the amount of crop lands
   * @returns FertilizerConsumer
   * @throws ApiError
   */
  public static manureFertilizerConsumersRetrieve({
    contactOrganisationUid,
    uid,
  }: {
    contactOrganisationUid: string,
    uid: string,
  }): CancelablePromise<FertilizerConsumer> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_consumers/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * **Step 1/3 in the manure administration**
   *
   * Fertilizer is consumed by farm land. Depending on the type of land and type of crop on
   * that land, it will consume some yearly amount of different types of fertilizer.
   * By summarizing an organization's grass lands and crops, you'll know the yearly budget
   * (or 'gebruiksruimte') for different types of fertilizer, which can then be balanced
   * against fertilizer producers.
   *
   * These records are usable in the budget tables in the yearly manure administration. The
   * record's FertilizerBudgetType identifies the respective table.
   *
   * Expected usage for the Dutch RvO manure balance calculation is exemplified in steps 1 2 and 3
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * | RvO Table | fertilizer_budget_type | description examples |
   * |-----------|------------------------|----------------------|
   * | stikstof uit dierlijke mest     | ANIMAL_NITROGEN  | landbouwgrond |
   * | totale stikstof gerbruiksruimte | TOTAL_NITROGEN   | grasland (met beweiden), suikerbieten |
   * | totale fosfaat gerbruiksruimte  | TOTAL_PHOSPHATE | grasland, bouwland |
   *
   *
   * Note: The summary of areas does not represent the total farmland that belonged
   * to the respective contact in the respective year. Plots of land can be entered
   * multiple times with different descriptions, for different budgets. For example:
   * - the amounts of grass lands and crop lands together define the total farmland
   * - the summarized crops that aren't follow-ups define the amount of crop lands
   * @returns FertilizerConsumer
   * @throws ApiError
   */
  public static manureFertilizerConsumersUpdate({
    contactOrganisationUid,
    uid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    uid: string,
    requestBody: FertilizerConsumer,
  }): CancelablePromise<FertilizerConsumer> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_consumers/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 1/3 in the manure administration**
   *
   * Fertilizer is consumed by farm land. Depending on the type of land and type of crop on
   * that land, it will consume some yearly amount of different types of fertilizer.
   * By summarizing an organization's grass lands and crops, you'll know the yearly budget
   * (or 'gebruiksruimte') for different types of fertilizer, which can then be balanced
   * against fertilizer producers.
   *
   * These records are usable in the budget tables in the yearly manure administration. The
   * record's FertilizerBudgetType identifies the respective table.
   *
   * Expected usage for the Dutch RvO manure balance calculation is exemplified in steps 1 2 and 3
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * | RvO Table | fertilizer_budget_type | description examples |
   * |-----------|------------------------|----------------------|
   * | stikstof uit dierlijke mest     | ANIMAL_NITROGEN  | landbouwgrond |
   * | totale stikstof gerbruiksruimte | TOTAL_NITROGEN   | grasland (met beweiden), suikerbieten |
   * | totale fosfaat gerbruiksruimte  | TOTAL_PHOSPHATE | grasland, bouwland |
   *
   *
   * Note: The summary of areas does not represent the total farmland that belonged
   * to the respective contact in the respective year. Plots of land can be entered
   * multiple times with different descriptions, for different budgets. For example:
   * - the amounts of grass lands and crop lands together define the total farmland
   * - the summarized crops that aren't follow-ups define the amount of crop lands
   * @returns FertilizerConsumer
   * @throws ApiError
   */
  public static manureFertilizerConsumersPartialUpdate({
    contactOrganisationUid,
    uid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    uid: string,
    requestBody?: PatchedFertilizerConsumer,
  }): CancelablePromise<FertilizerConsumer> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_consumers/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 1/3 in the manure administration**
   *
   * Fertilizer is consumed by farm land. Depending on the type of land and type of crop on
   * that land, it will consume some yearly amount of different types of fertilizer.
   * By summarizing an organization's grass lands and crops, you'll know the yearly budget
   * (or 'gebruiksruimte') for different types of fertilizer, which can then be balanced
   * against fertilizer producers.
   *
   * These records are usable in the budget tables in the yearly manure administration. The
   * record's FertilizerBudgetType identifies the respective table.
   *
   * Expected usage for the Dutch RvO manure balance calculation is exemplified in steps 1 2 and 3
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * | RvO Table | fertilizer_budget_type | description examples |
   * |-----------|------------------------|----------------------|
   * | stikstof uit dierlijke mest     | ANIMAL_NITROGEN  | landbouwgrond |
   * | totale stikstof gerbruiksruimte | TOTAL_NITROGEN   | grasland (met beweiden), suikerbieten |
   * | totale fosfaat gerbruiksruimte  | TOTAL_PHOSPHATE | grasland, bouwland |
   *
   *
   * Note: The summary of areas does not represent the total farmland that belonged
   * to the respective contact in the respective year. Plots of land can be entered
   * multiple times with different descriptions, for different budgets. For example:
   * - the amounts of grass lands and crop lands together define the total farmland
   * - the summarized crops that aren't follow-ups define the amount of crop lands
   * @returns void
   * @throws ApiError
   */
  public static manureFertilizerConsumersDestroy({
    contactOrganisationUid,
    uid,
  }: {
    contactOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_consumers/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * **Step 2/3 in the manure administration**
   *
   * An contact's yearly manure balance is made up of different items; from animal feed that was
   * locally produced to this year's manure on stock. Depending on the mutation of these items,
   * their respective nitrogen and phosphate will be added or subtracted on the balance.
   *
   * The origin and mutation types correspond to the ones displayed in the RvO example
   * in steps 4 and 5 [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * A contact's yearly records will add up to a nitrogen and phosphor surplus that need to be
   * equal or lower than the respective budgets captured by the FertilizerConsumer table.
   * The phosphorous component can be compared directly to the budget for the nitrogenous component
   * you should first fill calculate the effective application weight in step 3/3, see
   * `/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen`
   * @returns PaginatedProductionBalanceItemList
   * @throws ApiError
   */
  public static manureFertilizerProducersBalanceList({
    contactOrganisationUid,
    contactUid,
    contactUidIn,
    fertilizerOrigin,
    fertilizerOriginIn,
    mutationType,
    mutationTypeIn,
    o,
    page,
    pageSize,
    year,
  }: {
    contactOrganisationUid: string,
    contactUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    contactUidIn?: Array<string>,
    /**
     * indicate the type of this fertilizer or where it originated from
     *
     * * `ANIMAL_MANURE_SUPPLY` - animal manure supply
     * * `MILK_PRODUCING_COWS` - milk producing cows
     * * `GRAZING_ANIMALS` - grazing animals
     * * `EQUINEM_HORSES` - equinem horses
     * * `STABLE_ANIMALS_FEED` - stable animals feed
     * * `OTHER_ORGANIC_SUPPLY` - other organic supply
     * * `ANORGANIC_SUPPLY` - anorganic supply
     */
    fertilizerOrigin?: 'ANIMAL_MANURE_SUPPLY' | 'ANORGANIC_SUPPLY' | 'EQUINEM_HORSES' | 'GRAZING_ANIMALS' | 'MILK_PRODUCING_COWS' | 'OTHER_ORGANIC_SUPPLY' | 'STABLE_ANIMALS_FEED',
    /**
     * Multiple values may be separated by commas.
     */
    fertilizerOriginIn?: Array<string>,
    /**
     * End amounts and Exports are subtractions on the balance, the others are additions
     *
     * * `BEGIN_AMOUNT` - begin amount
     * * `IMPORT` - import
     * * `PRODUCTION` - production
     * * `EXPORT` - export
     * * `END_AMOUNT` - end amount
     */
    mutationType?: 'BEGIN_AMOUNT' | 'END_AMOUNT' | 'EXPORT' | 'IMPORT' | 'PRODUCTION',
    /**
     * Multiple values may be separated by commas.
     */
    mutationTypeIn?: Array<string>,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    year?: number,
  }): CancelablePromise<PaginatedProductionBalanceItemList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/balance',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
      },
      query: {
        'contact__uid': contactUid,
        'contact__uid__in': contactUidIn,
        'fertilizer_origin': fertilizerOrigin,
        'fertilizer_origin__in': fertilizerOriginIn,
        'mutation_type': mutationType,
        'mutation_type__in': mutationTypeIn,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'year': year,
      },
    });
  }
  /**
   * **Step 2/3 in the manure administration**
   *
   * An contact's yearly manure balance is made up of different items; from animal feed that was
   * locally produced to this year's manure on stock. Depending on the mutation of these items,
   * their respective nitrogen and phosphate will be added or subtracted on the balance.
   *
   * The origin and mutation types correspond to the ones displayed in the RvO example
   * in steps 4 and 5 [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * A contact's yearly records will add up to a nitrogen and phosphor surplus that need to be
   * equal or lower than the respective budgets captured by the FertilizerConsumer table.
   * The phosphorous component can be compared directly to the budget for the nitrogenous component
   * you should first fill calculate the effective application weight in step 3/3, see
   * `/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen`
   * @returns ProductionBalanceItem
   * @throws ApiError
   */
  public static manureFertilizerProducersBalanceCreate({
    contactOrganisationUid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    requestBody: ProductionBalanceItem,
  }): CancelablePromise<ProductionBalanceItem> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/balance',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 2/3 in the manure administration**
   *
   * An contact's yearly manure balance is made up of different items; from animal feed that was
   * locally produced to this year's manure on stock. Depending on the mutation of these items,
   * their respective nitrogen and phosphate will be added or subtracted on the balance.
   *
   * The origin and mutation types correspond to the ones displayed in the RvO example
   * in steps 4 and 5 [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * A contact's yearly records will add up to a nitrogen and phosphor surplus that need to be
   * equal or lower than the respective budgets captured by the FertilizerConsumer table.
   * The phosphorous component can be compared directly to the budget for the nitrogenous component
   * you should first fill calculate the effective application weight in step 3/3, see
   * `/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen`
   * @returns ProductionBalanceItem
   * @throws ApiError
   */
  public static manureFertilizerProducersBalanceRetrieve({
    contactOrganisationUid,
    uid,
  }: {
    contactOrganisationUid: string,
    uid: string,
  }): CancelablePromise<ProductionBalanceItem> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/balance/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * **Step 2/3 in the manure administration**
   *
   * An contact's yearly manure balance is made up of different items; from animal feed that was
   * locally produced to this year's manure on stock. Depending on the mutation of these items,
   * their respective nitrogen and phosphate will be added or subtracted on the balance.
   *
   * The origin and mutation types correspond to the ones displayed in the RvO example
   * in steps 4 and 5 [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * A contact's yearly records will add up to a nitrogen and phosphor surplus that need to be
   * equal or lower than the respective budgets captured by the FertilizerConsumer table.
   * The phosphorous component can be compared directly to the budget for the nitrogenous component
   * you should first fill calculate the effective application weight in step 3/3, see
   * `/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen`
   * @returns ProductionBalanceItem
   * @throws ApiError
   */
  public static manureFertilizerProducersBalanceUpdate({
    contactOrganisationUid,
    uid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    uid: string,
    requestBody: ProductionBalanceItem,
  }): CancelablePromise<ProductionBalanceItem> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/balance/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 2/3 in the manure administration**
   *
   * An contact's yearly manure balance is made up of different items; from animal feed that was
   * locally produced to this year's manure on stock. Depending on the mutation of these items,
   * their respective nitrogen and phosphate will be added or subtracted on the balance.
   *
   * The origin and mutation types correspond to the ones displayed in the RvO example
   * in steps 4 and 5 [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * A contact's yearly records will add up to a nitrogen and phosphor surplus that need to be
   * equal or lower than the respective budgets captured by the FertilizerConsumer table.
   * The phosphorous component can be compared directly to the budget for the nitrogenous component
   * you should first fill calculate the effective application weight in step 3/3, see
   * `/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen`
   * @returns ProductionBalanceItem
   * @throws ApiError
   */
  public static manureFertilizerProducersBalancePartialUpdate({
    contactOrganisationUid,
    uid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    uid: string,
    requestBody?: PatchedProductionBalanceItem,
  }): CancelablePromise<ProductionBalanceItem> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/balance/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 2/3 in the manure administration**
   *
   * An contact's yearly manure balance is made up of different items; from animal feed that was
   * locally produced to this year's manure on stock. Depending on the mutation of these items,
   * their respective nitrogen and phosphate will be added or subtracted on the balance.
   *
   * The origin and mutation types correspond to the ones displayed in the RvO example
   * in steps 4 and 5 [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf).
   *
   * A contact's yearly records will add up to a nitrogen and phosphor surplus that need to be
   * equal or lower than the respective budgets captured by the FertilizerConsumer table.
   * The phosphorous component can be compared directly to the budget for the nitrogenous component
   * you should first fill calculate the effective application weight in step 3/3, see
   * `/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen`
   * @returns void
   * @throws ApiError
   */
  public static manureFertilizerProducersBalanceDestroy({
    contactOrganisationUid,
    uid,
  }: {
    contactOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/balance/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * **Step 3/3 in the manure administration**
   *
   * The nitrogen fertilizer component in manure that gets applied to the land is not fully
   * absorbed by the ground. The items on the balance in step 2/3 will tell us the yearly
   * surplus of manure production for a Contact; this endpoint will record how that surplus
   * has been applied. In the end the RvO needs to know how much fertilizer has been *effectively*
   * applied to the land and this depends on the type of fertilizer and the type of land.
   *
   * This endpoint will help you recorde the table in the RvO's example, step 6, visible
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf)
   *
   * The resulting `effective_nitrogen_weight` is the number that needs to add up to the yearly
   * nitrogen budget captured in step 1/3 for the respective contact.
   * @returns PaginatedNitrogenApplicationList
   * @throws ApiError
   */
  public static manureFertilizerProducersEffectiveNitrogenList({
    contactOrganisationUid,
    contactUid,
    contactUidIn,
    isAnimalManure,
    o,
    page,
    pageSize,
    year,
  }: {
    contactOrganisationUid: string,
    contactUid?: string,
    /**
     * Multiple values may be separated by commas.
     */
    contactUidIn?: Array<string>,
    isAnimalManure?: boolean,
    /**
     * Which field to use when ordering the results.
     */
    o?: string,
    /**
     * A page number within the paginated result set.
     */
    page?: number,
    /**
     * Number of results to return per page.
     */
    pageSize?: number,
    year?: number,
  }): CancelablePromise<PaginatedNitrogenApplicationList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
      },
      query: {
        'contact__uid': contactUid,
        'contact__uid__in': contactUidIn,
        'is_animal_manure': isAnimalManure,
        'o': o,
        'page': page,
        'page_size': pageSize,
        'year': year,
      },
    });
  }
  /**
   * **Step 3/3 in the manure administration**
   *
   * The nitrogen fertilizer component in manure that gets applied to the land is not fully
   * absorbed by the ground. The items on the balance in step 2/3 will tell us the yearly
   * surplus of manure production for a Contact; this endpoint will record how that surplus
   * has been applied. In the end the RvO needs to know how much fertilizer has been *effectively*
   * applied to the land and this depends on the type of fertilizer and the type of land.
   *
   * This endpoint will help you recorde the table in the RvO's example, step 6, visible
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf)
   *
   * The resulting `effective_nitrogen_weight` is the number that needs to add up to the yearly
   * nitrogen budget captured in step 1/3 for the respective contact.
   * @returns NitrogenApplication
   * @throws ApiError
   */
  public static manureFertilizerProducersEffectiveNitrogenCreate({
    contactOrganisationUid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    requestBody: NitrogenApplication,
  }): CancelablePromise<NitrogenApplication> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 3/3 in the manure administration**
   *
   * The nitrogen fertilizer component in manure that gets applied to the land is not fully
   * absorbed by the ground. The items on the balance in step 2/3 will tell us the yearly
   * surplus of manure production for a Contact; this endpoint will record how that surplus
   * has been applied. In the end the RvO needs to know how much fertilizer has been *effectively*
   * applied to the land and this depends on the type of fertilizer and the type of land.
   *
   * This endpoint will help you recorde the table in the RvO's example, step 6, visible
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf)
   *
   * The resulting `effective_nitrogen_weight` is the number that needs to add up to the yearly
   * nitrogen budget captured in step 1/3 for the respective contact.
   * @returns NitrogenApplication
   * @throws ApiError
   */
  public static manureFertilizerProducersEffectiveNitrogenRetrieve({
    contactOrganisationUid,
    uid,
  }: {
    contactOrganisationUid: string,
    uid: string,
  }): CancelablePromise<NitrogenApplication> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * **Step 3/3 in the manure administration**
   *
   * The nitrogen fertilizer component in manure that gets applied to the land is not fully
   * absorbed by the ground. The items on the balance in step 2/3 will tell us the yearly
   * surplus of manure production for a Contact; this endpoint will record how that surplus
   * has been applied. In the end the RvO needs to know how much fertilizer has been *effectively*
   * applied to the land and this depends on the type of fertilizer and the type of land.
   *
   * This endpoint will help you recorde the table in the RvO's example, step 6, visible
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf)
   *
   * The resulting `effective_nitrogen_weight` is the number that needs to add up to the yearly
   * nitrogen budget captured in step 1/3 for the respective contact.
   * @returns NitrogenApplication
   * @throws ApiError
   */
  public static manureFertilizerProducersEffectiveNitrogenUpdate({
    contactOrganisationUid,
    uid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    uid: string,
    requestBody: NitrogenApplication,
  }): CancelablePromise<NitrogenApplication> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 3/3 in the manure administration**
   *
   * The nitrogen fertilizer component in manure that gets applied to the land is not fully
   * absorbed by the ground. The items on the balance in step 2/3 will tell us the yearly
   * surplus of manure production for a Contact; this endpoint will record how that surplus
   * has been applied. In the end the RvO needs to know how much fertilizer has been *effectively*
   * applied to the land and this depends on the type of fertilizer and the type of land.
   *
   * This endpoint will help you recorde the table in the RvO's example, step 6, visible
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf)
   *
   * The resulting `effective_nitrogen_weight` is the number that needs to add up to the yearly
   * nitrogen budget captured in step 1/3 for the respective contact.
   * @returns NitrogenApplication
   * @throws ApiError
   */
  public static manureFertilizerProducersEffectiveNitrogenPartialUpdate({
    contactOrganisationUid,
    uid,
    requestBody,
  }: {
    contactOrganisationUid: string,
    uid: string,
    requestBody?: PatchedNitrogenApplication,
  }): CancelablePromise<NitrogenApplication> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * **Step 3/3 in the manure administration**
   *
   * The nitrogen fertilizer component in manure that gets applied to the land is not fully
   * absorbed by the ground. The items on the balance in step 2/3 will tell us the yearly
   * surplus of manure production for a Contact; this endpoint will record how that surplus
   * has been applied. In the end the RvO needs to know how much fertilizer has been *effectively*
   * applied to the land and this depends on the type of fertilizer and the type of land.
   *
   * This endpoint will help you recorde the table in the RvO's example, step 6, visible
   * [here](https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf)
   *
   * The resulting `effective_nitrogen_weight` is the number that needs to add up to the yearly
   * nitrogen budget captured in step 1/3 for the respective contact.
   * @returns void
   * @throws ApiError
   */
  public static manureFertilizerProducersEffectiveNitrogenDestroy({
    contactOrganisationUid,
    uid,
  }: {
    contactOrganisationUid: string,
    uid: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v5/organisations/{contact__organisation__uid}/manure/fertilizer_producers/effective-nitrogen/{uid}',
      path: {
        'contact__organisation__uid': contactOrganisationUid,
        'uid': uid,
      },
    });
  }
  /**
   * When filing the manure administration, a stable manager needs to know how many horses and ponies
   * haven been in his stable over the course of the past year. This endpoint serializes that for the
   * provided contact and year. The result will contain a 'head count' made at the first of the month
   * and how that averages over the year.
   *
   * The production balance registration is based on a contact (uid). The uid in the url provided for
   * this endpoint should be the same and (e.g. the stable's location). You can only provide the
   * current or past years. Month that have not yet come to pass are filled in with the numbers
   * based on the current horse locations.
   * @returns AveragedHistoricHorseCount
   * @throws ApiError
   */
  public static manureStableHistoricHorseCountContactRetrieve({
    organisationUid,
    uid,
    year,
  }: {
    organisationUid: string,
    uid: string,
    year: string,
  }): CancelablePromise<AveragedHistoricHorseCount> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v5/organisations/{organisation__uid}/manure/stable-historic-horse-count/{year}/contact/{uid}',
      path: {
        'organisation__uid': organisationUid,
        'uid': uid,
        'year': year,
      },
    });
  }
}
