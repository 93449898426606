import { TFunction } from 'i18next';
import { FertilizerOriginEnum, MutationTypeEnum, OperatorEnum, ProductionBalanceItem } from 'openapi';

/**
 * Translate a production balance enum item to a string
 */
export const productionsBalanceItemToString = (
  fertilizerOrigin: FertilizerOriginEnum,
  mutationType: MutationTypeEnum,
  t: TFunction,
): string => {
  if (fertilizerOrigin === FertilizerOriginEnum.MILK_PRODUCING_COWS) {
    return t('producing-cows', 'Producing cows');
  } else if (fertilizerOrigin === FertilizerOriginEnum.GRAZING_ANIMALS) {
    return t('grazing-animals', 'Grazing animals');
  } else if (fertilizerOrigin === FertilizerOriginEnum.STABLE_ANIMALS_FEED) {
    return t('stable-animals', 'Stable animals');
  } else if (fertilizerOrigin === FertilizerOriginEnum.EQUINEM_HORSES) {
    return t('equinem-horses', 'Equinem horses');
  }

  if (mutationType === MutationTypeEnum.BEGIN_AMOUNT) {
    return t('weight-begin-amound', 'Weight begin amount');
  } else if (mutationType === MutationTypeEnum.IMPORT) {
    return t('weight-in-import', 'Weight in import');
  } else if (mutationType === MutationTypeEnum.EXPORT) {
    return t('weight-in-export', 'Weight in export');
  } else if (mutationType === MutationTypeEnum.END_AMOUNT) {
    return t('weight-end-amount', 'Weight end amount');
  }

  return '';
};

/**
 * Translate a fertilizer enum item to a string
 */
export const fertilizerOriginToString = (fertilizerOrigin: FertilizerOriginEnum | undefined, t: TFunction): string => {
  if (!fertilizerOrigin) {
    return t('unknown', 'Unknown');
  }

  switch (fertilizerOrigin) {
    case FertilizerOriginEnum.ANIMAL_MANURE_SUPPLY:
      return t('animal-manure-supply', 'Animal manure supply');
    case FertilizerOriginEnum.MILK_PRODUCING_COWS:
      return t('milk-producing-cows', 'Milk producing cows');
    case FertilizerOriginEnum.GRAZING_ANIMALS:
      return t('grazing-animals', 'Grazing animals');
    case FertilizerOriginEnum.EQUINEM_HORSES:
      return t('equinem-horses', 'Equinem horses');
    case FertilizerOriginEnum.STABLE_ANIMALS_FEED:
      return t('Stable animals feed', 'stable animals feed');
    case FertilizerOriginEnum.OTHER_ORGANIC_SUPPLY:
      return t('other-organic-supply', 'Other organic supply');
    case FertilizerOriginEnum.ANORGANIC_SUPPLY:
      return t('anorganic-supply', 'Anorganic supply');
  }
};

/**
 * Small helper to format the nitrogen weight
 */
export const formatRowNitrogen = (item: Pick<ProductionBalanceItem, 'nitrogen_weight'> | undefined, t: TFunction): string => {
  if (!item || item.nitrogen_weight === 0) {
    return t('none-lowercase', 'none');
  }

  return `${item.nitrogen_weight} kg`;
};

/**
 * Small helper to format the fosfate weight
 */
export const formatRowFosfate = (item: Pick<ProductionBalanceItem, 'phosphate_weight'> | undefined, t: TFunction): string => {
  if (!item || item.phosphate_weight === 0) {
    return t('none-lowercase', 'none');
  }

  return `${item.phosphate_weight} kg`;
};

/**
 * Sum up the total with the given operator
 */
export const sumUpTotal = (operator: OperatorEnum, weight: number | undefined | null, total: number): number => {
  if (operator === OperatorEnum.ADD) {
    return total + Number(weight);
  }

  return total - Number(weight);
};
