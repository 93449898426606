import { Horse, List, SidebarSimple } from '@phosphor-icons/react';
import useWebshop from 'api/hooks/useWebshop';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import { t } from 'i18next';
import React from 'react';
import { Theme } from '../Theme';
import OrganizationSelector from './OrganizationSelector';

interface Props {
  open: boolean;
  setOpen: (_open: boolean) => void;
  theme: Theme;
}

export default function HeaderSection({ open, setOpen, theme }: Props): JSX.Element {
  const rootClassName = classNames('flex gap-2 border-b text-neutral-50 flex p-2 h-14 min-h-14', {
    'bg-primary': theme === Theme.Equinem,
    'bg-webshop': theme === Theme.Webshop,
  });

  const { webshopOrganization } = useWebshop();
  const { organizations } = useOrganization();

  if (theme === Theme.Webshop) {
    return (
      <>
        <div className='select-none bg-webshop text-white flex flex-col items-center w-full pb-4 pt-6'>
          <div className='w-16 h-16 mb-4 flex items-center justify-center rounded-full bg-white opacity-80'>
            <Horse weight='thin' size={35} color='webshop' />
          </div>
          <h1 className='text-lg'>{webshopOrganization?.name}</h1>
          <p className='text-xs opacity-50 uppercase'>{t('webshop', 'Webshop')}</p>
        </div>
        {organizations.length > 1 && <div className='mb-4'>{open && <OrganizationSelector />}</div>}
      </>
    );
  }

  return (
    <div className={rootClassName}>
      {open && <OrganizationSelector />}
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames('items-center px-2 hidden md:flex cursor-pointer', { ['py-2 w-full justify-center']: !open })}
      >
        {open && <SidebarSimple size={25} className={'opacity-60'} />}
        {!open && <List size={25} className={'opacity-60'} />}
      </div>
    </div>
  );
}
