import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { ManureService, NitrogenApplication } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onDeleted: () => void;
  effectiveNitrogenItem: NitrogenApplication | undefined;
}

function DeleteEffectiveNitrogenModal({ effectiveNitrogenItem, isVisible, onRequestClose, onDeleted }: Props): JSX.Element {
  const [apiError, setApiError] = useState<ApiErrorParser<NitrogenApplication> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const deleteLocation = async () => {
    if (!selectedOrganization || !effectiveNitrogenItem) return;

    try {
      await ManureService.manureFertilizerProducersEffectiveNitrogenDestroy({
        contactOrganisationUid: selectedOrganization.uid,
        uid: effectiveNitrogenItem.uid,
      });

      // close the modal
      onRequestClose();

      // fire the onDeleted callback
      onDeleted();
    } catch (error) {
      setApiError(new ApiErrorParser<NitrogenApplication>(error));
    }
  };

  return (
    <ActionModal
      open={isVisible}
      onClose={onRequestClose}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestClose,
        },
        {
          text: t('delete', 'Delete'),
          variant: ButtonVariant.PrimaryDanger,
          onClick: deleteLocation,
        },
      ]}
      title={t('delete-effective-nitrogen', 'Delete effective nitrogen row')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2 w-full'>{t('delete-effective-nitrogen-hint', 'Are you sure you want to delete this row')}</p>
    </ActionModal>
  );
}

export default DeleteEffectiveNitrogenModal;
