import EquiBoardTile from 'components/Organization/Tiles/EquiBoardTile';
import InfoTile from 'components/Organization/Tiles/InfoTile';
import LogoTile from 'components/Organization/Tiles/LogoTile';
import ManageLocationsTiles from 'components/Organization/Tiles/ManageLocationsTiles';
import { useOrganization } from 'context/OrganizationContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from 'ui/Layout';
import { PageMaxWidth } from 'ui/Layout/Page';
import { LoadingSection } from 'ui/Loading';

/**
 * This page lets the organization admin update the organization info.
 */
export default function OrganizationInfo(): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  return (
    <Page title={t('settings', 'Settings')} maxWidth={PageMaxWidth.Tile}>
      {selectedOrganization?.uid ? (
        <div className='space-y-4'>
          <div className='flex flex-col gap-4 items-stretch lg:flex-row'>
            <InfoTile />
            <LogoTile className='lg:w-[400px]' />
          </div>
          <ManageLocationsTiles />
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <EquiBoardTile />
          </div>
        </div>
      ) : (
        <LoadingSection />
      )}
    </Page>
  );
}
