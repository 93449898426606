import { FertilizerBudgetTypeEnum, FertilizerConsumer } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { ButtonVariant } from 'ui/Button';
import { useParams } from 'react-router-dom';
import AnimalNitrogenForm from './AnimalNitrogenForm';
import TotalNitrogenForm from './TotalNitrogenForm';
import TotalPhosphateForm from './TotalPhosphateForm';

interface Props {
  fertilizerConsumer?: FertilizerConsumer;
  type: FertilizerBudgetTypeEnum;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSaved: () => void;
  onClosed: () => void;
}

function SaveFertilizerConsumerModal({ type, isVisible, onRequestCloseModal, onSaved, onClosed, fertilizerConsumer }: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>();

  const { t } = useTranslation();
  const { year } = useParams();

  const modalTitle = useMemo(() => {
    switch (type) {
      case FertilizerBudgetTypeEnum.ANIMAL_NITROGEN:
        return fertilizerConsumer
          ? t('update-usable-space-for-animal-manure-form-title', 'Update usable space for animal manure {{year}}', { year })
          : t('add-usable-space-for-animal-manure-form-title', 'Add usable space for animal manure {{year}}', { year });
      case FertilizerBudgetTypeEnum.TOTAL_NITROGEN:
        return fertilizerConsumer
          ? t('update-nitrogen-usage-space-form-title', 'Update nitrogen usage space {{year}}', { year })
          : t('add-nitrogen-usage-space-form-title', 'Add nitrogen usage space {{year}}', { year });
      case FertilizerBudgetTypeEnum.TOTAL_PHOSPHATE:
        return fertilizerConsumer
          ? t('update-phosphate-usage-space-form-title', 'Update phosphate usage space {{year}}', { year })
          : t('add-phosphate-usage-space-form-title', 'Add phosphate usage space {{year}}', { year });
      default:
        return '';
    }
  }, [type, fertilizerConsumer, t, year]);

  return (
    <>
      <PageModal open={isVisible} onClosed={onClosed}>
        <PageModalTitle title={modalTitle} onClose={onRequestCloseModal} />
        <PageModalContent>
          {type === FertilizerBudgetTypeEnum.ANIMAL_NITROGEN && (
            <AnimalNitrogenForm
              fertilizerConsumer={fertilizerConsumer}
              onSaved={() => {
                onSaved();
                setIsSubmitting(false);
                onRequestCloseModal();
              }}
              onSave={() => setIsSubmitting(true)}
              onError={() => setIsSubmitting(false)}
            />
          )}

          {type === FertilizerBudgetTypeEnum.TOTAL_NITROGEN && (
            <TotalNitrogenForm
              fertilizerConsumer={fertilizerConsumer}
              onSaved={() => {
                onSaved();
                setIsSubmitting(false);
                onRequestCloseModal();
              }}
              onSave={() => setIsSubmitting(true)}
              onError={() => setIsSubmitting(false)}
            />
          )}

          {type === FertilizerBudgetTypeEnum.TOTAL_PHOSPHATE && (
            <TotalPhosphateForm
              fertilizerConsumer={fertilizerConsumer}
              onSaved={() => {
                onSaved();
                setIsSubmitting(false);
                onRequestCloseModal();
              }}
              onSave={() => setIsSubmitting(true)}
              onError={() => setIsSubmitting(false)}
            />
          )}
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'save-fertilizer-consumer-form',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default SaveFertilizerConsumerModal;
