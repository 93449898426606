import { CaretRight, Stool } from '@phosphor-icons/react';
import { AppRoutes } from 'AppRoutes';
import { useAccount } from 'context/AccountContext';
import { useOrganization } from 'context/OrganizationContext';
import { HorseDetail, HorsesService, StallionMount, StallionmountsService } from 'openapi';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DescriptionList from 'ui/Layout/DescriptionList';
import Page, { navBackToThisPage, PageMaxWidth } from 'ui/Layout/Page';
import { Tile } from 'ui/Layout/Tile';
import { NavLink, generatePath } from 'react-router-dom';
import { ApiPromises } from 'utilities/ApiPromises';
import { table, tableHiddenColumnMd, tableHiddenHeaderMd, tableTbody, tableTbodyTr, tableTheadTd, tableTheadTdSticky } from 'ui/Const';
import classNames from 'classnames';

// A page that shows the details of a specific stallion mount. It also has to
// actions to remove or edit the mount.
export default function StallionMountInventoryDetailsPage(): JSX.Element {
  const { t } = useTranslation();
  const [stallion, setStallion] = useState<HorseDetail>();
  const [mounts, setMounts] = useState<StallionMount[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { selectedOrganization } = useOrganization();
  const { formatDate } = useAccount();
  const { uid } = useParams();
  const navigate = useNavigate();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }
    promises.appendList<StallionMount>(
      'mounts',
      () =>
        StallionmountsService.stallionmountsList({
          stallionOrganisationUid: selectedOrganization.uid,
          stallionUid: uid,
          portionsFreshAvailableGte: 0,
          strawsFrozenAvailableGte: 0,
        }),
      setMounts,
    );

    promises.appendSingle<HorseDetail>(
      'stallion',
      () =>
        HorsesService.horsesRetrieve({
          organisationUid: selectedOrganization?.uid ?? '',
          uid: uid ?? '',
        }),
      setStallion,
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, uid]);

  /**
   * This will return an array of the sum of the portions fresh and straws frozen available.
   */
  const calculatedMount = useMemo(() => {
    if (!mounts) {
      return undefined;
    }

    const mappedMounts: Map<
      string,
      Pick<StallionMount, 'stallion_uid' | 'portions_fresh' | 'portions_fresh_available' | 'straws_frozen' | 'straws_frozen_available'>
    > = new Map();
    for (const mount of mounts) {
      const found = mappedMounts.get(mount.stallion_uid);
      if (found) {
        found.portions_fresh = Number(mount.portions_fresh) + Number(found.portions_fresh);
        found.portions_fresh_available = Number(mount.portions_fresh_available) + Number(found.portions_fresh_available);
        found.straws_frozen = Number(mount.straws_frozen) + Number(found.straws_frozen);
        found.straws_frozen_available = Number(mount.straws_frozen_available) + Number(found.straws_frozen_available);
      } else {
        mappedMounts.set(mount.stallion_uid, {
          stallion_uid: mount.stallion_uid,
          portions_fresh: mount.portions_fresh,
          portions_fresh_available: mount.portions_fresh_available,
          straws_frozen: mount.straws_frozen,
          straws_frozen_available: mount.straws_frozen_available,
        });
      }
    }

    const asArray = Array.from(mappedMounts.values());

    // we are sure that the array has at least one element
    // cause we fetch only the mounts with the uid of the current stallion
    return asArray[0];
  }, [mounts]);

  const breadCrumbs = useMemo(() => [AppRoutes.BreedingInventoryList], []);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, uid]); //eslint-disable-line

  return (
    <Page
      title={`${t('semen-inventory-for', 'Semen inventory for {{stallionName}}', { stallionName: stallion?.name ?? '' })}`}
      breadCrumbs={breadCrumbs}
      maxWidth={PageMaxWidth.Tile}
      loading={apiPromises}
    >
      <div className='space-y-5'>
        <Tile title={t('general', 'General')}>
          <DescriptionList
            list={[
              {
                term: t('stallion', 'Stallion'),
                definition: (
                  <NavLink
                    className='text-blue-500'
                    to={{
                      pathname: generatePath(AppRoutes.HorsesDetails.path, { uid: stallion?.uid ?? '' }),
                      search: navBackToThisPage().toString(),
                    }}
                  >
                    {stallion?.name}
                  </NavLink>
                ),
              },
              {
                term: t('semen-portions-fresh', 'Portions fresh'),
                definition: <>{calculatedMount?.portions_fresh_available ?? 0}</>,
              },
              {
                term: t('semen-straws-frozen', 'Straws frozen'),
                definition: <>{calculatedMount?.straws_frozen_available ?? 0}</>,
              },
            ]}
          />
        </Tile>

        {mounts && mounts.length > 0 && (
          <Tile title={t('semen-inventory-by-mount', 'Semen inventory by mount')}>
            <table className={table}>
              <thead>
                <tr className={tableHiddenHeaderMd}>
                  <td className={classNames('w-10', tableTheadTdSticky)} />
                  <td className={tableTheadTdSticky}>{t('semen-collection-date', 'Collection date')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>
                    {t('semen-concentration-million-per-ml-short', 'Conc. (mil/ml)')}
                  </td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('moving-cells', 'Moving cells')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('semen-portions-fresh', 'Portions fresh')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('semen-straws-frozen', 'Straws frozen')}</td>

                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {(mounts ?? []).map(mount => {
                  let formattedStorage: ReactNode | undefined = undefined;
                  if (mount.storage_canister) {
                    formattedStorage = (
                      <span className='text-gray-500 text-sm'>
                        ({mount.storage_container} {mount.storage_canister && <> / {mount.storage_canister}</>})
                      </span>
                    );
                  }

                  return (
                    <tr
                      className={tableTbodyTr}
                      key={mount.uid}
                      onClick={() => navigate(`${generatePath(AppRoutes.MountDetails.path, { uid: mount.uid })}?${navBackToThisPage()}`)}
                    >
                      <td className='text-center w-10'>
                        <Stool size={24} weight='light' className='inline' />
                      </td>
                      <td className={tableHiddenColumnMd}>
                        {mount.collection_date && formatDate(new Date(Date.parse(mount.collection_date)))}
                      </td>
                      <td className={tableHiddenColumnMd}>{mount.concentration ? mount.concentration : '-'}</td>
                      <td className={tableHiddenColumnMd}>{mount.moving_cells_percentage ? mount.moving_cells_percentage + ' %' : '-'}</td>
                      <td className={tableHiddenColumnMd}>{mount.portions_fresh_available ?? 0}</td>
                      <td className={tableHiddenColumnMd}>
                        {mount.straws_frozen_available ?? 0} {formattedStorage}
                      </td>
                      <td className='md:hidden'>
                        <div className='flex items-center p-2 gap-2'>
                          <div className='flex flex-col grow'>
                            <p className='text-sm'>
                              {t('semen-portions-fresh', 'Portions fresh')}: {mount.portions_fresh_available ?? 0}
                            </p>
                            <p className='text-sm'>
                              {t('semen-straws-frozen', 'Straws frozen')}: {mount.straws_frozen_available ?? 0}
                            </p>
                          </div>

                          <CaretRight size={22} weight='light' className='inline' />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tile>
        )}
      </div>
    </Page>
  );
}
