import { differenceInYears, subYears } from 'date-fns';
import { Contact } from 'openapi';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ButtonDropdownOption } from 'ui/Button/ButtonDropdown';

interface Props {
  contacts: Contact[] | undefined;
}

interface ReturnType {
  searchParams: URLSearchParams;
  stableOptions: ButtonDropdownOption[];
  yearOptions: ButtonDropdownOption[];
  selectedYear: string | undefined;
  selectedStable: Contact | undefined;
}

/**
 * Custom hook that return a list of years and stables to select from
 */
function useManureSelection({ contacts }: Props): ReturnType {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedYear, setSelectedYear] = useState<string>();
  const [selectedStable, setSelectedStable] = useState<Contact>();

  const yearOptions = useMemo((): ButtonDropdownOption[] => {
    const startDate = subYears(new Date(), 10);
    const endDate = new Date();
    const diff = differenceInYears(endDate, startDate);
    const startYear = startDate.getFullYear();
    const years: ButtonDropdownOption[] = [];

    for (let i = 0; i <= diff; i++) {
      const year = `${startYear + i}`;
      years.push({
        value: year,
        name: year,
        onClick: () => {
          setSearchParams(prevState => {
            prevState.set('year', year);
            return prevState;
          });

          setSelectedYear(year);
        },
      });
    }

    return years;
  }, [setSearchParams]);

  const stableOptions = useMemo((): ButtonDropdownOption[] => {
    return (contacts ?? [])
      .filter(contact => contact.stable_location_uid !== null)
      .map(stable => ({
        value: stable.uid,
        name: stable.business_name ?? '',
        onClick: () => {
          setSearchParams(prevState => {
            prevState.set('stable', stable.uid);
            return prevState;
          });
          setSelectedStable(stable);
        },
      }));
  }, [contacts, setSearchParams]);

  /**
   * based on the search params, set the selected year and stable
   */
  useEffect(() => {
    if (searchParams.has('year')) {
      setSelectedYear(searchParams.get('year') as string);
    } else {
      const now = new Date();
      searchParams.set('year', now.getFullYear().toString());
      setSearchParams(prevState => {
        searchParams.set('year', now.getFullYear().toString());
        return prevState;
      });
    }
    if (searchParams.has('stable')) {
      setSelectedStable(contacts?.find(contact => contact.uid === searchParams.get('stable')));
    } else {
      const firstStable = contacts?.find(contact => contact.stable_location_uid !== null);
      if (firstStable) {
        setSearchParams(prevState => {
          searchParams.set('stable', firstStable.uid);
          return prevState;
        });
      }
    }
  }, [contacts, searchParams, setSearchParams]);

  return {
    searchParams,
    stableOptions,
    yearOptions,
    selectedYear,
    selectedStable,
  };
}

export default useManureSelection;
