import React from 'react';
import Tile from '../Tile';
import { Calendar, Cardholder, GenderIntersex, Horse, Palette, Ruler, Tag } from '@phosphor-icons/react';
import { ColorEnum, PublicHorsePage } from 'openapi';
import DetailRow from '../DetailRow';
import { useTranslation } from 'react-i18next';
import ColorWithName from 'ui/ColorWithName';
import { formatDate } from 'utilities/date.utilities';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import { AllColors } from 'utilities/colors';
import { age, gender } from 'utilities/Horse';

interface Props {
  horsePage: PublicHorsePage | undefined;
  loading: boolean;
}

function OverviewTitle({ horsePage, loading }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Tile title={t('overview', 'Overview')}>
      <ul className='mt-4 space-y-4'>
        <DetailRow loading={loading} icon={<Horse />} name={t('name', 'Name')} value={horsePage?.horse.name ?? '-'} />

        {horsePage?.horse.withers_height && (
          <DetailRow
            loading={loading}
            icon={<Ruler />}
            name={t('withers-height', 'Withers height')}
            value={`${horsePage.horse.withers_height}cm`}
          />
        )}

        {horsePage?.horse.color && (
          <DetailRow
            loading={loading}
            icon={<Palette />}
            name={t('color', 'Color')}
            value={<ColorWithName colorId={horsePage.horse.color as ColorEnum} />}
          />
        )}

        {horsePage?.horse.sex && (
          <DetailRow loading={loading} icon={<GenderIntersex />} name={t('gender', 'Gender')} value={gender(horsePage.horse.sex, t)} />
        )}

        {horsePage?.horse.date_of_birth && (
          <DetailRow
            loading={loading}
            icon={<Calendar />}
            name={t('date-of-birth', 'Date of birth')}
            value={
              <>
                {formatDate(new Date(horsePage?.horse.date_of_birth))}
                <p className='inline-block ml-2 text-gray-400 before:content-["("] after:content-[")"]'>{age(horsePage?.horse)}</p>
              </>
            }
          />
        )}

        {(horsePage?.horse.chip_nr || horsePage?.horse.UELN) && (
          <DetailRow
            loading={loading}
            icon={<Tag />}
            name={t('identification', 'Identification')}
            value={
              <div className='flex gap-2'>
                {horsePage?.horse.chip_nr && horsePage.horse.chip_nr !== '' && (
                  <Badge className='flex gap-0.5 items-center' size={BadgeSize.Small} color={AllColors.Orange}>
                    <span className='font-medium'>{t('chip-nr', 'Chip number')}:</span>
                    <span className='font-light'>{horsePage.horse.chip_nr}</span>
                  </Badge>
                )}
                {horsePage?.horse.UELN && horsePage.horse.UELN !== '' && (
                  <Badge className='flex gap-0.5 items-center' size={BadgeSize.Small} color={AllColors.Purple}>
                    <span className='font-medium'>{t('UELN', 'UELN')}:</span>
                    <span className='font-light'>{horsePage.horse.UELN}</span>
                  </Badge>
                )}
              </div>
            }
          />
        )}

        {horsePage?.horse.FEI_pass_nr && (
          <DetailRow
            loading={loading}
            icon={<Cardholder />}
            name={t('fei-pass-nr', 'FEI Pass number')}
            value={horsePage.horse.FEI_pass_nr}
          />
        )}
      </ul>
    </Tile>
  );
}

export default OverviewTitle;
