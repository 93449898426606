import { CloudArrowUp } from '@phosphor-icons/react';
import { HorseMedia, PrivateEnum } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueuedFileUpload, UseHorseHookType } from 'api/hooks/useHorseFiles';
import Button, { ButtonVariant } from 'ui/Button';
import useModal from 'ui/Modals/UseModal';
import { ErrorSection } from 'ui/Error';
import RenameFileModal from './RenameFileModal';
import classNames from 'classnames';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import { IS_MOBILE_APP } from 'const';
import { openFileForMobile } from 'utilities/DownloadFile';
import useFullPageLoader from 'hooks/UseFullPageLoader';
import ListFiles, { ListFile } from './ListFiles';
import DeleteFileModal from './DeleteFileModal';
import ViewFileModal from './ViewFileModal';
import { downloadFile } from 'utilities/Files';

interface Props {
  displayTypes: PrivateEnum[];
  uploadType: PrivateEnum;
  useHorseHookType: UseHorseHookType;
  canUpload: boolean;
}

// A component that shows files for horse. These can be the media files or the medical files.
export default function HorseFiles({ displayTypes, uploadType, useHorseHookType, canUpload }: Props): JSX.Element {
  const { t } = useTranslation();
  const { files, deleteFile, refresh, upload, error, uploading, selectAndUploadFiles } = useHorseHookType;
  const [selectedFile, setSelectedFile] = useState<HorseMedia | undefined>();
  const { modalIsVisible: deleteModalIsVisible, closeModal: closeDeleteModal, showModal: showDeleteModal } = useModal();
  const { modalIsVisible: previewModalIsVisible, closeModal: closePreviewModal, showModal: showPreviewModal } = useModal();
  const { modalIsVisible: renameModalIsVisible, closeModal: closeRenameModal, showModal: showRenameModal } = useModal();
  const [dragDropHover, setDragDropHover] = useState<boolean>(false);

  const { loadingElement: loadingElementMobile, setLoading: setLoadingForMobile } = useFullPageLoader({
    loadingText: t('loading-file', 'Loading file'),
  });

  const visibleFiles = useMemo((): ListFile[] | undefined => {
    return files
      ?.filter(file => file.private && displayTypes.includes(file.private))
      .flatMap<ListFile>(file => ({
        uid: file.uid,
        url: file.file ?? '',
        filename: file.filename,
        description: file.description,
        YTVim: file.YTVim ?? undefined,
        contentType: file.content_type,
      }));
  }, [files, displayTypes]);

  /**
   * Convert all files from a Drag N Drop event into a fileList of File[]
   */
  const fromDragDropEvent = async (items: DataTransferItemList): Promise<QueuedFileUpload[]> => {
    const processedFiles: QueuedFileUpload[] = [];
    const queuedEntries: FileSystemEntry[] = [];

    const add = async (entry: FileSystemEntry | null): Promise<QueuedFileUpload> => {
      // return custom promise as the .file() resolve the file in the callback
      return new Promise<QueuedFileUpload>((resolve, reject) => {
        if (entry && entry.isFile) {
          const correctEntryType = entry as FileSystemFileEntry;
          correctEntryType.file(
            file => {
              resolve({ bytes: file.arrayBuffer(), name: entry.name, contentType: file.type, privacy: uploadType });
            },
            error => reject(error),
          );
        }
      });
    };

    // When calling .file() or .createReader() on the result of webkitGetAsEntry() the next item (and others)
    // will return null from calling webkitGetAsEntry()
    // there for we need to fetch first all results from webkitGetAsEntry() to avoid this issue
    // see https://stackoverflow.com/questions/12105900/fileentry-file-method-makes-datatransferitemlist-empty
    if (items) {
      for (const item of items) {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          queuedEntries.push(entry);
        }
      }
    }

    for (const entry of queuedEntries) {
      if (entry && entry.isFile) {
        // single file
        processedFiles.push(await add(entry as FileSystemFileEntry));
      } else if (entry && entry.isDirectory) {
        // We don't do folders.
        console.error('We do not support uploading folders yet.');
      }
    }
    return processedFiles;
  };

  const openFile = async (fileUid?: string) => {
    const file = files?.find(file => file.uid === fileUid);
    if (!file) {
      console.error('Failed to open file. File not found');
      return;
    }

    // for mobile we are using a native way of opening files
    if (IS_MOBILE_APP) {
      setLoadingForMobile(true);
      try {
        await openFileForMobile(file.file_download_url);
      } catch (e) {
        console.error('Failed to open file for mobile', e);
      } finally {
        setLoadingForMobile(false);
      }
    } else {
      setSelectedFile(file);
      showPreviewModal();
    }
  };

  const onDownloadFile = async (fileUid?: string) => {
    const file = files?.find(file => file.uid === fileUid);
    if (!file) {
      console.error('Failed to open file. File not found');
      return;
    }

    downloadFile(file.file_download_url);
  };

  return (
    <div className='space-y-2'>
      <ErrorSection errors={error} />
      {canUpload && visibleFiles && visibleFiles.length === 0 && (
        <div
          className={classNames(
            'w-full rounded-xl border-2 border-blue-300 border-dashed flex flex-col gap-4 items-center justify-center py-12',
            { 'bg-blue-50': dragDropHover },
            { 'bg-blue-50/40': !dragDropHover },
          )}
          onDragOver={event => event.preventDefault()} // enables it to receive drop events
          onDragEnter={() => setDragDropHover(true)}
          onDragLeave={() => setDragDropHover(false)}
          onDrop={async event => {
            event.preventDefault();
            await upload(await fromDragDropEvent(event.dataTransfer.items));
            setDragDropHover(false);
          }}
        >
          <CloudArrowUp size={52} weight='thin' className='text-blue-300' />
          <Button onClick={selectAndUploadFiles} variant={ButtonVariant.Primary}>
            {t('add-file', 'Add file')}
          </Button>
        </div>
      )}

      {!canUpload && (
        <p className='text-gray-500 italic'>
          {t('no-files-uploaded-and-no-permission-to-upload', 'No files uploaded and you have no permission to upload files.')}
        </p>
      )}

      {visibleFiles && (
        <ListFiles
          files={visibleFiles}
          onOpenFile={openFile}
          onRename={fileUid => {
            const file = files?.find(file => file.uid === fileUid);
            if (!file) {
              console.error('Failed to open file. File not found');
              return;
            }

            setSelectedFile(file);
            showRenameModal();
          }}
          onDelete={fileUid => {
            const file = files?.find(file => file.uid === fileUid);
            if (!file) {
              console.error('Failed to open file. File not found');
              return;
            }

            setSelectedFile(file);
            showDeleteModal();
          }}
        />
      )}

      {uploading && (
        <div className='flex gap-1 items-center text-gray-700'>
          <Spinner size={SpinnerSize.XSmall} />
          <span>{t('uploading', 'Uploading')}...</span>
        </div>
      )}

      <DeleteFileModal
        file={selectedFile}
        deleteFile={deleteFile}
        isVisible={deleteModalIsVisible}
        onDeleted={refresh}
        onRequestCloseModal={() => {
          setSelectedFile(undefined);
          closeDeleteModal();
        }}
      />

      <RenameFileModal
        file={selectedFile}
        visible={renameModalIsVisible}
        onSaved={refresh}
        requestClose={closeRenameModal}
        onClosed={() => setSelectedFile(undefined)}
      />

      <ViewFileModal
        file={
          selectedFile
            ? {
                uid: selectedFile.uid,
                name: selectedFile.filename,
                url: selectedFile.file_download_url,
                contentType: selectedFile.content_type,
              }
            : undefined
        }
        isVisible={previewModalIsVisible}
        downloadFile={onDownloadFile}
        onRequestClose={closePreviewModal}
      />

      {loadingElementMobile}
    </div>
  );
}
