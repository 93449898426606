// Lists the country codes where we have VAT percentages configured for

import { TFunction } from 'i18next';
import { NestedVATPercentage, PaymentStatus, PaymentTypeEnum, VatCategoryEnum, VATPercentage } from 'openapi';
import { AllColors } from 'utilities/colors';

/**
 * Returns a nice human readable string of the vat percentage.
 */
export const vatPercentageToString = (t: TFunction, vatPercentage: VATPercentage | NestedVATPercentage): string => {
  let subText: string | undefined = undefined;
  if (vatPercentage.vat_category === VatCategoryEnum.AE) {
    subText = t('vat-reverse-charge', 'Reverse-charge');
  } else if (vatPercentage.vat_category === VatCategoryEnum.G) {
    subText = t('vat-export', 'Export');
  }
  let res = `${Number(vatPercentage.percentage)}%`;
  if (subText) {
    res += ` (${subText})`;
  }
  return res;
};

export const PaymentStatusToString = (t: TFunction, paymentStatus: PaymentStatus): string => {
  switch (paymentStatus) {
    case PaymentStatus.OPEN:
      return t('open', 'Open');
    case PaymentStatus.PROCESSING:
      return t('processing', 'Processing');
    case PaymentStatus.PAID:
      return t('paid', 'Paid');
  }
};

export const PaymentStatusToColor = (paymentStatus: PaymentStatus): AllColors => {
  switch (paymentStatus) {
    case PaymentStatus.OPEN:
      return AllColors.Amber;
    case PaymentStatus.PROCESSING:
      return AllColors.Amber;
    case PaymentStatus.PAID:
      return AllColors.Green;
  }
};

export const PaymentMethodToString = (t: TFunction, paymentType: PaymentTypeEnum): string => {
  switch (paymentType) {
    case PaymentTypeEnum.BANK:
      return t('payment-bank', 'Bank');
    case PaymentTypeEnum.CASH:
      return t('payment-cash', 'Cash');
    case PaymentTypeEnum.CREDIT_CARD:
      return t('payment-credit-card', 'Credit card');
    case PaymentTypeEnum.PIN:
      return t('payment-pin', 'Pin');
    case PaymentTypeEnum.MOLLIE:
      return 'Mollie';
    case PaymentTypeEnum.PAY_PAL:
      return 'PayPal';
  }
};
