import { cachedPaginatedApiData } from 'api/ApiCache';
import ApiErrorParser from 'api/ApiErrorParser';
import { useHorseDetailContext } from 'context/HorseDetailContext';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import {
  CancelablePromise,
  Contact,
  ContactsService,
  HorseDetail,
  HorseLocation,
  HorselocationsService,
  HorsesService,
  ModulePermissionsEnum,
  PaginatedContactList,
  PaginatedHorseLocationList,
} from 'openapi';
import { Dispatch, SetStateAction, useCallback } from 'react';
import usePermissions from './UsePermissions';

interface ReturnType {
  loadHorseLocations: (selectedOrganizationUid: string, horseUid: string, pageNr?: number) => CancelablePromise<PaginatedHorseLocationList>;
  horseLocations: HorseLocation[] | undefined;
  loadHorse: (selectedOrganizationUid: string, horseUid: string) => CancelablePromise<HorseDetail>;
  horse: HorseDetail | undefined;
  setHorse: Dispatch<SetStateAction<HorseDetail | undefined>>;
  loadContacts: (selectedOrganizationUid: string) => CancelablePromise<PaginatedContactList>;
  contacts: Contact[] | undefined;
}

/**
 * This hook use the useHorseDetailContext and should only be used for our horse detail page
 * It implement the contextApi arround the tiles and include the basic fetch/load functions via this hook
 */
export default function useHorseDetail(): ReturnType {
  const { setHorseLocations, horseLocations, horse, setHorse, setContacts, contacts } = useHorseDetailContext();
  const { generateCacheKey } = useOrganization();
  const { setApiError } = usePage();
  const { hasPermission } = usePermissions();

  /**
   * Load the horse locations
   */
  const loadHorseLocations = useCallback(
    (selectedOrganizationUid: string, horseUid: string, pageNr = 1): CancelablePromise<PaginatedHorseLocationList> => {
      const promise = HorselocationsService.horselocationsList({
        locationOrganisationUid: selectedOrganizationUid,
        page: pageNr,
        // load 10 items, we do not want to overload the page
        // Also, 10 may be enough for 99% of the horses
        // We use 11 as that is the trigger for us to know that we have more items
        pageSize: 11,
        horseUid: horseUid,
        // filter on the arrival date but also filter on the departure_date as we could have a move on the same day
        o: '-arrival_date,departure_date',
      });

      cachedPaginatedApiData<HorseLocation>(generateCacheKey('horseLocation'), promise, setHorseLocations);
      return promise;
    },
    [generateCacheKey, setHorseLocations],
  );

  /**
   * Load the horse from api
   */
  const loadHorse = useCallback(
    (selectedOrganizationUid: string, horseUid: string): CancelablePromise<HorseDetail> => {
      const promise = HorsesService.horsesRetrieve({
        organisationUid: selectedOrganizationUid,
        uid: horseUid,
      });
      promise
        .then(result => setHorse(result))
        .catch(error => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<HorseDetail>(error));
          }
        });
      return promise;
    },
    [setApiError, setHorse],
  );

  /**
   * Load the contacts
   */
  const loadContacts = useCallback(
    (selectedOrganizationUid: string): CancelablePromise<PaginatedContactList> => {
      if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
        const promise = ContactsService.contactsList({ organisationUid: selectedOrganizationUid });
        cachedPaginatedApiData<Contact>(generateCacheKey('contacts'), promise, setContacts);
        return promise;
      } else {
        // Note: We use the contacts endpoint within the horse service.
        const promise = HorsesService.horsesContactsList({ organisationUid: selectedOrganizationUid });
        cachedPaginatedApiData<Contact>(generateCacheKey('horse-contacts'), promise, setContacts);
        return promise;
      }
    },
    [generateCacheKey, setContacts, hasPermission],
  );

  return {
    loadHorseLocations,
    horseLocations,
    horse,
    loadHorse,
    setHorse,
    loadContacts,
    contacts,
  };
}
