import { AveragedHistoricHorseCount, FertilizerNorm, FertilizerNormTypeEnum, ManureService, PublicService } from 'openapi';
import { useCallback, useMemo, useState } from 'react';
import { ApiPromiseErrors, ApiPromises } from 'utilities/ApiPromises';
import useApiPromises from 'api/hooks/useApiPromises';
import { useOrganization } from 'context/OrganizationContext';
import { useTranslation } from 'react-i18next';

interface CalculatedTotals {
  horse: {
    phosphate: number;
    nitrogen: number;
  };
  pony: {
    phosphate: number;
    nitrogen: number;
  };
  total: {
    phosphate: number;
    nitrogen: number;
  };
}
interface ReturnType {
  loadApiData: () => ApiPromises;
  loading: boolean;
  error: ApiPromiseErrors | undefined;
  norms: FertilizerNorm[] | undefined;
  horseCount: AveragedHistoricHorseCount | undefined;
  typeToString: (type: FertilizerNormTypeEnum) => string;
  totals: CalculatedTotals;
}

interface Props {
  year: string | undefined;
  stableContactUid: string | undefined;
}

/**
 * Custom hook that fetches the horse manure data and calculates the totals
 */
function useHorseManure({ year, stableContactUid }: Props): ReturnType {
  const [norms, setNorms] = useState<FertilizerNorm[]>();
  const [horseCount, setHorseCount] = useState<AveragedHistoricHorseCount>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { loading, apiPromisesError } = useApiPromises({ apiPromises, catchErrors: true });
  const { selectedOrganizationUid } = useOrganization();
  const { t } = useTranslation();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid || !stableContactUid || !year) {
      return promises;
    }

    promises.appendList<FertilizerNorm>(
      'fertilizer-norm',
      () => PublicService.apiV5AdministrationManureFertilizerNormsList({ year }),
      setNorms,
    );

    promises.appendSingle<AveragedHistoricHorseCount>(
      'averaged-historic-horse-count',
      () =>
        ManureService.manureStableHistoricHorseCountContactRetrieve({
          organisationUid: selectedOrganizationUid,
          uid: stableContactUid,
          year: String(year),
        }),
      setHorseCount,
    );

    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, stableContactUid, year]);

  /**
   * Calculate the totals of both the horses and ponies
   */
  const totals = useMemo((): CalculatedTotals => {
    const horseNorm = norms?.find(norm => norm.type === FertilizerNormTypeEnum.HORSE);
    const ponyNorm = norms?.find(norm => norm.type === FertilizerNormTypeEnum.PONY);

    const totalHorseNitrogen = Math.round(Number(horseCount?.average.horse_count ?? 0) * Number(horseNorm?.nitrogen_kg ?? 0));
    const totalHorsePhospate = Math.round(Number(horseCount?.average.horse_count ?? 0) * Number(horseNorm?.phosphate_kg ?? 0));
    const totalPonyNitrogen = Math.round(Number(horseCount?.average.pony_count ?? 0) * Number(ponyNorm?.nitrogen_kg ?? 0));
    const totalPonyPhospate = Math.round(Number(horseCount?.average.pony_count ?? 0) * Number(ponyNorm?.phosphate_kg ?? 0));

    return {
      horse: {
        nitrogen: totalHorseNitrogen,
        phosphate: totalHorsePhospate,
      },
      pony: {
        nitrogen: totalPonyNitrogen,
        phosphate: totalPonyPhospate,
      },
      total: {
        nitrogen: totalHorseNitrogen + totalPonyNitrogen,
        phosphate: totalHorsePhospate + totalPonyPhospate,
      },
    };
  }, [horseCount?.average.horse_count, horseCount?.average.pony_count, norms]);

  /**
   * Convert the type to a string
   */
  const typeToString = (type: FertilizerNormTypeEnum): string => {
    switch (type) {
      case FertilizerNormTypeEnum.HORSE:
        return t('horse', 'Horse');
      case FertilizerNormTypeEnum.PONY:
        return t('Ponies-and-foals-up-to', 'Ponies and foals (up to 1.56m / 6 months old)');
      default:
        return '<unknown>';
    }
  };

  return {
    loadApiData,
    loading,
    error: apiPromisesError,
    norms,
    horseCount,
    typeToString,
    totals,
  };
}

export default useHorseManure;
