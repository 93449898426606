import { Barn, Calendar } from '@phosphor-icons/react';
import { AppRoutes } from 'AppRoutes';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import useHorseManure from 'hooks/UseHorseManure';
import useManureSelection from 'hooks/UseManureSelection';
import { Contact, ContactsService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import ButtonDropdown from 'ui/Button/ButtonDropdown';
import { table, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import Page, { PageMaxWidth } from 'ui/Layout/Page';
import Skeleton from 'ui/Layout/Skeleton';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { months } from 'utilities/date.utilities';

function ManureHorseSummaryPage(): JSX.Element {
  const [contacts, setContacts] = useState<Contact[]>();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  const { selectedStable, selectedYear, stableOptions, yearOptions } = useManureSelection({ contacts });
  const { t } = useTranslation();
  const { selectedOrganizationUid } = useOrganization();
  const {
    loadApiData: loadHorseCountData,
    horseCount,
    norms,
    typeToString,
    loading,
    totals,
  } = useHorseManure({ stableContactUid: selectedStable?.uid, year: selectedYear });

  /**
   * Set the breadcrumbs with the stable and year in the path
   */
  const breadCrumbs = useMemo(() => {
    return [
      {
        name: AppRoutes.Manure.name,
        path: `${generatePath(AppRoutes.Manure.path)}?stable=${selectedStable?.uid}&year=${selectedYear}`,
      },
    ];
  }, [selectedStable, selectedYear]);

  /**
   * Return a list of the months
   */
  const getMonthName = (index: number) => {
    const month = months(t).at(index - 1);
    if (!month) {
      return '-';
    }

    return month;
  };

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }

    promises.appendListObj<Contact>('contacts', setContacts, {
      promiseCallback: () => {
        return ContactsService.contactsList({
          organisationUid: selectedOrganizationUid,
          hidden: false,
          isUser: false,
        });
      },
    });

    setApiPromises(promises);

    return promises;
  }, [selectedOrganizationUid]);

  /**
   * Load from the api
   */
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  useEffect(() => {
    if (selectedOrganizationUid && selectedYear) {
      loadHorseCountData();
    }
  }, [loadHorseCountData, selectedOrganizationUid, selectedYear]);

  return (
    <Page
      title={t('manure-horse-summary-year', 'Manure horse summary {{year}}', { year: selectedYear })}
      maxWidth={PageMaxWidth.Tile}
      loading={apiPromises}
      breadCrumbs={breadCrumbs}
    >
      <div className='flex gap-x-3 mb-4 px-2 md:px-0'>
        <ButtonDropdown
          selectedOption={selectedStable?.uid}
          options={stableOptions}
          showCaretDown={true}
          icon={<Barn />}
          showIconOnlyOnMobile={false}
        >
          {selectedStable ? (selectedStable.business_name ?? '') : t('stable', 'Stable')}
        </ButtonDropdown>
        <ButtonDropdown
          selectedOption={selectedStable?.uid}
          options={yearOptions}
          showCaretDown={true}
          icon={<Calendar />}
          showIconOnlyOnMobile={false}
        >
          {selectedYear ?? t('year', 'Year')}
        </ButtonDropdown>
      </div>

      <div className='space-y-4'>
        <Tile title={t('summary', 'Summary')}>
          <p>
            {t(
              'manuare-horse-summary-desc',
              'Below you see the summary for all horses that where known to EquineM with their calculated nitrogen and phostphate.',
            )}
          </p>
          <table className={table}>
            <thead className={tableThead}>
              <tr>
                <th className={tableTheadTd} />
                <th className={tableTheadTd}>{t('nitrogen', 'Nitrogen')}</th>
                <th className={tableTheadTd}>{t('phosphate', 'Phosphate')}</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-36' />
                  </td>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-10' />
                  </td>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-10' />
                  </td>
                </tr>
              )}
              {!loading && (
                <>
                  <tr className={tableTbodyTrNoClick}>
                    <td className={tableTheadTd}>{t('horses', 'Horses')}</td>
                    <td className={tableTheadTd}>
                      {totals.horse.nitrogen} {'kg'}
                    </td>
                    <td className={tableTheadTd}>
                      {' '}
                      {totals.horse.phosphate} {'kg'}
                    </td>
                  </tr>

                  <tr className={tableTbodyTrNoClick}>
                    <td className={tableTheadTd}>{t('ponies-and-foals', 'Ponies and foals')}</td>
                    <td className={tableTheadTd}>
                      {totals.pony.phosphate} {'kg'}
                    </td>
                    <td className={tableTheadTd}>
                      {' '}
                      {totals.pony.nitrogen} {'kg'}
                    </td>
                  </tr>

                  <tr key='summary' className={tableTbodyTrNoClick}>
                    <td className={classNames('font-bold', tableTheadTd)}>{t('total', 'Total')}</td>
                    <td className={classNames('font-bold', tableTheadTd)}>
                      {totals.total.nitrogen} {'kg'}
                    </td>
                    <td className={classNames('font-bold', tableTheadTd)}>
                      {totals.total.phosphate} {'kg'}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </Tile>

        <Tile title={t('norm', 'Norm')}>
          <p>
            {t('manuare-horse-summary-norm-desc', 'Each year can have a different norm. Below you see the norm for the selected year.')}
          </p>
          <table className={table}>
            <thead className={tableThead}>
              <tr>
                <th className={tableTheadTd}>{t('animal-species-and-category', 'Animal species and category')}</th>
                <th className={tableTheadTd}>{t('nitrogen', 'Nitrogen')}</th>
                <th className={tableTheadTd}>{t('phosphate', 'Phosphate')}</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-36' />
                  </td>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-10' />
                  </td>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-10' />
                  </td>
                </tr>
              )}
              {!loading && (
                <>
                  {norms?.map(norm => (
                    <tr key={norm.type} className={tableTbodyTrNoClick}>
                      <td className={tableTheadTd}>{typeToString(norm.type)}</td>
                      <td className={tableTheadTd}>
                        {norm.nitrogen_kg} {'kg'}
                      </td>
                      <td className={tableTheadTd}>
                        {norm.phosphate_kg} {'kg'}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Tile>

        <Tile title={t('number-of-animals-present', 'Number of animals present')}>
          <p>{t('number-of-animals-present-desc', 'Below you see a detailled table view per month per horse or pony.')}</p>
          <table className={table}>
            <thead className={tableThead}>
              <tr>
                <th className={tableTheadTd}>{t('month', 'Month')}</th>
                <th className={tableTheadTd}>{t('ponies-and-foals', 'Ponies and foals')}</th>
                <th className={tableTheadTd}>{t('horses', 'Horses')}</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-36' />
                  </td>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-10' />
                  </td>
                  <td className={tableTheadTd}>
                    <Skeleton className='h-6 w-10' />
                  </td>
                </tr>
              )}
              {!loading && (
                <>
                  {horseCount?.samples.map(sample => (
                    <tr key={sample.month} className={tableTbodyTrNoClick}>
                      <td className={tableTheadTd}>{getMonthName(sample.month)}</td>
                      <td className={tableTheadTd}>{Number(sample.pony_count ?? 0)}</td>
                      <td className={tableTheadTd}>{Number(sample.horse_count ?? 0)}</td>
                    </tr>
                  ))}
                  <tr key='summary' className={tableTbodyTrNoClick}>
                    <td className={classNames('font-bold', tableTheadTd)}>
                      {t('average-number-over-the-year', 'Average number over the year')}
                    </td>
                    <td className={classNames('font-bold', tableTheadTd)}>{Number(horseCount?.average.pony_count ?? 0)}</td>
                    <td className={classNames('font-bold', tableTheadTd)}>{Number(horseCount?.average.horse_count ?? 0)}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </Tile>
      </div>
    </Page>
  );
}

export default ManureHorseSummaryPage;
