import { zodResolver } from '@hookform/resolvers/zod';
import {
  CancelablePromise,
  Contact,
  HorseDetail,
  HorseMove,
  HorsesService,
  CountryEnum,
  ServiceContact,
  ServicecontactsService,
} from 'openapi';
import useFormError from 'api/hooks/useFormError';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { DateInput, SelectInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { transformEmptyNumber, zodInputIsRequired } from 'utilities/zod';
import { ButtonVariant } from 'ui/Button';
import { useOrganization } from 'context/OrganizationContext';
import { getHorseDepartueReasonsList } from 'utilities/Horse';
import classNames from 'classnames';
import TogggleInput from 'ui/Inputs/ToggleInput';
import ApiErrorParser from 'api/ApiErrorParser';
import RadioButtonGroup from 'ui/Inputs/RadioGroupInput';
import { z } from 'zod';
import { cachedApiData } from 'api/ApiCache';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import useModal from 'ui/Modals/UseModal';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';
import RvoConfirmReportModal from 'components/Horses/RvoConfirmReportModal';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import { MapPinArea } from '@phosphor-icons/react';

interface Props {
  horse: HorseDetail;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  contacts: Contact[] | undefined;
  onMoved?: () => void;
  onContactSaved: () => void;
}

interface HorseMoveModel extends HorseMove {
  move_to_unknown_location: boolean;
}

function MoveHorseModal({ horse, isVisible, onRequestCloseModal, contacts, onMoved, onContactSaved }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>();
  const [serviceContacts, setServiceContacts] = useState<ServiceContact[]>();
  const [destinationUidFormValue, setDestinationUidFormValue] = useState<string | null | undefined>();
  const [moveToUnknownLocationFormValue, setMoveToUnkownLocationFormValue] = useState<boolean>(false);
  const [fieldSetInactiveIsVisible, setFieldSetInactiveIsVisible] = useState<boolean>(false);

  const { selectedOrganizationUid, selectedOrganizationDetails, generateCacheKey } = useOrganization();
  const { closeModal: closeConfirmModal, modalIsVisible: confirmModalIsVisible, showModal: showConfirmModal } = useModal();
  const { t } = useTranslation();
  const { loadReportCount } = useRvoReportCount();
  const navigate = useNavigate();

  // Form validation
  const schema = useMemo(() => {
    const baseScheme = schemas.HorseLocationMove.omit({
      departure_is_dead: true,
      restart_services: true,
      origin_uid: true,
      set_active: true,
    });

    // We want to force the user to make a choice
    const updatedScheme = baseScheme.merge(
      z.object({
        destination_uid: moveToUnknownLocationFormValue ? z.string().optional() : z.string(),
      }),
    );

    if (fieldSetInactiveIsVisible) {
      return updatedScheme.merge(
        // we override the set_inactive field as we do need user input
        z.object({
          set_inactive: z.boolean(),
        }),
      );
    }

    return updatedScheme;
  }, [destinationUidFormValue, moveToUnknownLocationFormValue, fieldSetInactiveIsVisible]); // eslint-disable-line

  // Construct the default values
  const defaultValues = useMemo((): Partial<HorseMoveModel> => {
    return {
      date: new Date().toISOString().substring(0, 10),
      stop_services: false,
      set_inactive: undefined,
      move_to_unknown_location: false,
    };
  }, []);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    watch,
    setValue,
  } = useForm<HorseMoveModel>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  // Watch the value destination_uid and assign it to a state so we can react on it
  useEffect(() => {
    const subscription = watch(({ destination_uid, move_to_unknown_location }, { name }) => {
      if (name === 'destination_uid') {
        setDestinationUidFormValue(destination_uid);
      }

      if (name === 'move_to_unknown_location' && typeof move_to_unknown_location === 'boolean') {
        setMoveToUnkownLocationFormValue(move_to_unknown_location);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // Set the destinations variable
  const destinationContact = contacts?.find(contact => contact.uid === destinationUidFormValue);
  const destinationIsStable =
    !!destinationContact && typeof destinationContact.stable_location_uid === 'string' && destinationContact.stable_location_uid !== '';
  const destinationIsMyLocation = !!destinationContact && destinationContact.external_location;

  // Get the current location contact
  const currentLocation = contacts?.find(contact => contact.uid === horse?.current_horselocation?.location_uid);

  // Visibility fields
  // See https://gitlab.qubis.nl/equinem/equinemcore/-/wikis/Horse/Move-field-information
  const fieldStopServicesIsVisible =
    !selectedOrganizationDetails?.is_bp && !!serviceContacts?.find(contact => contact.horse_uid === horse?.uid);
  const fieldIsExportedIsVisible = destinationUidFormValue && !destinationContact && currentLocation?.country === CountryEnum.NL;
  const fieldIsImportedIsVisible = destinationUidFormValue && !destinationContact && currentLocation?.country !== CountryEnum.NL;
  const setInactiveFormValue = watch('set_inactive');

  /**
   * Visibility field for field inActive
   * This is a state as we need to update the ZOD validation rules based on this value
   *
   * See https://gitlab.qubis.nl/equinem/equinemcore/-/wikis/Horse/Move-field-information
   */
  useEffect(() => {
    setFieldSetInactiveIsVisible(!destinationIsStable && !destinationIsMyLocation);
  }, [destinationIsMyLocation, destinationIsStable]);

  /**
   * Close event for the modal
   */
  const onClose = (resetForm = true) => {
    onRequestCloseModal();

    if (resetForm) {
      reset(defaultValues);
    }
  };

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    reset(defaultValues);
    setMoveToUnkownLocationFormValue(false);
    setDestinationUidFormValue(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorseMoveModel> = async (data: HorseMoveModel) => {
    if (!selectedOrganizationUid) return;
    setSubmitting(true);

    // as we set a custom validation for destination_uid
    // we can receive a '--' value. This is an unknown locations and should be
    //converted to undefined
    data.destination_uid = data.destination_uid === '--' ? undefined : data.destination_uid;

    try {
      const promise = HorsesService.horsesMoveCreate({
        organisationUid: selectedOrganizationUid,
        uid: horse.uid,
        requestBody: data,
      });

      await promise;

      // close the modal
      onRequestCloseModal();

      // fire event
      onMoved?.();

      // update the report count
      loadReportCount();

      // show the cofirm modal when we have mutations
      if (destinationContact?.machtiging_rvo_gegeven || currentLocation?.machtiging_rvo_gegeven) {
        showConfirmModal();
      }
    } catch (error) {
      setApiError(new ApiErrorParser<HorseDetail>(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Load the service Contacts
   */
  const loadServiceContact = useCallback((): CancelablePromise<ServiceContact[]> => {
    const promise = ServicecontactsService.servicecontactsList({
      customerOrganisationUid: selectedOrganizationUid ?? '',
    });
    promise.catch(e => {
      if (!promise.isCancelled) {
        setApiError(new ApiErrorParser<ServiceContact>(e));
      }
    });
    cachedApiData<ServiceContact>(generateCacheKey('serviceContact'), promise, setServiceContacts);
    return promise;
  }, [selectedOrganizationUid, generateCacheKey, setApiError]);

  /**
   * We need to load the serviceContacts on load
   */
  useEffect(() => {
    const promise = loadServiceContact();
    return () => promise.cancel();
  }, [loadServiceContact]);

  /**
   * Filter the contacts and split into 2 groups
   * 1. My locations
   * 2. other contacts
   */
  const filteredContacts = useMemo((): WrappedComboboxProps<Contact>[] => {
    const filtered = (contacts ?? []).filter(contact => horse?.current_horselocation?.location_uid !== contact.uid);

    return [
      {
        heading: t('my-locations', 'My locations'),
        items: filtered.filter(contact => contact.external_location || contact.stable_location_uid),
        notFoundLabel: t('no-locations-found', 'No locations found'),
        icon: <MapPinArea />,
      },
      {
        heading: t('contacts', 'Contacts'),
        items: filtered.filter(contact => !contact.external_location && !contact.stable_location_uid),
        notFoundLabel: t('no-contacts-found', 'No contacts found'),
      },
    ];
  }, [contacts, horse?.current_horselocation?.location_uid, t]);

  /**
   * When we move to an unknown location, we need to clear the destination_uid
   * otherwise it can be set if the user first set the destination and then
   * set the move_to_unknown_location to true
   */
  useEffect(() => {
    if (moveToUnknownLocationFormValue) {
      setValue('destination_uid', undefined);
    }
  }, [moveToUnknownLocationFormValue, setValue]);

  return (
    <>
      <PageModal
        open={isVisible}
        parentElement='form'
        parentProps={{ id: 'updateHorse', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
        onClosed={onClosed}
      >
        <PageModalTitle title={t('move-horse', 'Move horse')} onClose={onRequestCloseModal} />
        <PageModalContent>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='space-y-4'>
            <RadioButtonGroup
              name='move_to_unknown_location'
              control={control}
              options={[
                { id: true, name: t('yes', 'Yes') },
                { id: false, name: t('no', 'No') },
              ]}
              error={fieldError('move_to_unknown_location')}
              label={t('move-to-unknown-location', 'Move to an unknown location')}
              hint={t(
                'move-to-unknown-location-desc',
                'When you dont know the destination location, you can set this to option to yes, so we mark the new horse location as unknown',
              )}
            />

            <ContactInputSelect
              className={classNames({
                hidden: moveToUnknownLocationFormValue,
              })}
              name='destination_uid'
              control={control}
              contacts={filteredContacts}
              onCreated={contact => {
                onContactSaved(); // Update the list of contacts.
                setValue('destination_uid', contact.uid);
              }}
              label={t('destination', 'Destination')}
              error={fieldError('destination_uid')}
              required={zodInputIsRequired<HorseMoveModel>(schema, 'destination_uid')}
            />

            <DateInput
              control={control}
              error={fieldError('date')}
              required={zodInputIsRequired<HorseMoveModel>(schema, 'date')}
              label={t('departure-date', 'Departure date')}
              name='date'
            />

            <TogggleInput
              className={classNames({
                hidden: !fieldStopServicesIsVisible,
              })}
              name='stop_services'
              control={control}
              error={fieldError('stop_services')}
              label={t('end-invoicing-services', 'End invoicing services')}
              hint={t('end-invoicing-services-desc', 'Stop the invoicing service for the ongoing service contact')}
            />

            <RadioButtonGroup
              className={classNames({
                hidden: !fieldSetInactiveIsVisible,
              })}
              name='set_inactive'
              control={control}
              options={[
                { id: true, name: t('yes', 'Yes') },
                { id: false, name: t('no', 'No') },
              ]}
              error={fieldError('set_inactive')}
              label={t('make-horse-in-active', 'Make the horse inactive')}
              hint={t('make-horse-in-active-desc', 'You can set your horse as inactive when you move your Horse to a non-stable location.')}
              required={zodInputIsRequired<HorseMoveModel>(schema, 'set_inactive')}
            />

            <SelectInput
              className={classNames({
                hidden: !setInactiveFormValue,
              })}
              error={fieldError('departure_reason')}
              required={zodInputIsRequired<HorseMoveModel>(schema, 'departure_reason')}
              nullable={true}
              nullableValue=''
              options={getHorseDepartueReasonsList(selectedOrganizationDetails)}
              label={t('departure-reason', 'Reason')}
              hint={t('departure-reason-desc', 'Select the reason why the horse is leaving')}
              {...register('departure_reason', { setValueAs: transformEmptyNumber(undefined) })}
            />

            <TogggleInput
              className={classNames({
                hidden: !fieldIsExportedIsVisible,
              })}
              name='departure_is_export'
              control={control}
              error={fieldError('departure_is_export')}
              label={t('is-exported', 'Is exported')}
              hint={t('is-exported-desc', 'Mark the horse as exported if you move it to an unknown location abroad')}
            />

            <TogggleInput
              className={classNames({
                hidden: !fieldIsImportedIsVisible,
              })}
              name='arrival_is_import'
              control={control}
              error={fieldError('arrival_is_import')}
              label={t('is-imported', 'Is imported')}
              hint={t('is-imported-desc', 'Mark the horse as imported')}
            />
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: submitting,
              onClick: onClose,
              variant: ButtonVariant.Default,
              type: 'button',
              text: t('cancel', 'Cancel'),
            },
            {
              formId: 'updateHorse',
              loading: submitting,
              variant: ButtonVariant.Primary,
              type: 'submit',
              text: t('save', 'Save'),
            },
          ]}
        />
      </PageModal>

      <RvoConfirmReportModal
        isVisible={confirmModalIsVisible}
        onReport={() => navigate(generatePath(`${AppRoutes.HorsesRvoList.path}?horse=${horse.uid}`))}
        onRequestClose={closeConfirmModal}
        successMessage={t('horse-successfull-moved-desc', 'The horse has been successfully moved to the new location')}
      />
    </>
  );
}

export default MoveHorseModal;
