import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';

interface Props {
  loadingText?: string;
}

interface ReturnType {
  setLoading: Dispatch<SetStateAction<boolean>>;
  loadingElement: JSX.Element;
}

/**
 * This is a full overlay loader that will be displayed when the loading state is true
 * with a simple spinner and a text
 */
function useFullPageLoader({ loadingText }: Props): ReturnType {
  const [loading, setLoading] = useState(false);

  const animateClass = loading ? 'animate-fadeIn' : 'animate-fadeOut';

  const { t } = useTranslation();

  return {
    loadingElement: (
      <Portal node={document && document.getElementById('root')}>
        {loading && (
          <div
            className={classNames(
              'fixed z-50 animate-faster bg-black/60 h-screen w-screen top-0 right-0 flex justify-center items-center',
              {
                [animateClass]: loading,
              },
            )}
          >
            <span className='text-white flex gap-x-2 items-center'>
              <Spinner size={SpinnerSize.XSmall} /> {loadingText ?? t('loading', 'Loading')}
            </span>
          </div>
        )}
      </Portal>
    ),
    setLoading,
  };
}

export default useFullPageLoader;
