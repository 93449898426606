import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { FertilizerBudgetTypeEnum, FertilizerConsumer, ManureService } from 'openapi';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import { schemas } from 'openapi/zod-schemas';
import useFormError from 'api/hooks/useFormError';
import { transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import { useParams } from 'react-router-dom';
import TogggleInput from 'ui/Inputs/ToggleInput';

interface Props {
  onSave: () => void;
  onError: () => void;
  onSaved: (fertilizerConsumer: FertilizerConsumer) => void;
  fertilizerConsumer: FertilizerConsumer | undefined;
}

const schema = schemas.FertilizerConsumer.pick({
  description: true,
  is_follow_up_crop: true,
  has_derogation: true,
  area: true,
  consumption_norm: true,
}).required();

/**
 * The form to save the total nitrogen for non-animal
 */
function TotalNitrogenForm({ onSave, onError, onSaved, fertilizerConsumer }: Props): JSX.Element {
  const { selectedOrganizationUid } = useOrganization();
  const { t } = useTranslation();
  const { year, stableContactUid } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    control,
  } = useForm<FertilizerConsumer>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      has_derogation: false,
      is_follow_up_crop: false,
    },
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Submit handler
   */
  const onSubmit = async (data: FertilizerConsumer) => {
    if (!selectedOrganizationUid) return console.error('selectedOrganization is not defined');

    // fire the onSave event
    onSave();

    try {
      let savedFertilizerConsumer: FertilizerConsumer;
      const requestBody: FertilizerConsumer = {
        ...data,
        year: Number(year),
        contact_uid: String(stableContactUid),
        fertilizer_budget_type: FertilizerBudgetTypeEnum.TOTAL_NITROGEN,
      };

      // determine if we are creating or updating
      if (fertilizerConsumer) {
        const promiseUpdate = ManureService.manureFertilizerConsumersUpdate({
          contactOrganisationUid: selectedOrganizationUid,
          uid: fertilizerConsumer.uid,
          requestBody,
        });
        savedFertilizerConsumer = await promiseUpdate;
      } else {
        const promiseCreate = ManureService.manureFertilizerConsumersCreate({
          contactOrganisationUid: selectedOrganizationUid,
          requestBody,
        });
        savedFertilizerConsumer = await promiseCreate;
      }

      // fire the onSaved event
      onSaved(savedFertilizerConsumer);

      // clear states
      setApiError(undefined);
      clearErrors();
      reset();
    } catch (error) {
      // fire the onError event
      onError();
      setApiError(new ApiErrorParser<FertilizerConsumer>(error));
    }
  };

  /**
   * Set the values when the fertilizerConsumer is set
   */
  useEffect(() => {
    reset(fertilizerConsumer);
  }, [fertilizerConsumer, reset]);

  return (
    <form noValidate={true} id='save-fertilizer-consumer-form' onSubmit={handleSubmit(onSubmit)}>
      <ErrorSection errors={nonFieldErrors} />
      <div className='py-3 space-y-3'>
        <TextInput
          error={fieldError('area')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'area')}
          label={t('area', 'Area')}
          postText='ha'
          type='number'
          hint={t('area-hint', 'The area in hectares')}
          {...register('area', { valueAsNumber: true })}
        />

        <TextInput
          error={fieldError('description')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'description')}
          label={t('crop-name', 'Crop name')}
          hint={t('description-hint', 'Enter the name of your crop that you have planted on this location.')}
          {...register('description', { setValueAs: transformEmptyToUndefined() })}
        />

        <TextInput
          error={fieldError('consumption_norm')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'consumption_norm')}
          label={t('consumption_norm', 'Consumption norm')}
          hint={t('consumption-norm-hint', 'Enter the consumption norm in KG per hectare.')}
          postText='kg/ha'
          {...register('consumption_norm', { valueAsNumber: true })}
        />

        <TogggleInput
          name='is_follow_up_crop'
          control={control}
          error={fieldError('is_follow_up_crop')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'is_follow_up_crop')}
          label={t('is-follow-up-crop', 'Is this a follow up crop?')}
          hint={t('is-follow-up-crop-hint', 'Mark this when this crop is the second crop in a year on the same field.')}
        />

        <TogggleInput
          name='has_derogation'
          control={control}
          error={fieldError('has_derogation')}
          required={zodInputIsRequired<FertilizerConsumer>(schema, 'has_derogation')}
          label={t('has_derogation', 'Has derogation applied?')}
          hint={t(
            'has-derogation-hint',
            'When derogation is applied, the consumption norm can be different based on the province. Check the official RVO document for the correct norm.',
          )}
        />
      </div>
    </form>
  );
}

export default TotalNitrogenForm;
