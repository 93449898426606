import { TFunction } from 'i18next';
import {
  CatalogueProduct,
  Category,
  CountryEnum,
  Horse,
  Product,
  PurchaserOrder,
  SemenTypeEnum,
  SexEnum,
  ShippingServiceTypeEnum,
  SupplierOrder,
  UsageTypeEnum,
} from 'openapi';
import { RadioButtonGroupOption } from 'ui/Inputs/RadioGroupInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';

export interface Totals {
  currency: string;
  subTotal: number;
  vatTotal: number;
  total: number;
}

// Create a RadioOptionItem list of Semen Type options for a semen order.
export const SemenTypeOptions = (t: TFunction): RadioButtonGroupOption[] => {
  const options: RadioButtonGroupOption[] = [];
  Object.values(SemenTypeEnum).forEach(key => {
    let text = key.toString();
    switch (key) {
      case SemenTypeEnum.FRESH:
        text = t('semen-fresh', 'Fresh');
        break;
      case SemenTypeEnum.FROZEN:
        text = t('semen-frozen', 'Frozen');
        break;
    }
    options.push({ id: key, name: text });
  });
  return options;
};

// Create an OptionItem list of Usage type options for a semen order.
export const SemenUsageTypeOptions = (t: TFunction): OptionItemInterface[] => {
  const options: OptionItemInterface[] = [];
  Object.values(UsageTypeEnum).forEach(key => {
    const name = SemenUsageToString(t, key);
    if (name) {
      options.push({ id: key, name });
    }
  });
  return options;
};

export const SemenUsageToString = (t: TFunction, usageType: UsageTypeEnum): string | undefined => {
  switch (usageType) {
    case UsageTypeEnum.KI:
      return t('breeding-ai', 'Artificial Insemination (AI) / self-pregnancy');
    case UsageTypeEnum.ET:
      return t('breeding-et', 'Embryo Transfer (ET)');
    case UsageTypeEnum.ICSI:
      return t('breeding-icsi', 'Intra Cytoplasmic Sperm Injection (ICSI)');
  }
};

// Create an OptionItem list of breeding products (stallions) with a price.
export const BreedingProductOptions = (
  t: TFunction,
  horses: Horse[],
  products: Product[],
  productCategories: Category[],
  countryCode?: string,
  defaultCurrency?: string,
): OptionItemInterface[] => {
  const productName = (product: Product) => {
    const stallionName = horses.find(horse => horse.uid === product.stallion_uid)?.name ?? t('unknown-stallion', 'Unknown stallion');
    const price = product.current_price
      ? Intl.NumberFormat(countryCode, {
          style: 'currency',
          currency: product.current_price_currency ?? defaultCurrency,
        }).format(Number(product.current_price))
      : t('no-price', 'No price');
    return `${stallionName} (${price})`;
  };
  const breedingProductCategory = productCategories.find(cat => cat.default === 'BREEDING');

  return products
    .filter(product => product.hidden === false)
    .filter(product => product.category_uid === breedingProductCategory?.uid)
    .map(product => ({ id: product.uid, name: productName(product) }));
};

// Get the product name of breeding product.
export const BreedingProductName = (t: TFunction, products: Product[], horses: Horse[], productUid?: string): string => {
  const product = productUid ? products.find(prod => prod.uid === productUid) : undefined;
  if (!product) {
    return t('stallion', 'Stallion');
  }
  return horses.find(horse => horse.uid === product.stallion_uid)?.name ?? t('unknown-stallion', 'Unknown stallion');
};

/**
 * Create an OptionItem list of shipping options with a price that are not deleted(hidden).
 * @param products All known products
 * @param shippingCountryCode Optional country code to filter shipping options to
 * @param moneyFormatCountryCode // Country code for displaying the price in a localized manner
 * @returns Options to put in a dropdown.
 */
export const ShippingOptions = (
  products: Product[],
  productCategories: Category[],
  shippingCountryCode?: string,
  moneyFormatCountryCode?: string,
  defaultCurrency?: string,
): OptionItemInterface[] => {
  const productName = (product: Product) => {
    const price = Intl.NumberFormat(moneyFormatCountryCode, {
      style: 'currency',
      currency: product.current_price_currency ?? defaultCurrency,
    }).format(Number(product.current_price ?? '0'));
    return `${product.shipping_provider_name} (${price})`;
  };
  const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');
  return products
    .filter(
      product =>
        product.category_uid === shippingProductCategory?.uid &&
        (!shippingCountryCode || !product.shipping_countries || product.shipping_countries.includes(shippingCountryCode)) &&
        product.hidden === false,
    )
    .map(product => ({ id: product.uid, name: productName(product) }));
};

/**
 * Create an OptionItem list of shipping options with a price for a webshop purchaser.
 * @param products All known products
 * @param shippingCountryCode Optional country code to filter shipping options to
 * @param moneyFormatCountryCode // Country code for displaying the price in a localized manner
 * @returns Options to put in a dropdown.
 */
export const CatalogueShippingOptions = (
  products: CatalogueProduct[],
  shippingCountry?: CountryEnum,
  moneyFormatCountryCode?: string,
  defaultCurrency?: string,
): OptionItemInterface[] => {
  const productName = (product: CatalogueProduct) => {
    const price = Intl.NumberFormat(moneyFormatCountryCode, {
      style: 'currency',
      currency: product.price_currency ?? defaultCurrency,
    }).format(Number(product.price ?? '0'));
    return `${product.shipping_provider_name} (${price})`;
  };
  if (!products) {
    return [];
  }

  return products
    .filter(
      product =>
        product.category.default === 'SHIPPING' &&
        (!shippingCountry || !product.shipping_countries || product.shipping_countries.includes(shippingCountry)),
    )
    .map(product => ({ id: product.uid, name: productName(product) }));
};

/**
 * Generate a textual deliver address for an order.
 * We used this in the table view.
 */
export const deliveryAddress = (order: SupplierOrder): string | undefined => {
  const contact = order.historic_receiver;
  if (contact.city && contact.country) {
    return `${contact.city} (${contact.country})`;
  } else if (contact.address_line1) {
    return [contact.address_line1, contact.address_line2, contact.address_line3].join(' ');
  }
};

/**
 * Generate a textual deliver name for an order.
 * We used this in the table view.
 */
export const deliveryName = (order: SupplierOrder): string | undefined => {
  const contact = order.historic_receiver;
  if (contact.business_name) {
    return contact.business_name;
  } else if (contact.first_name) {
    return `${contact.first_name} ${contact.last_name}`;
  }
};

export const shippingServiceTypeToString = (t: TFunction, type: ShippingServiceTypeEnum): string => {
  switch (type) {
    case ShippingServiceTypeEnum.NEXT_DAY_DELIVERY:
      return t('shipping-next-day-delivery', 'Next day delivery');
    case ShippingServiceTypeEnum.PICK_UP:
      return t('shipping-pick-up', 'Pick up');
    case ShippingServiceTypeEnum.REGULAR:
      return t('shipping-regular', 'Regular');
    case ShippingServiceTypeEnum.SAME_DAY_DELIVERY:
      return t('shipping-same-day-delivery', 'Same day delivery');
    case ShippingServiceTypeEnum.SUNDAY_HOLIDAY_DELIVERY:
      return t('shipping-sunday-holiday-delivery', 'Sunday/holiday delivery');
  }
};

export const shippingServiceType = (t: TFunction, products: Product[], productCategories: Category[], order: SupplierOrder): string => {
  if (!order.order_items) {
    return t('unknown', 'Unknown');
  }
  const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');

  const result: string[] = [];
  order.order_items.forEach(orderItem => {
    const product = products.find(prod => prod.uid === orderItem.product_uid);
    if (!product || product.category_uid !== shippingProductCategory?.uid) return;
    result.push(product.shipping_service_type ? shippingServiceTypeToString(t, product.shipping_service_type) : t('unknown', 'Unknown'));
  });
  if (result.length === 0) {
    return t('no-shipping', 'No shipping');
  }
  return result.join(', ');
};

export const shippingName = (t: TFunction, products: Product[], productCategories: Category[], order: SupplierOrder): string => {
  if (!order.order_items) {
    return '';
  }
  const shippingProductCategory = productCategories.find(cat => cat.default === 'SHIPPING');
  const result: string[] = [];
  order.order_items.forEach(orderItem => {
    const product = products.find(prod => prod.uid === orderItem.product_uid);
    if (!product || product.category_uid !== shippingProductCategory?.uid) return;
    result.push(
      `${product.shipping_provider_name} (${product.shipping_service_type ? shippingServiceTypeToString(t, product.shipping_service_type) : t('unknown', 'Unknown')})`,
    );
  });
  return result.join(', ');
};

export const orderTotals = (order: SupplierOrder, defaultCurrency: string): Totals => {
  let subTotal = 0.0;
  let vatTotal = 0.0;
  let total = 0.0;
  let currency: string | undefined;

  order.order_items?.forEach(item => {
    const price = Number(item.unit_price ?? '0');
    const vatPercentage = Number(item.vat_percentage.percentage ?? '0');
    let vat = price * (vatPercentage / 100.0);
    // Round vat percentages (2 decimals) per line (like we do in the backend).
    // Use Number.EPSILON for better rounding https://stackoverflow.com/a/11832950
    // This is not bulletproof, we should receive calculations from the server.
    vat = Math.round((vat + Number.EPSILON) * 100) / 100;
    subTotal += price;
    vatTotal += vat;
    total += price + vat;
    if (currency) {
      if (currency !== item.unit_price_currency) {
        console.error('Multiple currencies in a order is not supported', order.uid);
      }
    } else {
      currency = item.unit_price_currency;
    }
  });
  return { subTotal, vatTotal, total, currency: currency ?? defaultCurrency };
};

export const orderRequiresPayment = (order: PurchaserOrder): boolean => {
  const total = order.order_items.reduce((total, item) => total + Number(item.upfront_payment), 0);
  return total !== 0;
};

export const getBreedingProducts = (
  financialCategories: Category[] | undefined,
  horses: Horse[] | undefined,
  products: Product[] | undefined,
): Product[] => {
  if (!products) return [];
  if (!horses) return [];
  const myStallions = horses.filter(horse => horse.sex === SexEnum._1);

  // Find the breeding product category
  const breedingProductCategory = financialCategories?.find(cat => cat.default === 'BREEDING');
  if (!breedingProductCategory) {
    console.error('Breeding product category not found');
    return [];
  }

  const result: Product[] = [];
  myStallions.forEach(stallion => {
    const productsForStallion = products.filter(
      product => product.stallion_uid === stallion.uid && product.category_uid === breedingProductCategory.uid,
    );
    if (productsForStallion.length === 0) return;
    if (productsForStallion.length > 1) {
      console.error('Multiple breeding prices for horse is not supported.');
    }
    result.push(productsForStallion[0]);
  });
  return result;
};
