import React from 'react';
import { PlanningProvider } from 'context/PlanningContext';
import ActivityList from 'components/Activities/ActivityList';
import { useParams } from 'react-router-dom';

export default function HorseActivityHistoryPage(): JSX.Element {
  const { uid } = useParams(); // The horse uid

  return (
    <PlanningProvider>
      <ActivityList horseUid={uid} componentType='page' timeType='history' />
    </PlanningProvider>
  );
}
