import { usePlanning } from 'hooks/UsePlanning';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { BluePrintState, CalendarActivityType, SelectedActivityState } from 'utilities/Planning';
import SaveActivityForm from './SaveActivityForm';

interface Props {
  onSaved?: () => Promise<void>;
}

export default function SaveActivityModal({ onSaved }: Props): JSX.Element {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { bluePrint, unsetBluePrint, selectedActivity, setSelectedActivity, loadActivityApiData } = usePlanning();

  const title = useMemo(() => {
    if (selectedActivity) {
      if (selectedActivity.activity.type === CalendarActivityType.Activity) {
        return t('change-activity', 'Change activity');
      } else if (selectedActivity.activity.type === CalendarActivityType.FacilityEvent) {
        return t('change-facility-event', 'Change facility reservation');
      } else {
        if (selectedActivity.activity.stableUid) {
          return t('change-daily-note', 'Change message/task');
        } else {
          return t('change-message', 'Change message');
        }
      }
    } else if (bluePrint) {
      if (bluePrint.type === undefined) {
        return t('new', 'New');
      } else if (bluePrint.type === CalendarActivityType.Activity) {
        return t('new-activity', 'New activity');
      } else if (bluePrint.type === CalendarActivityType.FacilityEvent) {
        return t('new-facility-event', 'Facility reservation');
      } else if (bluePrint.stableUid) {
        return t('new-daily-note', 'New message/task');
      } else {
        return t('new-message', 'New message');
      }
    } else {
      return '';
    }
  }, [selectedActivity, bluePrint, t]);

  return (
    <PageModal
      width={PageModalWidth.Sm}
      open={bluePrint?.state === BluePrintState.EditFull || selectedActivity?.selectedActivityState === SelectedActivityState.Edit}
    >
      <PageModalTitle
        title={title}
        onClose={() => {
          if (bluePrint) {
            unsetBluePrint();
          }
          if (selectedActivity) {
            setSelectedActivity(selectedActivity.activity, SelectedActivityState.Selected, selectedActivity.groupByUid);
          }
        }}
      />
      <PageModalContent>
        <SaveActivityForm
          compact={false}
          submitting={setIsSubmitting}
          onSaved={() => {
            if (onSaved) {
              onSaved();
            }
            return loadActivityApiData().watchAll();
          }}
        />
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            text: t('save', 'Save'),
            type: 'submit',
            formId: 'SaveActivityForm',
          },
        ]}
      />
    </PageModal>
  );
}
