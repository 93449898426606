import { useMemo } from 'react';
import { UAParser } from 'ua-parser-js';

interface ReturnType {
  animationsEnabled: boolean;
  useOwnDatePicker: boolean;
}

// Simple version number parser
const parseVersion = (str: string) => {
  const arr = str.split('.');

  // parse int or default to 0
  const maj = parseInt(arr[0]) || 0;
  const min = parseInt(arr[1]) || 0;
  const rest = parseInt(arr[2]) || 0;
  return {
    major: maj,
    minor: min,
    build: rest,
  };
};

// Parses the browser user agent. Returns convenience functions.
const useUserAgent = (): ReturnType => {
  const uaParser = useMemo(() => {
    return UAParser(window.navigator.userAgent);
  }, []);

  // We disable ui animations for Android 11 and lower for performance reasons.
  // This includes all 'old' Equiboards.
  const animationsEnabled = useMemo((): boolean => {
    if (uaParser.os.name !== 'Android') {
      return true;
    }
    if (!uaParser.os.version) {
      return true;
    }
    const versionNumber = parseVersion(uaParser.os.version);
    return versionNumber.major >= 12;
  }, [uaParser]);

  // In some cases we want to use our own date picker. Ie. in Safari the datepicker
  // is not usable enough. And on Firefox we also have some weird behavior (issue #356):
  //
  // When editing the date I hit the up arrow to jump to the next date (next month
  // in this case). This fails and resets the date to blank. This is bug one,
  // it should simply carry over to the next month. However, I think it also
  // triggers the next bug in that the time is reset to the morning slot rather than
  // the afternoon slot it had originally.
  const useOwnDatePicker = useMemo((): boolean => {
    return ['Safari', 'Firefox'].includes(uaParser.browser.name ?? '');
  }, [uaParser]);

  return {
    animationsEnabled,
    useOwnDatePicker,
  };
};

export default useUserAgent;
