import classNames from 'classnames';
import { isSameDay } from 'date-fns';
import React, { useMemo } from 'react';

export interface Props {
  day: Date;
  now: Date;
  heights: number[];
  visibleRange?: { start: number; end: number };
  className?: string;
  shape?: Shape;
}

export enum Shape {
  Line,
  LineDot,
  Triangle,
}

/**
 * A marker that is displayed within a day to show the current time.
 * This element goes hand in had with the ActivityContainerTimeScale.
 *
 * ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 * │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 * │      │      │      │      │      │      │      │
 * ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │------│      │      │      │      │      │  <-- now indicator
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * └──────└──────└──────└──────└──────└──────└──────┘
 *
 */
export function DayNowIndicator({ day, now, className, heights, visibleRange, shape = Shape.Line }: Props): JSX.Element {
  const negativeMarginTop = useMemo(() => {
    if (!visibleRange) {
      return 0;
    }
    const topHeights = heights.slice(0, visibleRange.start);
    return topHeights.reduce((partialSum, height) => partialSum + height, 0);
  }, [visibleRange, heights]);

  // We calculate position based on the full height.
  const fullHeight = useMemo(() => {
    return heights.reduce((partialSum, height) => partialSum + height, 0);
  }, [heights]);

  // Top offset position for the indicator (in pixels).
  const pos = useMemo(() => {
    if (!isSameDay(day, now)) {
      return 0;
    }
    const first = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0);
    const second = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1, 0, 0);
    const totalSecondsInTheDay = (second.getTime() - first.getTime()) / 1000;
    const passedSeconds = (now.getTime() - first.getTime()) / 1000;
    let top = Math.round(fullHeight * (passedSeconds / totalSecondsInTheDay));

    if (shape === Shape.Triangle) {
      top = top - 6;
    } else {
      top = top - 1;
    }

    return top;
  }, [now, fullHeight, day, shape]);

  const indicatorClassName = useMemo((): string => {
    if (shape === Shape.Line || shape === Shape.LineDot) {
      return classNames(`absolute h-[2px] left-0 -right-[1px] bg-primary md:bg-rose-600/50`, {
        'before:block before:w-3 before:h-3 before:absolute before:-left-2 before:-top-1 before:bg-primary before:md:bg-rose-600 before:rounded-full':
          shape === Shape.LineDot,
      });
    } else if (shape === Shape.Triangle) {
      return 'absolute h-[1px] left-0 -right-[1px] border-t-[6px] border-t-transparent border-l-[8px] border-l-primary md:border-l-rose-600/80 border-b-[6px] border-b-transparent';
    }
    return '';
  }, [shape]);

  if (!isSameDay(day, now)) {
    // No need to show anything
    return <></>;
  }

  return (
    <div className={classNames(className, 'overflow-hidden')}>
      <div style={{ height: fullHeight, marginTop: negativeMarginTop * -1 }} className='w-full relative overflow-hidden'>
        {pos !== 0 && <div style={{ top: `${pos}px` }} className={indicatorClassName} />}
      </div>
    </div>
  );
}
