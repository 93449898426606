import classNames from 'classnames';
import { FertilizerOriginEnum, MutationTypeEnum, ProductionBalanceItem } from 'openapi';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { table, tableHiddenColumnMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { ButtonVariant } from 'ui/Button';
import useModal from 'ui/Modals/UseModal';
import { PageAction } from 'context/PageContext';
import SaveProductionBalanceItemNonAnimalModal from './SaveProductionBalanceItemNonAnimalModal';
import { fertilizerOriginToString, sumUpTotal } from './helper';
import DropdownMenu from 'ui/DropdownMenu';
import DeleteBalanceItemNonAnimalModal from './DeleteBalanceItemNonAnimalModal';
import { ApiPromises } from 'utilities/ApiPromises';

interface Props {
  productionBalanceItem: ProductionBalanceItem[] | undefined;
  onSaved: () => void;
  onDeleted: () => void;
  apiPromises: ApiPromises | undefined;
}

/**
 * This component is used to determine the balance for all non animal manure on the farm.
 *
 * This step is part of step 5 of the RVO document
 * @see https://www.rvo.nl/sites/default/files/2023-08/Brochure-Hoeveel-mest-gebruiken-hoe-rekent-u-dat-uit-v1.7-Aug2023_0.pdf
 */
function CalculateBalanceNonAnimalManureTile({ productionBalanceItem, onSaved, onDeleted, apiPromises }: Props): JSX.Element {
  const [selectedProductionBalanceItem, setSelectedProductionBalanceItem] = useState<
    | {
        beginItem: ProductionBalanceItem | undefined;
        importItem: ProductionBalanceItem | undefined;
        exportItem: ProductionBalanceItem | undefined;
        endItem: ProductionBalanceItem | undefined;
      }
    | undefined
  >();

  const { t } = useTranslation();
  const { closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible, showModal: showSaveModal } = useModal();
  const { closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible, showModal: showDeleteModal } = useModal();

  /**
   * Return only the lise of consumers that are of type ANIMAL_NITROGEN
   */
  const filteredProductionBalanceItems = useMemo(() => {
    const items = (productionBalanceItem ?? []).filter(
      item =>
        item.fertilizer_origin === FertilizerOriginEnum.ANORGANIC_SUPPLY ||
        item.fertilizer_origin === FertilizerOriginEnum.OTHER_ORGANIC_SUPPLY,
    );

    // sort the items by description in a map
    const map: Map<string, ProductionBalanceItem[]> = new Map();
    items.forEach(item => {
      const key = item.name ?? '';
      const exists = map.get(key);
      if (!exists) {
        map.set(key, [item]);
      } else {
        exists.push(item);
      }
    });
    return map;
  }, [productionBalanceItem]);

  /**
   * Sumup a total for the given item
   */
  const sumTotal = (item: ProductionBalanceItem | undefined, total: number) => {
    if (!item) {
      return 0;
    }
    const type = item.nitrogen_weight ? item.nitrogen_weight : item.phosphate_weight;
    return sumUpTotal(item.operator, type, total);
  };

  const actions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add', 'Add'),
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: showSaveModal,
      },
    ];
  }, [showSaveModal, t]);

  return (
    <Tile
      title={`5. ${t('nitrogen-and-phosphate-from-all-other-types-of-fertilizer', 'Nitrogen and phosphate from all other types of fertilizer')}`}
      actions={actions}
      loading={apiPromises}
    >
      <table className={table}>
        <thead className={tableThead}>
          <tr>
            <td className={classNames(tableTheadTd, tableHiddenColumnMd)}>{t('name', 'Name')}</td>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>{t('type', 'Type')}</td>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>
              {t('begin-amount', 'Begin amount')} {'(+)'}
            </td>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>
              {t('import', 'Import')} {'(+)'}
            </td>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>
              {t('export', 'Export')} {'(-)'}
            </td>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>
              {t('end-amount', 'End amount')} {'(-)'}
            </td>
            <td className={classNames('w-40', tableTheadTd, tableHiddenColumnMd)}>{t('total', 'Total')}</td>
            <td className={classNames('w-10', tableTheadTd, tableHiddenColumnMd)} />
          </tr>
        </thead>
        <tbody className={tableTbody}>
          {[...filteredProductionBalanceItems].length === 0 && (
            <tr>
              <td className='text-center text-gray-500' colSpan={8}>
                {t('no-data', 'No data')}
              </td>
            </tr>
          )}

          {[...filteredProductionBalanceItems].map(([index, items]) => {
            const beginAmountItem = items.find(item => item.mutation_type === MutationTypeEnum.BEGIN_AMOUNT);
            const importItem = items.find(item => item.mutation_type === MutationTypeEnum.IMPORT);
            const exportItem = items.find(item => item.mutation_type === MutationTypeEnum.EXPORT);
            const endAmountItem = items.find(item => item.mutation_type === MutationTypeEnum.END_AMOUNT);
            const type = beginAmountItem?.nitrogen_weight ? 'nitrogen' : 'phosphate';
            const fertilizerOrigin = fertilizerOriginToString(beginAmountItem?.fertilizer_origin, t);

            // sum up the total
            let sum = 0;
            sum = sumTotal(beginAmountItem, sum);
            sum = sumTotal(importItem, sum);
            sum = sumTotal(exportItem, sum);
            sum = sumTotal(endAmountItem, sum);

            return (
              <tr key={index} className={tableTbodyTrNoClick}>
                <td className={tableHiddenColumnMd}>{beginAmountItem?.name}</td>
                <td className={tableHiddenColumnMd}>
                  <p>{type}</p>
                  <p className='text-gray-400 text-sm'>{fertilizerOrigin}</p>
                </td>
                <td className={tableHiddenColumnMd}>
                  {beginAmountItem?.nitrogen_weight ?? beginAmountItem?.phosphate_weight} {'kg'}
                </td>
                <td className={tableHiddenColumnMd}>
                  {importItem?.nitrogen_weight ?? importItem?.phosphate_weight} {'kg'}
                </td>

                <td className={tableHiddenColumnMd}>
                  {exportItem?.nitrogen_weight ?? exportItem?.phosphate_weight} {'kg'}
                </td>
                <td className={tableHiddenColumnMd}>
                  {endAmountItem?.nitrogen_weight ?? endAmountItem?.phosphate_weight} {'kg'}
                </td>
                <td className={classNames('font-semibold', tableHiddenColumnMd)}>
                  {sum} {'kg'}
                </td>

                <td className='md:hidden' colSpan={7}>
                  <div className='grid grid-cols-2'>
                    <div className='py-2 space-y-1'>
                      <div>
                        <p className='text-gray-500 text-sm'>{t('name', 'Name')}</p>
                        <p>{beginAmountItem?.name}</p>
                      </div>
                      <div>
                        <p className='text-gray-500 text-sm'>{t('type', 'Type')}</p>
                        <p>
                          {type} - {fertilizerOrigin}
                        </p>
                      </div>
                      <div>
                        <p className='text-gray-500 text-sm'>
                          {t('begin-amount', 'Begin amount')} {'+'}
                        </p>
                        <p>
                          {beginAmountItem?.nitrogen_weight ?? beginAmountItem?.phosphate_weight} {'kg'}
                        </p>
                      </div>
                    </div>

                    <div className='py-2 space-y-1'>
                      <div>
                        <p className='text-gray-500 text-sm'>
                          {t('import', 'Import')} {'(+)'}
                        </p>
                        <p>
                          {importItem?.nitrogen_weight ?? importItem?.phosphate_weight} {'kg'}
                        </p>
                      </div>
                      <div>
                        <p className='text-gray-500 text-sm'>
                          {t('export', 'Export')} {'(-)'}
                        </p>
                        <p>
                          {exportItem?.nitrogen_weight ?? exportItem?.phosphate_weight} {'kg'}
                        </p>
                      </div>
                      <div>
                        <p className='text-gray-500 text-sm'>
                          {t('end-amount', 'End amount')} {'(-)'}
                        </p>
                        <p>
                          {endAmountItem?.nitrogen_weight ?? endAmountItem?.phosphate_weight} {'kg'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='font-semibold'>
                    {t('total', 'Total')}
                    {':'} {sum} {'kg'}
                  </div>
                </td>

                <td align='right'>
                  <DropdownMenu
                    menuPlacement='right-end'
                    menuItems={[
                      [
                        {
                          element: t('edit', 'Edit'),
                          onClick: () => {
                            setSelectedProductionBalanceItem({
                              beginItem: beginAmountItem,
                              importItem: importItem,
                              exportItem: exportItem,
                              endItem: endAmountItem,
                            });
                            showSaveModal();
                          },
                        },
                      ],
                      [
                        {
                          element: t('remove', 'Remove'),
                          className: 'text-red-600',
                          onClick: () => {
                            setSelectedProductionBalanceItem({
                              beginItem: beginAmountItem,
                              importItem: importItem,
                              exportItem: exportItem,
                              endItem: endAmountItem,
                            });
                            showDeleteModal();
                          },
                        },
                      ],
                    ]}
                  >
                    <button className='px-2'>
                      <DotsThreeVertical size={20} weight='bold' />
                    </button>
                  </DropdownMenu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <SaveProductionBalanceItemNonAnimalModal
        productionBalanceItemBeginAmount={selectedProductionBalanceItem?.beginItem}
        productionBalanceItemImport={selectedProductionBalanceItem?.importItem}
        productionBalanceItemExport={selectedProductionBalanceItem?.exportItem}
        productionBalanceItemEndAmount={selectedProductionBalanceItem?.endItem}
        isVisible={saveModalIsVisible}
        onRequestCloseModal={() => {
          setSelectedProductionBalanceItem(undefined);
          closeSaveModal();
        }}
        onSaved={onSaved}
      />

      <DeleteBalanceItemNonAnimalModal
        productionBalanceItemBeginAmount={selectedProductionBalanceItem?.beginItem}
        productionBalanceItemImport={selectedProductionBalanceItem?.importItem}
        productionBalanceItemExport={selectedProductionBalanceItem?.exportItem}
        productionBalanceItemEndAmount={selectedProductionBalanceItem?.endItem}
        isVisible={deleteModalIsVisible}
        onDeleted={() => {
          setSelectedProductionBalanceItem(undefined);
          onDeleted();
        }}
        onRequestClose={closeDeleteModal}
      />
    </Tile>
  );
}

export default CalculateBalanceNonAnimalManureTile;
