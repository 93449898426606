import { Horse } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import UploadFile from 'api/UploadFile';
import { generateWebshopPath, WebshopRoutes } from 'AppRoutes';
import IdentityHeader from 'components/Common/IdentityHeader';
import GeneralHorseForm from 'components/Horses/DetailTiles/GeneralHorseForm';
import HorseAvatarForm from 'components/Horses/DetailTiles/HorseAvatarForm';
import IdentificationHorseFrom from 'components/Horses/DetailTiles/IdentificationHorseForm';
import PedigreeTile from 'components/Horses/DetailTiles/PedigreeTile';
import { useConfig } from 'context/ConfigContext';
import { useOrganization } from 'context/OrganizationContext';
import { usePage } from 'context/PageContext';
import useHorseDetail from 'hooks/UseHorseDetail';
import { HorsesService } from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import Page from 'ui/Layout/Page';
import { age, gender } from 'utilities/Horse';
import { getColorFromId } from 'utilities/string.utility';

function MareDetailsPage(): JSX.Element {
  const { loadHorse, horse, setHorse } = useHorseDetail();
  const { config } = useConfig();
  const { selectedOrganization } = useOrganization();
  const { uid } = useParams();
  const { setApiError } = usePage();
  const { publicAccessUuid } = useParams();
  const { t } = useTranslation();

  /**
   * Load the horses
   */
  useEffect(() => {
    if (selectedOrganization && uid && !horse) {
      const promise = loadHorse(selectedOrganization.uid, uid);

      promise
        .then(() => setApiError(undefined))
        .catch(e => {
          if (!promise.isCancelled) {
            setApiError(new ApiErrorParser<HorseDetail>(e));
          }
        });
      return () => promise.cancel();
    }
  }, [selectedOrganization, uid]); //eslint-disable-line

  const breadCrumbs = useMemo(() => {
    // We'll have to set the full path
    return [
      {
        path: generateWebshopPath(WebshopRoutes.MaresList.path, publicAccessUuid ?? ''),
        name: t('mares', 'Mares'),
        component: WebshopRoutes.MaresList.component,
        public: WebshopRoutes.MaresList.public,
      },
    ];
  }, [publicAccessUuid, t]);

  const onAvatarSave = async (imageData: ArrayBuffer, mimeType: string) => {
    if (!horse) throw Error('No horse is set.');
    if (!selectedOrganization) throw Error('No organization is set.');

    // Required because we use vercel to upload the image.
    if (!config?.hasServerlessFunctions) throw Error('Serverless functions are not enabled.');

    // Upload the image to s3 via Vercel serverless function.
    const url = await UploadFile.uploadHorseAvatar(config.serverlessFunctionsUrl, imageData, horse.uid, selectedOrganization.uid, mimeType);

    // Patch the horse with the avatar image s3 url.
    const updateHorsePromise = HorsesService.horsesPartialUpdate({
      organisationUid: selectedOrganization.uid,
      uid: horse.uid,
      requestBody: { avatar_file: url.toString() },
    });

    setHorse(await updateHorsePromise);
  };

  const subTitle = useMemo(() => {
    if (horse?.dam && horse?.sire) {
      return `${horse.dam} × ${horse.sire}`;
    }
    if (horse?.sex) {
      return gender(horse.sex, t);
    }
    if (horse?.color) {
      return getColorFromId(horse.color, t);
    }
    if (horse?.date_of_birth) {
      return t('years-old', '{{years}} years old', { years: age(horse) });
    }
    return '';
  }, [horse, t]);

  return (
    <Page
      title={horse?.name ?? ''}
      breadCrumbs={breadCrumbs}
      mobileHeaderComponent={
        <IdentityHeader
          title={horse?.name ?? ''}
          subTitle={subTitle}
          avatarUrl={horse?.avatar_file ?? undefined}
          icon={<Horse />}
          onAvatarEditRequest={onAvatarSave}
        />
      }
    >
      <div className='space-y-4'>
        <div className='flex flex-col-reverse lg:flex-row gap-4'>
          {/* for webshop users the location is most of the time unknown, therefor we can hide it */}
          <GeneralHorseForm showLocation={false} />
          <HorseAvatarForm onAvatarEditRequest={onAvatarSave} className='lg:w-[400px] hidden md:block' />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <IdentificationHorseFrom />
          <PedigreeTile
            pedigreePageUrl={generateWebshopPath(generatePath(WebshopRoutes.MarePedigree.path, { uid: uid }), publicAccessUuid ?? '')}
          />
        </div>
      </div>
    </Page>
  );
}

export default memo(MareDetailsPage);
