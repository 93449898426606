import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
  className?: string;
}

/**
 * Small wrapper component that will show a title and children
 */
function Tile({ title, children, className }: Props): JSX.Element {
  return (
    <div className={classNames('bg-white rounded-md border p-5', className)}>
      <h2 className='text-lg font-medium'>{title}</h2>
      {children}
    </div>
  );
}

export default Tile;
