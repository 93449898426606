import { X } from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { forwardRef, ForwardRefRenderFunction, TextareaHTMLAttributes, useState } from 'react';

export enum InputSize {
  XSmall,
  Small,
  Normal,
  Large,
}

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  hint?: string;
  postText?: string;
  error?: string;
  preText?: string;
  allowClear?: boolean;
  invalid?: boolean;
  size?: InputSize;
}

const TextAreaInput: ForwardRefRenderFunction<HTMLTextAreaElement, Props> = (
  {
    label,
    error,
    preText,
    postText,
    allowClear = false,
    invalid = false,
    size = InputSize.Normal,
    onChange,
    onBlur,
    onFocus,
    hint,
    className,
    ...props
  },
  ref,
) => {
  const [focused, setFocused] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // setValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleClear = () => {
    // setValue('');
  };
  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };
  const rootClassName = classNames('group relative', className);
  const inputRootClassName = classNames(
    'group w-full border flex items-center space-x-1.5 relative rounded-md border transition-all duration-200',
    {
      'border-primary': focused,
      'bg-gray-100 border-gray-200 cursor-not-allowed': props.disabled,
      'border-rose-400': error || invalid,
      'bg-white': !props.disabled,
      'py-1 px-1.5 ': size === InputSize.XSmall,
      'py-1 px-2 ': size === InputSize.Small,
      'py-1.5 px-2': size === InputSize.Normal,
      'py-2 px-2.5': size === InputSize.Large,
    },
  );
  const inputClassName = classNames(
    'block flex-1 bg-transparent rounded-md border-0 focus:ring-0 focus:outline-none text-gray-900 ring-0 placeholder:text-gray-400',
    {
      'text-xs leading-5': size === InputSize.XSmall,
      'placeholder:text-rose-300': error || invalid,
      'cursor-not-allowed': props.disabled,
      'text-sm leading-6': size === InputSize.Small,
      'sm:text-sm sm:leading-6': size === InputSize.Normal,
      'text-large sm:text-base leading-7 sm:leading-6': size === InputSize.Large,
    },
  );
  return (
    <div className={rootClassName}>
      {label && (
        <label className='block text-sm font-medium leading-4 text-gray-600 mb-2'>
          {label} {props.required && '*'}
        </label>
      )}
      <div className={inputRootClassName}>
        {preText && (
          <div className='pointer-events-none inset-y-0 flex items-center'>
            <span className='text-gray-500 sm:text-sm'>{preText}</span>
          </div>
        )}
        <textarea
          ref={ref}
          rows={3}
          {...props}
          onFocus={handleFocus}
          autoComplete={props.autoComplete ?? 'off'}
          onBlur={handleBlur}
          onChange={handleChange}
          className={inputClassName}
        />
        {postText && (
          <div className='pointer-events-none inset-y-0 flex items-center'>
            <span className='text-gray-500 sm:text-sm'>{postText}</span>
          </div>
        )}
        {allowClear && props.value && (
          <div onClick={handleClear} className={'bg-neutral-50 rounded-full p-1'}>
            <X className='cursor-pointer h-3 w-3 text-gray-400' />
          </div>
        )}
      </div>
      {hint && <label className='block text-xs leading-4 text-gray-500 mt-1'>{hint}</label>}
      <label
        className={classNames('block text-xs leading-4 text-red-500 transition-all duration-200', {
          'h-auto opacity-100  mt-1': !!error,
          'h-0 opacity-0': !error,
        })}
      >
        {error || ''}
      </label>
    </div>
  );
};
export default forwardRef<HTMLTextAreaElement, Props>(TextAreaInput);
