/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `FARM_LAND` - farm land
 * * `GRASS_LAND` - grass land
 */
export enum LandTypePhosphateEnum {
  FARM_LAND = 'FARM_LAND',
  GRASS_LAND = 'GRASS_LAND',
}
