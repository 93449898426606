import { useCallback, useState } from 'react';
import { useConfig } from '../../context/ConfigContext';

interface ReturnType {
  preparing: boolean;
  download: (publicAccessUuid: string) => void;
}

// A hook for downloading an invoice based on their public access uuid.
const useInvoiceDownloader = (): ReturnType => {
  const [preparing, setPreparing] = useState<boolean>(false);
  const { config } = useConfig();

  const download = useCallback(
    async (publicAccessUuid: string) => {
      if (!config?.hasServerlessFunctions) {
        alert('Serverless functions are not enabled');
        return;
      }
      setPreparing(true);

      // Build an url to the serverless function for invoice pdf generation.
      const serverlessFunctionsUrl = `${config?.serverlessFunctionsUrl ?? ''}/api/topdf?invoice=${publicAccessUuid}`;
      let response = await fetch(serverlessFunctionsUrl, { method: 'GET' });

      // Ok this is actually a funny part that needs some explanation. The pdf
      // generator (serverless function) might take a couple of seconds to
      // finish. In some cases with slow internet speeds this might trigger the
      // 10 second threshold. 10 seconds of serverless execution time is the
      // limit of Vercels free plan. It will throw a 504. But if we retrigger
      // the same download, it will be much faster due to Vercels caches. That's
      // why we will do 1 retry if we receive a 504.
      if (response.status === 504) {
        response = await fetch(serverlessFunctionsUrl, { method: 'GET' });
      }

      if (response.status === 200) {
        // In order to start a file download we need to pull a little trick.
        // We have to create an 'a' html element with the file content attached.
        // Inspired by: https://stackoverflow.com/a/42274086
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(await response.blob());
        a.download = `Invoice_${publicAccessUuid}.pdf`;
        // we need to append the element to the dom -> otherwise it will not
        // work in firefox.
        document.body.appendChild(a);
        a.click();
        // afterwards we remove the element again.
        a.remove();
      } else {
        alert('Failed to download invoice. Please check your internet connection and try again.');
        console.error('Failed to download invoice from serverless function', await response.text());
      }
      setPreparing(false);
    },
    [setPreparing, config],
  );

  return {
    preparing,
    download,
  };
};

export default useInvoiceDownloader;
