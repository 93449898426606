import { FertilizerConsumer, FertilizerOriginEnum, ManureService, MutationTypeEnum, ProductionBalanceItem } from 'openapi';
import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import { ButtonVariant } from 'ui/Button';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { schemas } from 'openapi/zod-schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import useFormError from 'api/hooks/useFormError';
import { ErrorSection } from 'ui/Error';
import { zodInputIsRequired } from 'utilities/zod';
import { TextInput } from 'ui/Inputs';
import { useOrganization } from 'context/OrganizationContext';
import ApiErrorParser from 'api/ApiErrorParser';
import { productionsBalanceItemToString } from '../helper';
import useHorseManure from 'hooks/UseHorseManure';
import Badge from 'ui/Badge';
import { Copy } from '@phosphor-icons/react';
import { navBackToThisPage } from 'ui/Layout/Page';
import { AppRoutes } from 'AppRoutes';

interface Props {
  type: {
    fertilizerOrigin: FertilizerOriginEnum;
    mutationType: MutationTypeEnum;
  };
  productionBalanceItem?: ProductionBalanceItem;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onSaved: () => void;
}

/**
 * The schema for the form
 */
const schema = schemas.ProductionBalanceItem.pick({
  nitrogen_weight: true,
  phosphate_weight: true,
}).required();

function SaveProductionBalanceItemAnimalModal({
  type,
  isVisible,
  onRequestCloseModal,
  onSaved,
  productionBalanceItem,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { year, stableContactUid } = useParams();
  const { selectedOrganizationUid, selectedOrganization } = useOrganization();

  // Add the equinem horses here so the user can select just the values from the API calculated values
  const { loadApiData: loadHorseCountData, horseCount, totals } = useHorseManure({ stableContactUid, year });

  /**
   * Define the default values
   */
  const defaultValues = useMemo(() => {
    return {
      nitrogen_weight: productionBalanceItem?.nitrogen_weight ?? 0,
      phosphate_weight: productionBalanceItem?.phosphate_weight ?? 0,
    };
  }, [productionBalanceItem]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
    setValue,
  } = useForm<ProductionBalanceItem>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const modalTitle = useMemo(() => {
    return productionsBalanceItemToString(type.fertilizerOrigin, type.mutationType, t);
  }, [t, type]);

  /**
   * Submit handler
   */
  const onSubmit = async (data: ProductionBalanceItem) => {
    if (!selectedOrganizationUid) return console.error('selectedOrganization is not defined');

    try {
      const requestBody: ProductionBalanceItem = {
        ...data,
        year: Number(year),
        contact_uid: String(stableContactUid),
        fertilizer_origin: type.fertilizerOrigin,
        mutation_type: type.mutationType,
        name: productionsBalanceItemToString(type.fertilizerOrigin, type.mutationType, t),
      };

      // determine if we are creating or updating
      if (productionBalanceItem) {
        const promiseUpdate = ManureService.manureFertilizerProducersBalancePartialUpdate({
          contactOrganisationUid: selectedOrganizationUid,
          uid: productionBalanceItem.uid,
          requestBody,
        });
        await promiseUpdate;
      } else {
        const promiseCreate = ManureService.manureFertilizerProducersBalanceCreate({
          contactOrganisationUid: selectedOrganizationUid,
          requestBody,
        });
        await promiseCreate;
      }

      // fire the onSaved event
      onSaved();

      // clear states
      setApiError(undefined);
      clearErrors();

      onRequestCloseModal();
    } catch (error) {
      setApiError(new ApiErrorParser<FertilizerConsumer>(error));
    }
  };

  /**
   * Load the horse count data
   */
  useEffect(() => {
    const promise = loadHorseCountData();
    return () => promise.cancel();
  }, [loadHorseCountData, selectedOrganization]);

  /**
   * Set the values when the fertilizerConsumer is set
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, productionBalanceItem, reset]);

  return (
    <>
      <PageModal
        open={isVisible}
        onClosed={() => {
          reset(defaultValues);
          setApiError(undefined);
        }}
        parentElement='form'
        parentProps={{ id: 'saveProductionItem', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={modalTitle} onClose={onRequestCloseModal} />
        <PageModalContent>
          <ErrorSection errors={nonFieldErrors} />

          {FertilizerOriginEnum.EQUINEM_HORSES === type.fertilizerOrigin && (
            <div className='mb-2'>
              <p className='mb-3'>
                {t(
                  'save-balance-item-horses-description',
                  'For the horses and ponies, we can give you a helping hand. Provided you have kept everything up to date in EquineM, we have been able to calculate the following for you based on this data.',
                )}
                <span className='text-xs'>{' (1)'}</span>
              </p>

              <p className='mb-3'>
                <Trans
                  i18nKey='save-balance-item-horses-summary-description'
                  defaults='In {{year}}, you had <strong>{{horseCount}}</strong> horses and <strong>{{ponyCount}}</strong> ponies on your stable(s). Based on this, we have calculated the following values for Nitrogen and Phosphate.'
                  values={{
                    year,
                    horseCount: Number(horseCount?.average.horse_count ?? 0),
                    ponyCount: Number(horseCount?.average.pony_count ?? 0),
                  }}
                />
              </p>

              <ul className='text-sm space-y-1 mb-1'>
                <li>
                  <p>
                    <span className='font-semibold'>
                      {t('nitrogen', 'Nitrogen')}
                      {':'}
                    </span>{' '}
                    <Badge
                      className='inline-flex items-center gap-x-2 cursor-pointer'
                      onClick={() => setValue('nitrogen_weight', Math.round(totals.total.nitrogen))}
                    >
                      {totals.total.nitrogen} {'kg'} <Copy />
                    </Badge>
                  </p>
                </li>
                <li>
                  <p>
                    <span className='font-semibold'>
                      {' '}
                      {t('phosphate', 'Phosphate')}
                      {':'}
                    </span>{' '}
                    <Badge
                      className='inline-flex items-center gap-x-2 cursor-pointer'
                      onClick={() => setValue('phosphate_weight', Math.round(totals.total.phosphate))}
                    >
                      {totals.total.phosphate} {'kg'} <Copy />
                    </Badge>
                  </p>
                </li>
              </ul>
              <p className='text-xs'>
                {t(
                  'save-balance-item-horses-help-text',
                  'You can apply these values by clicking on the value or by entering them manually.',
                )}
              </p>
            </div>
          )}

          <div className='py-3 space-y-3'>
            <div>
              {' '}
              <TextInput
                error={fieldError('nitrogen_weight')}
                required={zodInputIsRequired<ProductionBalanceItem>(schema, 'nitrogen_weight')}
                label={t('nitrogen-weight', 'Nitrogen weight')}
                postText='kg'
                type='number'
                {...register('nitrogen_weight', { valueAsNumber: true })}
              />
            </div>

            <TextInput
              error={fieldError('phosphate_weight')}
              required={zodInputIsRequired<ProductionBalanceItem>(schema, 'phosphate_weight')}
              label={t('phosphate-weight', 'Phosphate weight')}
              postText='kg'
              type='number'
              {...register('phosphate_weight', { valueAsNumber: true })}
            />
          </div>

          {FertilizerOriginEnum.EQUINEM_HORSES === type.fertilizerOrigin && (
            <p className='text-xs'>
              {'(1) '}
              <Trans
                i18nKey='save-balance-item-horses-help-text-with-link-to-horses-manure'
                defaults='For more information about the calculation of the values for your horses and ponies, click <0>here</0>.'
                components={[
                  <NavLink
                    key='horses_wo_chipnr_link'
                    className='underline'
                    to={{
                      pathname: generatePath(AppRoutes.ManureHorseSummary.path),
                      search: `year=${year}&stable=${stableContactUid}&${navBackToThisPage().toString()}`,
                    }}
                  >
                    here
                  </NavLink>,
                ]}
              />
            </p>
          )}
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              loading: isSubmitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'saveProductionItem',
            },
          ]}
        />
      </PageModal>
    </>
  );
}

export default SaveProductionBalanceItemAnimalModal;
