import * as Sentry from '@sentry/react';
import { SplashScreen } from '@capacitor/splash-screen';
import { useConfig } from 'context/ConfigContext';
import React, { Suspense, useEffect, useMemo } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import useMenu from 'hooks/UseMenu';
import { Theme } from 'ui/Layout/Theme';
import ScrollToTop from 'utilities/ScrollToTop';
import { AppRouteKeys, AppRoutes, WebshopRouteKeys, WebshopRoutes } from './AppRoutes';
import { useAccount } from './context/AccountContext';
import NotFound from './pages/NotFound';
import Layout from './ui/Layout';
import { LoadingSection } from './ui/Loading';
import useUserInvitations from 'hooks/UseUserInvitations';
import useRvoReportCount from 'hooks/UseRvoReportCount';
import { Home } from 'pages';
import { IS_MOBILE_APP } from 'const';
import ErrorBoundary from 'pages/ErrorBoundary';

export default function App(): JSX.Element {
  const { session, loading } = useAccount();
  const { t } = useTranslation();
  const { config } = useConfig();
  const { loadInvitations } = useUserInvitations();
  const { loadReportCount } = useRvoReportCount();
  const { EquinemFooterMenu, EquinemSideBarBottomMenu, EquinemSideBarTopMenu, WebshopFooterMenu, WebshopSideBarTopMenu } =
    useMenu('/shop/:publicAccessUuid/');

  const _routes = useMemo(() => {
    return Object.values(AppRouteKeys)
      .map(route => {
        return {
          ...AppRoutes[route],
          key: route,
        };
      })
      .filter(route => !!session || route.public);
  }, [session]);

  const _webshopRoutes = useMemo(() => {
    return Object.values(WebshopRouteKeys).map(route => {
      return {
        ...WebshopRoutes[route],
        key: route,
      };
    });
  }, []);

  /**
   * Remove the splashscreen for the mobile APP, as we can show the loader instead
   */
  useEffect(() => {
    if (IS_MOBILE_APP) {
      SplashScreen.hide();
    }
  }, []);

  /**
   * Load invitations if we are not on the webshop.
   * We should load them here as we set a global alert for the user in the menu
   */
  useEffect(() => {
    if (!config?.atWebshopHostname && session) {
      const promise = loadInvitations();
      return () => promise.cancel();
    }
  }, [config?.atWebshopHostname, loadInvitations, session]);

  /**
   * Load reportCount if we are not on the webshop.
   * We should load them here as we set a global badge for the user in the menu
   */
  useEffect(() => {
    if (!config?.atWebshopHostname) {
      const promise = loadReportCount();
      return () => promise && promise.cancel();
    }
  }, [config?.atWebshopHostname, loadInvitations, loadReportCount]);

  if (loading) {
    return <LoadingSection text={t('app.restore-session', 'Loading user')} />;
  }

  // For better sentry performance monitoring.
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, eventId }) => <ErrorBoundary error={error} componentStack={componentStack} eventId={eventId} />}
      >
        <Toaster />
        <ScrollToTop />
        <SentryRoutes>
          {/* Webshop routes */}
          {(!config?.hasWebshopHost || config?.atWebshopHostname) && (
            <>
              <Route path='/shop/:publicAccessUuid'>
                {_webshopRoutes
                  .filter(r => r.public === true)
                  .map(route => (
                    <Route path={route.path} element={route.component} key={route.key} />
                  ))}
              </Route>
              <Route
                path='/shop/:publicAccessUuid'
                element={
                  <Layout
                    theme={Theme.Webshop}
                    profileMenuPath={WebshopRoutes.Profile.path}
                    topMenu={WebshopSideBarTopMenu}
                    bottomMenu={[]}
                    footerMenu={WebshopFooterMenu}
                  />
                }
              >
                <Route path='' element={<Navigate to={session ? WebshopRoutes.OrderList.path : WebshopRoutes.Login.path} />} />
                {_webshopRoutes
                  .filter(r => !r.public && session)
                  .map(route => (
                    <Route path={route.path} element={route.component} key={route.key} />
                  ))}
                <Route path='*' element={session ? <NotFound /> : <Navigate to={WebshopRoutes.Login.path} />} />
              </Route>
            </>
          )}

          {/* EquineM routes */}
          {!config?.atWebshopHostname && (
            <>
              {_routes
                .filter(r => r.public === true)
                .map(route => (
                  <Route path={route.path} element={route.component} key={route.key} />
                ))}
              {/* PWA Landing page (configure in manifest.json -> start_url) */}
              {session && <Route path='pwa' element={<Home />} />}
              <Route
                element={
                  <Layout
                    theme={Theme.Equinem}
                    profileMenuPath={AppRoutes.Profile.path}
                    topMenu={EquinemSideBarTopMenu}
                    bottomMenu={EquinemSideBarBottomMenu}
                    footerMenu={EquinemFooterMenu}
                  />
                }
              >
                {_routes
                  .filter(r => !r.public)
                  .map(route => (
                    <Route path={route.path} element={route.component} key={route.key} />
                  ))}
                <Route
                  path='*'
                  element={
                    session ? <NotFound /> : <Navigate to={`${AppRoutes.Login.path}?redirect=${location.pathname}${location.search}`} />
                  }
                />
              </Route>
            </>
          )}
        </SentryRoutes>
      </Sentry.ErrorBoundary>
    </Suspense>
  );
}
