import { FileAudio, FilePdf, FileVideo, FileXls, IconContext, MicrosoftWordLogo, Spinner, YoutubeLogo, File } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { SpinnerSize } from 'ui/Loading/Spinner';
import OptimizedImage from 'ui/OptimizedImage';

/**
 * The the icon that belongs to a file.
 */
export const fileIcon = (contentType: string | undefined, YTVim: string | undefined): JSX.Element => {
  if (contentType === 'application/pdf') {
    return <FilePdf />;
  }
  if (contentType === 'application/msword' || contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return <MicrosoftWordLogo />;
  }
  if (contentType === 'application/vnd.ms-excel' || contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return <FileXls />;
  }
  if (contentType?.startsWith('video/') && <FileVideo />) {
    return <FileVideo />;
  }
  if (contentType?.startsWith('audio/')) {
    return <FileAudio />;
  }
  if (YTVim) {
    return <YoutubeLogo />;
  }
  return <File />;
};

interface Props {
  contentType?: string | undefined;
  url: string;
  name: string;
  YTVim?: string;
}

/**
 * Thumbnail element for tile view
 */
export function TileThumb({ contentType, url, name, YTVim }: Props): JSX.Element {
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);

  if (contentType?.startsWith('image/')) {
    return (
      <div className='w-full h-full bg-gray-300 relative'>
        <OptimizedImage className='w-full h-full object-cover' src={url} width={256} onLoaded={() => setPreviewLoading(false)} />
        {previewLoading && (
          <div className='absolute bottom-2 left-2'>
            <Spinner size={SpinnerSize.Small} />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <IconContext.Provider
        value={{
          size: 48,
          weight: 'light',
        }}
      >
        <div className='w-full h-full border flex flex-col gap-2 items-center justify-center'>
          {fileIcon(contentType, YTVim)}
          <p className='text-xs w-full line-clamp-2 break-all px-1 text-center'>{name}</p>
        </div>
      </IconContext.Provider>
    );
  }
}
