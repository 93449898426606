import React, { useCallback, useMemo, useState } from 'react';
import { usePlanning } from 'hooks/UsePlanning';
import { ActivityHeight, ActivityHeightSpacious, DayGrid, DayGridScale } from './DayGrid';
import { DayGridHeader } from './DayGridHeader';
import { DayNowIndicator, Shape } from './DayNowIndicator';
import SlidableCalendarView from './SlidableCalendarView';
import { ArrowsVertical } from '@phosphor-icons/react';
import classNames from 'classnames';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { CalendarView, DayParts, DragDropType, facilityTypeToString, GroupBy, groupByAppliedGetName, TimeScale } from 'utilities/Planning';
import Calendar, { CalendarCluster, equalGroupByApplied, GroupByApplied } from 'context/Calendar';
import { isSameDay } from 'date-fns';
import ActivityContainerDayParts from './ActivityContainerDayParts';
import { ActivityContainerTimeScale } from './ActivityContainerTimeScale';
import ActivityContainerDailyNotes from './ActivityContainerDailyNotes';
import { AvailableWeekdaysEnum, Contact, Facility, Horse, Role } from 'openapi';
import { useTranslation } from 'react-i18next';
import { HorseUsageBadges } from 'components/Horses/HorseUsageBadges';
import Badge, { BadgeSize } from 'ui/Badge/Badge';
import { parseTime } from 'utilities/date.utilities';
import useScreenSize, { ScreenSize } from 'hooks/UseScreenSize';

export interface Props {
  columnHeaderWidth: number;
  calendarView: CalendarView;
}

// The width of the column header on small screens (mobile).
const colHeaderWidthMobile = 64;

/**
 * The header for the multi day view. It show the dates as header columns.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * The view uses a SlidableCalendarView for extra smoothness.
 *
 * ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * └──────└──────└──────└──────└──────└──────└──────┘
 */
export function MultiDayHeader({ columnHeaderWidth: givenColumnHeaderWidth, calendarView }: Props): JSX.Element {
  const { now } = useRefreshingNow();
  const { width } = useScreenSize();
  const columnHeaderWidth = width <= ScreenSize.md ? colHeaderWidthMobile : givenColumnHeaderWidth;

  return (
    <div className='flex w-full'>
      <div className='md:border-r shrink-0 border-white md:border-inherit border-opacity-10' style={{ width: columnHeaderWidth }} />
      <SlidableCalendarView
        calendarView={calendarView}
        className='grow'
        render={(calendar: Calendar, availableWidth) => (
          <div className='w-full flex'>
            {calendar.days.map((day, index) => (
              <div className='grow' style={{ width: availableWidth ? availableWidth / calendar.days.length : 0 }} key={day.toDateString()}>
                <DayGridHeader className='w-full md:border-r' highlight={isSameDay(day, now)} day={day} />
                <div
                  className={classNames('w-full h-1', {
                    'md:border-r border-white md:border-inherit border-opacity-20': index !== calendar.days.length - 1,
                  })}
                />
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
}

export interface MultiDayHeaderDailyNotesProps {
  columnHeaderWidth: number;
  calendarView: CalendarView;
  selectedStableUids?: string[];
  showGrid?: boolean;
  enableBluePrint?: boolean;
  showAnnouncements?: boolean;
  spacious?: boolean;
  // On activity save from blue print modal
  onSaved?: () => Promise<void>;
}

/**
 * Fullday/multiday activities (Daily notes) are shown in this container.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * The view uses a SlidableCalendarView for extra smoothness.
 *
 * ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 * │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 * │      │      │      │      │      │      │      │
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * │      │      │      │      │      │      │      │
 * └──────└──────└──────└──────└──────└──────└──────┘
 */
export function MultiDayHeaderDailyNotes({
  columnHeaderWidth: givenColumnHeaderWidth,
  calendarView,
  selectedStableUids,
  showGrid = true,
  enableBluePrint = true,
  showAnnouncements = true,
  spacious = false,
  onSaved,
}: MultiDayHeaderDailyNotesProps): JSX.Element {
  const { width } = useScreenSize();
  const columnHeaderWidth = width <= ScreenSize.md ? colHeaderWidthMobile : givenColumnHeaderWidth;
  return (
    <div className='flex w-full'>
      <div
        className={classNames('shrink-0', {
          'border-r border-white md:border-inherit border-opacity-10': showGrid,
        })}
        style={{ width: columnHeaderWidth }}
      />
      <SlidableCalendarView
        calendarView={calendarView}
        className='grow'
        render={(calendar: Calendar, availableWidth) => {
          return (
            <ActivityContainerDailyNotes
              showAnnouncements={showAnnouncements}
              days={calendar.days}
              availableWidth={availableWidth}
              activities={calendar.dailyNotes}
              selectedStableUids={selectedStableUids}
              enableBluePrint={enableBluePrint}
              spacious={spacious}
              onSaved={onSaved}
            />
          );
        }}
      />
    </div>
  );
}

interface ColHeaderProps {
  orientation: 'col' | 'row';
  calendarRow: CalendarCluster;
  fullHeight: number;
  activityHeight: number;
  roles: Role[];
}

export function ColHeader({ orientation, calendarRow, fullHeight, activityHeight, roles }: ColHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const rolesForGroupBy = useCallback(
    (groupByApplied: GroupByApplied) => {
      if (groupByApplied.groupBy !== GroupBy.Staff) {
        return [];
      }
      const contact = groupByApplied.subject as Contact;
      return roles?.filter(role => contact.roles?.includes(role.uid));
    },
    [roles],
  );
  return (
    <div
      className={classNames('text-sm grow h-full w-full break-word overflow-x-hidden no-scrollbar flex', {
        'flex-row px-2 items-center justify-between gap-1 min-w-screen bg-neutral-100': orientation === 'row',
        'flex-col pl-2 pt-1 items-start': orientation === 'col',
      })}
    >
      <p className={classNames('text-base pr-0.5', { grow: orientation === 'row', inline: fullHeight <= activityHeight })}>
        <span>{groupByAppliedGetName(t, calendarRow.groupBy)}</span>
      </p>
      {calendarRow.groupBy.groupBy === GroupBy.Horse && (
        <HorseUsageBadges size={BadgeSize.Small} iconOnly={true} horse={calendarRow.groupBy.subject as Horse} />
      )}
      {calendarRow.groupBy.groupBy === GroupBy.Facility && (
        <Badge size={BadgeSize.Small}>
          {facilityTypeToString(t, (calendarRow.groupBy.subject as Facility).facility_type)} {'('}
          {t('facility-max-short', 'max')} {(calendarRow.groupBy.subject as Facility).slot_capacity ?? '-'}
          {')'}
        </Badge>
      )}
      <div className={classNames('flex gap-1', { 'flex-wrap': orientation === 'col' })}>
        {rolesForGroupBy(calendarRow.groupBy)?.map(role => (
          <span className='ml-[2px] text-xs bg-neutral-200 rounded px-0.5 text-neutral-500 opacity-80' key={role.uid}>
            {role.name}
          </span>
        ))}
      </div>
    </div>
  );
}

export interface BodyProps {
  // The reserved width of the column header.
  columnHeaderWidth: number;

  // The calendar data.
  calendarView: CalendarView;

  // When dropping an item, will you be able to specify date/time or only the 'group by target'.
  dragDropType: DragDropType;

  // Set to true when we don't want to display the groupBy name. Like the horse name, contact name, etc.
  hideColumnHeaderLabel?: boolean;

  // Give the activity items a bit more height and spacing.
  spacious?: boolean;

  showTopBorder?: boolean;

  enableClickToExpand?: boolean;

  // On activity save from blue print modal
  onSaved?: () => Promise<void>;
}

interface DaySections {
  heights: number[];
  visibleRange?: { start: number; end: number };
  plannableRange?: { start?: number; end?: number; weekdays: Array<AvailableWeekdaysEnum> };
}

/**
 * A multi day calendar view.
 * This is visible at this part (`x` marks the spot) of the calendar.
 * The view uses a SlidableCalendarView for extra smoothness.
 *
 *        ┌──────┌──────┌──────┌──────┌──────┌──────┌──────┐
 *        │  12  │  13  │  14  │  15  │  16  │  17  │  18  │
 *        │      │      │      │      │      │      │      │
 * ┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼──────┼
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * │xxxxxx│xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx│
 * └──────└──────└──────└──────└──────└──────└──────└──────┘
 */
export function MultiDayBody({
  columnHeaderWidth: givenColumnHeaderWidth,
  calendarView,
  hideColumnHeaderLabel = false,
  dragDropType,
  spacious = false,
  showTopBorder = false,
  enableClickToExpand = true,
  onSaved,
}: BodyProps): JSX.Element {
  const {
    timeScale,
    dayParts,
    selectedGroupBy,
    setSelectedGroupBy,
    unsetBluePrint,
    roles,
    dragActivity,
    moveActivityToContact,
    workingHours,
    hideNonWorkingHours,
  } = usePlanning();
  const { now } = useRefreshingNow();
  const { width } = useScreenSize();

  const columnHeaderWidth = width <= ScreenSize.md ? colHeaderWidthMobile : givenColumnHeaderWidth;

  // Height of one activity based on the spacious flag.
  const activityHeight = useMemo(() => {
    return spacious ? ActivityHeightSpacious : ActivityHeight;
  }, [spacious]);

  // Get the TimeScale based on the GroupByApplied element.
  const groupedByTimeScale = useCallback(
    (groupByTarget?: GroupByApplied): TimeScale => {
      if (groupByTarget && equalGroupByApplied(selectedGroupBy, groupByTarget)) {
        return TimeScale.TimeScale;
      } else {
        return timeScale;
      }
    },
    [timeScale, selectedGroupBy],
  );

  /** Calculate the height of each day part. This differs for each view type.
   * For time scale view we divide into one hour blocks.
   * For day parts view we divide into day parts.
   * For full day view we only have one.
   * For dayparts/fullday, each height is adapted to the amount of activities (and leave one extra block for the blueprint).
   */
  const calendarRowDaySections = useCallback(
    (row: CalendarCluster): DaySections => {
      if (equalGroupByApplied(selectedGroupBy, row.groupBy) || timeScale === TimeScale.TimeScale) {
        let plannableRange: { start?: number; end?: number; weekdays: Array<AvailableWeekdaysEnum> } | undefined;
        if (row.groupBy.groupBy === GroupBy.Facility) {
          const facility = row.groupBy.subject as Facility;

          // if(facility.available_weekdays)
          if (facility.available_from || facility.available_to || facility.available_weekdays.length) {
            plannableRange = { weekdays: facility.available_weekdays };
            if (facility.available_from) {
              const time = parseTime(facility.available_from);
              plannableRange.start = time.hours + (time.minutes > 0 ? time.minutes / 60 : 0);
            }
            if (facility.available_to) {
              const time = parseTime(facility.available_to);
              plannableRange.end = time.hours + (time.minutes > 0 ? time.minutes / 60 : 0);
            }
          }
        }

        let visibleRange: { start: number; end: number } | undefined;
        if (workingHours && hideNonWorkingHours) {
          visibleRange = { start: workingHours.from.hours, end: workingHours.to.hours };
        }
        return {
          heights: Array(DayParts.Hour).fill(activityHeight),
          visibleRange,
          plannableRange,
        };
      }
      if (timeScale === TimeScale.DayParts) {
        const heights: number[] = Array(dayParts?.length).fill(activityHeight);
        heights.splice(0, row.maxActivitiesInADayPart.length, ...row.maxActivitiesInADayPart.map(count => (count + 1) * activityHeight));
        return { heights };
      }
      if (timeScale === TimeScale.FullDay) {
        return { heights: [row.maxActivitiesInADay * activityHeight + activityHeight] };
      }
      console.error('Cannot calculate row heights for unknown TimeScale');
      return { heights: [] };
    },
    [dayParts, selectedGroupBy, timeScale, activityHeight, workingHours, hideNonWorkingHours],
  );

  interface DragOver {
    index: number; // What cluster index are we dragging over.
  }

  const [dragOver, setDragOver] = useState<DragOver | undefined>();

  return (
    <div className='flex select-none'>
      <div style={{ width: columnHeaderWidth }} className='shrink-0 flex flex-col items-end select-none'>
        {calendarView.current.clusters.map(calendarRow => {
          const sections = calendarRowDaySections(calendarRow);
          const subjectTimeScale = groupedByTimeScale(calendarRow.groupBy);
          // All heights together.
          const visibleHeights = sections.heights?.slice(
            sections.visibleRange?.start ?? 0,
            sections.visibleRange?.end ?? sections.heights.length,
          );
          const fullHeight = visibleHeights.reduce((partialSum, height) => partialSum + height, 0);
          return (
            <div
              key={calendarRow.id}
              style={{ width: columnHeaderWidth }}
              className={classNames('overflow-visible', { 'pt-8 md:pt-0': !hideColumnHeaderLabel })}
            >
              {!hideColumnHeaderLabel && (
                <div className='flex md:hidden w-screen border-b items-end max-h-8 h-8 -mt-8 sticky top-0 bg-neutral-50 z-10'>
                  <ColHeader
                    orientation='row'
                    calendarRow={calendarRow}
                    roles={roles ?? []}
                    fullHeight={fullHeight}
                    activityHeight={activityHeight}
                  />
                </div>
              )}
              <div
                className={classNames('flex border-r shrink-0 relative items-start justify-end', {
                  'hover:bg-gray-100 group hover:cursor-pointer overflow-hidden': timeScale !== TimeScale.TimeScale && enableClickToExpand,
                })}
                style={{
                  width: columnHeaderWidth,
                  height: fullHeight,
                }}
                onClick={() => {
                  if (enableClickToExpand && timeScale !== TimeScale.TimeScale) {
                    unsetBluePrint(0);

                    setSelectedGroupBy(equalGroupByApplied(selectedGroupBy, calendarRow.groupBy) ? undefined : calendarRow.groupBy);
                  }
                }}
              >
                {!hideColumnHeaderLabel && width > ScreenSize.md && (
                  <ColHeader
                    orientation='col'
                    calendarRow={calendarRow}
                    roles={roles ?? []}
                    fullHeight={fullHeight}
                    activityHeight={activityHeight}
                  />
                )}
                {timeScale !== TimeScale.TimeScale && (
                  <div
                    className={classNames('text-white group-hover:visible rounded-full p-1 absolute bottom-2 left-2 bg-blue-500', {
                      visible: equalGroupByApplied(selectedGroupBy, calendarRow.groupBy),
                      invisible: !equalGroupByApplied(selectedGroupBy, calendarRow.groupBy),
                    })}
                  >
                    <ArrowsVertical />
                  </div>
                )}
                {!hideColumnHeaderLabel && <div className={classNames('absolute inset-x-0 bottom-0 border-b h-1')} />}
                {subjectTimeScale !== TimeScale.FullDay && (
                  <>
                    <DayGridScale
                      heights={sections.heights}
                      visibleRange={sections.visibleRange}
                      dayParts={subjectTimeScale === TimeScale.DayParts ? dayParts : undefined}
                    />
                    <DayGrid
                      showFirstGridLine={showTopBorder}
                      className='w-1.5 min-w-1.5'
                      heights={sections.heights}
                      visibleRange={sections.visibleRange}
                    />
                    {subjectTimeScale === TimeScale.TimeScale && (
                      <DayNowIndicator
                        heights={sections.heights}
                        visibleRange={sections.visibleRange}
                        day={new Date()}
                        now={now}
                        shape={Shape.Triangle}
                        className='absolute inset-y-0 right-0 w-[7px]'
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <SlidableCalendarView
        calendarView={calendarView}
        className='grow'
        render={(calendar: Calendar) => {
          return (
            <div className='w-full'>
              {calendar.clusters.map((calendarRow, clusterIndex) => {
                const sections = calendarRowDaySections(calendarRow);
                // All heights together.
                const visibleHeights = sections.heights?.slice(
                  sections.visibleRange?.start ?? 0,
                  sections.visibleRange?.end ?? sections.heights.length,
                );
                const fullHeight = visibleHeights.reduce((partialSum, height) => partialSum + height, 0);
                return (
                  <div
                    key={calendarRow.id}
                    style={{
                      height: fullHeight,
                    }}
                    className={classNames('w-full flex flex-row relative', {
                      'border-t': showTopBorder,
                      'mt-8 md:mt-0': !hideColumnHeaderLabel,
                    })}
                    onDragOver={event => {
                      if (
                        dragDropType === DragDropType.Cluster ||
                        (dragDropType === DragDropType.ClusterPlus && dragActivity && dragActivity.originGroupByUid !== calendarRow.groupBy)
                      ) {
                        setDragOver({ index: clusterIndex });
                        event.preventDefault();
                      }
                    }}
                    onDragLeave={() => setDragOver(undefined)}
                    onDrop={event => {
                      event.preventDefault();
                      setDragOver(undefined);
                      const uid = dragActivity?.activity.uid;
                      if (!uid) {
                        console.error('Failed to get uid from drag event');
                        return;
                      }
                      if (dragOver) {
                        moveActivityToContact(
                          uid,
                          dragActivity.originGroupByUid?.subject?.uid,
                          calendarRow.groupBy.subject?.uid,
                          undefined,
                          undefined,
                          undefined,
                        ).catch(e => {
                          // TODO: Inform the user (we might need to do this via the usePlanning hook)
                          console.error(e);
                        });
                      }
                    }}
                  >
                    {calendarRow.columns.map(calendarDay => (
                      <DayGrid
                        className='grow h-full border-r'
                        key={calendarDay.day.valueOf()}
                        heights={sections.heights}
                        visibleRange={sections.visibleRange}
                      >
                        {groupedByTimeScale(calendarRow.groupBy) === TimeScale.TimeScale && (
                          <>
                            <ActivityContainerTimeScale
                              dragDropType={dragDropType}
                              heights={sections.heights}
                              visibleRange={sections.visibleRange}
                              plannableRange={sections.plannableRange}
                              day={calendarDay.day}
                              className='absolute inset-0'
                              activities={calendarDay.activities}
                              appliedGroupBy={calendarRow.groupBy}
                              showContactAvatar={
                                calendarRow.groupBy.groupBy === GroupBy.Horse || calendarRow.groupBy.groupBy === GroupBy.Stable
                              }
                              showHorseName={
                                calendarRow.groupBy.groupBy === GroupBy.StaffCatchAll ||
                                calendarRow.groupBy.groupBy === GroupBy.Staff ||
                                calendarRow.groupBy.groupBy === GroupBy.Stable
                              }
                              blockList={calendarDay.blockList}
                              onSaved={onSaved}
                            />
                            <DayNowIndicator
                              heights={sections.heights}
                              visibleRange={sections.visibleRange}
                              day={calendarDay.day}
                              now={now}
                              className='absolute inset-0 pointer-events-none'
                            />
                          </>
                        )}
                        {groupedByTimeScale(calendarRow.groupBy) !== TimeScale.TimeScale && (
                          <ActivityContainerDayParts
                            spacious={spacious}
                            dragDropType={dragDropType}
                            day={calendarDay.day}
                            className='absolute inset-0'
                            heights={sections.heights}
                            activities={calendarDay.activities}
                            appliedGroupBy={calendarRow.groupBy}
                            showContactAvatar={
                              calendarRow.groupBy.groupBy === GroupBy.Horse || calendarRow.groupBy.groupBy === GroupBy.Stable
                            }
                            showHorseName={
                              calendarRow.groupBy.groupBy === GroupBy.StaffCatchAll ||
                              calendarRow.groupBy.groupBy === GroupBy.Staff ||
                              calendarRow.groupBy.groupBy === GroupBy.Stable
                            }
                            onSaved={onSaved}
                          />
                        )}
                      </DayGrid>
                    ))}
                    {dragOver && dragOver.index === clusterIndex && (
                      <div className='absolute inset-0 bg-blue-500 bg-opacity-10 rounded-lg border-blue-500 border-2 pointer-events-none' />
                    )}
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
}
