import { Contact } from 'openapi';
import React from 'react';
import { table, tableTbody } from 'ui/Const';
import Row from './Row';
import UseCountries from 'hooks/UseCountries';

interface Props {
  locations: Contact[];
  onDeleted?: () => void;
}

function LocationTable({ locations, onDeleted }: Props): JSX.Element {
  // we get the countries here so it is only fetched once
  // and not in every row
  const { countries } = UseCountries();

  return (
    <>
      {locations && (
        <table className={table}>
          <tbody className={tableTbody}>
            {locations.map(stable => (
              <Row key={stable.uid} location={stable} onDeleted={onDeleted} countries={countries} />
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

export default LocationTable;
