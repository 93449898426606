import { ContactDetail, ModulePermissionsEnum } from 'openapi';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utilities/date.utilities';
import { Tile } from 'ui/Layout/Tile';
import DescriptionList from 'ui/Layout/DescriptionList';
import { Pencil } from '@phosphor-icons/react';
import { stringArrayToString } from 'utilities/string.utility';
import { DefinitionItem } from 'ui/Layout/DescriptionList/DescriptionListItem';
import OwnedHorseListTile from './Tiles/OwnedHorseListTile';
import OrdersTile from './Tiles/OrdersTile';
import UseCountries from 'hooks/UseCountries';
import { PageAction } from 'context/PageContext';
import usePermissions from 'hooks/UsePermissions';
import { ContactType, getContactType } from 'utilities/Contact';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import VatVerifiedBadge from './VatVerifiedBadge';

interface Props {
  contact: ContactDetail;
  onRequestUpdateAddress: () => void;
  onRequestUpdateGeneral: () => void;
}

function ContactDetails({ contact, onRequestUpdateAddress, onRequestUpdateGeneral }: Props): JSX.Element {
  const { t } = useTranslation();
  const { countryById } = UseCountries();
  const { hasPermission } = usePermissions();

  // detect if the contact is a personal contact
  const isBusiness = contact.business_name !== '';

  /**
   * Get the name of the country
   */
  const country = useMemo((): string => {
    const countryResult = countryById(contact.country);
    if (countryResult) {
      return countryResult.name;
    }

    return '-';
  }, [contact.country, countryById]);

  // generate the general description list
  // also include the business name if it's a business contact
  const generalDescriptionList = useMemo(() => {
    const list: DefinitionItem[] = [
      {
        term: t('title', 'Title'),
        definition: contact.title || '-',
      },
      {
        term: t('first-name', 'First name'),
        definition: contact.first_name || '-',
      },
      {
        term: t('last-name', 'Last name'),
        definition: contact.last_name || '-',
      },
      {
        term: t('email', 'Email'),
        definition: contact.email || '-',
      },
      {
        term: t('phone-number', 'Phone number'),
        definition: contact.phone_number || '-',
      },
    ];

    if (contact.second_phone_number) {
      list.push({
        term: t('second-phone-number', 'Second phone number'),
        definition: contact.second_phone_number,
      });
    }

    if (contact.date_of_birth) {
      list.push({
        term: t('date-of-birth', 'Date of birth'),
        definition: formatDate(new Date(contact.date_of_birth)),
      });
    }

    if (contact.note) {
      list.push({
        term: t('note', 'Note'),
        definition: contact.note,
      });
    }

    return list;
  }, [contact, t]);

  const businessDescriptionList = useMemo(() => {
    let list: DefinitionItem[] = [
      {
        term: t('business-name', 'Business name'),
        definition: contact.business_name || '-',
      },
    ];

    if (contact.first_name || contact.last_name) {
      list.push({
        term: t('first-name', 'First name'),
        definition: contact.first_name || '-',
      });
      list.push({
        term: t('last-name', 'Last name'),
        definition: contact.last_name || '-',
      });
    }

    list = [
      ...list,
      {
        term: t('email', 'Email'),
        definition: contact.email || '-',
      },
      {
        term: t('phone-number', 'Phone number'),
        definition: contact.phone_number || '-',
      },
    ];

    if (contact.second_phone_number) {
      list.push({
        term: t('second-phone-number', 'Second phone number'),
        definition: contact.second_phone_number || '-',
      });
    }

    list = [
      ...list,
      {
        term: t('company-registration-number', 'Company registration number'),
        definition: contact.company_registration_number || '-',
      },
      {
        term: t('vat-number', 'VAT number'),
        definition: contact.vat_number ? (
          <>
            {contact.vat_number} <VatVerifiedBadge vat_number_checked={contact.vat_number_checked} />
          </>
        ) : (
          '-'
        ),
      },
      {
        term: t('IBAN', 'IBAN'),
        definition: contact.IBAN || '-',
      },
    ];

    if (contact.UBN) {
      list.push({
        term: t('UBN', 'UBN'),
        definition: contact.UBN,
      });
    }

    if (contact.note) {
      list.push({
        term: t('note', 'Note'),
        definition: contact.note,
      });
    }

    return list;
  }, [contact, t]);

  const addressDescriptionList = useMemo(() => {
    const list: DefinitionItem[] = [];
    list.push({
      term: t('street', 'Street'),
      definition: stringArrayToString([contact.address_line1, contact.address_line3], ''),
    });
    if (contact.address_line2) {
      list.push({
        term: t('address-line2', 'Address line 2'),
        definition: contact.address_line2,
      });
    }
    list.push({
      term: t('postcode', 'Postcode'),
      definition: contact.postcode || '-',
    });
    list.push({
      term: t('city', 'City'),
      definition: contact.city || '-',
    });
    if (contact.state) {
      list.push({
        term: t('state', 'State'),
        definition: contact.state,
      });
    }
    list.push({
      term: t('country', 'Country'),
      definition: country || '-',
    });
    return list;
  }, [contact, t, country]);

  /**
   * Actions for the general tile
   */
  const generalTileAction = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_CONTACTS)) {
      return [
        {
          icon: <Pencil />,
          text: t('edit', 'Edit'),
          onClick: onRequestUpdateGeneral,
        },
      ];
    }
  }, [hasPermission, onRequestUpdateGeneral, t]);

  /**
   * Actions for the address tile
   */
  const addressTileAction = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_CONTACTS)) {
      return [
        {
          icon: <Pencil />,
          text: t('edit', 'Edit'),
          onClick: onRequestUpdateAddress,
        },
      ];
    }
  }, [hasPermission, onRequestUpdateAddress, t]);

  return (
    <>
      <Tile
        title={
          <>
            {t('general', 'General')}
            {getContactType(contact) === ContactType.Business && (
              <Badge className='ml-2' color={AllColors.Amber}>
                {t('business', 'Business')}
              </Badge>
            )}
            {getContactType(contact) === ContactType.Contact && (
              <Badge className='ml-2' color={AllColors.Blue}>
                {t('personal', 'Personal')}
              </Badge>
            )}
          </>
        }
        className='mb-4'
        actions={generalTileAction}
      >
        {isBusiness && <DescriptionList list={businessDescriptionList} />}
        {!isBusiness && <DescriptionList list={generalDescriptionList} />}
      </Tile>
      <Tile title={t('address', 'Address')} className='mb-4' actions={addressTileAction}>
        <DescriptionList list={addressDescriptionList} />
      </Tile>

      <OwnedHorseListTile contact={contact} />
      <OrdersTile contact={contact} />
    </>
  );
}

export default ContactDetails;
