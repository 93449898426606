import { MicrosoftExcelLogo, Plus } from '@phosphor-icons/react';
import useApiPromises from 'api/hooks/useApiPromises';
import { FilterOption, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import CreateHorseModal from 'components/Horses/CreateHorseModal';
import { useOrganization } from 'context/OrganizationContext';
import {
  CancelablePromise,
  ActivityTypeCategoryEnum,
  Contact,
  ContactDetail,
  ContactsService,
  Horse,
  HorseGroup,
  HorsegroupsService,
  HorsesService,
  ModulePermissionsEnum,
  Stable,
  StablesService,
} from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultApiPageSize } from 'ui/Const';
import { Tile } from 'ui/Layout/Tile';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeOrganizationHorses } from 'utilities/ApiRequests';
import ListFilterButton, { FilterButtonTypeEnum } from '../../components/Common/ListFilter/ListFilterButton';
import useListFilter from '../../components/Common/ListFilter/useListFilter';
import HorseList from '../../components/Horses/HorseList';
import { PageAction } from '../../context/PageContext';
import { ButtonVariant } from '../../ui/Button';
import Page from '../../ui/Layout/Page';
import { age, FilterHorse, gender, listFilter, textFilter } from 'utilities/Horse';
import { xlsxHorsesDownload } from 'components/Horses/Helpers';
import { useSearchParams } from 'react-router-dom';
import { contactName } from 'utilities/Contact';
import usePermissions from 'hooks/UsePermissions';
import { useAccount } from 'context/AccountContext';
import { stableListFilterTypes } from 'utilities/Stable';

export default function HorseListPage(): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganization, generateCacheKey: getCacheId } = useOrganization();
  const [searchText, setSearchText] = useState<string>('');
  const [groups, setGroups] = useState<HorseGroup[]>();
  const [stables, setStables] = useState<Stable[]>();
  const { accountDetails } = useAccount();
  // we use this contact so we can use the contact name
  // when the user filter on the owner of the horse
  const [ownedContact, setOwnedContact] = useState<ContactDetail>();
  const [horses, setHorses] = useState<Horse[]>();
  const [createHorseModalVisible, setCreateHorseModalVisible] = useState<boolean>(false);
  const [itemsVisible, setItemsVisible] = useState<number>(defaultApiPageSize);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [contacts, setContacts] = useState<Contact[]>();

  const [searchParams] = useSearchParams();
  const { hasPermission } = usePermissions();

  // When we have set a filter then we would like to load all available horses.
  // At first, we only load non-inactive horse with a stable (aka active horses).
  // Out of performance reasons we don't load all horses at first because all
  // horses from the webshop and all inactive horses are also in that list.
  const [loadAllHorses, setLoadAllHorses] = useState<boolean>(false);

  // Indicate if we're loading data from the api
  const { loading: loadingApiPromises } = useApiPromises({ apiPromises });

  const stableFilterTypes = useMemo((): ListFilterType[] | undefined => {
    return [stableListFilterTypes(t, stables ?? [], accountDetails?.preferred_stable ?? undefined)];
  }, [t, stables, accountDetails]);

  // A list of types we can filter by. Like Gender and DateOfBirth.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!horses) {
      return undefined;
    }
    let genderUnknownCount = 0;
    let genderStallionCount = 0;
    let genderMareCount = 0;
    let genderGeldingCount = 0;
    let inactiveCount = 0;
    let noStableCount = 0;

    // Counting the filters only makes sense if we have loaded all horses.
    const showCounts = loadAllHorses && !loadingApiPromises;

    const birthYears = new Map<number, number>();
    const ageCount = new Map<number, number>();
    let hasNotSetDate = 0; // True if we have a date that is undefined in the list.
    horses.forEach(h => {
      if (h.date_of_birth) {
        const date = new Date(Date.parse(h.date_of_birth));
        birthYears.set(date.getFullYear(), birthYears.get(date.getFullYear()) ?? 1);
        const horseAge = age(h);
        if (horseAge !== undefined && horseAge !== null) {
          // We already known the horseAge is not undefined (but TS wants this if-condition)
          ageCount.set(horseAge, ageCount.get(horseAge) ?? 1);
        }
      } else {
        hasNotSetDate++;
      }

      if (h.sex === 1) {
        genderStallionCount++;
      } else if (h.sex === 2) {
        genderMareCount++;
      } else if (h.sex === 3) {
        genderGeldingCount++;
      } else {
        genderUnknownCount++;
      }

      if (h.hidden) {
        inactiveCount++;
      }
      if (!h.stable_uid) {
        noStableCount++;
      }
    });

    const ageFilterOptions: FilterOption[] = [];
    if (hasNotSetDate > 0) {
      ageFilterOptions.push({ id: 'unknown', name: t('unknown', 'Unknown'), count: showCounts ? hasNotSetDate : undefined });
    }

    const sortedAges = Array.from(ageCount);
    sortedAges.sort();
    sortedAges.forEach(value => {
      ageFilterOptions.push({ id: `${value[0]}`, name: `${value[0]}`, count: showCounts ? value[1] : undefined });
    });

    const ageFilter: ListFilterType = {
      id: 'age',
      name: t('age', 'Age'),
      options: ageFilterOptions,
    };

    const genderFilter: ListFilterType = {
      id: 'gender',
      name: t('gender', 'Gender'),
      options: [
        { id: 'unknown', name: t('gender-unknown', 'Unknown'), count: showCounts ? genderUnknownCount : undefined },
        { id: 'stallion', name: gender(SexEnum._1, t), count: showCounts ? genderStallionCount : undefined },
        { id: 'mare', name: gender(SexEnum._2, t), count: showCounts ? genderMareCount : undefined },
        { id: 'gelding', name: gender(SexEnum._3, t), count: showCounts ? genderGeldingCount : undefined },
      ],
    };

    const passiveFilter: ListFilterType = {
      id: 'passive',
      name: t('passive', 'Passive'),
      options: [
        { id: 'inactive', name: t('inactive', 'Inactive'), count: showCounts ? inactiveCount : undefined },
        { id: 'no-stable', name: t('no-stable', 'No stable'), count: showCounts ? noStableCount : undefined },
      ],
    };

    const myLocationFilter: ListFilterType = {
      id: 'location',
      name: t('pastures-and-other-locations', 'Pastures and other locations'),
      options: (contacts ?? [])
        .filter(contact => contact.external_location)
        .map(contact => {
          return { id: contact.uid, name: contact.business_name ?? '-' };
        }),
    };

    const groupFilter: ListFilterType = {
      id: 'group',
      name: t('group', 'Group'),
      options: (groups ?? []).map(group => {
        return { id: group.uid, name: group.name ?? t('unnamed-group', 'Unnamed group') };
      }),
    };

    const categoryFilter: ListFilterType = {
      id: 'category',
      name: t('usage-category', 'Category'),
      options: [
        { id: ActivityTypeCategoryEnum.CARE, name: t('activity-type-category-care', 'Care') },
        { id: ActivityTypeCategoryEnum.SPORT, name: t('activity-type-category-sport', 'Sport') },
        { id: ActivityTypeCategoryEnum.BREEDING, name: t('activity-type-category-breeding', 'Breeding') },
      ],
    };

    const filters = [categoryFilter, groupFilter, myLocationFilter, genderFilter, ageFilter, passiveFilter];

    // include also the horses that are owned by a contact, given via the query parameter only
    // we should not show this filter when it is not present in the query parameters
    const ownedUid = searchParams.get('owned');
    if (ownedUid) {
      const name = ownedContact ? contactName(ownedContact) : ownedUid;
      filters.push({
        id: 'owned',
        name: t('owned-by', 'Owned by'),
        options: [{ id: ownedUid, name: name ?? ownedUid, count: undefined }],
      });
    }

    // Filter on horses that have no UELN or chip number
    // this is not an filter that is shown in the UI but is used in the RVO import process
    // when an import failed due to horses without UELN or chip number
    const noUELNorChipNr = searchParams.get('noUELNOrChipNr');
    if (noUELNorChipNr) {
      filters.push({
        id: 'noUELNOrChipNr',
        name: t('no-UELN-or-chip-nr', 'No UELN or chip number'),
        options: [{ id: noUELNorChipNr, name: 'yes' }],
      });
    }

    return filters;
  }, [horses, loadAllHorses, loadingApiPromises, t, contacts, searchParams, ownedContact, groups]);

  const { filters } = useListFilter(filterTypes ?? []);
  const { filters: stableFilters } = useListFilter(stableFilterTypes ?? []);

  const filterHorses = useCallback(
    (horses: Horse[]): FilterHorse[] => {
      // We find filter matches and text matches separately. This way we can display
      // results that are outside the filter. I.e. By default, horses without a
      // stable and inactive horses are filtered out. But when searching for a horse
      // you want to include them to have a full result instead of a text filtered
      // result of already filtered items.
      const filterMatch = listFilter(horses, filters.concat(stableFilters));
      if (!searchText) {
        return filterMatch.map(horse => {
          return { horse: horse, foundOutsideFilter: false };
        });
      }
      const textFilterMatch = textFilter(horses, searchText);
      const res = textFilterMatch.map(horse => {
        return { horse: horse, foundOutsideFilter: !filterMatch.includes(horse) };
      });
      res.sort((a, b) => {
        if (a.foundOutsideFilter !== b.foundOutsideFilter) {
          return a.foundOutsideFilter ? 1 : -1;
        }
        return 0;
      });
      return res;
    },
    [filters, stableFilters, searchText],
  );

  // List of horses after filter
  const filteredHorses = useMemo((): FilterHorse[] => {
    if (!horses) {
      return [];
    }
    return filterHorses(horses);
  }, [horses, filterHorses]);

  // We don't show the full list directly. Make use of the fetch more strategy of PullToRefresh.
  const visibleHorses = useMemo((): { horses: FilterHorse[]; canFetchMore: boolean } => {
    if (filteredHorses.length === 0) {
      return { horses: [], canFetchMore: false };
    }
    return { horses: filteredHorses.slice(0, itemsVisible), canFetchMore: itemsVisible < filteredHorses.length };
  }, [filteredHorses, itemsVisible]);

  /**
   * Load the contact by UID
   */
  const loadContactByUid = useCallback(
    (contactUid: string): CancelablePromise<ContactDetail> => {
      const promise = ContactsService.contactsRetrieve({
        organisationUid: selectedOrganization?.uid ?? '',
        uid: contactUid,
      });

      promise
        .then(contact => setOwnedContact(contact))
        .catch(() => {
          // ignore error as when the contact is not found the only thing that
          // happens is that the name is not shown but the UID instead
        });

      return promise;
    },
    [selectedOrganization?.uid],
  );

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();
    setItemsVisible(defaultApiPageSize);
    if (loadAllHorses) {
      promises.appendList<Horse>(
        'all-horses',
        () => {
          return HorsesService.horsesList({
            organisationUid: selectedOrganization?.uid ?? '',
            o: 'name',
          });
        },
        setHorses,
      );
    } else {
      promises.appendListObj<Horse>('horses', setHorses, activeOrganizationHorses(selectedOrganization?.uid ?? '', getCacheId));
    }

    promises.appendList<HorseGroup>(
      'horse-groups',
      () =>
        HorsegroupsService.horsegroupsList({
          organisationUid: selectedOrganization?.uid ?? '',
        }),
      setGroups,
      getCacheId('horse-groups'),
    );

    promises.appendList<Stable>(
      'stables',
      () =>
        StablesService.stablesList({
          organisationUid: selectedOrganization?.uid ?? '',
        }),
      setStables,
      getCacheId('stables'),
    );

    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      // Note: We use the contacts endpoint within the horse service.
      promises.appendList<Contact>(
        'contacts',
        () =>
          ContactsService.contactsList({
            organisationUid: selectedOrganization?.uid ?? '',
          }),
        setContacts,
        getCacheId('contacts'),
      );
    } else {
      // Note: We use the contacts endpoint within the horse service.
      promises.appendList<Contact>(
        'horse-contacts',
        () =>
          HorsesService.horsesContactsList({
            organisationUid: selectedOrganization?.uid ?? '',
          }),
        setContacts,
        getCacheId('horse-contacts'),
      );
    }

    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, getCacheId, loadAllHorses, hasPermission]);

  // Export the horse list based upon the filters. Not that horses outside the
  // filters are put into a separate worksheet.
  const exportToXlsx = useCallback(async () => {
    const result = await HorsesService.horsesList({
      organisationUid: selectedOrganization?.uid ?? '',
      o: 'name',
    });
    const filterMatch = listFilter(result.results, filters);
    const textFilterMatch = textFilter(result.results, searchText);
    const filteredHorses = textFilterMatch.map(horse => ({ horse: horse, foundOutsideFilter: !filterMatch.includes(horse) }));
    xlsxHorsesDownload(t, filteredHorses, groups ?? [], contacts ?? []);
  }, [t, selectedOrganization, filters, searchText, groups, contacts]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, loadAllHorses]); //eslint-disable-line

  // When filters change the make sure we load all our horses.
  useEffect(() => {
    if (filters.length > 0 || searchText) {
      setLoadAllHorses(true);
    }
  }, [filters, searchText]); //eslint-disable-line

  /**
   * Event that is invoked when we create a new horse
   */
  const onCreated = async () => {
    // as we are unsure how the sorting is on the API
    // the best way to include the new horse is to call loadHorses().
    // Because this is async, the createDialog will be closed after loadHorses() has been finished
    await loadApiData();
  };

  const pageActions = useMemo((): PageAction[] | undefined => {
    if (hasPermission(ModulePermissionsEnum.MANAGE_HORSES)) {
      return [
        {
          text: t('add-horse', 'Add horse'),
          isMobileAddAction: true,
          icon: <Plus />,
          buttonVariant: ButtonVariant.Primary,
          onClick: () => setCreateHorseModalVisible(true),
        },
      ];
    }
  }, [hasPermission, t]);

  /**
   * Load the contact by UID when the query parameter is present for the owned contact
   * we use this to filter the horses by the owner
   */
  useEffect(() => {
    const ownedBy = searchParams.get('owned');
    if (ownedBy) {
      const promise = loadContactByUid(ownedBy);
      return () => promise.cancel();
    }
  }, [loadContactByUid, searchParams]);

  return (
    <>
      <Page title={t('horses', 'Horses')} actions={pageActions} loading={apiPromises} showEmptyListPlaceholder={horses?.length === 0}>
        <PullScrollWrapper
          onRefresh={() => loadApiData().watchAll()}
          canFetchMore={visibleHorses.canFetchMore}
          onFetchMore={() => {
            setItemsVisible(itemsVisible + defaultApiPageSize);
            return Promise.resolve();
          }}
        >
          <Tile noBoxOnMobile={true} overflowContent={true}>
            <FilterWrapper
              listFilterTypes={filterTypes}
              actions={[
                {
                  text: t('export', 'Export'),
                  icon: <MicrosoftExcelLogo className='h-5 w-5' />,
                  buttonCompress: true,
                  onClick: exportToXlsx,
                },
              ]}
            >
              {(stables?.length ?? 0) > 1 && (
                <ListFilterButton
                  currentCountDisplay={() => `* ${t('your-preferred-stable', 'Your preferred stable')}`}
                  type={FilterButtonTypeEnum.Stable}
                  listFilterTypes={stableFilterTypes ?? []}
                />
              )}
              <ListFilterButton
                listFilterTypes={filterTypes ?? []}
                currentCountDisplay={() => t('current-horse-count', 'currently showing {{count}} horses', { count: filteredHorses.length })}
              />
              <input
                type='search'
                onChange={e => setSearchText(e.currentTarget.value)}
                size={10}
                placeholder={t('search-horse-placeholder', 'Search by Name, UELN, etc...')}
                className='placeholder:italic placeholder:text-sm px-2 max-w-md grow h-10 rounded-md border bg-white'
              />
              {loadingApiPromises && loadAllHorses && <Spinner size={SpinnerSize.Small} />}
            </FilterWrapper>
            <HorseList horseGroups={groups} horses={visibleHorses.horses} contacts={contacts} />
          </Tile>
        </PullScrollWrapper>
      </Page>
      <CreateHorseModal
        open={createHorseModalVisible}
        onRequestCloseModal={() => setCreateHorseModalVisible(false)}
        onCreated={onCreated}
      />
    </>
  );
}
