import { ArrowLeft, DotsThreeVertical, IconContext, List, TextOutdent } from '@phosphor-icons/react';
import { usePage } from 'context/PageContext';
import React, { useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { generatePath } from 'utilities/ReactRouter';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import DropdownMenu, { DropdownMenuItem, DropdownMenuItemArray } from 'ui/DropdownMenu';
import ButtonBadge from 'ui/Button/ButtonBadge';

interface Props {
  // When undefined, the sidebar menu icon is not shown.
  toggleSidebar?: () => void;
  open: boolean;
}

export default function Header({ open, toggleSidebar }: Props): JSX.Element {
  const { breadCrumbs, actions } = usePage();
  const params = useParams();
  const isMobileDevice = useIsMobileDevice();

  // @todo Add dropdown menu if actions count is above 2.

  // we should deal with params (:uid) in the url and add the matching value from useParams()
  const path = breadCrumbs.length > 0 ? generatePath(breadCrumbs[breadCrumbs.length - 1].path, params) : '';

  /**
   * Get the mobile menu items only
   */
  const mobileMenuItems = useMemo(() => {
    return actions.filter(actions => !actions.isMobileAddAction);
  }, [actions]);

  /**
   * Sum the badgeCount as total
   */
  const badgeCount = mobileMenuItems.reduce((total, currentItem) => {
    return total + (currentItem.badge ? currentItem.badge : 0);
  }, 0);

  /**
   * Render a list of page actions for the dropdown meu
   */
  const dropdownMenuItems = useMemo((): DropdownMenuItemArray[] | undefined => {
    const formattedMenuItems = mobileMenuItems.map<DropdownMenuItem>(action => ({
      element: (
        <>
          {action.text} {action.badge && action.badge > 0 && <ButtonBadge className='ml-2' badge={action.badge} />}
        </>
      ),
      onClick: action.onClick,
      isDisabled: action.disabled,
    }));

    if (formattedMenuItems.length > 0) {
      return [formattedMenuItems];
    }
  }, [mobileMenuItems]);

  // bail out if we are not on mobile
  if (!isMobileDevice) {
    return <></>;
  }

  return (
    <IconContext.Provider
      value={{
        size: 26,
        weight: 'regular',
        color: 'white',
      }}
    >
      <div className='flex print:hidden fixed z-20 inset-x-0 top-0 select-none text-white mt-safe justify-between w-full h-14 pointer-events-none'>
        <div className='w-16 flex'>
          {breadCrumbs.length > 0 && (
            <NavLink className='pointer-events-auto flex items-center px-3 cursor-pointer' to={path}>
              <ArrowLeft />
            </NavLink>
          )}
          {breadCrumbs.length === 0 && toggleSidebar && (
            <button className='pointer-events-auto flex items-center px-3 cursor-pointer' onClick={toggleSidebar}>
              {open ? <TextOutdent /> : <List />}
            </button>
          )}
        </div>

        <div className='w-16 flex items-center'>
          {dropdownMenuItems && (
            <DropdownMenu
              menuItems={dropdownMenuItems}
              offset={-15}
              buttonAs='button'
              buttonClassName='pointer-events-auto flex items-center justify-center px-3 w-full h-full grow'
              badge={badgeCount}
            >
              <DotsThreeVertical />
            </DropdownMenu>
          )}
        </div>
      </div>
    </IconContext.Provider>
  );
}
