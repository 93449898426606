import { zodResolver } from '@hookform/resolvers/zod';
import { HorseDetail, HorsePage, HorsepagesService, ModulePermissionsEnum } from 'openapi';
import useFormError from 'api/hooks/useFormError';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, DateInput } from 'ui/Inputs';
import { PageModal } from 'ui/Modals';
import { ActionProps, PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { zodInputIsRequired } from 'utilities/zod';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import TogggleInput from 'ui/Inputs/ToggleInput';
import { useOrganization } from 'context/OrganizationContext';
import ApiErrorParser from 'api/ApiErrorParser';
import { copyTextToClipboard } from 'utilities/string.utility';
import { addYears } from 'date-fns';
import { Alert } from 'ui/Alert';
import { Severity } from 'utilities/severity';
import classNames from 'classnames';
import usePermissions from 'hooks/UsePermissions';

interface HorsePageCreate extends HorsePage {
  adjust_expiration_date: boolean;
}

interface Props {
  horse: HorseDetail;
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onShared?: () => void;
}

function ShareHorseModal({ horse, isVisible, onRequestCloseModal }: Props): JSX.Element {
  const [horsePageCreated, setHorsePageCreated] = useState<HorsePage>();

  const { t } = useTranslation();
  const { selectedOrganizationUid } = useOrganization();
  const linkRef = useRef<HTMLParagraphElement>(null);
  const { hasPermission } = usePermissions();

  /**
   * Schema for the form
   */
  const schema = useMemo(() => {
    let fieldsToPick: { [k in keyof HorsePage]?: true } = {
      expires_at: true,
    };

    if (hasPermission(ModulePermissionsEnum.MEDICAL_FILES)) {
      fieldsToPick = { ...fieldsToPick, include_medical_documents: true };
    }

    return schemas.HorsePage.pick(fieldsToPick);
  }, [hasPermission]);

  /**
   * Get the current url from the location object
   * include the protocol, host and port if available
   */
  const currentUrl = `${window.location.protocol}//${window.location.host}`;

  // Construct the default values
  const defaultValues = useMemo((): Partial<HorsePage> => {
    return {
      expires_at: addYears(new Date(), 1).toISOString().substring(0, 10),
      horse_uid: horse.uid,
      include_medical_documents: false,
    };
  }, [horse.uid]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    clearErrors,
    register,
    watch,
  } = useForm<HorsePageCreate>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const adjustExpirationDateFormValue = watch('adjust_expiration_date');

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    clearErrors();
    setApiError(undefined);
    reset(defaultValues);
    setHorsePageCreated(undefined);
  };

  /**
   * Submit event handler, update the data via the API for this user
   */
  const onSubmit: SubmitHandler<HorsePage> = async (data: HorsePage) => {
    if (!selectedOrganizationUid || !horse) return;

    // clrear previous errors
    setApiError(undefined);

    try {
      const promise = HorsepagesService.horsepagesCreate({
        horseOrganisationUid: selectedOrganizationUid,
        requestBody: {
          horse_uid: horse.uid,
          expires_at: data.expires_at ?? null,
          include_medical_documents: data.include_medical_documents,
        } as HorsePage,
      });
      const horsePageCreated = await promise;
      setHorsePageCreated(horsePageCreated);
    } catch (error) {
      setApiError(new ApiErrorParser<HorsePage>(error));
    }
  };

  const actions = useMemo((): ActionProps[] => {
    const actions: ActionProps[] = [];

    if (!horsePageCreated) {
      actions.push({
        formId: 'shareHorse',
        loading: isSubmitting,
        variant: ButtonVariant.Primary,
        type: 'submit',
        text: t('share', 'Share'),
      });
    }

    actions.push({
      disabled: isSubmitting,
      onClick: onRequestCloseModal,
      variant: ButtonVariant.Default,
      type: 'button',
      text: !horsePageCreated ? t('cancel', 'Cancel') : t('close', 'Close'),
    });

    return actions;
  }, [horsePageCreated, isSubmitting, onRequestCloseModal, t]);

  const createLinkSelection = () => {
    if (linkRef.current) {
      // create a selection object
      const selection = window.getSelection();

      if (selection) {
        // create a range
        const range = document.createRange();
        range.selectNodeContents(linkRef.current);

        // Create the selection by
        // - remove the old selection
        // - and add the new selection
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  /**
   * When we creat the share, make sure we select the link
   */
  useEffect(() => {
    createLinkSelection();
  }, [horsePageCreated]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'shareHorse', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
      width={PageModalWidth.Sm}
    >
      <PageModalTitle title={t('share-horse', 'Share horse')} onClose={onRequestCloseModal} />
      <PageModalContent>
        <div
          className={classNames({
            hidden: horsePageCreated,
          })}
        >
          <ErrorSection className='mb-4' errors={nonFieldErrors} />
          <p className='mb-4'>
            {t(
              'horse-share-desc',
              'Here you can create an unique link for sharing the basic info of your horse. Visiting this link will show the basic info of your horse to your customer or viewer.',
            )}
          </p>
          <div className='space-y-4'>
            <div>
              <DateInput
                disabled={!adjustExpirationDateFormValue}
                control={control}
                error={fieldError('expires_at')}
                required={zodInputIsRequired<HorsePage>(schema, 'expires_at')}
                label={t('expires-at', 'Expires at')}
                name='expires_at'
              />

              <CheckboxInput
                {...register('adjust_expiration_date')}
                error={fieldError('adjust_expiration_date')}
                label={t('adjust-expiration-date', 'Adjust expiration date')}
              />
            </div>

            {hasPermission(ModulePermissionsEnum.MEDICAL_FILES) && (
              <TogggleInput
                name='include_medical_documents'
                control={control}
                error={fieldError('include_medical_documents')}
                label={t('include-medical-documents', 'Include medical documents')}
                hint={t('include-medical-documents-desc', 'Include the medical documents in the shared link')}
              />
            )}
          </div>
        </div>

        {horsePageCreated && (
          <>
            <Alert severity={Severity.Success} message={t('horse-share-link-created', 'Share link created')} />
            <p className='my-4'>
              {t('horse-share-success-desc', 'Your link has been created. You can copy the link below and share it with your customers.')}
            </p>
            <div className='flex items-center justify-between gap-2 mt-2 pt-2 border py-1 px-2'>
              <p ref={linkRef} className='italic text-sm'>{`${currentUrl}/p/horse/${horsePageCreated.public_access_uuid}`}</p>
              <Button
                onClick={() => {
                  createLinkSelection();
                  copyTextToClipboard(`${currentUrl}/p/horse/${horsePageCreated.public_access_uuid}`);
                }}
                variant={ButtonVariant.Default}
                size={ButtonSize.XSmall}
                type='button'
              >
                {t('copy', 'Copy')}
              </Button>
            </div>
          </>
        )}
      </PageModalContent>
      <PageModalActions actions={actions} />
    </PageModal>
  );
}

export default ShareHorseModal;
