import ApiErrorParser from 'api/ApiErrorParser';
import { useOrganization } from 'context/OrganizationContext';
import { HorseLocation, HorselocationsService } from 'openapi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { ActionModal } from 'ui/Modals';

interface Props {
  isVisible: boolean;
  onRequestCloseModal: () => void;
  onClosed: () => void;
  onDeleted: (location: HorseLocation) => void;
  location: HorseLocation | undefined;
}

function DeleteLocationHistoryModal({ isVisible, onRequestCloseModal, location, onDeleted, onClosed }: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiErrorParser<HorseLocation> | undefined>();

  const { t } = useTranslation();
  const { selectedOrganization } = useOrganization();

  const remove = async () => {
    if (!selectedOrganization || !location) return;
    setSubmitting(true);

    try {
      await HorselocationsService.horselocationsDestroy({
        locationOrganisationUid: selectedOrganization.uid,
        uid: location.uid,
      });

      // close the modal
      onRequestCloseModal();

      // fire the onDeleted callback
      onDeleted(location);
    } catch (error) {
      setApiError(new ApiErrorParser<HorseLocation>(error));
    } finally {
      setSubmitting(false);
    }
  };

  const onClosedModal = () => {
    setApiError(undefined);
    onClosed();
  };

  return (
    <ActionModal
      onClosed={onClosedModal}
      open={isVisible}
      onClose={onRequestCloseModal}
      actions={[
        {
          text: t('cancel', 'Cancel'),
          variant: ButtonVariant.Default,
          onClick: onRequestCloseModal,
        },
        {
          text: t('delete', 'Delete'),
          loading: submitting,
          variant: ButtonVariant.PrimaryDanger,
          onClick: remove,
        },
      ]}
      title={t('delete-location-history', 'Delete location history')}
    >
      {apiError && <ErrorSection errors={apiError} />}
      <p className='mt-2  w-full'>{t('delete-location-history-desc', 'Are you sure you want to remove this location history?')}</p>
    </ActionModal>
  );
}

export default DeleteLocationHistoryModal;
