import { DotsThreeVertical, Plus } from '@phosphor-icons/react';
import { FacilitiesService, Facility, Stable, StablesService } from 'openapi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { Tile } from 'ui/Layout/Tile';
import useModal from 'ui/Modals/UseModal';
import { table, tableHiddenHeaderMd, tableTbody, tableTbodyTrNoClick, tableThead, tableTheadTd } from 'ui/Const';
import DropdownMenu from 'ui/DropdownMenu';
import { ApiPromises } from 'utilities/ApiPromises';
import { useOrganization } from 'context/OrganizationContext';
import { facilityTypeToString } from 'utilities/Planning';
import SaveFacilityModal from './SaveFacilityModal';
import Badge from 'ui/Badge';
import { AllColors } from 'utilities/colors';
import DeleteFacilityModal from './DeleteFacilityModal';

export default function SettingsFacilitiesTile(): JSX.Element {
  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { t } = useTranslation();
  const { showModal: showSaveModal, closeModal: closeSaveModal, modalIsVisible: saveModalIsVisible } = useModal();
  const { showModal: showDeleteModal, closeModal: closeDeleteModal, modalIsVisible: deleteModalIsVisible } = useModal();
  const [facilities, setFacilities] = useState<Facility[]>();
  const [stables, setStables] = useState<Stable[]>([]);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const [selectedFacility, setSelectedFacility] = useState<Facility | undefined>();

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }
    promises.appendList<Facility>(
      'facilities',
      () =>
        FacilitiesService.facilitiesList({
          organisationUid: selectedOrganizationUid,
        }),
      setFacilities,
    );
    promises.appendList<Stable>(
      'stables',
      () =>
        StablesService.stablesList({
          organisationUid: selectedOrganizationUid,
        }),
      setStables,
      generateCacheKey('stables'),
    );
    setApiPromises(promises);
    return promises;
  }, [selectedOrganizationUid, generateCacheKey]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganizationUid) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganizationUid]); //eslint-disable-line

  /**
   * Return the actions for the tile
   */
  const tileActions = useMemo(() => {
    return [
      {
        onClick: () => {
          setSelectedFacility(undefined);
          showSaveModal();
        },
        text: t('add', 'Add'),
        buttonVariant: ButtonVariant.Default,
        icon: <Plus />,
      },
    ];
  }, [showSaveModal, t]);

  return (
    <Tile title={t('facilities', 'Facilities')} loading={apiPromises} actions={tileActions}>
      {facilities?.length === 0 && (
        <p className='text-gray-500 italic'>
          {t('no-facilities-settings', 'There are no facilities, please create one with the "add"-button.')}
        </p>
      )}
      {(facilities?.length ?? 0) > 0 && (
        <table className={table}>
          <thead className={tableThead}>
            <tr className={tableHiddenHeaderMd}>
              <td className={tableTheadTd}>{t('name', 'Name')}</td>
              <td className={tableTheadTd}>{t('category', 'Category')}</td>
              <td className='w-10' />
            </tr>
          </thead>
          <tbody className={tableTbody}>
            {facilities?.map(facility => (
              <tr className={tableTbodyTrNoClick} key={facility.uid}>
                <td>
                  {facility.name}
                  {facility.hidden && <Badge color={AllColors.Rose}>{t('disabled', 'Disabled')}</Badge>}
                </td>
                <td>{facilityTypeToString(t, facility.facility_type)}</td>
                <td align='right'>
                  <DropdownMenu
                    menuPlacement='right-end'
                    menuItems={[
                      [
                        {
                          element: t('edit', 'Edit'),
                          onClick: () => {
                            setSelectedFacility(facility);
                            showSaveModal();
                          },
                        },
                      ],
                      [
                        {
                          element: t('remove', 'Remove'),
                          className: 'text-red-600',
                          onClick: () => {
                            setSelectedFacility(facility);
                            showDeleteModal();
                          },
                        },
                      ],
                    ]}
                  >
                    <button className='px-2'>
                      <DotsThreeVertical size={20} weight='bold' />
                    </button>
                  </DropdownMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <SaveFacilityModal
        isVisible={saveModalIsVisible}
        onRequestClose={() => {
          closeSaveModal();
          setSelectedFacility(undefined);
        }}
        onSaved={() => {
          setSelectedFacility(undefined);
          loadApiData();
        }}
        facility={selectedFacility}
        stables={stables}
      />
      <DeleteFacilityModal
        isVisible={deleteModalIsVisible}
        onRequestClose={() => {
          closeDeleteModal();
          setSelectedFacility(undefined);
        }}
        onDeleted={() => {
          setSelectedFacility(undefined);
          loadApiData();
        }}
        facility={selectedFacility}
      />
    </Tile>
  );
}
