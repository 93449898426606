import React from 'react';
import { IconContext } from '@phosphor-icons/react';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

interface FooterButtonProps {
  path?: string;
  name: string;
  icon: ReactNode;
}

function FooterButton({ path, name, icon }: FooterButtonProps): JSX.Element {
  const location = useLocation();
  const active = !path ? false : path.startsWith(location.pathname) || location.pathname.startsWith(path);

  return (
    <IconContext.Provider
      value={{
        size: 20,
      }}
    >
      <div
        className={classNames('w-full min-w-16 flex flex-col items-center text-xs gap-1.5 font-medium', {
          ['text-blue-700']: active,
        })}
      >
        {icon}
        <span className='w-full truncate text-nowrap text-center'>{name}</span>
      </div>
    </IconContext.Provider>
  );
}

export default FooterButton;
