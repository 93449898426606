import { CaretRight, Plus, Stool } from '@phosphor-icons/react';
import SaveStallionMount from 'components/Breeding/SaveStallionMount';
import { AppliedListFilter, FilterOption, ListFilterType } from 'components/Common/ListFilter';
import FilterWrapper from 'components/Common/ListFilter/FilterWrapper';
import useListFilter from 'components/Common/ListFilter/useListFilter';
import HorseSelectButton from 'components/Horses/HorseSelectButton';
import { useOrganization } from 'context/OrganizationContext';
import { Contact, Horse, StallionMount, StallionmountsService } from 'openapi';
import { SexEnum } from 'openapi/models/SexEnum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  defaultApiPageSize,
  table,
  tableHiddenColumnMd,
  tableHiddenHeaderSm,
  tableTbody,
  tableTbodyTr,
  tableTheadTd,
  tableTheadTdSticky,
} from 'ui/Const';
import PullScrollWrapper from 'ui/PullScrollWrapper';
import { PageAction } from 'context/PageContext';
import { ButtonVariant } from 'ui/Button';
import Page from 'ui/Layout/Page';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'context/AccountContext';
import { Tile } from 'ui/Layout/Tile';
import { ApiPromises } from 'utilities/ApiPromises';
import { activeContacts, organizationHorses } from 'utilities/ApiRequests';

export default function StallionMountListPage(): JSX.Element {
  const { t } = useTranslation();
  const [horses, setHorses] = useState<Horse[]>();
  const [contacts, setContacts] = useState<Contact[]>();
  const [addMountModalOpen, setAddMountModalOpen] = useState<boolean>(false);
  const [mounts, setMounts] = useState<StallionMount[]>();
  const { selectedOrganization, generateCacheKey } = useOrganization();
  const navigate = useNavigate();
  const { formatDate } = useAccount();
  const [apiPromises, setApiPromises] = useState<ApiPromises>();

  // Filter all horses by type Stallion.
  const stallions = useMemo(() => {
    if (!horses) return [];
    return horses.filter(horse => horse.sex === SexEnum._1);
  }, [horses]);

  // A list of types we can filter by.
  const filterTypes = useMemo((): ListFilterType[] | undefined => {
    if (!stallions) {
      return undefined;
    }

    // A list of available stallions to choose from
    const stallionFilterOps: FilterOption[] = stallions.map(stallion => {
      return { id: stallion.uid, name: stallion.name };
    });

    const stallionFilter: ListFilterType = {
      id: 'stallion',
      name: t('stallion', 'Stallion'),
      options: stallionFilterOps, // We don't provide a list of stallions here, we hav a special dropdown for this.
    };

    return [stallionFilter];
  }, [t, stallions]);

  const { filters, setFilters } = useListFilter(filterTypes ?? []);

  // Get the selected stallion based on the filter (url search query).
  const selectedStallionUid = useMemo((): string | undefined => {
    const foundFilter = filters.find(filter => filter.type.id === 'stallion');
    if (!foundFilter) {
      return undefined;
    }
    if (foundFilter.options.length !== 1) {
      return undefined;
    }

    return foundFilter.options[0].id;
  }, [filters]);

  // Get the selected stallion based on the filter (url search query).
  const selectedStallion = useMemo((): Horse | undefined => {
    if (!selectedStallionUid) return undefined;
    return stallions.find(horse => horse.uid === selectedStallionUid);
  }, [stallions, selectedStallionUid]);

  // Select a stallion
  const selectStallion = useCallback(
    (horse?: Horse) => {
      if (!filterTypes) {
        return;
      }
      const foundFilterType = filterTypes.find(f => f.id === 'stallion');
      if (!foundFilterType) {
        // should never happen.
        console.error('Filter type not found');
        return;
      }

      // The selection is cleared.
      if (!horse) {
        setFilters([]);
        return;
      }

      const stallionFilter: AppliedListFilter = {
        type: foundFilterType,
        options: [{ id: horse.uid, name: horse.name }],
      };
      setFilters([stallionFilter]);
    },
    [filterTypes, setFilters],
  );

  const pageActions = useMemo((): PageAction[] => {
    return [
      {
        text: t('add-mount', 'New semen collection'),
        isMobileAddAction: true,
        icon: <Plus />,
        buttonVariant: ButtonVariant.Primary,
        onClick: () => {
          setAddMountModalOpen(true);
        },
      },
    ];
  }, [t]);

  const formatStorage = (mount: StallionMount): string => {
    if (!mount.storage_container) {
      return '-';
    }
    let result = mount.storage_container;
    if (mount.storage_canister) {
      result += ' / ' + mount.storage_canister;
    }
    return result;
  };

  const formatStallion = (mount: StallionMount): string => {
    const found = (horses ?? []).find(stallion => stallion.uid === mount.stallion_uid);
    if (!found) {
      return '-';
    }
    return found.name;
  };

  // Load data from the api/cache
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganization) {
      return promises;
    }
    promises.setPaginated<StallionMount>(
      'mounts',
      apiPageNumber =>
        StallionmountsService.stallionmountsList({
          stallionOrganisationUid: selectedOrganization.uid,
          page: apiPageNumber,
          pageSize: defaultApiPageSize,
          stallionUid: selectedStallionUid,
          o: '-collection_date,id',
        }),
      setMounts,
      selectedStallionUid === undefined, // Only count as empty when we don't filter.
    );
    promises.appendListObj<Horse>('horses', setHorses, organizationHorses(selectedOrganization.uid, generateCacheKey));
    promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganization.uid, generateCacheKey));
    setApiPromises(promises);
    return promises;
  }, [selectedOrganization, generateCacheKey, selectedStallionUid]);

  // Load from the api
  useEffect(() => {
    if (selectedOrganization) {
      const promise = loadApiData();
      return () => promise.cancel();
    }
  }, [selectedOrganization, selectedStallionUid]); //eslint-disable-line

  return (
    <>
      <Page title={t('semen-journal', 'Semen journal')} loading={apiPromises} actions={pageActions}>
        <PullScrollWrapper apiPromises={apiPromises}>
          <Tile noBoxOnMobile={true}>
            {filterTypes && horses && (
              <FilterWrapper>
                <HorseSelectButton selectedHorse={selectedStallion} stallionsOnly={true} horses={horses} horseSelected={selectStallion} />
              </FilterWrapper>
            )}
            <table className={table}>
              <thead>
                <tr className={tableHiddenHeaderSm}>
                  <td className={classNames('w-10', tableTheadTdSticky)} />
                  <td className={tableTheadTdSticky}>{t('semen-collection-date', 'Collection date')}</td>
                  {!selectedStallionUid && <td className={tableTheadTdSticky}>{t('stallion', 'Stallion')}</td>}
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('semen-volume', 'Volume')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>
                    {t('semen-concentration-million-per-ml-short', 'Conc. (mil/ml)')}
                  </td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('moving-cells', 'Moving cells')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('semen-portions-fresh', 'Portions fresh')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('semen-straws-frozen', 'Straws frozen')}</td>
                  <td className={classNames(tableTheadTd, tableTheadTdSticky)}>{t('semen-storage', 'Storage')}</td>

                  <td className='w-10 md:hidden' />
                </tr>
              </thead>
              <tbody className={tableTbody}>
                {(mounts ?? []).map(mount => {
                  return (
                    <tr className={tableTbodyTr} key={mount.uid} onClick={() => navigate(mount.uid)}>
                      <td className='text-center w-10'>
                        <Stool size={24} weight='light' className='inline' />
                      </td>
                      <td className={tableHiddenColumnMd}>
                        {mount.collection_date && formatDate(new Date(Date.parse(mount.collection_date)))}
                      </td>
                      {!selectedStallionUid && <td className={tableHiddenColumnMd}>{formatStallion(mount)}</td>}
                      <td className={tableHiddenColumnMd}>{mount.volume ? mount.volume + ' ml' : '-'}</td>
                      <td className={tableHiddenColumnMd}>{mount.concentration ? mount.concentration : '-'}</td>
                      <td className={tableHiddenColumnMd}>{mount.moving_cells_percentage ? mount.moving_cells_percentage + ' %' : '-'}</td>
                      <td className={tableHiddenColumnMd}>
                        {mount.portions_fresh ?? '-'}
                        <span className='text-gray-500 text-sm'>{` (${mount.portions_fresh_available ?? 0} ${t('in-stock', 'in stock')})`}</span>
                      </td>
                      <td className={tableHiddenColumnMd}>
                        {mount.straws_frozen ?? '-'}
                        <span className='text-gray-500 text-sm'>{` (${mount.straws_frozen_available ?? 0} ${t('in-stock', 'in stock')})`}</span>
                      </td>
                      <td className={tableHiddenColumnMd}>{formatStorage(mount)}</td>
                      <td className={classNames('md:hidden')}>
                        <div className='flex items-center px-2 gap-2'>
                          <div className='grow'>
                            {!selectedStallionUid && (
                              <>
                                {mount.collection_date && formatDate(new Date(Date.parse(mount.collection_date)))} {t('by', 'by')}{' '}
                                {formatStallion(mount)}
                              </>
                            )}
                            {selectedStallionUid && <>{mount.collection_date && formatDate(new Date(Date.parse(mount.collection_date)))}</>}
                          </div>
                          <CaretRight size={22} weight='light' className='inline' />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tile>
        </PullScrollWrapper>
      </Page>
      <SaveStallionMount
        stallions={stallions}
        contacts={contacts ?? []}
        selectedStallion={selectedStallion}
        open={addMountModalOpen}
        onSaved={(reload: boolean) => {
          if (reload) {
            loadApiData();
          }
        }}
        onRequestClose={() => setAddMountModalOpen(false)}
      />
    </>
  );
}
