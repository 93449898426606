import { zodResolver } from '@hookform/resolvers/zod';
import { CheckCircle, Horse as HorseIcon, MagnifyingGlass, MapPinArea, X } from '@phosphor-icons/react';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import classNames from 'classnames';
import { useOrganization } from 'context/OrganizationContext';
import {
  Contact,
  EquineMHorseSearchDetail,
  HorseGroup,
  HorseLocationMove,
  HorsegroupsService,
  HorsesService,
  ModulePermissionsEnum,
  Stable,
  StablesService,
} from 'openapi';
import { HorseDetail } from 'openapi/models/HorseDetail';
import { schemas } from 'openapi/zod-schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { CheckboxInput, DateInput, SelectInput, TextInput } from 'ui/Inputs';
import { InputSize, OptionItemInterface } from 'ui/Inputs/SelectInput';
import { Label } from 'ui/Label';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle } from 'ui/Modals/PageModal';
import useModal from 'ui/Modals/UseModal';
import { transformEmpty, transformEmptyNumber, transformEmptyToUndefined, zodInputIsRequired } from 'utilities/zod';
import { z } from 'zod';
import HorseBasicDetailsModal from './HorseBasicDetailsModal';
import TogggleInput from 'ui/Inputs/ToggleInput';
import ContactInputSelect from 'components/Contacts/ContactInputSelect';
import { colorList } from 'utilities/string.utility';
import { bloodlineString, gender, genderList } from 'utilities/Horse';
import Fieldset from 'ui/Fieldset';
import { WrappedComboboxProps } from 'ui/Inputs/SelectList';
import { BreedingBadge, CareBadge, SportBadge } from './HorseUsageBadges';
import { ApiPromises } from 'utilities/ApiPromises';
import usePermissions from 'hooks/UsePermissions';
import { activeContacts } from 'utilities/ApiRequests';
import useApiPromises from 'api/hooks/useApiPromises';
import { useAccount } from 'context/AccountContext';
import { Spinner } from 'ui/Loading';
import { SpinnerSize } from 'ui/Loading/Spinner';
import Badge from 'ui/Badge';
import { BadgeSize } from 'ui/Badge/Badge';
import RvoConfirmReportModal from './RvoConfirmReportModal';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'AppRoutes';

interface HorseCreate extends HorseDetail {
  move_date: HorseLocationMove['date'];
  move_destination_uid: HorseLocationMove['destination_uid'];
  move_arrival_is_import: HorseLocationMove['arrival_is_import'];
  location_differs_from_stable: boolean;
}

interface FoundHorse {
  details: HorseDetail;
  selected: boolean;
}

interface Props {
  open: boolean;
  onRequestCloseModal: () => void;
  onCreated: (newHorse: HorseDetail) => Promise<void> | void;
  defaultName?: string;
  newOwner?: Contact;

  // disabled fields
  disableStable?: boolean;
  disableUsage?: boolean;
  disableLocation?: boolean;
}

function CreateHorseModal({
  open,
  onRequestCloseModal,
  onCreated,
  defaultName,
  newOwner,
  disableStable,
  disableUsage,
  disableLocation,
}: Props): JSX.Element {
  const [foundHorse, setFoundHorse] = useState<FoundHorse>();
  const [searchHorse, setSearchHorse] = useState<EquineMHorseSearchDetail>();
  const [searchingHorse, setSearchingHorse] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>();
  const [groups, setGroups] = useState<HorseGroup[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [stables, setStables] = useState<Stable[]>([]);
  const [apiPromises, setApiPromises] = useState<ApiPromises>();
  const { errorString, loading } = useApiPromises({ apiPromises, catchErrors: true });
  const [createdHorse, setCreatedHorse] = useState<HorseDetail>();

  const { selectedOrganizationUid, generateCacheKey } = useOrganization();
  const { accountDetails } = useAccount();
  const { t } = useTranslation();
  const { closeModal: closeBasicHorseModal, modalIsVisible: basicHorseModalIsVisible, showModal: showBasicHorseModal } = useModal();
  const { closeModal: closeModalRvo, modalIsVisible: modalIsVisiblRvo, showModal: showModalRvo } = useModal();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  // convert the color list to a selectList
  const colors = useMemo((): OptionItemInterface[] => colorList(t).map<OptionItemInterface>(c => ({ id: c.value, name: c.label })), [t]);
  const genders = useMemo((): OptionItemInterface[] => genderList(t).map<OptionItemInterface>(c => ({ id: c.value, name: c.label })), [t]);

  // Form validation
  const schema = useMemo(() => {
    const fields: { [k in keyof HorseDetail]?: true } = {};

    // include the fields that are only needed when we create a new horse
    if (!foundHorse?.selected) {
      fields.name = true;
      fields.color = true;
      fields.sex = true;
      fields.date_of_birth = true;
      fields.UELN = true;
      fields.chip_nr = true;
      fields.withers_height = true;
    }

    if (!disableStable) {
      fields.stable_uid = true;
    }

    if (!disableUsage) {
      fields.use_in_breeding = true;
      fields.use_in_care = true;
      fields.use_in_sport = true;
    }

    const _schema = schemas.HorseDetail.pick(fields);

    if (!disableStable) {
      _schema.extend({
        stable_uid: z.string().nullish(),
      });
    }

    if (!disableLocation) {
      _schema.extend({
        move_destination_uid: z.string().nullish(),
        move_arrival_is_import: z.boolean().default(false),
        move_date: z.string(),
      });
    }

    // change the schema when we create a new horse
    if (!foundHorse?.selected) {
      return _schema.extend({
        // We should have a nullable type for both color and sex
        // as we are sure about the value, it is a number, we could add also a nullable validator
        color: z.number().nullable(),
        sex: z.number().nullable(),
      });
    }

    return _schema;
  }, [disableLocation, disableStable, disableUsage, foundHorse?.selected]);

  const defaultValues = useMemo(() => {
    return {
      move_date: new Date().toISOString().substring(0, 10),
      move_arrival_is_import: false,
      use_in_breeding: !disableUsage ? true : false,
      use_in_care: !disableUsage ? true : false,
      use_in_sport: !disableUsage ? true : false,
    };
  }, [disableUsage]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    control,
  } = useForm<HorseCreate>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  // watch specific form values.
  // needed for checking if there is an inactive horse with the same values
  const nameFormValue = watch('name');
  const uelnFormValue = watch('UELN');
  const chipNrFormValue = watch('chip_nr');
  const destinationUidFormValue = watch('move_destination_uid');
  const stableUidFormValue = watch('stable_uid');
  const locationDiffersFromStableValue = watch('location_differs_from_stable');

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  // Set the destinations variable
  const destinationContact = contacts?.find(contact => contact.uid === destinationUidFormValue);

  // Visibility fields
  // See https://gitlab.qubis.nl/equinem/equinemcore/-/wikis/Horse/Move-field-information
  const fieldIsImportIsVisible = !destinationContact;

  // build the option list for the select
  const groupOptions: OptionItemInterface[] = groups.map(group => ({
    id: group.uid,
    name: group.name ?? group.uid,
  }));

  // build the option list for the select
  const stableOptions: OptionItemInterface[] = stables.map(stable => ({
    id: stable.uid,
    name: stable.location.business_name,
  }));

  /**
   * Split into 2 groups
   * 1. My locations
   * 2. other contacts
   */
  const filteredContacts = useMemo((): WrappedComboboxProps<Contact>[] => {
    return [
      {
        heading: t('my-locations', 'My locations'),
        items: (contacts ?? []).filter(contact => contact.external_location || contact.stable_location_uid),
        notFoundLabel: t('no-locations-found', 'No locations found'),
        icon: <MapPinArea />,
      },
      {
        heading: t('contacts', 'Contacts'),
        items: (contacts ?? []).filter(contact => !contact.external_location && !contact.stable_location_uid),
        notFoundLabel: t('no-contacts-found', 'No contacts found'),
      },
    ];
  }, [contacts, t]);

  /**
   * Submit handler
   */
  const onSubmit = async (data: HorseCreate) => {
    if (!selectedOrganizationUid) return;

    setSubmitting(true);

    try {
      // save the newHorse so we can use it in the onCreated event
      let newHorse: HorseDetail;

      // there are 2 situations
      // 1) we have found a horse and we should set the location and group
      // 2) we have not found a horse and we should create a new one

      if (foundHorse?.selected) {
        // we should set the horse active and set the location
        const setLocationPromise = HorsesService.horsesMoveCreate({
          organisationUid: selectedOrganizationUid,
          uid: foundHorse.details.uid,
          requestBody: {
            set_active: true,
            date: data.move_date,
            destination_uid: data.move_destination_uid,
            arrival_is_import: data.move_arrival_is_import,
          },
        });
        await setLocationPromise;

        // set the other data for the horse
        const setGroupPromise = HorsesService.horsesPartialUpdate({
          organisationUid: selectedOrganizationUid,
          uid: foundHorse.details.uid,
          requestBody: {
            group_uid: data.group_uid,
            sex: data.sex,
            color: data.color,
            date_of_birth: data.date_of_birth,
            use_in_breeding: data.use_in_breeding,
            use_in_care: data.use_in_care,
            use_in_sport: data.use_in_sport,
            new_owners: newOwner ? [newOwner.uid] : undefined,
          },
        });
        newHorse = await setGroupPromise;
      } else {
        const createHorsePromise = HorsesService.horsesCreate({
          organisationUid: selectedOrganizationUid,
          requestBody: {
            stable_uid: data.stable_uid,
            name: data.name,
            group_uid: data.group_uid,
            UELN: data.UELN,
            chip_nr: data.chip_nr,
            sex: data.sex,
            color: data.color,
            date_of_birth: data.date_of_birth,
            withers_height: data.withers_height,
            use_in_breeding: data.use_in_breeding,
            use_in_care: data.use_in_care,
            use_in_sport: data.use_in_sport,
            dam: data.dam,
            sire: data.sire,
            damsire: data.damsire,
            FEI_pass_nr: data.FEI_pass_nr,
            id_horsetelex: data.id_horsetelex,
            studbook: data.studbook,
            register_move: {
              date: data.move_date,
              destination_uid: data.move_destination_uid,
              arrival_is_import: data.move_arrival_is_import,
            },
            new_owners: newOwner ? [newOwner.uid] : undefined,
          } as HorseDetail,
        });

        newHorse = await createHorsePromise;
      }

      // fire the onCreated event
      await onCreated(newHorse);

      // save the horse, so we can check if we need to show an RVO modal
      setCreatedHorse(newHorse);
      // show the cofirm modal when we have mutations
      if (destinationContact?.machtiging_rvo_gegeven) {
        showModalRvo();
      }

      // close the modal
      onRequestCloseModal();
    } catch (error: unknown) {
      setApiError(new ApiErrorParser(error));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * event that will be fired after the modal has been closed
   */
  const onClosed = () => {
    setApiError(undefined);
    setFoundHorse(undefined);
    reset(defaultValues);
    setSearchHorse(undefined);
    setSearchingHorse(false);
  };

  /**
   * Load data from the api/cache
   */
  const loadApiData = useCallback((): ApiPromises => {
    const promises = new ApiPromises();

    if (!selectedOrganizationUid) {
      return promises;
    }

    // Load all contacts, including the removed once.
    // fall back on the horseContactsList endpoint if the user does not have the permission to view all contacts
    if (hasPermission(ModulePermissionsEnum.VIEW_CONTACTS)) {
      promises.appendListObj<Contact>('contacts', setContacts, activeContacts(selectedOrganizationUid, generateCacheKey));
    } else {
      promises.appendList<Contact>(
        'contacts',
        () =>
          HorsesService.horsesContactsList({
            organisationUid: selectedOrganizationUid,
          }),
        setContacts,
        generateCacheKey('horse-contacts'),
      );
    }

    promises.appendList<HorseGroup>(
      'horse-groups',
      () =>
        HorsegroupsService.horsegroupsList({
          organisationUid: selectedOrganizationUid,
        }),
      setGroups,
      generateCacheKey('horse-groups'),
    );

    promises.appendList<Stable>(
      'stables',
      () =>
        StablesService.stablesList({
          organisationUid: selectedOrganizationUid,
        }),
      setStables,
      generateCacheKey('stables'),
    );

    setApiPromises(promises);
    return promises;
  }, [generateCacheKey, hasPermission, selectedOrganizationUid]);

  /**
   * Toggle a foundHorse as selected or not
   */
  const toggleSelectedHorse = (selected: boolean) => {
    setFoundHorse(prevState => (prevState ? { ...prevState, selected } : undefined));
  };

  /**
   * True when all input fields are identical with the suggested values from searchHorse.
   */
  const autoFilled = useMemo(() => {
    if (!searchHorse) {
      return false;
    }
    return (
      (!searchHorse.name || searchHorse.name === getValues('name')) &&
      (!searchHorse.date_of_birth || searchHorse.date_of_birth === getValues('date_of_birth')) &&
      (!searchHorse.sex || searchHorse.sex === getValues('sex')) &&
      (!searchHorse.sire || searchHorse.sire === getValues('sire')) &&
      (!searchHorse.dam || searchHorse.dam === getValues('dam')) &&
      (!searchHorse.damsire || searchHorse.damsire === getValues('damsire')) &&
      (!searchHorse.UELN || searchHorse.UELN === getValues('UELN')) &&
      (!searchHorse.chip_nr || searchHorse.chip_nr === getValues('chip_nr')) &&
      (!searchHorse.FEI_pass_nr || searchHorse.FEI_pass_nr === getValues('FEI_pass_nr')) &&
      (!searchHorse.id_horsetelex || searchHorse.id_horsetelex === getValues('id_horsetelex')) &&
      (!searchHorse.current_location_arrival_date || searchHorse.current_location_arrival_date === getValues('move_date')) &&
      (!searchHorse.studbook || searchHorse.studbook === getValues('studbook'))
    );
  }, [searchHorse, getValues]);

  // Update the input fields with the found horse data.
  const autoFill = useCallback(() => {
    if (searchHorse?.name) {
      setValue('name', searchHorse.name);
    }
    if (searchHorse?.date_of_birth) {
      setValue('date_of_birth', searchHorse.date_of_birth);
    }
    if (searchHorse?.sex) {
      setValue('sex', searchHorse.sex);
    }
    if (searchHorse?.sire) {
      setValue('sire', searchHorse.sire);
    }
    if (searchHorse?.dam) {
      setValue('dam', searchHorse.dam);
    }
    if (searchHorse?.damsire) {
      setValue('damsire', searchHorse.damsire);
    }
    if (searchHorse?.UELN && getValues('UELN') !== searchHorse.UELN) {
      setValue('UELN', searchHorse.UELN);
    }
    if (searchHorse?.chip_nr && getValues('chip_nr') !== searchHorse.chip_nr) {
      setValue('chip_nr', searchHorse.chip_nr);
    }
    if (searchHorse?.FEI_pass_nr) {
      setValue('FEI_pass_nr', searchHorse.FEI_pass_nr);
    }
    if (searchHorse?.id_horsetelex) {
      setValue('id_horsetelex', searchHorse.id_horsetelex);
    }
    if (searchHorse?.current_location_arrival_date) {
      setValue('move_date', searchHorse.current_location_arrival_date);
    }
    if (searchHorse?.studbook) {
      setValue('studbook', searchHorse.studbook);
    }
  }, [searchHorse, setValue, getValues]);

  /**
   * Initial load when the modal opened
   */
  useEffect(() => {
    if (!open) return;
    if (!selectedOrganizationUid) return;

    const promise = loadApiData();
    return () => promise.cancel();
  }, [loadApiData, open, selectedOrganizationUid]);

  /**
   * in case we just have one value for Stables, Locations or Groups
   * we can use that first value and hide the form field.
   */
  useEffect(() => {
    if (groups.length === 1) {
      setValue('group_uid', groups[0].uid);
    }

    let stable: Stable | undefined = undefined;

    // With only one stable it's simple.
    if (stables.length === 1) {
      stable = stables[0];
    }

    // When we have a preferred stable, then search for that one.
    if (!stable && accountDetails?.preferred_stable) {
      stable = stables.find(stable => stable.uid === accountDetails?.preferred_stable);
    }

    // Otherwise take the first stable.
    if (!stable && stables.length > 0) {
      stable = stables[0];
    }

    // Only set the stable if we not disable the stable field
    !disableStable && setValue('stable_uid', stable?.uid);

    // Set the move_destination_uid to the stable location uid but only when the location field is not disabled
    !disableLocation && setValue('move_destination_uid', stable?.location.uid);
  }, [groups, contacts, setValue, stables, accountDetails?.preferred_stable, disableStable, disableLocation]);

  /**
   * If we change the stable_uid, we should also set the move_destination_uid as the stable_uid
   */
  useEffect(() => {
    // If the stableUidFormValue is set to undefined, it has not set yet
    if (stableUidFormValue === undefined) return;

    // if it has set to null, it means the user has reset th default value
    if (stableUidFormValue === null) {
      setValue('move_destination_uid', undefined);
      return;
    }

    // find the stable with the given stable_uid
    // and update the move_destination_uid with the location uid of the stable
    const stable = contacts.find(contact => contact.stable_location_uid === stableUidFormValue);
    if (stable) {
      setValue('move_destination_uid', stable.uid);
    }
  }, [contacts, setValue, stableUidFormValue, stables]);

  /**
   * check if the user either has given a name && ueln OR name && chipNr
   * We debounce  so we don't make api calls on every keystroke
   */
  useEffect(() => {
    if (!selectedOrganizationUid) return;

    // use a timeout so we can debounced our keystroke
    const timer = setTimeout(() => {
      let params:
        | {
            uelnIexact?: string;
            chipNrIexact?: string;
            nameIcontains?: string;
          }
        | undefined = undefined;

      // check if we need to set name and UELN or name and chip_nr
      if (nameFormValue && uelnFormValue) {
        params = {
          nameIcontains: nameFormValue,
          uelnIexact: uelnFormValue,
        };
      } else if (nameFormValue && chipNrFormValue) {
        params = {
          nameIcontains: nameFormValue,
          chipNrIexact: chipNrFormValue,
        };
      }

      // Search for horse in RVO, HorseTelex, etc for auto filling the form.
      if (uelnFormValue || chipNrFormValue) {
        const findHorseInfo = HorsesService.horsesSearchHorseCreate({
          organisationUid: selectedOrganizationUid,
          requestBody: { q: uelnFormValue ?? chipNrFormValue ?? '' },
        });

        setSearchingHorse(true);
        findHorseInfo
          .then(res => {
            // For now we ignore when we have multiple values
            if (res.items.length === 1) {
              setSearchHorse(res.items[0]);
            } else if (res.items.length === 0) {
              setSearchHorse(undefined);
            }
          })
          .catch(error => console.error('Failed to search horse', error))
          .finally(() => setSearchingHorse(false));
      }

      // if we have set the params for the request, check if the horse can be found
      if (params) {
        const promise = HorsesService.horsesList({
          organisationUid: selectedOrganizationUid,
          hidden: true,
          onUnknownLocation: false,
          ...params,
        });
        promise
          .then(res => {
            if (res.results?.length) {
              const found = res.results[0];
              HorsesService.horsesRetrieve({ organisationUid: selectedOrganizationUid, uid: found.uid })
                .then(details => {
                  setFoundHorse({ details, selected: false });
                })
                .catch(error => console.error(error));
            } else {
              setFoundHorse(undefined);
            }
          })
          .catch(error => console.error(error));
      } else {
        setFoundHorse(undefined);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [nameFormValue, uelnFormValue, chipNrFormValue, selectedOrganizationUid, setSearchingHorse]);

  /**
   * reset the move_arrival_is_import value once the destinationContact has been set
   */
  useEffect(() => {
    if (destinationContact) {
      setValue('move_arrival_is_import', undefined);
    }
  }, [destinationContact, setValue]);

  /**
   * set the defaultName value to the name input
   */
  useEffect(() => {
    setValue('name', defaultName ?? '');
  }, [defaultName, setValue]);

  /**
   * Set the group to the first default_id
   */
  useEffect(() => {
    const defaultGroup = groups.find(group => group.default_id === 1);
    if (defaultGroup) {
      setValue('group_uid', defaultGroup?.uid);
    }
  }, [groups, setValue]);

  /**
   * Reset the state where we hold the created horse when opening the modal
   */
  useEffect(() => {
    if (open) {
      setCreatedHorse(undefined);
    }
  }, [open]);

  return (
    <>
      <PageModal
        open={open}
        onClosed={onClosed}
        parentElement='form'
        parentProps={{ id: 'addHorseForm', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      >
        <PageModalTitle title={t('add-horse', 'Add horse')} onClose={onRequestCloseModal} />
        <PageModalContent>
          {/* api load errors */}
          {errorString && <ErrorSection errors={errorString} />}

          {/* Form errors */}

          {/* Show stable error when the stable field is hidden */}
          {stables.length <= 1 && <ErrorSection className='mb-4' errors={fieldError('stable_uid')} />}

          {/* Show stable error when the group field is hidden */}
          {groups.length <= 1 && <ErrorSection className='mb-4' errors={fieldError('group_uid')} />}

          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='space-y-4'>
            <div
              className={classNames({
                // use a grid layout when we have fields to show on the right-side
                // disableLocation, disableStable and disableUsage are used to hide the fields on the left-side
                'grid md:grid-cols-2 gap-4': !(disableLocation && disableStable && disableUsage),
              })}
            >
              <div className='flex flex-col gap-4'>
                {!foundHorse?.selected && (
                  <>
                    <TextInput
                      error={fieldError('name')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'name')}
                      label={t('name', 'Name')}
                      {...register('name', { setValueAs: transformEmptyToUndefined() })}
                    />
                    <TextInput
                      error={fieldError('UELN')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'UELN')}
                      label={t('horse_ueln', 'UELN')}
                      {...register('UELN', { setValueAs: transformEmptyToUndefined() })}
                      postText={searchingHorse ? <Spinner size={SpinnerSize.XSmall} /> : undefined}
                    />
                    {searchHorse && (
                      <div
                        className={classNames('mt-1 border rounded-md p-3 font-medium relative flex items-center', {
                          'border-indigo-500 bg-indigo-50': !autoFilled,
                          'border-green-500 bg-green-50': autoFilled,
                        })}
                      >
                        <div className='grow flex flex-row items-center gap-2'>
                          <MagnifyingGlass className='inline' />
                          <div>
                            <p className='text-sm text-gray-700 flex flex-row items-center gap-1'>
                              <span>{searchHorse.name}</span>
                              <Badge size={BadgeSize.Small}>{searchHorse.source_db.toString()}</Badge>
                            </p>
                            {bloodlineString(searchHorse) && <p className='text-xs text-gray-500'>{bloodlineString(searchHorse)}</p>}
                          </div>
                        </div>
                        {autoFilled && (
                          <p className='text-sm text-green-500 flex items-center gap-1'>
                            <CheckCircle className='inline text-lg' />
                            <span>{t('horse-auto-filled', 'Auto filled')}</span>
                          </p>
                        )}
                        {!autoFilled && !searchingHorse && (
                          <Button type='button' onClick={autoFill}>
                            {t('auto-fill', 'Auto fill')}
                          </Button>
                        )}
                      </div>
                    )}
                    <TextInput
                      // Chrome does not respect the autocomplete="off" attribute on the input field.
                      // So in this case Chrome think that chip number (translated in NL to chipnummer) is a cc field (match to trigger the cc autofill is the label name "*nummer")
                      // To prevent Chrome from autocomplete as CC we e use the "bday-day" autocomplete. bday-day is a numeric field (chip number is also numeric)
                      // @see https://issues.chromium.org/issues/41163264#comment165
                      // @see https://issues.chromium.org/issues/339033226
                      // @see https://gitlab.qubis.nl/equinem/equiapp/-/issues/202
                      autoComplete='bday-day'
                      error={fieldError('chip_nr')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'chip_nr')}
                      label={t('horse_chip_nr', 'Chip number')}
                      {...register('chip_nr', { setValueAs: transformEmptyToUndefined() })}
                    />
                    {!foundHorse?.selected && (
                      <SelectInput
                        error={fieldError('sex')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'sex')}
                        options={genders}
                        nullable={true}
                        nullableValue=''
                        label={t('gender', 'Gender')}
                        {...register('sex', { setValueAs: transformEmptyNumber(null) })}
                      />
                    )}
                  </>
                )}

                {foundHorse && (
                  <div>
                    {!foundHorse.selected && <Label>{t('existing_horse', 'Existing Horse')}</Label>}
                    {foundHorse.selected && <Label>{t('horse', 'Horse')}</Label>}

                    <div
                      className={classNames('mt-1 border rounded-md p-3 text-gray-500 font-medium text-sm relative', {
                        'border-indigo-500 bg-indigo-50': !foundHorse.selected,
                        'border-green-500 bg-green-50': foundHorse.selected,
                      })}
                    >
                      {foundHorse.selected && (
                        <button type='button' className='absolute right-2 top-2' onClick={() => toggleSelectedHorse(false)}>
                          <X size={20} />
                        </button>
                      )}

                      <div className='space-y-3'>
                        {!foundHorse.selected && (
                          <>
                            <p>{t('existing_horse_title', 'We have found an inactive horse with the same identification.')}</p>
                            <p>{t('existing_horse_sub_title', 'Do you want to use existing information?')}</p>
                          </>
                        )}

                        {foundHorse.selected && <p>{t('existing_horse_selected_title', 'You have selected the following horse')}</p>}

                        <div className='flex'>
                          <div className='flex-1 flex gap-3'>
                            <div className='w-16 h-16 rounded-lg overflow-hidden relative bg-gray-400 flex items-center justify-center shrink-0'>
                              {foundHorse.details.avatar_file && <img className='w-full h-full' src={foundHorse.details.avatar_file} />}
                              {!foundHorse.details.avatar_file && <HorseIcon width={35} height={35} className='text-gray-50' />}
                            </div>
                            <div className='flex flex-col'>
                              <span className='text-gray-700 font-semibold'>
                                {foundHorse.details.display_name}{' '}
                                {foundHorse.details.sex ? <span className='font-light'>({gender(foundHorse.details.sex, t)})</span> : ''}
                              </span>
                              <span>
                                {t('EULN', 'EULN')}
                                {':'} {foundHorse.details.UELN}
                              </span>
                              <span>
                                {t('chip_nr', 'Chip Nr')}
                                {':'} {foundHorse.details.chip_nr}
                              </span>
                            </div>
                          </div>
                          {!foundHorse.selected && (
                            <div className='flex space-x-2'>
                              <Button
                                size={ButtonSize.XSmall}
                                onClick={() => toggleSelectedHorse(true)}
                                variant={ButtonVariant.Primary}
                                type='button'
                              >
                                {t('use', 'Use')}
                              </Button>
                              <Button variant={ButtonVariant.Default} onClick={showBasicHorseModal} size={ButtonSize.XSmall} type='button'>
                                {t('view', 'View')}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='flex flex-col gap-4'>
                {!disableLocation && (
                  <DateInput
                    control={control}
                    error={fieldError('move_date')}
                    required={true}
                    label={t('date_of_arrival', 'Date of arrival')}
                    name='move_date'
                  />
                )}

                {stables.length > 1 && !disableStable && (
                  <>
                    <SelectInput
                      loading={loading}
                      error={fieldError('stable_uid')}
                      required={zodInputIsRequired<HorseDetail>(schema, 'stable_uid')}
                      nullable={true}
                      nullableValue=''
                      options={stableOptions}
                      label={t('stable', 'Stable')}
                      {...register('stable_uid', {
                        // we transform the empty value to null so we know if the user has reset the value
                        setValueAs: transformEmpty(null),
                      })}
                    />

                    <CheckboxInput
                      {...register('location_differs_from_stable')}
                      error={fieldError('location_differs_from_stable')}
                      label={t('location-differs-from-stable', 'Horse location differs from stable')}
                    />
                  </>
                )}

                {!disableLocation && (
                  <ContactInputSelect
                    className={classNames({
                      hidden: !(locationDiffersFromStableValue || stables.length === 1),
                    })}
                    loading={loading}
                    name='move_destination_uid'
                    control={control}
                    contacts={filteredContacts}
                    onCreated={newContact => {
                      setContacts(prevState => [...prevState, newContact]); // Update the list of contacts.
                      setValue('move_destination_uid', newContact.uid);
                    }}
                    label={t('location', 'Location')}
                    error={fieldError('move_destination_uid')}
                  />
                )}

                {!disableLocation && (
                  <TogggleInput
                    className={classNames({
                      hidden: !fieldIsImportIsVisible,
                    })}
                    name='move_arrival_is_import'
                    control={control}
                    error={fieldError('move_arrival_is_import')}
                    label={t('is-imported', 'Is imported')}
                    hint={t('is-imported-description', 'Is this horse imported from a different country?')}
                  />
                )}

                {!disableUsage && (
                  <div className='space-y-1'>
                    <p className='block text-sm font-medium leading-4 text-gray-600 mb-2'>{t('horse-usage', 'Usage')}</p>
                    <CheckboxInput
                      size={InputSize.XSmall}
                      {...register('use_in_care')}
                      error={fieldError('use_in_care')}
                      labelElement={
                        <div className='flex items-center gap-1'>
                          <CareBadge size={BadgeSize.Normal} />
                        </div>
                      }
                    />
                    <CheckboxInput
                      size={InputSize.XSmall}
                      {...register('use_in_sport')}
                      error={fieldError('use_in_sport')}
                      labelElement={
                        <div className='flex items-center gap-1'>
                          <SportBadge size={BadgeSize.Normal} />
                        </div>
                      }
                    />
                    <CheckboxInput
                      size={InputSize.XSmall}
                      {...register('use_in_breeding')}
                      error={fieldError('use_in_breeding')}
                      labelElement={
                        <div className='flex items-center gap-1'>
                          <BreedingBadge size={BadgeSize.Normal} />
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            {!foundHorse?.selected && (
              <Fieldset canToggle={true} legend={t('additional-horse-details', 'Additional horse details')} classNameWrapper='!mt-10'>
                {isOpen => (
                  <div className='flex flex-col md:flex-row gap-4'>
                    <div className='space-y-4 grow'>
                      <SelectInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('color')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'color')}
                        options={colors}
                        nullable={true}
                        nullableValue=''
                        label={t('color', 'Color')}
                        {...register('color', { setValueAs: transformEmptyNumber(null) })}
                      />

                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        postText='cm'
                        error={fieldError('withers_height')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'withers_height')}
                        label={t('withers-height', 'Withers height')}
                        {...register('withers_height', { setValueAs: transformEmptyNumber(null) })}
                      />

                      <DateInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        control={control}
                        error={fieldError('date_of_birth')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'date_of_birth')}
                        label={t('date_of_birth', 'Date of birth')}
                        {...register('date_of_birth', { setValueAs: transformEmptyToUndefined() })}
                      />
                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('studbook')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'studbook')}
                        label={t('studbook', 'Studbook')}
                        {...register('studbook', { setValueAs: transformEmptyToUndefined() })}
                      />
                    </div>
                    <div className='space-y-4 grow'>
                      {groups.length > 1 && (
                        <SelectInput
                          loading={loading}
                          error={fieldError('group_uid')}
                          nullable={true}
                          nullableValue=''
                          required={zodInputIsRequired<HorseDetail>(schema, 'group_uid')}
                          options={groupOptions}
                          label={t('group', 'Group')}
                          {...register('group_uid', { setValueAs: transformEmptyToUndefined() })}
                        />
                      )}
                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('sire')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'sire')}
                        label={t('sire', 'Sire')}
                        {...register('sire', { setValueAs: transformEmptyToUndefined() })}
                      />
                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('dam')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'dam')}
                        label={t('dam', 'Dam')}
                        {...register('dam', { setValueAs: transformEmptyToUndefined() })}
                      />
                      <TextInput
                        tabIndex={isOpen ? undefined : -1} // enable tabIndex for our fieldset when its open
                        error={fieldError('damsire')}
                        required={zodInputIsRequired<HorseDetail>(schema, 'damsire')}
                        label={t('damsire', 'Damsire')}
                        {...register('damsire', { setValueAs: transformEmptyToUndefined() })}
                      />
                    </div>
                  </div>
                )}
              </Fieldset>
            )}
          </div>
        </PageModalContent>
        <PageModalActions
          actions={[
            {
              disabled: loading || errorString !== undefined,
              loading: submitting,
              variant: ButtonVariant.Primary,
              text: t('save', 'Save'),
              type: 'submit',
              formId: 'addHorseForm',
            },
          ]}
        />
      </PageModal>

      {foundHorse && (
        <HorseBasicDetailsModal open={basicHorseModalIsVisible} closeModal={closeBasicHorseModal} horse={foundHorse.details} />
      )}

      {createdHorse && (
        <RvoConfirmReportModal
          isVisible={modalIsVisiblRvo}
          onReport={() => navigate(generatePath(`${AppRoutes.HorsesRvoList.path}?horse=${createdHorse.uid}`))}
          onRequestClose={closeModalRvo}
          successMessage={t('horse-successfull-created-desc', 'Your horse has been successfully created.')}
        />
      )}
    </>
  );
}

export default CreateHorseModal;
