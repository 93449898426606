import React from 'react';
import { PlanningProvider } from 'context/PlanningContext';
import ActivityList from 'components/Activities/ActivityList';

export default function ActivityHistoryPage(): JSX.Element {
  return (
    <PlanningProvider>
      <ActivityList timeType='history' componentType='page' />
    </PlanningProvider>
  );
}
