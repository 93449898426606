import { Calendar, CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useAccount } from 'context/AccountContext';
import React, { useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'ui/ButtonGroup/ButtonGroup';
import { isToday } from 'utilities/date.utilities';

interface Props {
  selectDate: Date;
  onPrevDay: () => void;
  onNextDay: () => void;
  onSelectDate: (date: Date) => void;
}

interface ReturnType {
  element: JSX.Element;
}

function useDayNavigationFilter({ selectDate, onSelectDate, onPrevDay, onNextDay }: Props): ReturnType {
  const { formatDate: formatUserDate } = useAccount();
  const { t } = useTranslation();
  const startRef = useRef<ReactDatePicker>(null);

  const selectedDayString = !isToday(selectDate) ? formatUserDate(selectDate) : t('today', 'Today');

  const element = (
    <div className='w-full flex gap-x-2 justify-between md:justify-normal'>
      <ButtonGroup
        iconOnly={true}
        items={[
          {
            onClick: onPrevDay,
            icon: <CaretLeft />,
          },
          {
            onClick: () => {
              // cause closing the datepicker will trigger this onClick event
              // we should check if the datepicker is open before opening it
              // so only open the datepicker if it's not open
              if (!startRef.current?.isCalendarOpen) {
                startRef.current?.setOpen(true);
              }
            },
            text: (
              <ReactDatePicker
                ref={startRef}
                wrapperClassName='w-2/3 md:w-auto !inline-flex items-center'
                showPopperArrow={false}
                dateFormat='dd/MM/yyyy'
                placeholderText='dd/mm/yyyy'
                onChange={date => {
                  const today = new Date();
                  if (!date) {
                    onSelectDate(today);
                    startRef.current?.setOpen(false);
                    return;
                  }
                  if (
                    date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear()
                  ) {
                    // Set to undefined for today
                    onSelectDate(today);
                  } else {
                    onSelectDate(date);
                  }

                  startRef.current?.setOpen(false);
                }}
                customInput={
                  <div className='flex gap-x-1 justify-center items-center h-full'>
                    <Calendar /> {selectedDayString}
                  </div>
                }
                // place the datapicker always on top
                popperClassName='!z-10'
                // place the picker in a portal with ID=date-picker-portal
                portalId='date-picker-portal'
                todayButton={t('today', 'Today')}
              />
            ),
          },
          {
            onClick: onNextDay,
            icon: <CaretRight />,
          },
        ]}
      />
    </div>
  );

  return { element };
}

export default useDayNavigationFilter;
