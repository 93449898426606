import React, { useCallback, useMemo } from 'react';
import { usePlanning } from 'hooks/UsePlanning';
import { ActivityHeight, DayGrid, DayGridScale } from './DayGrid';
import { DayGridHeader } from './DayGridHeader';
import { DayNowIndicator } from './DayNowIndicator';
import useRefreshingNow from 'hooks/UseRefreshingNow';
import { CalendarView, DayParts, DragDropType, GroupBy, groupByAppliedGetName, TimeScale } from 'utilities/Planning';
import { isSameDay } from 'date-fns';
import classNames from 'classnames';
import { ActivityContainerTimeScale } from './ActivityContainerTimeScale';
import ActivityContainerDayParts from './ActivityContainerDayParts';
import { useTranslation } from 'react-i18next';
import { CalendarCluster } from 'context/Calendar';
import { AvailableWeekdaysEnum, Facility } from 'openapi';
import { parseTime } from 'utilities/date.utilities';

export interface Props {
  columnHeaderWidth: number;
  calendarView: CalendarView;
}

/** Header for the single day layout. Where calendar clusters are placed on the x-axis */
export function DayViewGroupByHeader({ columnHeaderWidth, calendarView }: Props): JSX.Element {
  const { now } = useRefreshingNow();
  const { t } = useTranslation();

  const day = calendarView?.current.days[0];
  if (!day) {
    return <></>;
  }
  const isToday = isSameDay(day, now);

  return (
    <div className='flex'>
      <div className='shrink flex justify-stretch'>
        <DayGridHeader columnHeaderWidth={columnHeaderWidth} className='border-r pb-1 grow' highlight={isToday} day={day} />
      </div>
      <div className='grow flex'>
        {calendarView.current.clusters.map(cluster => {
          return (
            <div key={cluster.id} className='select-none w-full flex flex-col items-center justify-end text-gray-600 h-full pb-1'>
              <p
                style={{ writingMode: calendarView.current.clusters.length > 10 ? 'vertical-rl' : 'inherit' }}
                className={classNames('text-sm break-all max-h-24 line-clamp-1')}
              >
                {groupByAppliedGetName(t, cluster.groupBy)}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );

  return <></>;
}

export interface BodyProps {
  columnHeaderWidth: number;
  calendarView: CalendarView;
  // On activity save from blue print modal
  onSaved?: () => Promise<void>;
}

/**
 * A single day layout. It places the calendar clusters (horse, staff, etc) on the x-axis and time on the y-axis
 */
export function DayBody({ columnHeaderWidth, calendarView, onSaved }: BodyProps): JSX.Element {
  const { timeScale, dayParts, workingHours, hideNonWorkingHours } = usePlanning();
  const { now } = useRefreshingNow();

  /** Calculate the height of each day part. This differs for each view type.
   * For time scale view we divide into one hour blocks.
   * For day parts view we divide into day parts.
   * For full day view we only have one.
   * For dayparts/fullday, each height is adapted to the amount of activities (and leave one extra block for the blueprint).
   */
  const heights = useMemo((): number[] => {
    const compartment = calendarView.current.clusters[0];
    if (!compartment) {
      return [];
    }
    if (timeScale === TimeScale.TimeScale) {
      // In the timescale view we increase the ActivityHeight*2. This gives the user more space to create and view the activities during the day.
      // We can do this now because we collapse the nightly hours.
      return Array(DayParts.Hour).fill(ActivityHeight * 2);
    }
    if (timeScale === TimeScale.DayParts) {
      const heights: number[] = Array(dayParts?.length).fill(ActivityHeight);
      heights.splice(
        0,
        calendarView.current.maxActivitiesInADayPart.length,
        ...calendarView.current.maxActivitiesInADayPart.map(count => (count + 1) * ActivityHeight),
      );
      return heights;
    }
    if (timeScale === TimeScale.FullDay) {
      return [calendarView.current.maxActivitiesInADay * ActivityHeight + ActivityHeight];
    }
    return [];
  }, [timeScale, calendarView, dayParts]);

  // Returns which day sections/parts should be visible.
  // This is done based in the working hours from usePlanning.
  // The hidden hours can be expanded by the user, see `hideNonWorkingHours`.
  const visibleRange = useMemo(() => {
    if (workingHours && hideNonWorkingHours && timeScale === TimeScale.TimeScale) {
      return { start: workingHours.from.hours, end: workingHours.to.hours };
    }
  }, [workingHours, hideNonWorkingHours, timeScale]);

  // On which moments of can we actually add activities.
  // This is used for the opening-hours of a facility.
  // We return the weekdays that the facility is available.
  // But also the day sections/parts where the facility is available.
  const plannableRange = useCallback(
    (calendarCluster: CalendarCluster): { start?: number; end?: number; weekdays: Array<AvailableWeekdaysEnum> } | undefined => {
      if (calendarCluster.groupBy.groupBy !== GroupBy.Facility) {
        return undefined;
      }
      const facility = calendarCluster.groupBy.subject as Facility;
      let plannableRange: { start?: number; end?: number; weekdays: Array<AvailableWeekdaysEnum> } | undefined;

      if (facility.available_from || facility.available_to || facility.available_weekdays.length) {
        plannableRange = { weekdays: facility.available_weekdays };
        if (facility.available_from) {
          const time = parseTime(facility.available_from);
          plannableRange.start = time.hours + (time.minutes > 0 ? time.minutes / 60 : 0);
        }
        if (facility.available_to) {
          const time = parseTime(facility.available_to);
          plannableRange.end = time.hours + (time.minutes > 0 ? time.minutes / 60 : 0);
        }
      }
      return plannableRange;
    },
    [],
  );

  const day = calendarView?.current.days[0];
  if (!day) {
    return <></>;
  }

  // All heights together.
  const visibleHeights = heights.slice(visibleRange?.start ?? 0, visibleRange?.end ?? heights.length);
  const fullVisibleHeight = visibleHeights.reduce((partialSum, height) => partialSum + height, 0);
  const fullHeight = heights.reduce((partialSum, height) => partialSum + height, 0);

  return (
    <div className='flex'>
      <div
        style={{
          width: columnHeaderWidth,
          height: fullVisibleHeight,
        }}
        className='flex h-full justify-end border-r'
      >
        {timeScale !== TimeScale.FullDay && (
          <div
            className='overflow-hidden flex'
            style={{
              height: fullVisibleHeight,
            }}
          >
            <div style={{ height: fullHeight }}>
              <DayGridScale
                className='h-full border-b'
                heights={heights}
                dayParts={timeScale === TimeScale.DayParts ? dayParts : undefined}
                visibleRange={visibleRange}
              />
            </div>
            <DayGrid className={`h-full w-2`} heights={heights} visibleRange={visibleRange} />
          </div>
        )}
      </div>
      {calendarView.current.clusters.map(compartment => (
        <div className='h-full grow' key={compartment.groupBy.subject?.uid}>
          <DayGrid className='h-full w-full border-r' heights={heights} visibleRange={visibleRange}>
            {timeScale === TimeScale.TimeScale && (
              <>
                <DayNowIndicator visibleRange={visibleRange} heights={heights} day={day} now={now} className='absolute inset-0' />
                <ActivityContainerTimeScale
                  dragDropType={DragDropType.Disabled}
                  heights={heights}
                  visibleRange={visibleRange}
                  plannableRange={plannableRange(compartment)}
                  day={day}
                  className='absolute inset-0'
                  activities={compartment.columns[0].activities}
                  appliedGroupBy={compartment.groupBy}
                  showContactAvatar={compartment.groupBy.groupBy === GroupBy.Horse}
                  showHorseName={compartment.groupBy.groupBy === GroupBy.Staff}
                  blockList={compartment.columns[0].blockList}
                  onSaved={onSaved}
                />
              </>
            )}
            {timeScale !== TimeScale.TimeScale && (
              <ActivityContainerDayParts
                spacious={false}
                dragDropType={DragDropType.DayPart}
                heights={heights}
                day={day}
                className='absolute inset-0'
                activities={compartment.columns[0].activities}
                appliedGroupBy={compartment.groupBy}
                showContactAvatar={compartment.groupBy.groupBy === GroupBy.Horse}
                showHorseName={compartment.groupBy.groupBy === GroupBy.Staff}
                onSaved={onSaved}
              />
            )}
          </DayGrid>
        </div>
      ))}
    </div>
  );
}
