import ApiErrorParser from 'api/ApiErrorParser';
import SplashWrapper, { WrapperStyle } from 'components/Common/Splash/Wrapper';
import { AccountService, PasswordResetConfirm } from 'openapi';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'ui/Button';
import { ButtonSize, ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { TextInput } from 'ui/Inputs';
import Inform from 'ui/Layout/Inform';
import { schemas } from 'openapi/zod-schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import useFormError from 'api/hooks/useFormError';
import { transformEmptyToUndefined } from 'utilities/zod';

export default function ConfirmPasswordReset(): JSX.Element {
  const [success, setSuccess] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | null>(null);
  const [uid, setUid] = useState<string | null>(null);

  const { t } = useTranslation();

  const schema = useMemo(() => {
    return schemas.PasswordResetConfirm.pick({ new_password1: true, new_password2: true }).refine(
      data => data.new_password1 === data.new_password2,
      {
        message: t('password-mismatch', "Passwords don't match"),
        path: ['new_password2'],
      },
    );
  }, [t]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetConfirm>({
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  const onSubmit = async (data: PasswordResetConfirm) => {
    // safety check, normally we catch this before but just in case
    if (!token || !uid) return console.error('Token or uid is not defined');

    const promise = AccountService.apiV5PasswordResetConfirmCreate({
      requestBody: {
        ...data,
        token,
        uid,
      },
    });
    try {
      setSubmitting(true);
      await promise;
      setSuccess(true);
    } catch (e) {
      setApiError(new ApiErrorParser<PasswordResetConfirm>(e));
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Set the values of the token and uid from the URL
   */
  useEffect(() => {
    const token = searchParams.get('token');
    const uid = searchParams.get('uid');

    if (token && uid) {
      setToken(token);
      setUid(uid);
    }
  }, [searchParams]);

  return (
    <SplashWrapper
      styleType={WrapperStyle.Anonymous}
      title={success ? t('reset-password-success-title', 'Your password is updated') : t('reset-password-title', 'Choose a new password')}
    >
      {success && (
        <div className='mt-8 md:mt-12'>
          <Inform
            type='success'
            message={t('reset-password-success', 'We have updated your password. Please login with your new password.')}
          />
        </div>
      )}

      {(!token || !uid) && !success && (
        <ErrorSection
          className='mb-4'
          errors={t(
            'password-reset-url-invalid',
            'Your password reset URL is invalid. Did you use the correct URL from the email we just sent you?',
          )}
        />
      )}

      {token && uid && !success && (
        <form className='flex flex-col mt-8 gap-4' noValidate={true} id='resetPassword' onSubmit={handleSubmit(onSubmit)}>
          <ErrorSection className='mb-4' errors={nonFieldErrors} />

          <div className='flex flex-col gap-8'>
            <TextInput
              label={t('new-password', 'New password')}
              autoFocus={true}
              required={true}
              autoComplete='new-password'
              {...register('new_password1', { setValueAs: transformEmptyToUndefined() })}
              error={fieldError('new_password1')}
              type='password'
            />
            <TextInput
              label={t('new-password-confirm', 'Confirm password')}
              required={true}
              autoComplete='new-password'
              {...register('new_password2', { setValueAs: transformEmptyToUndefined() })}
              error={fieldError('new_password2')}
              type='password'
            />

            <div className='flex flex-col items-stretch'>
              <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} loading={submitting} type='submit'>
                {t('save-password', 'Save password')}
              </Button>
            </div>
          </div>
          <div className='text-center border-t pt-2 text-sm mt-4'>
            <span className='text-gray-400'>
              {t('reset-password-hint', 'Please contact us if you have difficulties resetting your password.')}
            </span>
          </div>
        </form>
      )}
    </SplashWrapper>
  );
}
