import { zodResolver } from '@hookform/resolvers/zod';
import ApiErrorParser from 'api/ApiErrorParser';
import useFormError from 'api/hooks/useFormError';
import { useOrganization } from 'context/OrganizationContext';
import { ActivityType, ActivityTypeCategoryEnum, ActivitytypesService, Role } from 'openapi';
import { schemas } from 'openapi/zod-schemas';
import React, { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'ui/Button';
import { ErrorSection } from 'ui/Error';
import { SelectInput, TextInput } from 'ui/Inputs';
import MultiSelectInput from 'ui/Inputs/MultiSelectInput';
import { OptionItemInterface } from 'ui/Inputs/SelectInput';
import { PageModal } from 'ui/Modals';
import { PageModalActions, PageModalContent, PageModalTitle, PageModalWidth } from 'ui/Modals/PageModal';
import { getActivityTypeCategory } from 'utilities/Planning';
import { transformEmptyNumber, transformEmptyToUndefined } from 'utilities/zod';

interface Props {
  activityType?: ActivityType;
  isVisible: boolean;
  onRequestClose: () => void;
  onSaved: (activityType: ActivityType) => void;
  roles: Role[];
}

export default function SaveActivityTypeModal({ isVisible, onRequestClose, activityType, onSaved, roles }: Props): JSX.Element {
  const { selectedOrganization } = useOrganization();
  const { t } = useTranslation();

  // Form validation
  const schema = useMemo(() => {
    return schemas.ActivityType.pick({
      name: true,
      category: true,
      notify_before: true,
      possible_roles: true,
    }).required({
      name: true,
      category: true,
    });
  }, []);

  const defaultValues = useMemo((): Partial<ActivityType> => {
    return activityType ?? {};
  }, [activityType]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    clearErrors,
  } = useForm<ActivityType>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { fieldError, nonFieldErrors, setApiError } = useFormError(schema, errors);

  /**
   * Function invoked after the modal has been closed
   */
  const onClosed = () => {
    // clear the errors
    clearErrors();
    setApiError(undefined);
    reset();
  };

  /**
   * Define the page title based on the selected group
   */
  const pageTitle = useMemo(() => {
    return activityType ? t('edit-activity-type', 'Edit activity type') : t('add-activity-type', 'Add activity type');
  }, [activityType, t]);

  const categoryOptions = [
    { id: ActivityTypeCategoryEnum.BREEDING, name: getActivityTypeCategory(t, ActivityTypeCategoryEnum.BREEDING) },
    { id: ActivityTypeCategoryEnum.CARE, name: getActivityTypeCategory(t, ActivityTypeCategoryEnum.CARE) },
    { id: ActivityTypeCategoryEnum.SPORT, name: getActivityTypeCategory(t, ActivityTypeCategoryEnum.SPORT) },
  ];

  const roleOptions: OptionItemInterface[] = (roles ?? []).map(role => ({
    id: role.uid,
    name: role.name ?? '',
  }));

  /**
   * Submit event handler, update the data via the API
   */
  const onSubmit: SubmitHandler<ActivityType> = async (data: Partial<ActivityType>) => {
    if (!selectedOrganization) return;

    try {
      let updatedActivityType: ActivityType;

      // update if we deal with an existing horse group, otherwise create a new one
      if (activityType) {
        const promise = ActivitytypesService.activitytypesPartialUpdate({
          organisationUid: selectedOrganization.uid,
          uid: activityType.uid,
          requestBody: {
            name: data.name,
            category: data.category,
            notify_before: data.notify_before,
            possible_roles: data.possible_roles,
            hidden: false,
          },
        });
        updatedActivityType = await promise;
      } else {
        const promise = ActivitytypesService.activitytypesCreate({
          organisationUid: selectedOrganization.uid,
          requestBody: {
            name: data.name,
            category: data.category,
            notify_before: data.notify_before,
            possible_roles: data.possible_roles,
          } as ActivityType,
        });
        updatedActivityType = await promise;
      }

      // fire the onSaved event
      onSaved(updatedActivityType);

      // close the modal
      onRequestClose();
    } catch (error) {
      setApiError(new ApiErrorParser<ActivityType>(error));
    }
  };

  /**
   * Reset the form when the default values change
   */
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, isVisible, reset]);

  return (
    <PageModal
      open={isVisible}
      parentElement='form'
      parentProps={{ id: 'saveActivityType', noValidate: true, onSubmit: handleSubmit(onSubmit) }}
      onClosed={onClosed}
      width={PageModalWidth.Sm}
    >
      <PageModalTitle title={pageTitle} onClose={onRequestClose} />
      <PageModalContent>
        <ErrorSection className='mb-4' errors={nonFieldErrors} />

        <div className='py-3 space-y-4'>
          <TextInput
            required={true}
            label={t('name', 'Name')}
            error={fieldError('name')}
            {...register('name', { setValueAs: transformEmptyToUndefined() })}
          />
          <SelectInput
            nullable={true}
            required={true}
            label={t('category', 'Category')}
            options={categoryOptions}
            error={fieldError('category')}
            {...register('category', { setValueAs: transformEmptyToUndefined() })}
          />
          <TextInput
            type='number'
            step='1'
            label={t('days-between-reminder', 'Days between reminder and planned activity')}
            {...register('notify_before', { setValueAs: transformEmptyNumber(undefined) })}
            postText={t('days', 'days')}
            error={fieldError('notify_before')}
          />
          <MultiSelectInput<ActivityType>
            name='possible_roles'
            control={control}
            error={fieldError('possible_roles')}
            label={t('possible-roles', 'Roles')}
            hint={t('possible-roles-hint', 'Select the roles that can execute this activity type')}
            options={roleOptions}
            showToolbar={false}
          />
        </div>
      </PageModalContent>
      <PageModalActions
        actions={[
          {
            disabled: isSubmitting,
            onClick: onRequestClose,
            variant: ButtonVariant.Default,
            type: 'button',
            text: t('cancel', 'Cancel'),
          },
          {
            formId: 'saveActivityType',
            loading: isSubmitting,
            variant: ButtonVariant.Primary,
            type: 'submit',
            text: activityType?.hidden ? t('save-and-enable', 'Save and enable') : t('save', 'Save'),
          },
        ]}
      />
    </PageModal>
  );
}
